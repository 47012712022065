<section class="content-header" style="padding: 0.5% !important">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/mark']">Điểm môn học</a>
          </li>
          <li class="breadcrumb-item active">Nhập điểm thi</li>
          &nbsp;
          <li class="{{ TitleClass }}"></li>
        </ol>
      </div>
      <div class="col-sm-6 float-sm-right">
        <button
          (click)="ExportTOExcel()"
          style="float: right"
          class="btn btn-sm btn-info"
        >
          <i class="fas fa-file-download"></i> Xuất Excel
        </button>
        <button
          *ngIf="Enable"
          class="btn btn-sm btn-danger"
          style="float: right; margin-right: 10px"
          (click)="KhoaDiem()"
        >
          <i class="fas fa-lock"></i> Khóa
        </button>
        <button
          (click)="Publish(false)"
          style="float: right; margin-right: 10px"
          class="btn btn-sm btn-warning"
          *ngIf="CongBoDiemDiemEnable"
        >
          <i class="fas fa-times"></i> Huỷ công bố
        </button>
        <button
        *ngIf="CongBoDiemDiemEnable"
          (click)="Publish(true)"
          style="float: right; margin-right: 10px"
          class="btn btn-sm btn-info"
        >
          <i class="fas fa-bullhorn"></i> Công bố
        </button>
        <button
          *ngIf="Enable"
          style="float: right; margin-right: 10px"
          class="btn btn-sm btn-success"
          (click)="Save()"
        >
          <i class="fas fa-save"></i> Lưu
        </button>
        <select
          class="btn btn-sm"
          (change)="LanThiChange()"
          id="Lan_thi"
          style="background-color: lightgray; float: right; margin-right: 20px"
        >
          <option *ngFor="let number of SoLanThiLai" value="{{ number }}">
            {{ number }}
          </option>
        </select>
        <span style="font-size: 15px; float: right"> Lần thi :&nbsp;</span>
        <select
          class="btn btn-sm"
          (change)="LanHocChange()"
          [(ngModel)]="Lan_hoc"
          id="Lan_hoc"
          style="background-color: lightgray; float: right; margin-right: 20px"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
        <span style="font-size: 15px; float: right">Lần học :&nbsp;</span>
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body" style="padding: 0px 20px 0px 20px">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row pt10">
            <div class="col-md-8">
              <label class="col-md-10">Môn học : {{ TitleMon }}</label>
            </div>
            <div class="col-md-4">
              <label class="col-md-10"
                >Học kỳ : {{ TitleHocKy }} - Năm học : {{ TitleNamHoc }}
              </label>
            </div>
            <div class="col-md-12">
              <label class="col-md-10">Lớp : {{ TitleLop }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
                style="height: 70vh"
              >
                <table
                  class="table table-striped table-bordered dataTables_wrapper no-footer"
                  id="lstStudents"
                  (keydown)="onKeydown($event)"
                ></table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card-body -->
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row pt10">
            <div class="col-md-12 text-center">
              <button
                (click)="ExportTOExcel()"
                style="float: right"
                class="btn btn-sm btn-info"
              >
                <i class="fas fa-file-download"></i> Xuất Excel
              </button>
              <button
                *ngIf="Enable"
                class="btn btn-sm btn-danger"
                style="float: right; margin-right: 10px"
                (click)="KhoaDiem()"
              >
                <i class="fas fa-lock"></i> Khóa
              </button>
              <button
                (click)="Publish(false)"
                style="float: right; margin-right: 10px"
                class="btn btn-sm btn-warning"
                *ngIf="CongBoDiemDiemEnable"
              >
                <i class="fas fa-times"></i> Huỷ công bố
              </button>
              <button
                (click)="Publish(true)"
                style="float: right; margin-right: 10px"
                class="btn btn-sm btn-info"
                *ngIf="CongBoDiemDiemEnable"
              >
                <i class="fas fa-bullhorn"></i> Công bố
              </button>
              <button
                *ngIf="Enable"
                style="float: right; margin-right: 10px"
                class="btn btn-sm btn-success"
                (click)="Save()"
              >
                <i class="fas fa-save"></i> Lưu
              </button>
            </div>
            <!-- /.col -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #MotaModal let-modal>
  <div class="modal-header">
    <h4 style="text-align: center">Thông tin ghi chú</h4>
  </div>
  <div class="modal-body">
    <div class="cus-box">
      <div class="row">
        <div
          class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
        >
          <table datatable class="table-bordered dataTables_wrapper no-footer">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let p of this.ClassData.KyHieus">
                <td>{{ p.Ky_hieu }}</td>
                <td>{{ p.Ten_ky_hieu }}</td>
                <td>{{ p.Co_ly_do }}</td>
                <td>{{ p.Hoc_lai }}</td>
                <td>{{ p.Khong_tinh_diem_thanh_phan }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        type="button"
        class="btn btn-md btn-danger"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
