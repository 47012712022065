import {
  Component,
  OnInit,
  ViewChild,
  Input,
  EventEmitter,
} from "@angular/core";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { common } from "src/app/app.common";
import { Router, ActivatedRoute } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { DeclarationService } from "src/app/Utils/service/declaration.service";
import { AbstractControl } from "@angular/forms";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { WorkGroupService } from "src/app/Utils/service/work-group.service";
import { WorkService } from "src/app/Utils/service/work.service";
import { ShiftService } from "src/app/Utils/service/shift.service";
import { OutSchoolService } from "src/app/Utils/service/out-school.service";
import { ClassTypeService } from "src/app/Utils/service/class-type.service";
import { HeDaoTaoService } from "src/app/Utils/service/he-dao-tao.service";
import { ExportService } from "src/app/Utils/service/export.service";
import { ClassService } from "src/app/Utils/service/class.service";
import { data } from "jquery";
import { NgxSpinnerService } from "ngx-spinner";
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, "").length) {
    control.setValue("");
  }
  return null;
}
@Component({
  selector: "app-declaration",
  templateUrl: "./declaration.component.html",
  styleUrls: ["./declaration.component.scss"],
})
export class DeclarationComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    public router: Router,
    private toastr: ToastrService,
    private DeclarationService: DeclarationService,
    private WorkGroupService: WorkGroupService,
    private WorkService: WorkService,
    private ShiftService: ShiftService,
    private OutSchoolService: OutSchoolService,
    private ClassTypeService: ClassTypeService,
    private HeDaoTaoService: HeDaoTaoService,
    private exportService: ExportService,
    private ClassService: ClassService,
    private spinner: NgxSpinnerService
  ) {}
  Filter = new FormGroup({
    Hoc_ky: new FormControl(0),
    Nam_hoc: new FormControl(
      new Date().getFullYear() + "-" + (new Date().getFullYear() + 1)
    ),
    Nhom_cong_viec: new FormControl(""),
    Ten_cong_viec: new FormControl(""),
    Tu_ngay: new FormControl(""),
    Den_ngay: new FormControl(""),
    Noi_dung: new FormControl(""),
    Ten_lop: new FormControl(""),
  });
  Insert = new FormGroup({
    ID_he: new FormControl("1", [Validators.required]),
    ID_khoa: new FormControl(""),
    Khoa_hoc: new FormControl(""),
    Hoc_ky: new FormControl(0, [Validators.required]),
    Nam_hoc: new FormControl(
      new Date().getFullYear() + "-" + (new Date().getFullYear() + 1),
      [Validators.required]
    ),
    Dot: new FormControl(1, [Validators.required]),
    Nhom_cong_viec: new FormControl(""),
    Cong_viec: new FormControl("", [Validators.required]),
    Don_vi: new FormControl(""),
    So_gio: new FormControl(1),
    ID_mon: new FormControl("", [Validators.required]),
    Tu_ngay: new FormControl("", [Validators.required]),
    Den_ngay: new FormControl("", [Validators.required]),
    Tu_tiet: new FormControl(1),
    Den_tiet: new FormControl(1),
    Loai_lop: new FormControl(""),
    ID_lop: new FormControl("", [Validators.required]),
    So_sinh_vien: new FormControl("", [Validators.required]),
    Thu: new FormControl(""),
    He_so_cong_viec: new FormControl(null),
    He_so_chuc_danh: new FormControl(null),
    He_so_he: new FormControl(null),
    He_so_loai_lop: new FormControl(null),
    He_so_ngoai_gio: new FormControl(null),
    He_so_ngoai_truong: new FormControl(null),
    He_so_lop: new FormControl(null),
    He_so_loai_mon: new FormControl(null),
  });

  InsertCV = new FormGroup({
    ID_he: new FormControl("", [Validators.required]),
    ID_khoa: new FormControl(""),
    Khoa_hoc: new FormControl(""),
    Hoc_ky: new FormControl("", [Validators.required]),
    Nam_hoc: new FormControl(
      new Date().getFullYear() + "-" + (new Date().getFullYear() + 1)
    ),
    Nhom_cong_viec: new FormControl("", [Validators.required]),
    Cong_viec: new FormControl("", [Validators.required]),
    Don_vi: new FormControl(""),
    So_gio: new FormControl(0, [Validators.required]),
    Loai_lop: new FormControl(""),
    ID_lop: new FormControl(""),
    Ten_mon: new FormControl(""),
  });
  InsertKH = new FormGroup({
    Nam_hoc: new FormControl(
      new Date().getFullYear() + "-" + (new Date().getFullYear() + 1)
    ),
    Nhom_cong_viec: new FormControl(""),
    Cong_viec: new FormControl("", [Validators.required]),
    Ten_mon: new FormControl(""),
    Don_vi: new FormControl(""),
    So_gio: new FormControl(1, [Validators.min(0)]),
    Tu_ngay: new FormControl(""),
    Den_ngay: new FormControl(""),
    Hoc_ky: new FormControl(""),
    So_nguoi_tham_gia: new FormControl(1, [Validators.min(1)]),
  });
  Edit = new FormGroup({
    Hoc_ky: new FormControl(""),
    Nam_hoc: new FormControl(""),
    Nhom_cong_viec: new FormControl(1),
    Cong_viec: new FormControl(""),
    Don_vi: new FormControl(""),
    So_gio: new FormControl(0),
    selectedItem: new FormControl(""),
    Tu_ngay: new FormControl(""),
    Den_ngay: new FormControl(""),
    Ngoai_gio: new FormControl(0),
    Loai_lop: new FormControl(""),
    Ten_lop: new FormControl(""),
    So_sinh_vien: new FormControl(""),
    Ngoai_truong: new FormControl(""),
    Loai_mon: new FormControl(""),
    ID_mon: new FormControl(""),
    ID_he: new FormControl(""),
    ID_khoa: new FormControl(""),
    Khoa_hoc: new FormControl(""),
    Dot: new FormControl(1),
    Tu_tiet: new FormControl(null),
    Den_tiet: new FormControl(null),
    So_nguoi_tham_gia: new FormControl(null),
    He_so_cong_viec: new FormControl(""),
    He_so_chuc_danh: new FormControl(""),
    He_so_he: new FormControl(""),
    He_so_loai_lop: new FormControl(""),
    He_so_ngoai_gio: new FormControl(""),
    He_so_ngoai_truong: new FormControl(""),
    He_so_lop: new FormControl(""),
    He_so_loai_mon: new FormControl(""),
  });
  isDisplay = false;
  dataClass: any;
  dataClass_ID: any;
  dataClassFilter: any;
  selectedItem: any;
  dataQuyDinh: any = "";
  dinhmucnamhoc: any = "";
  dinhmucgiangday: any = "";
  dinhmucchuyenmon: any = "";
  dinhmuchoctap: any = "";
  dinhmuckhoahoc: any = "";
  tonggiothuchien: any = "";
  dataMon = {};
  dropdownSettings: any = {};
  closeDropdownSelection = false;
  disabled: boolean;
  dataTable: any = [];
  dataHeDaoTao: any = [];
  dataNhomCongViec: any = [];
  dataCongViec: any = [];
  dataCongViecAfter: any = [];
  dataNhomCongViecAfter: any = [];
  dataNgoaiGio: any = [];
  dataLoaiLop: any = [];
  dataNgoaiTruong: any = [];
  dataHeSoMon: any = [];
  dataMonHoc: any = [];
  dataHe: any = [];
  dataKhoa: any = [];
  dataBoMon: any = [];
  dataKhoaHoc: any = [];
  dataKhoaHocAfter: any = [];
  dataLop: any = [];
  dataLopFilter: any = [];
  ClassListAll: any = [];
  Nam_hoc_Arr: any = [];
  ThoiGianKeKhai: any;
  selectItem: any;
  requestObject: RequestBase = new RequestBase();
  closeResult: string;
  submitted = false;
  submittedCV = false;
  submittedKH = false;
  ID_ngoai_gio: any;
  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtTrigger = new Subject();
  public com: common;
  Token: any;
  ID_cb: any;
  Ten_khoa: any;
  ID: any;
  ID_bm: any;
  id_mon: any;
  UserName: any;
  checkInsert: any;
  titleModal: any;
  ClassTinChi: any;
  ClassFilter: any;
  dataGV: any = [];
  Ten_lop: any;
  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
      closeDropDownOnSelection: this.closeDropdownSelection,
      idField: "ID_mon_hoc",
      textField: "Ten_mon",
    };
    this.com = new common(this.router);
    var User = this.com.getUserinfo();
    this.ID_cb = User.Info.UserID;
    this.Ten_khoa = User.Info.Ten_khoa;
    this.UserName = User.Info.UserName;
    var yearNow = new Date().getFullYear();
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + "-" + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_Arr.push(object);
      yearNow--;
    }
    $.fn["dataTable"].ext.search.push((settings, data, dataIndex) => {
      let hockydt = data[2];
      let NamHocdt = data[3];
      let Nhomcv = data[4];
      let Tencv = data[6];
      let Noidung = data[9];
      let Tenlop = data[10];
      let Tungay =
        data[12].split("/")[2] +
        "-" +
        data[12].split("/")[1] +
        "-" +
        data[12].split("/")[0];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool5 = true;
      let bool6 = true;
      let bool7 = true;
      let fillter = true;

      if (this.Filter.value.Hoc_ky > 0) {
        bool1 = this.Filter.value.Hoc_ky == hockydt;
      }
      if (
        this.Filter.value.Nam_hoc != "" ||
        this.Filter.value.Nam_hoc != null
      ) {
        bool2 = NamHocdt.includes(this.Filter.value.Nam_hoc);
      }
      if (this.Filter.value.Nhom_cong_viec > 0) {
        bool3 = Nhomcv == this.Filter.value.Nhom_cong_viec;
      }
      if (this.Filter.value.Ten_cong_viec != "") {
        bool4 = Tencv.includes(this.Filter.value.Ten_cong_viec);
      }
      if (this.Filter.value.Noi_dung != "") {
        bool5 = Noidung.includes(this.Filter.value.Noi_dung);
      }
      if (this.Filter.value.Ten_lop != "") {
        bool6 = Tenlop.includes(this.Filter.value.Ten_lop);
      }
      if (this.Filter.value.Tu_ngay != "" && this.Filter.value.Den_ngay != "") {
        bool7 =
          Tungay >= this.Filter.value.Tu_ngay &&
          Tungay <= this.Filter.value.Den_ngay;
      } else if (
        this.Filter.value.Tu_ngay != "" &&
        this.Filter.value.Den_ngay == ""
      ) {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        var day = +yyyy + "-" + mm + "-" + dd;
        bool7 =
          Tungay >= this.Filter.value.Tu_ngay &&
          Tungay <= this.Filter.value.Den_ngay;
      } else if (
        this.Filter.value.Tu_ngay == "" &&
        this.Filter.value.Den_ngay != ""
      ) {
        bool7 = Tungay >= "1999-1-1" && Tungay <= this.Filter.value.Den_ngay;
      }

      fillter = bool1 && bool2 && bool3 && bool4 && bool5 && bool6 && bool7;
      return fillter;
    });
    this.requestObject.UserName = User.Info.UserName;
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.access_token;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        { visible: false },
        {
          title: "ID",
          visible: false,
        },
        {
          title: "Hoc ky",
          visible: false,
        },
        {
          title: "Nam hoc",
          visible: false,
        },
        {
          title: "ID_nhom_cv",
          visible: false,
        },
        {
          title: "Nhóm công việc",
        },
        {
          title: "Tên công việc",
          width: "30%",
        },
        {
          title: "Số giờ/Số lượng",
        },
        {
          title: "Số giờ thực hiện",
        },
        {
          title: "Tên môn/Nội dung công việc",
          width: "40%",
        },
        {
          title: "Tên lớp",
        },
        {
          title: "Số sv",
        },
        {
          title: "Từ ngày",
        },
        {
          title: "Đến ngày",
        },
        {
          title: "Đơn giá",
        },
        {
          title: "Thành tiền",
        },
        {
          title: "Bộ môn/Khoa duyệt",
          className: "dt-center",
        },
        {
          title: "Ngày khoa duyệt",
          className: "dt-center",
        },
        {
          title: "Phòng ban duyệt",
          className: "dt-center",
        },
        {
          title: "Ngày phòng duyệt",
          className: "dt-center",
        },
        {
          title: "Đào tạo duyệt",
          className: "dt-center",
        },
        {
          title: "Ngày đào tạo duyệt",
          className: "dt-center",
        },
        {
          title: "Tài vụ duyệt",
          className: "dt-center",
        },
        {
          title: "Ngày tài vụ duyệt",
          className: "dt-center",
        },
        {
          title: "Duyệt thanh toán",
          className: "dt-center",
        },
        {
          title: "Ngày duyệt thanh toán",
          className: "dt-center",
        },
        {
          title: "Cán bộ kê khai duyệt",
          className: "dt-center",
          visible: false,
        },
        {
          title: "Lý do/Yêu cầu",
        },
        {
          title: "Thao tác",
          width: "13%",
          className: "dt-center",
        },
      ],
      order: [[0, "desc"]],
    };
    this.dataTable = [];
    this.dtTrigger.next();
    this.getList();
    this.getNhomCongViec();
    this.getListClass(this.requestObject, a.access_token);
    this.GiangVien_Load_ID();
    this.LopTinChi();
    this.ShiftService.getList(this.Token).subscribe((z) => {
      this.dataNgoaiGio = z.Data;
      this.HeDaoTaoService.getList(this.Token).subscribe((z) => {
        this.dataHeDaoTao = z.Data;
        this.ClassTypeService.getList(this.Token).subscribe((z) => {
          this.dataLoaiLop = z.Data;
          this.OutSchoolService.getList(this.Token).subscribe((z) => {
            this.dataNgoaiTruong = z.Data;
            this.DeclarationService.LoaiMon(this.Token).subscribe((z) => {
              this.dataHeSoMon = z.Data;
              let req = {
                Hoc_ky: this.Filter.value.Hoc_ky,
                Nam_hoc: this.Filter.value.Nam_hoc,
              };
              this.DeclarationService.quyDinhKeKhai(req, this.Token).subscribe(
                (z) => {
                  this.dataQuyDinh = z;
                  if (z.length < 1) {
                    this.dataQuyDinh = "Chưa quy định thời gian kê khai!";
                  } else {
                    let Tu_ngay = z[0].Tu_ngay.split("T")[0].split("-");
                    let Den_ngay = z[0].Den_ngay.split("T")[0].split("-");
                    this.dataQuyDinh =
                      [Tu_ngay[2], Tu_ngay[1], Tu_ngay[0]].join("/") +
                      " đến " +
                      [Den_ngay[2], Den_ngay[1], Den_ngay[0]].join("/");
                  }
                }
              );

              var reqdm = {
                ID_gv: this.ID_cb,
                Nam_hoc: this.Filter.value.Nam_hoc,
              };
              this.DeclarationService.QuyDinhDinhMuc(
                reqdm,
                this.Token
              ).subscribe((z) => {
                this.dinhmucnamhoc = z;
                if (z.length < 1) {
                  this.dataQuyDinh = "Chưa quy định định mức!";
                } else {
                  var dmchuyenmon = parseInt(z[0].Dinh_muc_chuyen_mon);
                  var dmhoctap = parseInt(z[0].Dinh_muc_hoc_tap);
                  var dmgiangday = parseInt(z[0].Dinh_muc_giang_day);
                  var dmkhoahoc = parseInt(z[0].Dinh_muc_khoa_hoc);
                  var dmgiamtrucv = parseInt(z[0].Dinh_muc_mien_giam_chuc_vu);
                  var dmgiamtrukhac = parseInt(z[0].Dinh_muc_mien_giam_khac);
                  this.dinhmucnamhoc =
                    dmchuyenmon +
                    dmgiangday +
                    dmkhoahoc +
                    dmhoctap -
                    dmgiamtrucv -
                    dmgiamtrukhac;
                  this.dinhmucgiangday = dmgiangday;
                  this.dinhmucchuyenmon = dmchuyenmon;
                  this.dinhmuchoctap = dmhoctap;
                  this.dinhmuckhoahoc = dmkhoahoc;
                }
              });
            });
          });
        });
      });
    });
  }
  GiangVien_Load_ID() {
    this.DeclarationService.GiangVien_Load_ID(this.ID_cb, this.Token).subscribe(
      (z) => {
        this.dataGV = z;
        this.ID_ngoai_gio = this.dataGV[0].ID_don_vi_quan_ly;
      }
    );
  }
  changeTiet() {
    let sotiet = this.Insert.value.Den_tiet - this.Insert.value.Tu_tiet + 1;
    this.Insert.get("So_gio").setValue(sotiet);
    let sotietcv =
      this.InsertCV.value.Den_tiet - this.InsertCV.value.Tu_tiet + 1;
    this.InsertCV.get("So_gio").setValue(sotietcv);
  }
  changeNhomCongViecGD() {
    this.dataCongViec = this.dataCongViecAfter.filter(
      (x) => x.ID_nhom_cong_viec == this.Insert.value.Nhom_cong_viec
    );
  }
  changeCongViec() {
    let data = this.dataCongViec.filter(
      (x) => x.ID_cong_viec == this.Insert.value.Cong_viec
    );
    this.Insert.get("Don_vi").setValue(data[0].Don_vi_tinh);
  }
  changeNhomCongViecCM() {
    this.dataCongViec = this.dataCongViecAfter.filter(
      (x) => x.ID_nhom_cong_viec == this.InsertCV.value.Nhom_cong_viec
    );
  }
  changeNhomCongViecKH() {
    this.dataCongViec = this.dataCongViecAfter.filter(
      (x) => x.ID_nhom_cong_viec == this.InsertKH.value.Nhom_cong_viec
    );
  }
  changeCongViecCV() {
    let data = this.dataCongViec.filter(
      (x) => x.ID_cong_viec == this.InsertCV.value.Cong_viec
    );
    this.InsertCV.get("Don_vi").setValue(data[0].Don_vi_tinh);
  }
  changeCongViecKH() {
    let data = this.dataCongViec.filter(
      (x) => x.ID_cong_viec == this.InsertKH.value.Cong_viec
    );
    this.InsertKH.get("Don_vi").setValue(data[0].Don_vi_tinh);
  }
  open(content, sizm, Loai, Data) {
    this.selectItem = Data;
    this.getHeKhoa();
    this.ID = Data.ID_cv_gd;
    this.submitted = false;
    this.submittedCV = false;
    this.submittedKH = false;
    if (Loai == "Add") {
      this.isDisplay = false;
      this.titleModal = "Kê khai giờ lên lớp";
      this.checkInsert = true;
      this.WorkGroupService.getList(this.Token).subscribe((z) => {
        this.dataNhomCongViec = z.Data.filter(
          (x) => x.Ma_nhom_cong_viec == "GD"
        );
        this.dataNhomCongViecAfter = this.dataNhomCongViec;
        this.WorkService.getList(this.Token).subscribe((z) => {
          this.dataCongViec = z.Data;
          this.dataCongViecAfter = z.Data;
        });
        let req = {
          UserName: this.com.getUserinfo().Info.UserName,
          Hoc_ky: this.Insert.value.Hoc_ky,
          Nam_hoc: this.Insert.value.Nam_hoc,
        };
        this.DeclarationService.getListMonHoc(req, this.Token).subscribe(
          (z) => {
            this.dataMonHoc = z.Data;
            // this.dataMonHoc = this.dataMonHoc.filter(i => i.Hoc_ky == this.Insert.value.Hoc_ky);
            // this.dataMonHoc = this.dataMonHoc.filter(i => i.Nam_hoc == this.Insert.value.Nam_hoc);
            // this.dataMonHoc = this.dataMonHoc.filter(i => i.Dot == this.Insert.value.Dot);
          }
        );
      });
      this.Insert.get("Hoc_ky").setValue(this.Filter.value.Hoc_ky);
      this.Insert.get("Nam_hoc").setValue(this.Filter.value.Nam_hoc);
      this.Insert.get("Don_vi").setValue("");
      this.Insert.get("Cong_viec").setValue("");
      this.Insert.get("So_gio").setValue(0);
      this.Insert.get("Tu_ngay").setValue("");
      this.Insert.get("Den_ngay").setValue("");
      this.Insert.get("ID_he").setValue("");
      this.Insert.get("ID_khoa").setValue("");
      this.Insert.get("Khoa_hoc").setValue("");
      this.Insert.get("Loai_lop").setValue("");
      this.Insert.get("ID_lop").setValue("");
      this.Insert.get("So_sinh_vien").setValue(0);
      this.Insert.get("ID_mon").setValue("");
      this.Insert.get("Tu_tiet").setValue(1);
      this.Insert.get("Den_tiet").setValue(1);
      this.Insert.get("Thu").setValue("");
      this.Insert.get("He_so_cong_viec").setValue(0);
      this.Insert.get("He_so_chuc_danh").setValue(0);
      this.Insert.get("He_so_he").setValue(0);
      this.Insert.get("He_so_loai_lop").setValue(0);
      this.Insert.get("He_so_ngoai_gio").setValue(0);
      this.Insert.get("He_so_ngoai_truong").setValue(0);
      this.Insert.get("He_so_lop").setValue(0);
      this.Insert.get("He_so_loai_mon").setValue(0);
    } else if (Loai == "AddKH") {
      this.checkInsert = true;
      this.titleModal = "Thêm mới công việc nghiên cứu khoa học";
      this.InsertKH.get("So_gio").setValue(1);
      this.InsertKH.get("So_nguoi_tham_gia").setValue(1);
      this.WorkGroupService.getList(this.Token).subscribe((z) => {
        this.dataNhomCongViec = z.Data.filter(
          (x) => x.Ma_nhom_cong_viec == "KH"
        );
      });
      this.WorkService.getList(this.Token).subscribe((z) => {
        this.dataCongViec = z.Data;
        this.dataCongViecAfter = z.Data;
      });
    } else if (Loai == "AddCV") {
      this.getHeKhoa();
      this.checkInsert = true;
      this.titleModal = "Ra đề, chấm bài, trông thi, KLTN ";
      this.WorkGroupService.getList(this.Token).subscribe((z) => {
        this.dataNhomCongViec = z.Data.filter(
          (x) => x.Ma_nhom_cong_viec == "CM"
        );
        this.WorkService.getList(this.Token).subscribe((z) => {
          this.dataCongViec = z.Data;
          this.dataCongViecAfter = z.Data;
        });
      });
      let req = {
        UserName: this.com.getUserinfo().Info.UserName,
      };
      this.InsertCV.get("Nhom_cong_viec").setValue("");
      this.InsertCV.get("Cong_viec").setValue("");
      this.InsertCV.get("Don_vi").setValue("");
      this.InsertCV.get("So_gio").setValue(0);
      this.InsertCV.get("ID_he").setValue("");
      this.InsertCV.get("Loai_lop").setValue("");
      this.InsertCV.get("ID_lop").setValue("");
      this.InsertCV.get("ID_khoa").setValue("");
      this.InsertCV.get("Khoa_hoc").setValue("");
      this.InsertCV.get("Hoc_ky").setValue(1);
      this.InsertCV.get("Nam_hoc").setValue(this.Filter.value.Nam_hoc);
      this.InsertCV.get("Ten_mon").setValue("");
      this.filterLopTinChi();
      this.dataCongViec = [];
    } else if (Loai == "Edit") {
      this.isDisplay = true;
      this.titleModal = "Sửa kê khai giờ dạy";
      this.checkInsert = false;
      this.WorkGroupService.getList(this.Token).subscribe((z) => {
        this.dataNhomCongViec = z.Data.filter(
          (x) => x.Ma_nhom_cong_viec == "GD"
        );
        this.WorkService.getList(this.Token).subscribe((z) => {
          this.dataCongViec = z.Data;
          this.dataCongViecAfter = this.dataCongViec;
          this.Insert.get("Don_vi").setValue(this.dataCongViec[0].Don_vi_tinh);
          let req = {
            UserName: this.com.getUserinfo().Info.UserName,
            Hoc_ky: this.Insert.value.Hoc_ky,
            Nam_hoc: this.Insert.value.Nam_hoc,
          };
          this.DeclarationService.getListMonHoc(req, this.Token).subscribe(
            (z) => {
              this.dataMonHoc = z.Data;
            }
          );
        });
      });
      this.changeDanhSachLop(null,null);
      this.Insert.get("Hoc_ky").setValue(Data.Hoc_ky);
      this.Insert.get("Nam_hoc").setValue(Data.Nam_hoc);
      this.Insert.get("So_gio").setValue(Data.So_gio_goc);
      this.Insert.get("Tu_ngay").setValue(
        !Data.Tu_ngay ? "" : Data.Tu_ngay.split("T")[0]
      );
      this.Insert.get("Den_ngay").setValue(
        !Data.Den_ngay ? "" : Data.Den_ngay.split("T")[0]
      );
      this.Insert.get("ID_he").setValue(Data.ID_he);
      this.Insert.get("ID_khoa").setValue(Data.ID_khoa);
      this.Insert.get("Khoa_hoc").setValue(Data.Khoa_hoc);
      this.Insert.get("Cong_viec").setValue(Data.ID_cong_viec);
      this.Insert.get("Nhom_cong_viec").setValue(Data.ID_nhom_cong_viec);
      this.Insert.get("Loai_lop").setValue(Data.ID_loai_lop);
      this.Insert.get("ID_lop").setValue(Data.ID_lop);
      this.Insert.get("So_sinh_vien").setValue(Data.So_sv);
      this.Insert.get("ID_mon").setValue(Data.ID_mon);
      this.Insert.get("Tu_tiet").setValue(Data.Tu_tiet);
      this.Insert.get("Den_tiet").setValue(Data.Den_tiet);
      this.Insert.get("Thu").setValue(Data.Thu);
      this.Insert.get("He_so_cong_viec").setValue(Data.He_so_cong_viec);
      this.Insert.get("He_so_chuc_danh").setValue(Data.He_so_chuc_danh);
      this.Insert.get("He_so_he").setValue(Data.He_so_he);
      this.Insert.get("He_so_loai_lop").setValue(Data.He_so_loai_lop);
      this.Insert.get("He_so_ngoai_gio").setValue(Data.He_so_ngoai_gio);
      this.Insert.get("He_so_ngoai_truong").setValue(Data.He_so_ngoai_truong);
      this.Insert.get("He_so_lop").setValue(Data.He_so_lop);
      this.Insert.get("He_so_loai_mon").setValue(Data.He_so_mon);
      this.Ten_lop = Data.Ten_lop;
    } else if (Loai == "EditCV") {
      this.getClassListAll();
      this.checkInsert = false;
      this.titleModal = "Ra đề, chấm bài, trông thi, KLTN";
      this.WorkGroupService.getList(this.Token).subscribe((z) => {
        this.dataNhomCongViec = z.Data.filter(
          (x) => x.Ma_nhom_cong_viec == "CM"
        );
        this.WorkService.getList(this.Token).subscribe((z) => {
          this.dataCongViec = z.Data;
          this.InsertCV.get("Cong_viec").setValue(Data.ID_cong_viec);
          let data = this.dataCongViec.filter(
            (x) => x.ID_cong_viec == this.InsertCV.value.Cong_viec
          );
          this.InsertCV.get("Don_vi").setValue(data[0].Don_vi_tinh);
          this.InsertCV.get("Hoc_ky").setValue(Data.Hoc_ky);
          this.InsertCV.get("Nam_hoc").setValue(Data.Nam_hoc);
          this.InsertCV.get("Nhom_cong_viec").setValue(Data.ID_nhom_cong_viec);
          this.InsertCV.get("So_gio").setValue(Data.So_gio_goc);
          this.InsertCV.get("ID_he").setValue(Data.ID_he);
          this.InsertCV.get("ID_khoa").setValue(Data.ID_khoa);
          this.InsertCV.get("Khoa_hoc").setValue(Data.Khoa_hoc);
          this.InsertCV.get("Loai_lop").setValue(Data.ID_loai_lop);
          this.InsertCV.get("ID_lop").setValue(Data.ID_lop);
          this.InsertCV.get("Ten_mon").setValue(Data.Ten_mon);
          this.changeDanhSachLop(0,Data.ID_lop);
          if ((this.InsertCV.value.ID_lop = 0)) {
            this.Ten_lop = "";
          } else {
            this.Ten_lop = Data.Ten_lop;
          }
        });
      });
    } else if (Loai == "EditKH") {
      this.getClassListAll();
      this.checkInsert = false;
      this.titleModal = "Sửa kê khai công việc nghiên cứu khoa học";
      this.WorkGroupService.getList(this.Token).subscribe((z) => {
        this.dataNhomCongViec = z.Data.filter(
          (x) => x.Ma_nhom_cong_viec == "KH"
        );
        this.WorkService.getList(this.Token).subscribe((z) => {
          this.dataCongViec = z.Data;
          this.dataCongViecAfter = z.Data;
          this.InsertKH.get("Cong_viec").setValue(Data.ID_cong_viec);
          let data = this.dataCongViec.filter(
            (x) => x.ID_cong_viec == this.InsertKH.value.Cong_viec
          );
          this.InsertKH.get("Don_vi").setValue(data[0].Don_vi_tinh);
          this.InsertKH.get("Nam_hoc").setValue(Data.Nam_hoc);
          this.InsertKH.get("Nhom_cong_viec").setValue(Data.ID_nhom_cong_viec);
          this.InsertKH.get("So_gio").setValue(Data.So_gio_goc);
          this.InsertKH.get("So_nguoi_tham_gia").setValue(
            Data.So_nguoi_tham_gia
          );
          this.InsertKH.get("Ten_mon").setValue(Data.Ten_mon);
          this.InsertKH.get("Hoc_ky").setValue(Data.Hoc_ky);
          this.InsertKH.get("Tu_ngay").setValue(
            Data.Tu_ngay == null ? "" : Data.Tu_ngay.split("T")[0]
          );
          this.InsertKH.get("Den_ngay").setValue(
            Data.Den_ngay == null ? "" : Data.Den_ngay.split("T")[0]
          );
          this.changeDanhSachLop(0, Data.ID_lop);
        });
      });
    }
    // else if (Loai == 'Detail') {
    //   this.WorkService.getList(this.Token).subscribe(z => {
    //     this.dataCongViec = z.Data
    //     this.dataCongViecAfter =  z.Data
    //     let data = this.dataCongViec.filter(x => x.ID_cong_viec == Data.ID_cong_viec)
    //     this.Insert.get('Don_vi').setValue(data[0].Don_vi_tinh);
    //     let req = {
    //       UserName: this.com.getUserinfo().Info.UserName
    //     }
    //     this.DeclarationService.getListMonHoc(req, this.Token).subscribe(z => {
    //       this.dataMonHoc = z.Data
    //     })
    //   });
    //   this.Insert.get('Hoc_ky').setValue(Data.Hoc_ky);
    //   this.Insert.get('Nam_hoc').setValue(Data.Nam_hoc);
    //   let req = {
    //     ID_he: this.Insert.value.ID_he ? this.Insert.value.ID_he : '',
    //     ID_khoa: this.Insert.value.ID_khoa ? this.Insert.value.ID_khoa : '',
    //     Khoa_hoc: this.Insert.value.Khoa_hoc ? this.Insert.value.Khoa_hoc : '',
    //     Dot: this.Insert.value.Dot ? this.Insert.value.Dot : '',
    //     Hoc_ky: this.Insert.value.Hoc_ky ? this.Insert.value.Hoc_ky : '',
    //     Nam_hoc: this.Insert.value.Nam_hoc ? this.Insert.value.Nam_hoc : '',
    //   }
    //   this.DeclarationService.getDanhSachLop(req, this.com.getUserinfo().Info.UserName, Data.ID_mon, this.Token).subscribe(z => {
    //     this.dataLop = z.Data
    //   })
    //   this.Edit.get('So_gio').setValue(Data.So_gio_goc);
    //   this.Edit.get('Tu_ngay').setValue(!Data.Tu_ngay ? '' : Data.Tu_ngay.split("T")[0]);
    //   this.Edit.get('Den_ngay').setValue(!Data.Den_ngay ? '' : Data.Den_ngay.split("T")[0]);
    //   this.Edit.get('Ngoai_gio').setValue(Data.ID_ngoai_gio);
    //   this.Edit.get('ID_he').setValue(Data.ID_he);
    //   this.Edit.get('ID_khoa').setValue(Data.ID_khoa);
    //   this.Edit.get('Khoa_hoc').setValue(Data.Khoa_hoc);
    //   this.Edit.get('Cong_viec').setValue(Data.ID_cong_viec);
    //   this.Edit.get('Loai_lop').setValue(Data.ID_loai_lop);
    //   this.Edit.get('Ten_lop').setValue(Data.ID_lop);
    //   this.Edit.get('So_sinh_vien').setValue(Data.So_sv);
    //   this.Edit.get('Ngoai_truong').setValue(Data.ID_ngoai_truong);
    //   this.Edit.get('Loai_mon').setValue(Data.ID_he_so_mon);
    //   this.Edit.get('ID_mon').setValue(Data.ID_mon);
    //   this.Edit.get('Tu_tiet').setValue(Data.Tu_tiet);
    //   this.Edit.get('Den_tiet').setValue(Data.Den_tiet);
    //   this.Edit.get('He_so_cong_viec').setValue(Data.He_so_cong_viec);
    //   this.Edit.get('He_so_chuc_danh').setValue(Data.He_so_chuc_danh);
    //   this.Edit.get('He_so_he').setValue(Data.He_so_he);
    //   this.Edit.get('He_so_loai_lop').setValue(Data.He_so_loai_lop);
    //   this.Edit.get('He_so_ngoai_gio').setValue(Data.He_so_ngoai_gio);
    //   this.Edit.get('He_so_ngoai_truong').setValue(Data.He_so_ngoai_truong);
    //   this.Edit.get('He_so_lop').setValue(Data.He_so_lop);
    //   this.Edit.get('He_so_loai_mon').setValue(Data.He_so_mon);
    //   this.Edit.get('so_nhom').setValue(Data.So_nhom);
    // }
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  changeLop() {
    let req = {
      ID_he: this.Insert.value.ID_he ? this.Insert.value.ID_he : "",
      ID_khoa: this.Insert.value.ID_khoa ? this.Insert.value.ID_khoa : "",
      Khoa_hoc: this.Insert.value.Khoa_hoc ? this.Insert.value.Khoa_hoc : "",
      Dot: this.Insert.value.Dot ? this.Insert.value.Dot : "",
      Hoc_ky: this.Insert.value.Hoc_ky ? this.Insert.value.Hoc_ky : "",
      Nam_hoc: this.Insert.value.Nam_hoc ? this.Insert.value.Nam_hoc : "",
      ID_mon: this.Insert.value.ID_mon ? this.Insert.value.ID_mon : "",
    };
    this.DeclarationService.getSoSv(
      this.Insert.value.ID_lop,
      this.Token
    ).subscribe((z) => {
      if (z.So_sinh_vien != null) {
        this.Insert.get("So_sinh_vien").setValue(z.So_sinh_vien);
      }

      this.DeclarationService.getDanhSachLop(
        req,
        this.UserName,
        this.id_mon,
        this.Token,
        0
      ).subscribe((z) => {
        this.dataLop = z.Data;
      });
      let data = this.dataLop;
      if (data.length != 0) {
        this.Ten_lop = data[0].Ten_lop;
        this.Insert.get("Tu_ngay").setValue(data[0].Tu_ngay.split("T")[0]);
        this.Insert.get("Den_ngay").setValue(data[0].Den_ngay.split("T")[0]);
      } else {
        this.Insert.get("Tu_ngay").setValue("");
        this.Insert.get("Den_ngay").setValue("");
      }
    });
  }


  getClassListAll(){
    let req = {
      Dot: null,
      Hoc_ky: null,
      Nam_hoc: null
    };

    this.DeclarationService.getDanhSachLop(
      req,
      this.UserName,
      0,
      this.Token,
      0
    ).subscribe((z) => {
      this.ClassListAll = z.Data;
    });
  };

  filterLopTinChi() {
    this.ClassFilter = this.ClassTinChi;
    if (this.InsertCV.value.Hoc_ky > 0) {
      this.ClassFilter = this.ClassFilter.filter(
        (i) => i.Hoc_ky == this.InsertCV.value.Hoc_ky
      );
    }

    if (this.InsertCV.value.Nam_hoc != "") {
      this.ClassFilter = this.ClassFilter.filter(
        (i) => i.Nam_hoc == this.InsertCV.value.Nam_hoc
      );
    }
  }
  changeDanhSachLop(id_mon, id_lop) {
    let req = {
      ID_he: this.Insert.value.ID_he ? this.Insert.value.ID_he : "",
      ID_khoa: this.Insert.value.ID_khoa ? this.Insert.value.ID_khoa : "",
      Khoa_hoc: this.Insert.value.Khoa_hoc ? this.Insert.value.Khoa_hoc : "",
      Dot: this.Insert.value.Dot ? this.Insert.value.Dot : "",
      Hoc_ky: this.Insert.value.Hoc_ky ? this.Insert.value.Hoc_ky : "",
      Nam_hoc: this.Insert.value.Nam_hoc ? this.Insert.value.Nam_hoc : "",
      ID_mon: this.Insert.value.ID_mon ? this.Insert.value.ID_mon : "",
      Ten_lop: this.Insert.value.Ten_lop ? this.Insert.value.Ten_lop : "",
    };

    let reqmh = {
      UserName: this.com.getUserinfo().Info.UserName,
      Hoc_ky: this.Insert.value.Hoc_ky,
      Nam_hoc: this.Insert.value.Nam_hoc,
    };

    this.DeclarationService.getListMonHoc(reqmh, this.Token).subscribe((z) => {
      this.dataMonHoc = z.Data;
      this.DeclarationService.getDanhSachLop(
        req,
        this.UserName,
        id_mon != null ? id_mon : this.Insert.value.ID_mon,
        this.Token,
        id_lop
      ).subscribe((z) => {
        this.dataLop = z.Data;
        this.ClassFilter = z.Data;
        this.DeclarationService.LoadMon(
          id_mon,
          this.Token
        ).subscribe((z) => {
          this.dataBoMon = z;
          this.ID_bm = this.dataBoMon[0].ID_bm;
        });
      });
    });
  }
  getDanhSachLop() {
    let req = {
      ID_he: this.Insert.value.ID_he ? this.Insert.value.ID_he : "",
      ID_khoa: this.Insert.value.ID_khoa ? this.Insert.value.ID_khoa : "",
      Khoa_hoc: this.Insert.value.Khoa_hoc ? this.Insert.value.Khoa_hoc : "",
      Dot: this.Insert.value.Dot ? this.Insert.value.Dot : "",
      Hoc_ky: this.Insert.value.Hoc_ky ? this.Insert.value.Hoc_ky : "",
      Nam_hoc: this.Insert.value.Nam_hoc ? this.Insert.value.Nam_hoc : "",
      ID_mon: this.Insert.value.ID_mon ? this.Insert.value.ID_mon : "",
      Ten_lop: this.Insert.value.Ten_lop ? this.Insert.value.Ten_lop : "",
    };
    this.DeclarationService.getDanhSachLop(
      req,
      this.UserName,
      this.Insert.value.ID_mon,
      this.Token,
      0
    ).subscribe((z) => {
      this.dataLop = z.Data;
    });
  }
  changeDanhSachLopCV() {
    let req = {
      ID_he: this.InsertCV.value.ID_he ? this.InsertCV.value.ID_he : "",
      ID_khoa: this.InsertCV.value.ID_khoa ? this.InsertCV.value.ID_khoa : "",
      Khoa_hoc: this.InsertCV.value.Khoa_hoc
        ? this.InsertCV.value.Khoa_hoc
        : "",
      Dot: this.InsertCV.value.Dot ? this.InsertCV.value.Dot : "",
      Hoc_ky: this.InsertCV.value.Hoc_ky ? this.InsertCV.value.Hoc_ky : "",
      Nam_hoc: this.InsertCV.value.Nam_hoc ? this.InsertCV.value.Nam_hoc : "",
      ID_mon: this.InsertCV.value.ID_mon ? this.InsertCV.value.ID_mon : "",
    };
    let reqmh = {
      UserName: this.com.getUserinfo().Info.UserName,
      Hoc_ky: this.InsertCV.value.Hoc_ky ? this.InsertCV.value.Hoc_ky : "",
      Nam_hoc: this.InsertCV.value.Nam_hoc ? this.InsertCV.value.Nam_hoc : "",
    };
    this.DeclarationService.getListMonHoc(reqmh, this.Token).subscribe((z) => {
      this.dataMonHoc = z.Data;
      this.dataMonHoc = this.dataMonHoc.filter(
        (i) => i.Hoc_ky == this.InsertCV.value.Hoc_ky
      );
      this.dataMonHoc = this.dataMonHoc.filter(
        (i) => i.Nam_hoc == this.InsertCV.value.Nam_hoc
      );
      this.dataMonHoc = this.dataMonHoc.filter(
        (i) => i.Dot == this.InsertCV.value.Dot
      );
    });

    this.DeclarationService.LopTinChi_Load_ID(
      this.InsertCV.value.ID_lop,
      this.Token
    ).subscribe((z) => {
      this.dataLop = z;
      let data = this.dataLop;

      this.Ten_lop = data[0].Ten_lop_tc;
    });
  }
  getHeKhoa() {
    this.DeclarationService.getHe(this.Token).subscribe((z) => {
      this.dataHe = z;
      this.DeclarationService.getKhoa(this.Token).subscribe((z) => {
        this.dataKhoa = z;
        this.DeclarationService.getKhoaHoc(this.Token).subscribe((z) => {
          if (z.Data != null) {
            this.dataKhoaHoc = z.Data.filter(
              (item, i, arr) =>
                arr.findIndex((t) => t.Khoa_hoc === item.Khoa_hoc) === i
            );
            this.dataKhoaHocAfter = this.dataKhoaHoc;
          }
        });
      });
    });
  }
  changeHe() {
    if (this.Insert.value.ID_he != "") {
      this.dataKhoaHoc = this.dataKhoaHocAfter.filter(
        (x) => x.ID_he == this.Insert.value.ID_he
      );
    } else {
      this.dataKhoaHoc = this.dataKhoaHocAfter;
    }
  }

  changeHeCV() {
    if (this.InsertCV.value.ID_he != "") {
      this.dataKhoaHoc = this.dataKhoaHocAfter.filter(
        (x) => x.ID_he == this.InsertCV.value.ID_he
      );
    } else {
      this.dataKhoaHoc = this.dataKhoaHocAfter;
    }
  }
  getList() {
    this.spinner.show();
    this.tonggiothuchien = "";
    this.DeclarationService.getList(this.ID_cb, this.Token).subscribe((z) => {
      this.spinner.hide();
      this.dataTable = z.Data.filter((x, i, a) => a.indexOf(x) == i);
      this.DeclarationService.ThoiGianKeKhai(this.Token).subscribe((z) => {
        this.ThoiGianKeKhai = z;
        if (this.ThoiGianKeKhai == 0) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      });
      if (z.Data) {
        this.id_mon = z.Data.length > 0 ? z.Data[0].ID_mon : 0;
        var tonggio = 0.0;
        var data = this.dataTable;
        data = this.dataTable.filter(
          (x) => x.Nam_hoc == this.Filter.value.Nam_hoc
        );
        if (this.Filter.value.Nhom_cong_viec > 0) {
          data = data.filter(
            (x) => x.ID_nhom_cong_viec == this.Filter.value.Nhom_cong_viec
          );
        }
        if (this.Filter.value.Hoc_ky > 0) {
          data = data.filter((x) => x.Hoc_ky == this.Filter.value.Hoc_ky);
        }
        if (this.Filter.value.Ten_cong_viec != "") {
          data = data.filter(
            (x) => x.Ten_cong_viec == this.Filter.value.Ten_cong_viec
          );
        }

        if (this.Filter.value.Ten_lop != "") {
          data = data.filter((x) => x.Ten_lop == this.Filter.value.Ten_lop);
        }
        if (this.Filter.value.Noi_dung != "") {
          data = data.filter((x) => x.Ten_mon == this.Filter.value.Noi_dung);
        }
        for (let i = 0; i < data.length; i++) {
          var sogioth = parseFloat(data[i].So_gio_chuan);
          tonggio += sogioth;
        }

        this.tonggiothuchien = tonggio.toFixed(2);
      }
      this.dtTrigger.next();
    });
  }
  getHeDaoTao() {
    this.HeDaoTaoService.getList(this.Token).subscribe((z) => {
      this.dataHeDaoTao = z.Data;
    });
  }
  getNhomCongViec() {
    this.WorkGroupService.getList(this.Token).subscribe((z) => {
      this.dataNhomCongViec = z.Data;
    });
  }
  changeFilter() {
    let req = {
      Hoc_ky: this.Filter.value.Hoc_ky,
      Nam_hoc: this.Filter.value.Nam_hoc,
    };
    this.DeclarationService.quyDinhKeKhai(req, this.Token).subscribe((z) => {
      this.dataQuyDinh = z;
      if (z.length < 1) {
        this.dataQuyDinh = "Chưa quy định thời gian kê khai!";
      } else {
        let Tu_ngay = z[0].Tu_ngay.split("T")[0].split("-");
        let Den_ngay = z[0].Den_ngay.split("T")[0].split("-");
        this.dataQuyDinh =
          [Tu_ngay[2], Tu_ngay[1], Tu_ngay[0]].join("/") +
          " đến " +
          [Den_ngay[2], Den_ngay[1], Den_ngay[0]].join("/");
      }
    });
    var reqdm = {
      ID_gv: this.ID_cb,
      Nam_hoc: this.Filter.value.Nam_hoc,
    };
    this.DeclarationService.QuyDinhDinhMuc(reqdm, this.Token).subscribe((z) => {
      this.dinhmucnamhoc = z;
      if (z.length < 1) {
        this.dataQuyDinh = "Chưa quy định định mức!";
      } else {
        var dmchuyenmon = parseInt(z[0].Dinh_muc_chuyen_mon);
        var dmhoctap = parseInt(z[0].Dinh_muc_hoc_tap);
        var dmgiangday = parseInt(z[0].Dinh_muc_giang_day);
        var dmkhoahoc = parseInt(z[0].Dinh_muc_khoa_hoc);
        var dmgiamtrucv = parseInt(z[0].Dinh_muc_mien_giam_chuc_vu);
        var dmgiamtrukhac = parseInt(z[0].Dinh_muc_mien_giam_khac);
        this.dinhmucnamhoc =
          dmchuyenmon +
          dmgiangday +
          dmkhoahoc +
          dmhoctap -
          dmgiamtrucv -
          dmgiamtrukhac;
        this.dinhmucgiangday = dmgiangday;
        this.dinhmucchuyenmon = dmchuyenmon;
        this.dinhmuchoctap = dmhoctap;
        this.dinhmuckhoahoc = dmkhoahoc;
      }
    });
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.getList();
  }

  getListClass(req: RequestBase, token) {
    this.ClassService.getClass(req, token).subscribe((z) => {
      this.dataClass = z.Data;
      this.dataClassFilter = z.Data;
    });
  }
  get checkValue() {
    return this.Insert.controls;
  }

  get checkValueCV() {
    return this.InsertCV.controls;
  }
  get checkValueKH() {
    return this.InsertKH.controls;
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    $.fn["dataTable"].ext.search.pop();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  onSubmitCV() {
    if (this.InsertCV.value.ID_lop != 0) {
      this.DeclarationService.LopTinChi_Load_ID(
        this.InsertCV.value.ID_lop,
        this.Token
      ).subscribe((x) => {
        this.dataClass_ID = x;
        this.Ten_lop = this.dataClass_ID[0].Ten_lop_tc;
      });
    } else {
      this.Ten_lop = "";
    }
    this.submittedCV = true;
    if (this.InsertCV.invalid) {
      return false;
    }
    if (
      this.InsertCV.value.Khoa_hoc == null ||
      this.InsertCV.value.Khoa_hoc == "" ||
      this.InsertCV.value.Khoa_hoc == undefined
    ) {
      this.InsertCV.value.Khoa_hoc = 0;
    }

    let req = {
      ID_cv_gd: this.ID,
      Hoc_ky: this.InsertCV.value.Hoc_ky,
      Nam_hoc: this.InsertCV.value.Nam_hoc,
      ID_he: this.InsertCV.value.ID_he,
      ID_khoa: this.InsertCV.value.ID_khoa,
      Khoa_hoc: this.InsertCV.value.Khoa_hoc,
      ID_bm: this.ID_bm,
      ID_nhom_cong_viec: this.InsertCV.value.Nhom_cong_viec,
      ID_cong_viec: this.InsertCV.value.Cong_viec,
      ID_gv: this.ID_cb,
      ID_mon: "",
      Ten_mon: this.InsertCV.value.Ten_mon,
      ID_lop: this.InsertCV.value.ID_lop,
      Ten_lop: this.Ten_lop,
      ID_loai_lop: this.InsertCV.value.Loai_lop,
      So_sv: 0,
      So_nhom: 0,
      ID_ngoai_gio: this.ID_ngoai_gio,
      ID_ngoai_truong: 0,
      So_gio_goc: this.InsertCV.value.So_gio,
      He_so_cong_viec: 0,
      He_so_chuc_danh: 0,
      He_so_he: 0,
      He_so_lop: 0,
      He_so_loai_lop: 0,
      He_so_ngoai_gio: 0,
      He_so_ngoai_truong: 0,
      So_gio_chuan: 0,
      Don_gia: 0,
      Thanh_tien: 0,
      Nguon_nhap_lieu: 0,
      Create_date: new Date(),
      Modify_date: new Date(),
      User_create: this.com.getUserinfo().Info.FullName,
      Duyet_gv: 0,
      Duyet_khoa: 0,
      User_duyet_khoa: " ",
      Duyet_phong: 0,
      User_duyet_phong: " ",
      He_so: 0,
      Tu_ngay: "",
      Den_ngay: "",
      Tu_tiet: "",
      Den_tiet: "",
    };
    this.spinner.show();
    if (this.checkInsert) {
      this.DeclarationService.Insert(req, this.Token).subscribe((z) => {
        this.spinner.hide();
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else if (z.Status == 4) {
          this.toastr.error("Thêm thất bại!");
        } else {
          this.toastr.warning(z.Message);
        }
        //this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
      });
    } else {
      this.DeclarationService.Update(req, this.Token).subscribe((z) => {
        this.spinner.hide();
        if (z.Status == 8) {
          this.toastr.warning(z.Message);
        }
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else if (z.Status == 4) {
          this.toastr.error("Sửa thất bại!");
        }
        //this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
      });
    }
  }
  onSubmitKH() {
    this.submittedKH = true;
    if (this.InsertKH.value.Tu_ngay > this.InsertKH.value.Den_ngay) {
      this.toastr.warning("Từ ngày Không được lớn hơn đến ngày!");
      return false;
    }
    if (this.InsertKH.invalid) {
      return false;
    }

    let req = {
      ID_cv_gd: this.ID,
      Hoc_ky: this.InsertKH.value.Hoc_ky,
      Nam_hoc: this.InsertKH.value.Nam_hoc,
      ID_he: 0,
      ID_khoa: 0,
      Khoa_hoc: 0,
      ID_bm: this.ID_bm,
      ID_nhom_cong_viec: this.InsertKH.value.ID_nhom_cong_viec,
      ID_cong_viec: this.InsertKH.value.Cong_viec,
      ID_gv: this.ID_cb,
      ID_mon: 0,
      Ten_mon: this.InsertKH.value.Ten_mon,
      ID_lop: 0,
      Ten_lop: " ",
      ID_loai_lop: 0,
      So_sv: 0,
      So_nhom: 0,
      ID_ngoai_gio: this.ID_ngoai_gio,
      ID_ngoai_truong: 0,
      So_gio_goc: this.InsertKH.value.So_gio,
      He_so_cong_viec: 0,
      He_so_chuc_danh: 0,
      He_so_he: 0,
      He_so_lop: 0,
      He_so_loai_lop: 0,
      He_so_ngoai_gio: 0,
      He_so_ngoai_truong: 0,
      So_gio_chuan: 0,
      Don_gia: 0,
      Thanh_tien: 0,
      Nguon_nhap_lieu: 0,
      Create_date: new Date(),
      Modify_date: new Date(),
      User_create: this.com.getUserinfo().Info.FullName,
      Duyet_gv: 0,
      Duyet_khoa: 0,
      User_duyet_khoa: " ",
      Duyet_phong: 0,
      User_duyet_phong: " ",
      He_so: 0,
      Tu_ngay: this.InsertKH.value.Tu_ngay,
      Den_ngay: this.InsertKH.value.Den_ngay,
      So_nguoi_tham_gia: this.InsertKH.value.So_nguoi_tham_gia,
    };
    if (this.checkInsert) {
      this.DeclarationService.Insert(req, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else if (z.Status == 4) {
          this.toastr.error("Thêm thất bại!");
        } else {
          this.toastr.warning(z.Message);
        }
        //this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
      });
    } else {
      this.DeclarationService.Update(req, this.Token).subscribe((z) => {
        if (z.Status == 8) {
          this.toastr.warning(z.Message);
        }
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else if (z.Status == 4) {
          this.toastr.error("Sửa thất bại!");
        }
        //this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
      });
    }
  }
  onItemSelect(item: any) {
    this.dataMon = item;
  }
  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      closeDropDownOnSelection: this.closeDropdownSelection,
    });
  }
  Delete() {
    let ID_cv_gd = this.ID;
    this.spinner.show();
    this.DeclarationService.Delete(ID_cv_gd, this.ID_cb, this.Token).subscribe(
      (z) => {
        this.spinner.hide();
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else if (z.Status == 4) {
          this.toastr.error("Xoá thất bại");
        }
        this.modalService.dismissAll("AddModal");
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
      }
    );
  }
  onSubmit() {
    this.submitted = true;

    if (this.Insert.invalid) {
      return false;
    }
    if (this.Insert.value.Tu_ngay > this.Insert.value.Den_ngay) {
      this.toastr.warning("Từ ngày Không được lớn hơn đến ngày!");
      return false;
    }
    if (
      parseInt(this.Insert.value.Tu_tiet) > parseInt(this.Insert.value.Den_tiet)
    ) {
      this.toastr.warning("Từ tiết không được lớn hơn đến tiết!");
      return false;
    }
    if (
      this.Insert.value.Khoa_hoc == null ||
      this.Insert.value.Khoa_hoc == "" ||
      this.Insert.value.Khoa_hoc == undefined
    ) {
      this.Insert.value.Khoa_hoc = 0;
    }
    let req = {
      ID_cv_gd: this.ID,
      Hoc_ky: this.Insert.value.Hoc_ky,
      Nam_hoc: this.Insert.value.Nam_hoc,
      ID_he: this.Insert.value.ID_he,
      ID_khoa: this.Insert.value.ID_khoa,
      Khoa_hoc: this.Insert.value.Khoa_hoc,
      ID_bm: this.ID_bm,
      ID_nhom_cong_viec: this.Insert.value.Nhom_cong_viec,
      ID_cong_viec: this.Insert.value.Cong_viec,
      ID_gv: this.ID_cb,
      ID_mon: this.Insert.value.ID_mon,
      Ten_mon: "",
      ID_lop: this.Insert.value.ID_lop,
      Ten_lop: this.Ten_lop,
      ID_loai_lop: this.Insert.value.Loai_lop,
      So_sv: this.Insert.value.So_sinh_vien,
      So_gio_goc: this.Insert.value.So_gio,
      He_so_cong_viec: 0,
      He_so_chuc_danh: 0,
      He_so_he: 0,
      He_so_lop: 0,
      He_so_loai_lop: 0,
      He_so_ngoai_gio: 0,
      He_so_ngoai_truong: 0,
      So_gio_chuan: 0,
      Don_gia: 0,
      Thanh_tien: 0,
      Nguon_nhap_lieu: 0,
      Create_date: new Date(),
      Modify_date: new Date(),
      User_create: this.com.getUserinfo().Info.FullName,
      User_name: this.com.getUserinfo().Info.UserName,
      Duyet_gv: 0,
      Duyet_khoa: 0,
      User_duyet_khoa: " ",
      Duyet_phong: 0,
      User_duyet_phong: " ",
      He_so: 0,
      Tu_ngay: this.Insert.value.Tu_ngay,
      Den_ngay: this.Insert.value.Den_ngay,
      ID_he_so_mon: this.Insert.value.Loai_mon,
      Tu_tiet: this.Insert.value.Tu_tiet,
      Den_tiet: this.Insert.value.Den_tiet,
      Thu: this.Insert.value.Thu,
      ID_ngoai_gio: this.ID_ngoai_gio,
    };
    if (this.checkInsert) {
      this.DeclarationService.Insert(req, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else if (z.Status == 4) {
          this.toastr.error("Thêm thất bại!");
        } else {
          this.toastr.warning(z.Message);
        }
        //this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
      });
    } else {
      this.DeclarationService.Update(req, this.Token).subscribe((z) => {
        if (z.Status == 8) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else if (z.Status == 4) {
          this.toastr.error("Thêm thất bại!");
        } else {
          this.toastr.warning(z.Message);
        }
        //this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
      });
    }
  }

  CheckAll() {
    var datatable = $("#datatable")
      .DataTable()
      .rows({ filter: "applied" })
      .data();
    var length = datatable.rows({ filter: "applied" }).nodes().length;
    var lstID = [];
    for (var i = 0; i < length; i++) {
      var ID_cv_gd = parseInt(datatable[i][1]);
      lstID.push(ID_cv_gd);
    }
    for (let index = 0; index < this.dataTable.length; index++) {
      if (lstID.includes(this.dataTable[index].ID_cv_gd)) {
        this.dataTable[index].Selected = $("#SelectALL").prop("checked");
      }
    }
  }
  SelectRow(ID_cv_gd) {
    for (let index = 0; index < this.dataTable.length; index++) {
      if (this.dataTable[index].ID_cv_gd == ID_cv_gd) {
        if ($("#" + ID_cv_gd).prop("checked")) {
          {
            this.dataTable[index].Selected = true;
          }
        } else {
          this.dataTable[index].Selected = false;
        }
        break;
      }
    }
  }
  MultiUpdateStatus() {
    if (this.dataTable.filter((x) => x.Selected == true).length < 1) {
      this.toastr.warning("Bạn chưa chọn kê khai!");
      this.modalService.dismissAll("AddModal");
      return false;
    }
    let req = this.dataTable.filter((x) => x.Selected == true);
    this.DeclarationService.multipleUpdate(req, this.Token).subscribe((z) => {
      if (z.Status == 2) {
        this.toastr.warning(z.Message);
      } else if (z.Status == 1) {
        this.toastr.success(z.Message);
      } else if (z.Status == 4) {
        this.toastr.error("Thêm thất bại!");
      } else {
        this.toastr.warning(z.Message);
      }
      this.modalService.dismissAll("AddModal");
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.getList();
    });
  }
  UpdateStatus() {
    let req = [];
    req.push(this.selectItem);
    this.DeclarationService.multipleUpdate(req, this.Token).subscribe((z) => {
      if (z.Status == 2) {
        this.toastr.warning(z.Message);
      } else if (z.Status == 1) {
        this.toastr.success(z.Message);
      } else if (z.Status == 4) {
        this.toastr.error("Thêm thất bại!");
      } else {
        this.toastr.warning(z.Message);
      }
      this.modalService.dismissAll("AddModal");
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.getList();
    });
  }
  changeTuNgay() {
    var datenow = new Date();
    var dateinsert = new Date(this.Insert.value.Tu_ngay);
    var dateinsertcv = new Date(this.InsertCV.value.Tu_ngay);
    if (datenow < dateinsert || datenow < dateinsertcv) {
      this.toastr.warning("Từ ngày không được lớn hơn ngày hiện tại!");
      return false;
    } else {
      this.Insert.get("Den_ngay").setValue(this.Insert.value.Tu_ngay);
      this.InsertCV.get("Den_ngay").setValue(this.InsertCV.value.Tu_ngay);
    }
  }
  changeDenNgay() {
    this.Insert.get("Den_ngay").setValue(this.Insert.value.Den_ngay);
    this.InsertCV.get("Den_ngay").setValue(this.InsertCV.value.Den_ngay);
  }
  changeTuNgayAll() {
    var datenow = new Date();
    var dateinsert = new Date(this.Insert.value.Tu_ngay);
    var dateinsertcv = new Date(this.InsertCV.value.Tu_ngay);
    if (datenow < dateinsert || datenow < dateinsertcv) {
      this.toastr.warning("Từ ngày không được lớn hơn ngày hiện tại!");
      return false;
    } else {
      this.Insert.get("Den_ngay").setValue(this.Insert.value.Tu_ngay);
      this.InsertCV.get("Den_ngay").setValue(this.InsertCV.value.Tu_ngay);
    }
  }
  changeDenNgayAll() {
    this.Insert.get("Den_ngay").setValue(this.Insert.value.Den_ngay);
    this.InsertCV.get("Den_ngay").setValue(this.InsertCV.value.Den_ngay);
  }
  Export_KeKhaiGiangDay() {
    let req = {
      ID_gv: this.ID_cb,
      Nam_hoc: this.Filter.value.Nam_hoc,
      Hoc_ky: this.Filter.value.Hoc_ky,
    };
    this.DeclarationService.Export_KeKhaiGiangDay(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, "KeKhaiGiangDay");
          this.toastr.success("File đã được tải xuống", "Thao tác thành công");
        } else {
          this.toastr.error(z.Message, "Thao tác thất bại");
        }
      }
    );
  }

  LopTinChi() {
    this.DeclarationService.LopTinChi_Load(this.Token).subscribe((z) => {
      this.ClassTinChi = z;
    });
  }
}
