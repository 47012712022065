import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppService } from 'src/app/Utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Class } from 'src/app/Utils/services/Class.service';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { AnnounceService } from 'src/app/Utils/service/announce.service';
import { ResponseClassListAll, ClassInfo } from 'src/app/Models/output.model/ResponseClassListAll';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AppConfiguration, AppConfig } from 'src/configuration';
import { Injectable, Inject } from '@angular/core';

@Component({
  selector: 'app-announce',
  templateUrl: './announce.component.html',
  styleUrls: ['./announce.component.scss']
})
export class AnnounceComponent implements OnDestroy,OnInit {
  dtOptions: DataTables.Settings
  Res: any
  ClassData: any
  requestObject: RequestBase = new RequestBase()
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  TieuDe: string;
  Title: string="Đang tải danh sách thông báo..."
  TitleClass: string="spinner-border text-muted"
  constructor(
    @Inject(AppConfig) 
    private readonly appConfig: AppConfiguration,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private AnnounceService: AnnounceService
  ) {
    // 

  }
  dtTrigger= new Subject();
  public com: common;
  ngOnInit() {
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let TieuDedt = data[5]; 
       let bool1=true
       let fillter=true;
      if(this.TieuDe != undefined){
        bool1=TieuDedt.includes(this.TieuDe)
      }
      fillter=bool1
      return fillter;
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    //var rs=this.Class.ClassListAll(a.Info.UserName, a.Token)
    this.requestObject.UserName = a.Info.UserName
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }

      },
      columns: [
        {
          title: 'Học kỳ',
          data: 'Anh_dai_dien',
          visible: false
        },
        {
          title: 'Loại',
          data: 'CategoryName',
            
        },
        {
          data: 'ID_thong_bao',
          visible: false
        },
        {
          data: 'Loai_thong_bao',
          visible: false
        },
        {
          data: 'Noi_dung',
          visible: false
        },
        {
          title: 'Tiêu đề',
          data: 'Tieu_de'
        },
        {
          title: 'Trích dẫn',
          data: 'Trich_dan'
        },
        {
          title: 'Thao tác',
          width: "10%",
          className: "dt-center",
        },
      ],
    };
    this.getListAnnounce(this.requestObject, a.Token)
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl+"?"+this.appConfig.document_xem_thong_bao;
    window.open(urlHelp,'_blank');
  }
  getListAnnounce(req: RequestBase, token) {
    this.AnnounceService.getListAnnounce(req, token)
    .subscribe(z => {
      this.Res = z
      this.ClassData = this.Res.Data
      this.dtTrigger.next();
      this.Title="Danh sách thông báo"
      this.TitleClass=""
    })
    ;
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

}
