import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { common } from "src/app/app.common";
import { CasService } from "src/app/Utils/service/cas.service";
import { SsoService } from "src/app/Utils/service/sso.service";
import { AppConfig, AppConfiguration } from "src/configuration";

@Component({
  selector: "app-verification",
  templateUrl: "./verification.component.html",
  styleUrls: ["./verification.component.scss"],
})
export class VerificationComponent implements OnInit {
  public com: common;
  Token: any;
  code: any = null;
  ticket: any = null;
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    public route: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public ssoService: SsoService,
    public casService: CasService
  ) {}
  ngOnInit() {
    this.com = new common(this.router);
    let userData = this.com.getUserinfo();
    if (userData == null) {
      this.route.queryParams.subscribe((params) => {
        this.code = params.code;
        this.ticket = params.ticket;
        if (this.appConfig.AuthorizeType == 1 || (this.appConfig.sso != null && this.appConfig.AuthorizeType== null)) {
          // Đăng nhập qua SSO
          if (userData == null && this.code == null) {
            this.spinner.hide();
            this.ssoService.RedirectSSO();
          } else {
            if (this.code != null) {
              this.ssoService.authorizationCode(this.code).subscribe((z) => {
                if (z.Status == 1) {
                  this.spinner.hide();
                  localStorage.setItem("UserInfo", JSON.stringify(z.Data));
                  this.com.login();
                }
              });
            }
            if (this.code == null && userData != null) {
              this.spinner.hide();
              this.com.login();
            }
          }
        }

        if (this.appConfig.AuthorizeType == 0 || ((this.appConfig.sso == null && this.appConfig.AuthorizeType == null))) {
          // đăng nhập sử dụng UniSystemAPI đối với hệ thống không sử dụng cơ chế SSO
          this.com.redirectToLoginSystem();
        }

        if (this.appConfig.AuthorizeType == 2) {
          // Đăng nhập qua CAS
          if (this.ticket == null) {
            this.casService.RedirectCAS();
          } else {
            this.spinner.show();
            this.casService.validate(this.ticket).subscribe((z) => {
              this.spinner.hide();
              if (z.Status == 1) {
                this.spinner.hide();
                localStorage.setItem("UserInfo", JSON.stringify(z.Data));
                this.com.login();
              } else {
                this.toastr.warning(z.Message);
              }
            });
          }
        }
      });
    } else {
      this.com.login();
    }
  }
}
