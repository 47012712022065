import { RequestBase } from './../../Models/input.model/RequestBase';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { RequestSendMessage } from 'src/app/Models/input.model/RequestSendMessage';
import { AppConfiguration, AppConfig } from 'src/configuration';
@Injectable({
    providedIn: 'root'
})
export class SupportService {
    constructor(
        @Inject(AppConfig) 
        private readonly appConfig: AppConfiguration,
        private http : HttpClient
    ) { }

    GetSupportLink(token) : Observable<any>{
        return this.http.get<any>(this.appConfig.UtilsUrl+'ThamSoHeThong/Read_ThamsoHeThong?ID_ph=13&Gia_tri=Don_vi_su_dung',{
          headers: new HttpHeaders()
            .set('Authorization', `Bearer ${token}`)
        } ).pipe(map(z=> {return z}))
      }
}
