<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Điểm danh sinh viên</li>
                </ol>
            </div>
            <div class="col-md-6">
                <button (click)="help()" class="btn btn-warning btn-sm"  type="submit" style="float: right; ">
                    <i class="fas fa-info-circle"></i>
                    Hướng dẫn
                </button>
            </div>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card cus-fs">
        <div class="card-body cus-body">
            <div class="tab-content">
                <div class="active tab-pane">
                    <ngb-tabset [destroyOnHide]="false">
                        <ngb-tab>
                            <ng-template ngbTabTitle><strong>Điểm danh theo tuần</strong></ng-template>
                            <ng-template ngbTabContent>
                                <form class="form-horizontal" [formGroup]="Datetime">
                                    <div class="card-header p-2 " style="align-content:flex-end ">
                                        <div class="row" style="padding-left:10px; padding-right:10px">
                                            <div class="col-md-4">
                                                <label class="col-md-10">Từ ngày:</label>
                                                <input readonly class="form-control" type="date" id="FromDay"
                                                    formControlName="FromDay">
                                            </div>
                                            <div class="col-md-4">
                                                <label class="col-md-10">Đến ngày:</label>
                                                <input readonly class="form-control" type="date" id="ToDay"
                                                    formControlName="ToDay">
                                            </div>
                                            <!-- <div class="col-md-4">
                                                <label class="col-md-12">&nbsp;</label>
                                                <button type="button" (click)="GetData()"
                                                    class="btn-sm btn-info btn">Tìm kiếm</button>
                                            </div> -->
                                        </div>
                                    </div>
                                </form>
                                <div class="tab-content" style="margin-top: 15px;">
                                    <div class="active tab-pane" id="TeachInfo">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="{{TitleClass}}"></div>
                                                    <div class=" col-md-3">
                                                        <h3 class="card-title">{{Title}}</h3>
                                                    </div>
                                                </div>
                                                <div
                                                    class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                                        class=" table-bordered dataTables_wrapper no-footer">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let p of ClassData">
                                                                <td>{{ p.ID_lich_day }}</td>
                                                                <td>{{ p.Ky_hieu }}</td>
                                                                <td>{{ p.Ten_mon }}</td>
                                                                <td>{{ p.ID_lop }}</td>
                                                                <td>{{ p.Loai_lop }}</td>
                                                                <td>{{ p.Ten_lop }}</td>
                                                                <td>{{ p.Thu }}</td>
                                                                <td>{{ p.Ca_hoc }}</td>
                                                                <td>{{ p.Tu_tiet }}</td>
                                                                <td>{{ p.Den_tiet }}</td>
                                                                <td>{{ p.Giao_vien }}</td>
                                                                <td>{{ p.UserName }}</td>
                                                                <td>{{ p.Phong_hoc }}</td>
                                                                <td>{{ p.Tu_ngay | date:'dd/MM/yyyy' }}</td>
                                                                <td>{{ p.Den_ngay | date:'dd/MM/yyyy' }}</td>
                                                                <td>{{ p.Tu_gio }}</td>
                                                                <td>{{ p.Den_gio }}</td>
                                                                <td>
                                                                    <a [routerLink]="['/admin/attendenceedit']"
                                                                        class="btn btn-success btn-sm"
                                                                        [queryParams]="{IDLop: p.ID_lop,CaHoc:p.Ca_hoc,Tiet:(p.Den_tiet-p.Tu_tiet+1),
                                                                            ID_lich_day:p.ID_lich_day,Ten_lop:p.Ten_lop,
                                                                            Loai_lop:p.Loai_lop,ID_mon:p.ID_mon,Status:false,Ngay_thang:p.Ngay_hoc,Ten_mon:p.Ten_mon}"
                                                                        queryParamsHandling="merge">
                                                                        <i class="fas fa-user-check"
                                                                            title="Điểm danh"></i>
                                                                    </a>&nbsp;&nbsp;
                                                                
                                                                   
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab style="font-weight: bold;">
                            <ng-template ngbTabTitle><strong>Điểm danh theo kế hoạch</strong></ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-content" style="margin-top: 15px;">
                                    <div class="content cus-body">
                                        <div class="card">
                                            <div class="card-body" style="padding: 0px 20px 0px 20px;">
                                                <div class="tab-content">
                                                    <div class="active tab-pane">
                                                        <div class="row pt10" style="    margin-bottom: 1%;">
                                                            <div class="col-md-4">
                                                                <label class="col-md-10">Năm học</label>
                                                                <select class="form-control" [(ngModel)]="Nam_hoc_fil">
                                                                    <option value="">Tất cả</option>
                                                                    <option *ngFor = "let p of Nam_hoc_arr" value="{{p.value}}">{{p.name}}</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <label class="col-md-10">Tên môn</label>
                                                                <input  class="form-control" type="text" placeholder="Nhập tên môn"
                                                                [(ngModel)]="Ten_mon_fil">
                                                            </div>
                                                            <div class="col-md-4">
                                                                <label class="col-md-10">Tên lớp</label>
                                                                <input  class="form-control" type="text" placeholder="Nhập tên lớp"
                                                                [(ngModel)]="Ten_lop_fil">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="active tab-pane" id="TeachInfo">
                                        <div class="row">
                                            <!-- /.col -->
                                            <div class="col-md-12">
                                                <div
                                                    class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                                    <table class="table-bordered table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Tên môn</th>
                                                                <th style="width:20%;">Tên lớp</th>
                                                                <th style="width:7%;text-align: center;">Học kỳ</th>
                                                                <th style="width:7%;text-align: center;">Năm học</th>
                                                                <th style="width:7% ;text-align: center;">Ký hiệu</th>
                                                                <th style="width:7%;text-align: center;">Số SV</th>
                                                                <th style="text-align: center;">Loại lớp</th>
                                                                <th style="text-align: center;">Thao tác</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let p of TableData | tableFilter: Nam_hoc_fil | FilterClass:{Ten_mon : Ten_mon_fil , Ten_lop : Ten_lop_fil }">
                                                                <td>{{ p.Ten_mon }}</td>
                                                                <td>{{ p.Ten_lop }}</td>
                                                                <td style="text-align: center;">{{ p.Hoc_ky }}</td>
                                                                <td style="text-align: center;">{{ p.Nam_hoc }}</td>
                                                                <td style="text-align: center;">{{ p.Ky_hieu }}</td>
                                                                <td style="text-align: center;">{{ p.So_sv }}</td>
                                                                <td style="text-align: center;">{{ p.Ten_loai_lop }}</td>
                                                                <td style="text-align: center;">
                                                                    <!-- <a [routerLink]="['/admin/attendence-plan']"
                                                                        class="btn btn-success btn-sm"
                                                                        [queryParams]="{IDLop: p.ID_lop,Nam_hoc: p.Nam_hoc,Hoc_ky:p.Hoc_ky,Loai_lop:p.Loai_lop,Ten_mon:p.Ten_mon,Ten_lop:p.Ten_lop}"
                                                                        queryParamsHandling="merge">
                                                                        <i class="fas fa-user-check"
                                                                            title="Điểm danh"></i>
                                                                    </a>&nbsp;&nbsp; -->
                                                                    <a [routerLink]="['/admin/attendence-plan-new']" *ngIf="!p.Diem_danh_theo_tiet"
                                                                        class="btn btn-primary btn-sm"
                                                                        [queryParams]="{ID_mon: p.ID_mon,IDLop: p.ID_lop,Nam_hoc: p.Nam_hoc,Hoc_ky:p.Hoc_ky,Loai_lop:p.Loai_lop,Ten_mon:p.Ten_mon,Ten_lop:p.Ten_lop}"
                                                                        queryParamsHandling="merge">
                                                                        <i class="fas fa-user-check"
                                                                            title="Điểm danh"></i>
                                                                    </a>&nbsp;&nbsp;
                                                                    <a [routerLink]="['/admin/ds-diem-danh-theo-tiet']" *ngIf="p.Diem_danh_theo_tiet"
                                                                    class="btn btn-success btn-sm"
                                                                    [queryParams]="{ID_mon: p.ID_mon,So_sv:p.So_sv,IDLop: p.ID_lop,Nam_hoc: p.Nam_hoc,Hoc_ky:p.Hoc_ky,Loai_lop:p.Loai_lop,Ten_mon:p.Ten_mon,Ten_lop:p.Ten_lop}"
                                                                    queryParamsHandling="merge">
                                                                    <i class="fas fa-user-check"
                                                                        title="Điểm danh theo tiết"></i>
                                                                </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</section>
<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">