import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { ResponseBase } from 'src/app/Models/output.model/ResponseBase';
import { RequestTeachingScheduleList, RequestClassInfo } from 'src/app/Models/input.model/RequestTeachingScheduleList';
import {  ResponseListSchedule } from 'src/app/Models/output.model/ResponseListSchedule';
import { AppConfiguration, AppConfig } from 'src/configuration';
@Injectable({
  providedIn: 'root'
})
export class TeachingSchedule {
  constructor(@Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http : HttpClient) { }
  List(req:RequestTeachingScheduleList, token) : Observable<ResponseListSchedule>{
    return this.http.post<ResponseListSchedule>(this.appConfig.TeacherUrl + 'TeachingSchedule/TeachingScheduleList',req,{
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    } ).pipe(map(z=> {return z}))  
  }

  BaoNghi(req:any, token) : Observable<ResponseListSchedule>{
    return this.http.post<ResponseListSchedule>(this.appConfig.TeacherUrl + 'TeachingSchedule/BaoNghi',req,{
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    } ).pipe(map(z=> {return z}))  
  }
}
