import { ResponseLogin } from "src/app/Models/output.model/ResponseLogin";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";

export class common {
  public LoginResult: ResponseLogin;
  public cookieService: CookieService;
  public CheckLogin() {
    this.LoginResult = new ResponseLogin();
    this.LoginResult = this.getUserinfo();
    if (this.LoginResult == null) {
      this.router.navigate(["/"]);
    }
  }
  constructor(private router: Router) {}
  public getUserinfo() {
    return JSON.parse(localStorage.getItem("UserInfo"));
  }
  login() {
    this.router.navigate(["/admin"]);
  }

  logout() {
    localStorage.removeItem("UserInfo");
    this.router.navigate(["/"]);
  }

  redirectToLoginSystem() {
    localStorage.removeItem("UserInfo");
    this.router.navigate(["/login"]);
  }
}
