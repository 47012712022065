<section class="content-header" style="padding:5px !important">
    <div class="container-fluid">
        <div class="row">
            <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
             </ol>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-3 col-xs-6">
                        <div class="small-box bg-aqua" style="background-color:bisque">
                            <div class="inner">
                                <h5>
                                    <i class="fas fa-chalkboard-teacher"></i> Số lớp đã dạy :
                                </h5>
                                <h5>
                                    <span style="font-weight:700;font-size:35px;">{{ClassNumber}}</span>
                                </h5>
                            </div>
                            <div class="icon">
                                <i class="far fa-address-book"></i>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-xs-6">
                        <div class="small-box  bg-yellow">
                            <div class="inner">
                                <h5>
                                    <i class="fas fa-users"></i> Số lớp đang học :
                                </h5>
                                <h5>
                                    <span style="font-weight:700;font-size:35px;">{{ClassNumberOnline}}</span>
                                </h5>
                            </div>
                            <div class="icon">
                                <i class="fas fa-id-card"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-6">
                        <div class="small-box bg-green">
                            <div class="inner">
                                <h5>
                                    <i class="far fa-address-book"></i> Số SV đã dạy:
                                </h5>
                                <h5>
                                    <span style="font-weight:700;font-size:35px;">{{StudentsNumber}}</span>
                                </h5>
                            </div>
                            <div class="icon">
                                <i class="far fa-address-card"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-6">
                        <div class="small-box " style="background: #4a90b9;">
                            <div class="inner">
                                <h5>
                                    <i class="fas fa-user-graduate"></i> Số SV đang học :
                                </h5>
                                <h5>
                                    <span style="font-weight:700;font-size:35px;">{{StudentsNumberOnline}}</span>
                                </h5>
                            </div>
                            <div class="icon">
                                <i class="fas fa-server"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="card ">
                            <div class="card-header" style="font-size:24px;background:#c3bdbd;">
                                <i class="far fa-calendar-alt"></i> &nbsp; Lịch giảng hôm nay
                            </div>
                            <div class="card-body">
                                <div *ngFor="let p of eventsModel" style="list-style-type:none;">
                                    <div [routerLink]="['/admin/attendence-plan-new']"
                                    [queryParams]="{IDLop: p.ID_lop,CaHoc:p.Ca_hoc,Tiet:(p.Den_tiet-p.Tu_tiet+1),ID_lich_day:p.ID_lich_day,Ten_lop:p.Ten_lop,Loai_lop:p.Loai_lop,ID_mon:p.ID_mon,Status:false,Ngay_thang:p.start,Ten_mon:p.Ten_mon}"
                                    queryParamsHandling="merge">{{ p.title }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card card-warning">
                            <div class="card-header" style="font-size:24px;">
                                <i class="fas fa-calendar-minus"></i> &nbsp; Sinh viên nghỉ học
                            </div>
                            <div class="card-body" style="padding-top: 0px;">
                                <div class="table-responsive  dataTables_wrapper">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="table table-striped table-bordered dataTable">
                                        <thead>
                                            <tr>
                                                <th class="cus_tb_tr"></th>
                                                <th class="cus_tb_tr"></th>
                                                <th class="cus_tb_tr"></th>
                                                <th class="cus_tb_tr"></th>
                                                <th class="cus_tb_tr"></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let p of ClassDataTruant">
                                                <td>{{ p.Ten_mon }}</td>
                                                <td>{{ p.So_Tiet_Nghi }}</td>
                                                <td>{{ p.Ten_Lop }}</td>
                                                <td>{{ p.Ho_Ten }}</td>
                                                <td>{{ p.Ma_SV }}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.col-md-6 -->
                    <!-- /.col-md-6 -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /.content -->