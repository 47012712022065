<section class="content-header" style="padding: 0.5% !important">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Hồ sơ của tôi</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          (click)="help()"
          class="btn btn-warning btn-sm"
          type="submit"
          style="float: right"
        >
          <i class="fas fa-info-circle"></i>
          Hướng dẫn
        </button>
      </div>
    </div>
  </div>
</section>
<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card card-primary">
          <div class="card-header no-border">
            <div class="row">
              <div class="{{ TitleClass }}"></div>
              <div class="col-md-3">
                <h3 class="card-title">{{ Title }}</h3>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="tab-content">
              <div class="active tab-pane" id="TeachInfo">
                <form class="form-horizontal" [formGroup]="UserForm">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Mã cán bộ:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <input
                            type="email"
                            class="form-control"
                            readonly
                            formControlName="Ma_cb"
                            id="Ma_cb"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Tên đăng nhập:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <input
                            type="text"
                            class="form-control"
                            formControlName="Ten_dang_nhap"
                            value=""
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Họ và tên:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <input
                            type="text"
                            class="form-control"
                            formControlName="Ho_ten"
                            id="Ho_ten"
                            value=""
                            readonly
                            placeholder="Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Chức danh:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <select class="form-control" formControlName="ID_chuc_danh">
                            <option selected value="0">Vui lòng chọn</option>
                            <option *ngFor="let p of ChucDanhs" [value]="p.ID_chuc_danh">{{p.Chuc_danh}}</option>
                        </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Ngày sinh:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <input
                            type="date"
                            class="form-control"
                            formControlName="Ngay_sinh"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Học vị:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <select class="form-control" formControlName="ID_hoc_vi">
                            <option selected value="0">Vui lòng chọn</option>
                            <option *ngFor="let p of HocVis" [value]="p.ID_hoc_vi">{{p.Hoc_vi}}</option>
                        </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Giới tính:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <select class="form-control" formControlName="ID_gioi_tinh">
                            <option selected value="-1">Vui lòng chọn</option>
                            <option *ngFor="let p of GioiTinhs" [value]="p.ID_gioi_tinh">{{p.Gioi_tinh}}</option>
                        </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Học hàm:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <select class="form-control" formControlName="ID_hoc_ham">
                            <option selected value="0">Vui lòng chọn</option>
                            <option *ngFor="let p of HocHams" [value]="p.ID_hoc_ham">{{p.Hoc_ham}}</option>
                        </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Tôn giáo:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <select class="form-control" formControlName="ID_ton_giao">
                            <option selected value="0">Vui lòng chọn</option>
                            <option *ngFor="let p of TonGiaos" [value]="p.ID_ton_giao">{{p.Ton_giao}}</option>
                        </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Ngành chủ trì đào tạo:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <input
                            type="text"
                            class="form-control"
                            formControlName="Chuyen_mon_dao_tao"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Dân tộc:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <select class="form-control" formControlName="ID_dan_toc">
                            <option selected value="0">Vui lòng chọn</option>
                            <option *ngFor="let p of DanTocs" [value]="p.ID_dan_toc">{{p.Dan_toc}}</option>
                        </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Chuyên ngành:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <input
                            type="text"
                            class="form-control"
                            formControlName="Chuyen_nganh_giang_day"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Phân loại:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <input
                            type="text"
                            class="form-control"
                            formControlName="Phan_loai"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Số điện thoại:
                          </label>
                        </div>
                        <div class="col-md-9"> 
                          <input
                          type="text"
                          class="form-control"
                          formControlName="So_dien_thoai"
                        /></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Quốc tịch:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <select class="form-control" formControlName="ID_quoc_tich">
                            <option value="0">Vui lòng chọn</option>
                            <option *ngFor="let p of QuocTichs" [value]="p.ID_quoc_tich">{{p.Quoc_tich}}</option>
                        </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Email:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <input
                          type="text"
                          class="form-control"
                          formControlName="Email"
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Số sổ bảo hiểm:
                          </label>
                        </div>
                        <div class="col-md-9"><input
                          type="text"
                          class="form-control"
                          formControlName="So_so_bao_hiem"
                        /></div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Địa chỉ liên hệ:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <input
                          type="text"
                          class="form-control"
                          formControlName="Dia_chi_lien_he"
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Số CMND/CCCD:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <input
                          type="text"
                          class="form-control"
                          formControlName="CMTND"
                        />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Số tài khoản:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <input
                          type="text"
                          class="form-control"
                          formControlName="So_tai_khoan"
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Ngày cấp:
                          </label>
                        </div>
                        <div class="col-md-9"> <input
                          type="date"
                          class="form-control"
                          formControlName="Ngay_cap_CMND"
                        /></div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Ngân hàng:
                          </label>
                        </div>
                        <div class="col-md-9"><input
                          type="text"
                          class="form-control"
                          formControlName="Ten_ngan_hang"
                        /></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                           Nơi cấp:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <input
                          type="text"
                          class="form-control"
                          formControlName="Noi_cap_CMND"
                        />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Đơn vị chủ quản:
                          </label>
                        </div>
                        <div class="col-md-9">
                          <select class="form-control" formControlName="ID_don_vi_quan_ly">
                            <option value="0">Vui lòng chọn</option>
                            <option *ngFor="let p of Khoas" [value]="p.ID_khoa">{{p.Ten_khoa}}</option>
                        </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Đơn vị công tác:
                          </label>
                        </div>
                        <div class="col-md-9"> <input
                          type="text"
                          class="form-control"
                          formControlName="Don_vi_cong_tac"
                        /></div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                            Chức vụ tại đơn vị:
                          </label>
                        </div>
                        <div class="col-md-9"><select class="form-control" formControlName="ID_chuc_vu">
                          <option value="0">Vui lòng chọn</option>
                          <option *ngFor="let p of ChucVus" [value]="p.ID_chuc_vu">{{p.Chuc_vu}}</option>
                      </select></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="inputName" class="control-label">
                           Mã số thuế:
                          </label>
                        </div>
                        <div class="col-md-9"><input
                          type="text"
                          class="form-control"
                          formControlName="Ma_so_thue"
                        /></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="tab-pane" id="settings">
                <form class="form-horizontal"></form>
              </div>
              <!-- /.tab-pane -->
            </div>
            <!-- /.tab-content -->
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
            <p
              (click)="UpdateInfo()"
              class="btn btn-success float-sm-right cs-i-c"
            >
              <i class="fas fa-save"></i> Lưu thông tin
            </p>
          </div>
        </div>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>
