import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { RequestSendMessage } from "src/app/Models/input.model/RequestSendMessage";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class MessageService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }
  ListInboxAll(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Message/ListInboxAll", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ListInboxUnRead(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Message/ListInboxUnRead", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ListSendAll(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Message/ListSendAll", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetMessage(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Message/InboxGet", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  SendMessage(req: RequestSendMessage, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Message/MessageSend", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetListUserName(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.TeacherUrl + "Message/MessageGetListUserName", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  SendMessage_TS(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Message/sendMessage_TS", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}
