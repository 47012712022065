import { Component, OnInit } from '@angular/core';
import { common } from 'src/app/app.common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {
  message = '';
  dtOptions: DataTables.Settings = {
    language: {
      processing: "Đang xử lý...",
      lengthMenu: "Xem _MENU_ mục",
      emptyTable: "Không có dữ liệu!",
      info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
      infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
      infoFiltered: "(được lọc từ _MAX_ mục)",
      infoPostFix: "",
      search: "Tìm kiếm nhanh:",
      url: "",
      searchPlaceholder: "Nhập từ khóa cần tìm...",
      paginate: {
        first: "Đầu",
        previous: "Trước",
        next: "Tiếp",
        last: "Cuối"
      }
    }
  };
  constructor(private toastr: ToastrService, public router: Router, ) { }
  public com: common;
  readonly rootURL = "http://localhost:33377/"
  ngOnInit() {
    this.com = new common(this.router);
    var a = this.com.getUserinfo();
    var rs = this.ClassListAll(a.Info.UserName, this.rootURL, a.access_token)
    if (rs.Status == 1) {
      this.FillDataToTable(rs.Data);
    }
    else {
      this.toastr.error(rs.Message, 'Thao tác thất bại');
    }

  }
  showValue(value) {
    alert(value)
  }
  FillDataToTable(inputdata) {
    this.dtOptions = {
      data: inputdata,
      language: this.dtOptions.language,
      columns: [{
        title: 'Học kỳ',
        data: 'Hoc_ky'
      },
      {
        title: 'ID_lop',
        data: 'ID_lop',
        visible: false
      },
      {
        title: 'Năm học',
        data: 'Nam_hoc'
      },
      {
        title: 'Ký hiệu',
        data: 'Ky_hieu'
      },
      {
        title: 'Tên môn',
        data: 'Ten_mon'
      },
      {
        title: 'Tên lớp',
        data: 'Ten_lop'
      },
      {
        title: 'Số sinh viên',
        data: 'So_sv'
      },
      {
        title: 'Tên loại lớp',
        data: 'Ten_loai_lop'
      },
      {
        title: 'Tên loại lớp',
        data: 'Loai_lop',
        visible:false
      },
      {
        title:'Thao tác',
        defaultContent: '<i class="fas fa-file-alt" id="NhapDiemThi" title"Nhập điểm thi"></i>'
      },
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $('td', row).unbind('click');
        $('#NhapDiemThi', row).bind('click', () => {
          // alert(data['Ten_mon'])
          this.router.navigate(['/admin/NhapDiemThi'],{ queryParams: { CategoryID: data['Loai_lop'], ID: data['ID_lop'] } });
        });
        return row;
      }
    };
  }
  ClassListAll(UserName, url, token) {
    var m;
    try {
      $.ajax({
        type: "POST",
        url: url + "/api/Teacher/ClassListAll",
        async: false,
        data: { 'UserName': UserName },
        beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + token); },
        success: function (data) {
          m = data;
        },
        error: function (err) {
          m.Status = 3;
        },
      });
    }
    catch (err) {
      m.Status = 3;
    }
    finally {
      return m;
    }
  }
}
