import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/Utils/service/message.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { RequestSendMessage } from 'src/app/Models/input.model/RequestSendMessage';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ClassService } from 'src/app/Utils/service/class.service';
import { ResponseStudentsClass, Student } from 'src/app/Models/output.model/ResponseClassListAll';
import { Location } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  public Editor = ClassicEditor;
  public ReqSendMessage: RequestSendMessage = new RequestSendMessage()
  requestObject: RequestBase = new RequestBase()
  Res: ResponseStudentsClass = new ResponseStudentsClass()
  constructor(
    private modalService: NgbModal,
    private location: Location,
    public route: ActivatedRoute,
    private MessageService: MessageService,
    public router: Router,
    private toastr: ToastrService,
    private ClassService: ClassService
  ) { }
  Req: RequestBase = new RequestBase()
  title = 'appBootstrap';
  closeResult: string;
  Search: string = ""
  fileName: string = ""
  fileNameUpLoad: string = ""
  Title: string = ""
  ID: number
  Loai_Lop: number
  Token: string = ""
  FromUser: string = ""
  Content: string = ""
  FileBase64: string
  Category: number
  UserNames = []
  SelectedUserNames = []
  SeacherUserNames = []
  com: common;
  fileToUpload: File = null;
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.access_token
    this.FromUser = a.Info.UserName
    this.route.queryParams
      .subscribe(params => {
        this.Category = params.Category
        this.ID = params.ID
        if (this.Category == 3) {
          this.SelectedUserNames.push("<" + params.ID + ">")
        }
        if (this.Category == 4) {
          this.com = new common(this.router);
          this.com.CheckLogin();
          this.requestObject.UserName = a.Info.UserName
          this.requestObject.RequestID = this.ID
          this.getListStudents(this.requestObject, a.access_token)
          if (this.Loai_Lop == 2) {
            this.getListStudents(this.requestObject, a.access_token)
          } else {
            this.getListStudentsNienChe(this.requestObject, a.access_token)
          }
        }
        if (this.Category == 1) {
          this.ID = params.ID
          this.Req.UserName = a.Info.UserName
          this.Req.RequestID = this.ID
          this.getMessage(this.Req, a.access_token)
        }
      });


    this.GetListUserName(a.Token)


  }
  getListStudentsNienChe(req: RequestBase, token) {
    this.ClassService.getClassStudentsNienChe(req, token)
      .subscribe(z => {
        this.Res = z
        for (var i = 0; i < Object.keys(z.Data).length; i++) {
          this.SelectedUserNames.push(z.Data[i].Ho_ten + " <" + z.Data[i].Ma_sv + ">")
        }
      })
      ;
  }
  open(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  backClicked() {
    this.location.back();
  }
  getListStudents(req: RequestBase, token) {
    this.ClassService.getClassStudents(req, token)
      .subscribe(z => {
        this.Res = z
        for (var i = 0; i < Object.keys(z.Data).length; i++) {
          this.SelectedUserNames.push(z.Data[i].Ho_ten + " <" + z.Data[i].Ma_sv + ">")
        }
      })
      ;
  }
  onKey() {
    var a = this.Search
    this.SeacherUserNames = this.UserNames
    this.SeacherUserNames = this.SeacherUserNames.filter(e => e.includes(this.Search))
  }
  Select(p) {
    let isBlank = p.lastIndexOf('>') - p.lastIndexOf('<') == 1
    if (isBlank) {
      this.toastr.warning("Người dùng này chưa có tài khoản")
    }
    else {
      let index = this.SelectedUserNames.indexOf(p, 0);
      if (index == -1) {
        this.SelectedUserNames.push(p)
      }
      else {
        this.toastr.warning("Tài khoản đã được thêm trước đó")
      }
    }
  }
  Remove(p) {
    const index = this.SelectedUserNames.indexOf(p, 0);
    if (index > -1) {
      this.SelectedUserNames.splice(index, 1);
    }
  }
  GetListUserName(token) {
    this.MessageService.GetListUserName(token)
      .subscribe(z => {
        this.UserNames = z.Data
      })
      ;
  }
  onChange({ editor }: ChangeEvent) {
    this.Content = editor.getData();
  }
  SendMessage() {
    if (this.SelectedUserNames.length == 0) {
      this.toastr.warning("Bạn chưa chọn người nhận tin")
      return;
    }
    if (this.Title == "") {
      this.toastr.warning("Bạn cần nhập tiêu đề tin nhắn")
      return;
    }
    if (this.Content == "") {
      this.toastr.warning("Bạn cần nhập nội dung tin nhắn")
      return;
    }
    let ToUsers = []
    this.SelectedUserNames.forEach(function (value) {
      let index = value.lastIndexOf('<')
      let u = value.substr(index + 1, (value.length - index - 2))
      ToUsers.push(u)
    });
    this.ReqSendMessage.Content = this.Content
    this.ReqSendMessage.Title = this.Title
    this.ReqSendMessage.ToUsers = ToUsers
    this.ReqSendMessage.FromUser = this.FromUser
    if (this.fileNameUpLoad.length > 0) {
      this.ReqSendMessage.Attachment = this.fileNameUpLoad
      this.ReqSendMessage.FileBase64 = this.FileBase64
    }
    this.MessageService.SendMessage(this.ReqSendMessage, this.Token)
      .subscribe(dt => {
        if (dt.Status == 1) {
          this.toastr.success(dt.Message)
          this.ClearData()
        }
        else {
          this.toastr.error(dt.Message)
        }

      });
  }

  handleFileInput(files: FileList) {
    if (files.item(0) != null) {
      this.fileToUpload = files.item(0)
      this.fileName = this.fileToUpload.name + ' (' + this.fileToUpload.size + ')';
      this.fileNameUpLoad = this.fileToUpload.name
      this.readThis(this.fileToUpload)
    }
    else {
      this.fileName = ""
      this.fileNameUpLoad = ""
      this.FileBase64 = ""
    }
  }
  readThis(file: File): void {
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.FileBase64 = myReader.result.toString();
      let startIndex = this.FileBase64.lastIndexOf('base64,') + 6
      this.FileBase64 = this.FileBase64.substr(startIndex + 1, (this.FileBase64.length - startIndex - 1))
    }
    myReader.readAsDataURL(file);
  }
  ClearData() {
    this.Title = ""
    this.Content = ""
    this.fileName = ""
    this.fileNameUpLoad = ""
    this.FileBase64 = ""
    this.SelectedUserNames = []
  }
  getMessage(req: RequestBase, token) {
    this.MessageService.GetMessage(req, token)
      .subscribe(z => {
        if (z.Status == 1) {
          this.SelectedUserNames.push("<" + z.Data.FromUser + ">")
          this.Title = z.Data.Title
        }
        else {
          this.toastr.error(z.Message)
        }

      })
      ;
  }
}
