import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class Class {
  // ClassListAll(UserName, token) {
  //   var m
  //   try {
  //     $.ajax({
  //       type: "POST",
  //       url: environment.TeacherUrl + "Class/ClassListAll",
  //       dataType: "json",
  //       async: false,
  //       data: { 'UserName': UserName },
  //       beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + token) },
  //       success: function (data) {
  //         m = data;
  //       },
  //       error: function (err) {
  //         m.Status = 3;
  //       },
  //     });
  //   }
  //   catch (err) {
  //     m.Status = 3;
  //   }
  //   finally {
  //     return m;
  //   }
  // }
 // trả về cái gì

}
