import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'src/app/Utils/service/message.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { RequestSendMessage } from 'src/app/Models/input.model/RequestSendMessage';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ClassService } from 'src/app/Utils/service/class.service';
import { ResponseStudentsClass, Student } from 'src/app/Models/output.model/ResponseClassListAll';
import { Location } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'send-mail-time-swap',
  templateUrl: './send-mail-time-swap.component.html',
  styleUrls: ['./send-mail-time-swap.component.scss']
})
export class SendMailTimeSwapComponent implements OnInit {
  public Editor = ClassicEditor;
  @Input() dtMail: any;
  Noi_dung: string
  public ReqSendMessage: RequestSendMessage = new RequestSendMessage()
  requestObject: RequestBase = new RequestBase()
  Res: ResponseStudentsClass = new ResponseStudentsClass()
  dataMail: any;
  Loai: string;
  Email_GV: string;
  CheckEdit: number =0;
  constructor(
    private modalService: NgbModal,
    private location: Location,
    public route: ActivatedRoute,
    private MessageService: MessageService,
    public router: Router,
    private toastr: ToastrService,
    private ClassService: ClassService,
    private spinner: NgxSpinnerService,
  ) { }
  Req: RequestBase = new RequestBase()
  title = 'appBootstrap';
  closeResult: string;
  Search: string = ""
  fileName: string = ""
  fileNameUpLoad: string = ""
  Title: string = ""
  Nguoi_nhan: string = ""
  ID: number
  Loai_Lop: number
  Token: string = ""
  FromUser: string = ""
  Content: string = ""
  FileBase64: string
  Category: number
  UserNames = []
  SelectedUserNames = []
  SeacherUserNames = []
  com: common;
  fileToUpload: File = null;
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.access_token
    this.FromUser = a.Info.UserName
    if(this.dtMail.Duyet > 0)
    {
      this.CheckEdit = 1;
    }
    if (this.dtMail.Loai == true) {
      this.Loai = 'đổi, bù giờ'
    } else {
      this.Loai = 'nghỉ'
    }
    if(this.dtMail.Den_ngay != null)
    {
      this.dtMail.Den_ngay = this.dtMail.Den_ngay.substring(0, 10).split("-").reverse().join("/")
    }else{
      this.dtMail.Den_ngay = ''
    }
    this.Email_GV = a.Info.Email
    this.Nguoi_nhan = this.dtMail.Email_duyet
    this.Title = this.dtMail.Ho_ten + " đề xuất " + this.Loai
    this.Noi_dung = '<p>Tên giảng viên :&nbsp;' + this.dtMail.Ho_ten + '</p><p>Loại :&nbsp;' + this.Loai + '</p><p>Tên lớp:&nbsp;'
      + this.dtMail.Ten_lop + '</p><p>Tên môn:&nbsp;' + this.dtMail.Ten_mon + '</p><p>Ngày nghỉ: ' + this.dtMail.Tu_ngay.substring(0, 10).split("-").reverse().join("/") 
      + '</p><p>Ngày dạy bù: ' + this.dtMail.Den_ngay + '</p>'
  }


  onChange({ editor }: ChangeEvent) {
    this.Content = editor.getData();
  }
  SendMessage() {
    if(this.Content == "")
    {
     this.Content = this.Noi_dung
    }
    if (this.Nguoi_nhan == "") {
      this.toastr.warning("Bạn cần nhập người nhận tin")
      return;
    }
    if (this.Title == "") {
      this.toastr.warning("Bạn cần nhập tiêu đề tin nhắn")
      return;
    }
    // if (this.Content == "") {
    //   this.toastr.warning("Bạn cần nhập nội dung tin nhắn")
    //   return;
    // }
    let ToUsers = []
    this.Nguoi_nhan.split(';').forEach(function (value) {
      ToUsers.push(value);
    });
    this.ReqSendMessage.Content = this.Content
    this.ReqSendMessage.Title = this.Title
    this.ReqSendMessage.ToUsers = ToUsers
    this.ReqSendMessage.FromUser = this.FromUser
    this.ReqSendMessage.Email_send = this.Email_GV
    if (this.fileNameUpLoad.length > 0) {
      this.ReqSendMessage.Attachment = this.fileNameUpLoad
      this.ReqSendMessage.FileBase64 = this.FileBase64
    }
    this.spinner.show()
    this.MessageService.SendMessage_TS(this.ReqSendMessage, this.Token)
      .subscribe(dt => {
        this.spinner.hide()
        this.modalService.dismissAll('AddModal');
        if (dt.Status == 1) {
          this.toastr.success(dt.Message)
          this.ClearData()
        }
        else {
          this.toastr.error(dt.Message)
        }
      });
  }

  handleFileInput(files: FileList) {
    if (files.item(0) != null) {
      this.fileToUpload = files.item(0)
      this.fileName = this.fileToUpload.name + ' (' + this.fileToUpload.size + ')';
      this.fileNameUpLoad = this.fileToUpload.name
      this.readThis(this.fileToUpload)
    }
    else {
      this.fileName = ""
      this.fileNameUpLoad = ""
      this.FileBase64 = ""
    }
  }
  readThis(file: File): void {
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.FileBase64 = myReader.result.toString();
      let startIndex = this.FileBase64.lastIndexOf('base64,') + 6
      this.FileBase64 = this.FileBase64.substr(startIndex + 1, (this.FileBase64.length - startIndex - 1))
    }
    myReader.readAsDataURL(file);
  }
  ClearData() {
    this.Title = ""
    this.Content = ""
    this.fileName = ""
    this.fileNameUpLoad = ""
    this.FileBase64 = ""
    this.SelectedUserNames = []
    this.Noi_dung = ""
    this.Nguoi_nhan = ""
    this.Content = ""
  }
  getMessage(req: RequestBase, token) {
    this.MessageService.GetMessage(req, token)
      .subscribe(z => {
        if (z.Status == 1) {
          this.SelectedUserNames.push("<" + z.Data.FromUser + ">")
          this.Title = z.Data.Title
        }
        else {
          this.toastr.error(z.Message)
        }

      })
      ;
  }
}
