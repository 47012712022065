<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-8">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/attendence']">Điểm danh</a></li>
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/attendence-plan-new']"
                            [queryParams]="{IDLop: IDLop,Loai_lop:Loai_lop,ID_mon:ID_mon,Ten_mon:TitileTenMon,Ten_lop:TitleTenLop}"
                            queryParamsHandling="merge">Điểm danh lớp kế hoạch</a></li>
                    <li class="breadcrumb-item active">Thêm ngày điểm danh</li>
                </ol>
            </div>
            <div class=" col-md-4">

                <button class="btn btn-sm btn-success" (click)="Save()" style="float: right;margin-left: 5px;"><i
                        class="fas fa-save"></i> Lưu</button>
                <button class="btn btn-sm btn-primary" (click)="CheckAllDiHoc()"
                    style="float: right;margin-left: 5px;"><i class="fas fa-user-check"></i> Chọn
                    tất cả</button>
                <button class="btn btn-sm btn-primary" (click)="GetListDiemDanh()"
                    style="float: right;margin-left: 5px;">
                    <i class="fas fa-search"></i> Lấy danh sách</button>
                    <button  style="float: right;;" class="btn btn-sm btn-info " (click)="ExportToExcel_BaoCaoCoVan()"><i
                        class="fas fa-file-excel"></i> Xuất Excel</button>
            </div>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body" style="padding: 0px 20px 0px 20px;">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row pt10">
                        <div class="col-md-8">
                            <label class="col-md-12">Môn học : {{TitileTenMon}}</label>
                        </div>
                        <div class="col-md-4">
                            <label class="col-md-12">Lớp : {{TitleTenLop}}</label>
                        </div>
                        <!-- <div class="col-md-12">
                            <label class="col-md-12">Ngày điểm danh : {{Ngay_thang | date:'dd/MM/yyyy'}}</label>
                        </div> -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <form class="form-horizontal" [formGroup]="DiemDanh">
                <div class="card-header p-2 " style="align-content:flex-end ">
                    <div class="row" style="padding-left:10px; padding-right:10px">
                        <div class="col-md-3">
                            <label class="col-md-10">Ngày giảng dạy</label>
                            <input class="form-control" (change)="ClearTable()" type="date"
                            class="form-control" [ngClass]="{
                                'is-invalid': submitted && checkvalue.Ngay_thang.errors
                              }" formControlName="Ngay_thang">
                            <div *ngIf="submitted && checkvalue.Ngay_thang.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Ngay_thang.errors.required">
                                Ngày giảng dạy không được bỏ trống
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-12">Ca học</label>
                            <select class="form-control" [ngClass]="{
                                'is-invalid': submitted && checkvalue.Ca_hoc.errors
                              }"  (change)="ClearTable()" formControlName="Ca_hoc">
                                <option value="0">Sáng</option>
                                <option value="1">Chiều</option>
                                <option value="2">Tối</option>
                                <option value="-1">Cả ngày</option>
                            </select>
                            <div *ngIf="submitted && checkvalue.Ca_hoc.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Ca_hoc.errors.required">
                                    Ca học không được bỏ trống
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Từ tiết</label>
                            <input class="form-control" [ngClass]="{
                                'is-invalid': submitted && checkvalue.Tu_tiet.errors
                              }" (change)="ClearTable()" type="number" formControlName="Tu_tiet">
                            <div *ngIf="submitted && checkvalue.Tu_tiet.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Tu_tiet.errors.required">
                                    Tiết học không được bỏ trống
                                </div>
                                <div *ngIf="checkvalue.Tu_tiet.errors.min">
                                    Tiết học phải lớn hơn 0
                                </div>
                                <div *ngIf="checkvalue.Tu_tiet.errors.max">
                                    Tiết học không được lớn hơn 30
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Đến tiết</label>
                            <input class="form-control" [ngClass]="{
                                'is-invalid': submitted && checkvalue.Den_tiet.errors
                              }" (change)="ClearTable()" type="number"
                                formControlName="Den_tiet">
                                <div *ngIf="submitted && checkvalue.Den_tiet.errors" class="invalid-feedback">
                                    <div *ngIf="checkvalue.Den_tiet.errors.required">
                                        Tiết học không được bỏ trống
                                    </div>
                                    <div *ngIf="checkvalue.Den_tiet.errors.min">
                                        Tiết học phải lớn hơn 0
                                    </div>
                                    <div *ngIf="checkvalue.Den_tiet.errors.max">
                                        Tiết học không được lớn hơn 30
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <!-- <table datatable id="classTable" [dtOptions]="dtOptions" class="row-border hover"></table> -->
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData;let i = index">
                                            <td>{{ p.ID_sv }}</td>
                                            <td>{{ p.ID_dt }}</td>
                                            <td>{{ p.Ma_sv }}</td>
                                            <td>{{ p.Ho_ten }}</td>
                                            <td>{{ p.Ngay_sinh }}</td>
                                            <td>{{ p.Gioi_tinh }}</td>
                                            <td>{{ p.Ten_khoa }}</td>
                                            <td>{{ p.ID_lop }}</td>
                                            <td>
                                                <ng-container *ngIf="p.Co_ly_do==false">
                                                    <!-- <input type="radio" id="{{'Co_ly_do'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Co_ly_do}}"> -->
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Co_ly_do'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Co_ly_do}}" /><label
                                                        for="{{'Co_ly_do'+p.ID_sv}}"></label>
                                                </ng-container>
                                                <ng-container *ngIf="p.Co_ly_do==true">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Co_ly_do'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Co_ly_do}}" checked /><label
                                                        for="{{'Co_ly_do'+p.ID_sv}}"></label>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="p.Khong_ly_do==false">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Khong_ly_do'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Khong_ly_do}}"><label
                                                        for="{{'Khong_ly_do'+p.ID_sv}}"></label>
                                                </ng-container>
                                                <ng-container *ngIf="p.Khong_ly_do==true">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Khong_ly_do'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Khong_ly_do}}" checked><label
                                                        for="{{'Khong_ly_do'+p.ID_sv}}"></label>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="p.Di_hoc_muon==false">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Di_hoc_muon'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Di_hoc_muon}}"><label
                                                        for="{{'Di_hoc_muon'+p.ID_sv}}"></label>
                                                </ng-container>
                                                <ng-container *ngIf="p.Di_hoc_muon==true">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Di_hoc_muon'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Di_hoc_muon}}" checked><label
                                                        for="{{'Di_hoc_muon'+p.ID_sv}}"></label>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="p.Di_hoc==false">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Di_hoc'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Di_hoc}}"><label for="{{'Di_hoc'+p.ID_sv}}"></label>
                                                </ng-container>
                                                <ng-container *ngIf="p.Di_hoc==true">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Di_hoc'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Di_hoc}}" checked><label
                                                        for="{{'Di_hoc'+p.ID_sv}}"></label>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <input type="text" id="{{'Ghi_chu'+p.ID_sv}}" value="{{ p.Ghi_chu }}" />
                                            </td>
                                            <td>
                                                <ng-container *ngIf="p.Not_Locked==0">
                                                    <input type="radio" id="{{'Not_Locked'+p.Ma_sv}}"
                                                        name="{{p.Ma_sv+'Not_Locked'}}" value="{{p.Not_Locked}}">
                                                </ng-container>
                                                <ng-container *ngIf="p.Not_Locked==1">
                                                    <input type="radio" id="{{'Not_Locked'+p.Ma_sv}}"
                                                        name="{{p.Ma_sv+'Not_Locked'}}" value="{{p.Not_Locked}}"
                                                        checked>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <!-- /.card-body -->
                            </div>

                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>