import { Component, OnInit, OnDestroy, ViewChild, Inject } from "@angular/core";
import { AppService } from "src/app/Utils/services/app.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Class } from "src/app/Utils/services/Class.service";
import { common } from "src/app/app.common";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { ClassService } from "src/app/Utils/service/class.service";
import {
  ResponseClassListAll,
  ClassInfo,
} from "src/app/Models/output.model/ResponseClassListAll";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { NoteBookService } from "src/app/Utils/service/note-book.service";
import { ExportService } from "src/app/Utils/service/export.service";
import { TeachingPlanService } from "src/app/Utils/services/teaching-plan.service";
import { MarkService } from "src/app/Utils/service/mark.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { AttendenceService } from "src/app/Utils/service/attendence.service";
import { AppConfig, AppConfiguration } from "src/configuration";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent implements OnInit {
  dtOptions: DataTables.Settings;
  Res: ResponseClassListAll = new ResponseClassListAll();
  ClassData: ClassInfo[];
  requestObject: RequestBase = new RequestBase();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  HocKy: number;
  NamHoc: string;
  MonHoc: string;
  ReportCategory: any;
  ID_cb: any;
  Ho_ten: any;
  closeResult: string;
  token: any;
  Title: string = "Đang tải danh sách lớp...";
  TitleClass: string = "spinner-border text-muted";
  DataTableExcel: any;
  Nam_hoc_fil: string = "";
  Ten_mon_fil: string;
  Ten_lop_fil: string;
  Nam_hoc_arr: any = [];
  Hoc_ky: any;
  Nam_hoc: any;
  Id_mon: any;
  ID_lop: any;
  Ten_lop: any;
  Ten_mon: any;
  Ly_thuyet: any;
  Thuc_hanh: any;
  Thang_nam: Date;
  ID_he: any;
  CheckDiemDanh: any = true;
  ClassCategory: number;
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private modalService: NgbModal,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private ClassService: ClassService,
    private NoteBookService: NoteBookService,
    private exportService: ExportService,
    private TeachingPlanService: TeachingPlanService,
    private MarkService: MarkService,
    private AttendenceService: AttendenceService
  ) {
    //
  }
  dtTrigger = new Subject();
  public com: common;
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + "?" + this.appConfig.document_nhan_xet_giang_day;
    window.open(urlHelp, "_blank");
  }
  ngOnInit() {
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + "-" + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    $.fn["dataTable"].ext.search.push((settings, data, dataIndex) => {
      let hockydt = data[2]; // use data for the id column
      let NamHocdt = data[4] + ""; // use data for the id column
      let MonHocdt = data[0] + ""; // use data for the id column
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let fillter = true;
      if (this.HocKy > 0) {
        bool1 = this.HocKy == hockydt;
      }
      if (this.NamHoc != undefined) {
        bool2 = NamHocdt.includes(this.NamHoc);
      }
      if (this.MonHoc != undefined) {
        bool3 = MonHocdt.includes(this.MonHoc);
      }
      fillter = bool1 && bool2 && bool3;
      return fillter;
    });

    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.ID_cb = a.Info.UserID;
    this.Ho_ten = a.Info.FullName;
    //var rs=this.Class.ClassListAll(a.Info.UserName, a.access_token)
    this.requestObject.UserName = a.Info.UserName;
    this.token = a.access_token;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "Tên môn",
          data: "Ten_mon",
        },
        {
          title: "Tên lớp",
          data: "Ten_lop",
        },
        {
          title: "Học kỳ",
          className: "dt-center",
          data: "Hoc_ky",
        },
        {
          title: "ID_lop",
          data: "ID_lop",
          visible: false,
        },
        {
          title: "Năm học",
          className: "dt-center",
          data: "Nam_hoc",
        },
        {
          title: "Ký hiệu",
          data: "Ky_hieu",
        },
        {
          title: "Số sinh viên",
          className: "dt-center",
          data: "So_sv",
        },
        {
          title: "Tên loại lớp",
          data: "Ten_loai_lop",
        },
        {
          title: "Loại lớp",
          data: "Loai_lop",
          visible: false,
        },
        {
          title: "Xuất biểu mẫu",
          width: "12%",
          className: "dt-center",
        },
      ],
      order: [[2, "desc"]],
    };
    this.getListClass(this.requestObject, a.access_token);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn["dataTable"].ext.search.pop();
  }
  getListClass(req: RequestBase, token) {
    this.ClassService.getClass(req, token).subscribe((z) => {
      this.Res = z;
      this.ClassData = this.Res.Data;
      this.dtTrigger.next();
      this.Title = "Danh sách lớp";
      this.TitleClass = "";
    });
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
  getReport(ReportCategory) {
    var m = new Date(this.Thang_nam);
    let month = m.getMonth() + 1;
    let year = m.getFullYear();
    let req = {
      RequestID: this.ID_lop,
      ID_mon: this.Id_mon,
      UserName: this.requestObject.UserName,
      Thang: month,
      Nam: year,
      Loai_lop: this.ClassCategory,
      ID_lop_tc: this.ID_lop,
      ID_lop: this.ID_lop,
      Ten_lop: this.Ten_lop,
      Ten_mon: this.Ten_mon,
      HocKy: this.Hoc_ky,
      NamHoc: this.Nam_hoc,
      LanThi: 1,
      LanHoc: 1,
      ID_he: this.ID_he,
      Ngay_thang: this.Thang_nam,
    };
    if (ReportCategory > 0) {
      var r = confirm("Bạn có muốn tải xuống tải xuống file dữ liệu?");
      if (r) {
        this.TitleClass = "spinner-border text-muted";
        this.TitleClass = "";
        if (ReportCategory == 1) {
          if (this.ClassCategory == 2) {
            this.DiemQuaTrinhCreditExport(req, this.token);
          } else {
            this.DiemTongHopNienCheExport(req, this.token);
          }
        }
        if (ReportCategory == 2) {
          this.CheckDiemDanh = false;
          if (this.Thang_nam == null) {
            this.toastr.warning("Bạn phải nhập tháng năm");
          } else {
            this.AttendenceService.AttendenceExport_DiemDanh(
              req,
              this.token
            ).subscribe((z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(
                  z.FileData,
                  "DiemDanhSinhVien_" + this.requestObject.UserName
                );

                this.toastr.success(
                  "File đã được tải xuống",
                  "Thao tác thành công"
                );
              } else {
                this.toastr.error(z.Message, "Thao tác thất bại");
              }
              this.TitleClass = "";
            });
          }
        }
        if (ReportCategory == 4) {
          let req = {
            ID_kh_giang_day_giang_vien: 0,
            Hoc_ky: this.Hoc_ky,
            Nam_hoc: this.Nam_hoc,
            ID_mon: this.Id_mon,
            ID_lop: this.ID_lop,
            ID_cb: this.ID_cb,
            Ten_lop: this.Ten_lop,
            Ten_mon: this.Ten_mon,
            LT: this.Ly_thuyet,
            TH: this.Thuc_hanh,
          };
          this.TeachingPlanService.TeachingPlanExport(
            req,
            this.token
          ).subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(
                z.FileData,
                "KeHoachGiangVien_" +
                  this.requestObject.UserName +
                  "_" +
                  this.requestObject.RequestID
              );
              this.toastr.success(
                "File đã được tải xuống",
                "Thao tác thành công"
              );
            } else {
              this.toastr.error(z.Message, "Thao tác thất bại");
            }
            this.TitleClass = "";
          });
        }
        if (ReportCategory == 5) {
          var Request = {
            ID_cb: this.ID_cb,
            ID_lop: this.ID_lop,
            ID_mon: this.Id_mon,
            Hoc_ky: this.Hoc_ky,
            Nam_hoc: this.Nam_hoc,
            LanThi: 1,
            LanHoc: 1,
            Ten_mon: this.Ten_mon,
            Ho_ten: this.Ho_ten,
          };
          this.GetSoLenLopDataExport(this.token, Request);
        }
      }
    }
  }
  GetSoLenLopDataExport(token, req) {
    this.NoteBookService.ExportExcel(req, token).subscribe((z) => {
      this.TitleClass = "";
      if (z.Status == 1) {
        this.exportService.exportExcelByte(z.FileData, "SoLenLop");
        this.toastr.success("File đã được tải xuống", "Thao tác thành công");
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.TitleClass = "";
    });
  }
  DiemTongHopNienCheExport(req: any, token) {
    this.MarkService.TongHopDiemNienCheExport(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.exportService.exportExcelByte(
          z.FileData,
          "DiemTongHop_" +
            this.requestObject.UserName +
            "_" +
            this.requestObject.RequestID
        );
        this.toastr.success("File đã được tải xuống", "Thao tác thành công");
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.TitleClass = "";
    });
  }
  DiemQuaTrinhCreditExport(req: any, token) {
    this.MarkService.DiemQuaTrinhCreditExport(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.exportService.exportExcelByte(
          z.FileData,
          "Bangtheodoi_" +
            this.requestObject.UserName +
            "_" +
            this.requestObject.RequestID
        );
        this.toastr.success("File đã được tải xuống", "Thao tác thành công");
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.Title = "Danh sách điểm";
      this.TitleClass = "";
    });
  }
  open(content, sizm, type, Data) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

    this.DataTableExcel = [
      {
        Ten_bieu_mau: "Sổ lên lớp",
        ID: 5,
      },
      {
        Ten_bieu_mau: "Bảng điểm danh",
        ID: 2,
      },
      {
        Ten_bieu_mau: "Bảng điểm môn học ",
        ID: 1,
      },
      {
        Ten_bieu_mau: "Kế hoạch giảng dạy",
        ID: 4,
      },
    ];
    this.Hoc_ky = Data.Hoc_ky;
    this.Nam_hoc = Data.Nam_hoc;
    this.Id_mon = Data.ID_mon;
    this.ID_lop = Data.ID_lop;
    this.Ten_mon = Data.Ten_mon;
    this.Ten_lop = Data.Ten_lop;
    this.Ly_thuyet = Data.Ly_thuyet;
    this.Thuc_hanh = Data.Thuc_hanh;
    this.ClassCategory = Data.Loai_lop;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
