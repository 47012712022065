import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mark-credit-edit',
  templateUrl: './mark-credit-edit.component.html',
  styleUrls: ['./mark-credit-edit.component.scss']
})
export class MarkCreditEditComponent implements OnInit {
  private CategoryID : number
  private ID : number
  private LanThi : number
  constructor(public route: ActivatedRoute,) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.CategoryID=params.CategoryID
        this.ID=params.ID
      });
    this.LanThi = parseInt($('#Lan_thi').children("option:selected").val().toString());
    if(this.CategoryID==2){

    }
  }
// ClassCredit_GetInfo(IDLop, Lan_thi) {
//         $.ajax({
//             type: "POST",
//             url: "/Teacher/Mark/StudentsCredit_DiemThiLoadList",
//             data: {
//                 'RequestID': IDLop,
//                 'LanThi': Lan_thi,
//             },
//             success: function (data) {
//                 if (data.Status == 1) {
//                     Students = data.Data;
//                     ThanhPhans = data.DiemThanhPhans;
//                     for (var i = 0; i < Students.length; i++) {
//                         if (Students[i].Ghi_chu == "null") {
//                             Students[i].Ghi_chu = ""
//                         }
//                     }
//                     RenderData(Students, ThanhPhans);
//                 }
//                 else {
//                     alert(data.Message);
//                 }
//             }
//         });
//     }

}
