import { Injectable, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import {
  RequestTeachingScheduleList,
  RequestClassInfo,
} from "src/app/Models/input.model/RequestTeachingScheduleList";
import { ResponseListSchedule } from "src/app/Models/output.model/ResponseListSchedule";
import { Observable } from "rxjs";
import { map, filter, catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class NoteBookService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }
  Load(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "/Notebook/Load", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  // ExportExcel(req: any, token): Observable<any> {
  //   return this.http.post<any>(this.appConfig.TeacherUrl + '/Notebook/ExportExcel', req, {
  //     headers: new HttpHeaders()
  //       .set('Authorization', `Bearer ${token}`)
  //   }).pipe(map(z => { return z }))
  // }
  Insert(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "/Notebook/Insert", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Update(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "/Notebook/Update", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Delete(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "/Notebook/Delete", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ExportExcel(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "/BaoCao/Load_SoLenLop", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  ImportFromTKB(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "/Notebook/InsertFromTKB", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}
