<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Thống kê báo cáo</li>
                </ol>
            </div>
            <!-- <div class="col-md-6">
               <button class="btn btn-success btn-sm" (click)="open(ThemModal, 'xl', 'Add', '')" style="float: right;">
                    <i class="fas fa-plus-circle"></i> Thêm mới
                </button> 
            </div> -->
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <form class="form-horizontal" [formGroup]="Filter">
                <div class="row">
                    <div class="col-md-3 ">
                        <label>Năm học</label>
                        <select class="form-control" formControlName="Nam_hoc_ft" (change)="changeFilter()">
                            <option *ngFor="let p of Nam_hoc_Arr" value="{{p.value}}">{{p.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 ">
                        <label>Học kỳ</label>
                        <select class="form-control" formControlName="Hoc_ky_ft" (change)="changeFilter()">
                            <option value="1">Học kỳ I</option>
                            <option value="2">Học kỳ II</option>
                        </select>
                    </div>
                    <div class="col-md-3 ">
                        <label>Tên lớp</label>
                        <select class="form-control" formControlName="Ten_lop_ft">
                            <option value="">Chọn lớp</option>
                            <option *ngFor="let p of ClassData" value="{{p.ID_lop}}">{{p.Ten_lop}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 ">
                        <label>Tên môn</label>
                        <select class="form-control" formControlName="Ten_mon_ft">
                            <option value="">Chọn môn</option>
                            <option *ngFor="let p of SubjectData" value="{{p.ID_mon}}">{{p.Ten_mon}}</option>
                        </select>
                    </div>

                </div>
                <div class="row pt10">
                    <div class="col-md-3">
                        <label>Loại</label>
                        <select class="form-control">
                            <option>Kế hoạch giảng viên</option>
                            <option>Bù giờ</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active">
                    <div class="row">
                        <div class="table-responsive">
                                <table class="table  table-bordered  table-hover" >
                                    <thead>
                                        <tr>
                                            <th>Tên báo cáo</th>
                                            <th class="text-center">Thao tác</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of DataReport">
                                            <td>{{ p.name }}</td>
                                            <td class="text-center"><a style="margin-right: 5px;" class="btn btn-primary btn-sm"
                                                (click)="ExportTOExcel(p.id)"   >
                                                    <i class="fas fa-plus-circle" ngbTooltip="Kế hoạch"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>
</section>
