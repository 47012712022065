import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/Utils/services/app.service';
import { Class } from 'src/app/Utils/services/Class.service';
import { ClassService } from 'src/app/Utils/service/class.service';
import { AttendenceService } from 'src/app/Utils/service/attendence.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { common } from 'src/app/app.common';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

import { ExportService } from 'src/app/Utils/service/export.service';
@Component({
  selector: 'app-attendence-edit-theo-tiet',
  templateUrl: './attendence-edit-theo-tiet.component.html',
  styleUrls: ['./attendence-edit-theo-tiet.component.scss']
})
export class AttendenceEditTheoTietComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(
    public route: ActivatedRoute,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private ClassService: ClassService,
    private AttendenceService: AttendenceService,
    private spinner: NgxSpinnerService,
    private exportService: ExportService,
  ) { }
  public com: common;
  
  So_sv:any
  ID_lop: any;
  ID_mon: any;
  Ten_mon: string
  Ten_lop: string
  UserName: any;
  token: any;
  Loai_lop: any;
  TableData: any;
  requestObject: any;
  dtTrigger = new Subject();
  ClassData: any = [];
  dtOptions: DataTables.Settings
  ngOnInit() {

    this.spinner.show()
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.route.queryParams
      .subscribe(params => {
        this.ID_lop = params.IDLop
        this.Loai_lop = params.Loai_lop
        this.ID_mon = params.ID_mon
        this.Ten_mon = params.Ten_mon
        this.Ten_lop = params.Ten_lop
        this.So_sv = params.So_sv
      });
    //var rs=this.Class.ClassListAll(a.Info.UserName, a.access_token)
    this.UserName = a.Info.UserName
    this.token = a.access_token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [
        {
          title: 'Ngày giảng dạy',
          className: "dt-center"
        },
        {
          title: 'Ca học',
          className: "dt-center"
        },
        {
          title: 'Số sinh viên',
          className: "dt-center"
        },
      
        {
          title: 'Thao tác',
          width: "10%",
          className: "dt-center"
        },
      ],
    };
    this.getListSchedule(this.UserName, this.ID_lop, this.ID_mon, this.Loai_lop, this.token)
  }
  getListSchedule(userName, ID_lop, ID_mon, Loai, token) {
    this.AttendenceService.DiemDanhKeHoach_Load_List(userName, ID_lop, ID_mon, Loai, token)
      .subscribe(z => {
        this.ClassData = z.Data
        this.dtTrigger.next();
        this.spinner.hide()
      })
      ;
  }
  Delete( ID_lop, ID_mon, Ngay_thang, Tiet, So_tiet, Ca_hoc) {
    var r=confirm("Bạn có muốn xóa dữ liệu điểm danh ngày "+Ngay_thang  +" không?")
    if(r){
      this.AttendenceService.DiemDanhKeHoach_Delete(this.UserName, ID_lop, ID_mon, this.Loai_lop, Ngay_thang, Tiet, So_tiet, Ca_hoc, this.token)
      .subscribe(z => {
        if (z.Status == 1) {
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
          this.getListSchedule(this.UserName, this.ID_lop, this.ID_mon, this.Loai_lop, this.token)
          this.toastr.success(z.Message)
        }
        else {
          this.toastr.warning(z.Message)
        }
      });
    }
    
  }
  BangDiemDanhTheoDoi_Export(){
    
    let req={
      ID_lop: this.ID_lop ,
      Loai_lop:  this.Loai_lop,
      Ten_lop:this.Ten_lop,
      Ten_mon:this.Ten_mon
    }
    this.AttendenceService.BangDiemDanhTheoDoi_Export(req,this.token) .subscribe(z => {
      if (z.Status == 1) {
        this.exportService.exportExcelByte(z.FileData, 'DiemDanhTheoNgay'+this.Ten_lop );

        this.toastr.success("File đã được tải xuống", "Thao tác thành công")
      }
      else {
        this.toastr.error(z.Message, "Thao tác thất bại")
      }
    });
  }
}
