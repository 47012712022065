import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppService } from 'src/app/Utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Class } from 'src/app/Utils/services/Class.service';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ClassService } from 'src/app/Utils/service/class.service';
import { AttendenceService } from 'src/app/Utils/service/attendence.service';
import { ResponseClassListAll, ClassInfo } from 'src/app/Models/output.model/ResponseClassListAll';
import { ResponseListSchedule, ClassData } from 'src/app/Models/output.model/ResponseListSchedule';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { RequestTeachingScheduleList, RequestClassInfo } from 'src/app/Models/input.model/RequestTeachingScheduleList';
import { ResponseDiemDanhChiTiet } from 'src/app/Models/output.model/ResponseTeachingScheduleList';
import { ResponseBase } from 'src/app/Models/output.model/ResponseBase';
import { count } from 'rxjs/operators';
import { ZipOperator } from 'rxjs/internal/observable/zip';
import { ExportService } from 'src/app/Utils/service/export.service';
import { UserInfo } from 'src/app/Models/output.model/ResponseLogin';


@Component({
  selector: 'app-attendence-day',
  templateUrl: './attendence-day.component.html',
  styleUrls: ['./attendence-day.component.scss']
})
export class AttendenceDayComponent implements OnInit {
  dtOptions: DataTables.Settings
  Res: ResponseDiemDanhChiTiet = new ResponseDiemDanhChiTiet()
  Response: ResponseBase = new ResponseBase()
  ClassData: any
  requestObject: RequestClassInfo = new RequestClassInfo()
  requestObject1: RequestTeachingScheduleList = new RequestTeachingScheduleList()
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  IDLop: number;
  IDMon: number;
  CaHoc: string;
  Tiet: number;
  LoaiLop: any;
  IsAll: boolean;
  Title: string = "Đang tải danh sách điểm danh"
  TitleClass: string = "spinner-border text-muted cus_width"
  TitleTenLop: string
  Token: string;
  IDLop_tc:number
  Info: UserInfo;
  TitleTenMon: any;
  constructor(
    public route: ActivatedRoute,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private ClassService: ClassService,
    private AttendenceService: AttendenceService,
    private exportService: ExportService,
    private AppService: AppService
  ) {
    // 

  }
  dtTrigger = new Subject();
  public com: common;
  ngOnInit() {
    this.IsAll = true
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.access_token
    this.Info = a.Info
    this.route.queryParams
      .subscribe(params => {
        this.IDLop = params.IDLop
        this.IDMon = params.ID_mon
        this.CaHoc = params.CaHoc
        this.Tiet = params.Tiet
        this.TitleTenLop = params.Ten_lop
        this.LoaiLop = params.Loai_lop
        this.TitleTenMon = params.Ten_mon
      });

    this.requestObject.UserName = a.Info.UserName
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [
        {
          title: 'Ngày tháng'
        },
        {
          title: 'Ca học', className: "dt-center",
        },
        {
          title: 'Số tiết đi học', className: "dt-center",
        },
        {
          title: 'Số tiết nghỉ', className: "dt-center",
        },
        {
          title: 'Số tiết nghỉ có lý do', className: "dt-center",
        },
        {
          title: 'Số tiết nghỉ không lý do', className: "dt-center",
        },
        {
          title: 'Tình hình đi học',
        },
        {
          title: 'Chi tiết', className: "dt-center",
        }
      ],
    };

    this.ClassCreditGetInfo()
  }
  ClassCreditGetInfo() {
    if (this.LoaiLop == 2) {
      this.AttendenceService.AttendenceGetListDay_TinChi(this.IDLop, this.IDMon, this.Token)
        .subscribe(z => {
          this.Res = z
          for (var i = 0; i < this.Res.Data.length; i++) {
            var datePart = this.Res.Data[i].Ngay_thang.substring(0,10).split("-").reverse().join("/")
            this.Res.Data[i].Ngay_thang =  datePart
          }
          this.ClassData = this.Res.Data
          this.dtTrigger.next();
          this.Title = ""
          this.TitleClass = ""
        },
        errors => {
          this.toastr.error("ERROR: " + errors.message);
          this.AppService.logout();
        },);
    }else{
      this.AttendenceService.AttendenceGetListDay_NienChe(this.IDLop, this.IDMon, this.Token)
      .subscribe(z => {
        
        for (let i = 0; i < Object.keys(z.Data).length; i++) {
          z.Data[i].Ten_lop = this.IDLop
        }
        this.Res = z
        for (var i = 0; i < this.Res.Data.length; i++) {
          var datePart = this.Res.Data[i].Ngay_thang.substring(0,10).split("-").reverse().join("/")
          this.Res.Data[i].Ngay_thang =  datePart
        }
        this.ClassData = this.Res.Data
        this.dtTrigger.next();
        this.Title = ""
        this.TitleClass = ""
      },
      errors => {
        this.toastr.error("ERROR: " + errors.message);
        this.AppService.logout();
      });
    }
  }
  getStyle(x, ca) {
    console.log (this.ClassData);
    console.log (x);
    for (let i = 0; i < Object.keys(this.ClassData).length; i++) {
      if (this.ClassData[i].Ngay_thang == x && this.ClassData[i].Ca_hoc== ca) {
        let floorElements = document.getElementById(x+'_' + (ca==='Sáng' ?0:1));
        if (this.ClassData[i].Phan_tram_di_hoc == "NaN") {
          this.ClassData[i].Phan_tram_di_hoc = 0;
        }
        floorElements.setAttribute("style", "width:" + this.ClassData[i].Phan_tram_di_hoc + "%");
        break;
      }
    }
  }
  getStyledr(x, ca) {
    for (let i = 0; i < Object.keys(this.ClassData).length; i++) {
      if (this.ClassData[i].Ngay_thang == x && this.ClassData[i].Ca_hoc== ca) {
        let floorElements = document.getElementById(x+'_' + (ca==='Sáng' ?0:1) + 'dr');
        if (this.ClassData[i].Phan_tram_nghi_hoc == "NaN") {
          this.ClassData[i].Phan_tram_nghi_hoc = 0;
        }
        floorElements.setAttribute("style", "width:" + this.ClassData[i].Phan_tram_nghi_hoc + "%");
        break;
      }
    }
  }

  exportExcel() {
    let res = {
      MonHoc: {
        ID_mon: this.IDMon,
        ID_lop: this.IDLop,
        Ten_lop: this.TitleTenLop,
        Loai_lop:this.LoaiLop,
        Ten_mon: this.TitleTenMon
      }
    }
    this.AttendenceService.AttendenceDayOffExport(res, this.Token)
      .subscribe(z => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'DiemDanhSinhVien' + this.Info.FullName + '_' + this.Info.Ten_khoa);

          this.toastr.success("File đã được tải xuống", "Thao tác thành công")
        }
        else {
          this.toastr.error(z.Message, "Thao tác thất bại")
        }
        this.TitleClass = ""
      });
  }
}

