<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <ol class="breadcrumb float-sm-left">
                <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                <!-- <li class="breadcrumb-item"><a [routerLink]="['/admin/attendencehistory']">Lịch sử điểm danh</a></li> -->
                <li class="breadcrumb-item"><a style="color: #007bff;" (click)="backClicked()">Tra cứu điểm danh</a>
                </li>
                <!-- <li class="breadcrumb-item active">{{TitleTenSV}} &nbsp;</li> -->
                <li class="breadcrumb-item active">{{TitleLop}} &nbsp;</li>

                <li class="breadcrumb-item active">{{TitleTenSV}} &nbsp;</li>
                <!-- <li class="breadcrumb-item active">Dữ liệu điểm danh sinh viên &nbsp;</li> -->
                <div class="{{TitleClass}}"></div>
            </ol>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<!-- Main content -->
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <!-- <table datatable id="classTable" [dtOptions]="dtOptions" class="row-border hover"></table> -->
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th style="width: 15%;"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData;let i = index ">
                                            <td>{{ i+1 }}</td>
                                            <td>{{ p.Ngay_thang }}</td>
                                            <td>{{ p.Ten_ca }}</td>
                                            <td>{{ p.So_tiet }}</td>
                                            <td>
                                                <!-- <ng-container *ngIf="p.Co_ly_do==false">
                                                    <input type="radio" id="{{'Co_ly_do'+p.STT}}" disabled
                                                        name="{{p.STT}}" value="{{p.Co_ly_do}}">
                                                </ng-container> -->
                                                <ng-container *ngIf="p.Co_ly_do==true">
                                                    <i style="color:#17a2b8" class="fas fa-check"></i>
                                                </ng-container>

                                            </td>
                                            <td>

                                                <!-- <ng-container *ngIf="p.Khong_ly_do==false">
                                                    <input type="radio" id="{{'Khong_ly_do'+p.STT}}" disabled
                                                        name="{{p.STT}}" value="{{p.Khong_ly_do}}">
                                                </ng-container> -->
                                                <ng-container *ngIf="p.Khong_ly_do==true">
                                                    <i style="color:#17a2b8" class="fas fa-check"></i>
                                                </ng-container>
                                            </td>
                                            <td>

                                                <!-- <ng-container *ngIf="p.Di_muon==false">
                                                    <input type="radio" id="{{'Di_muon'+p.STT}}" disabled
                                                        name="{{p.STT}}" value="{{p.Di_muon}}">
                                                </ng-container> -->
                                                <ng-container *ngIf="p.Di_muon==true">
                                                    <i style="color:#17a2b8" class="fas fa-check"></i>
                                                </ng-container>

                                            </td>
                                            <td>

                                                <!-- <ng-container *ngIf="p.Di_hoc==false">
                                                    <input type="radio" id="{{'Di_hoc'+p.STT}}" disabled
                                                        name="{{p.STT}}" value="{{p.Di_hoc}}">
                                                </ng-container> -->
                                                <ng-container *ngIf="p.Di_hoc==true">
                                                    <i style="color:#17a2b8" class=" fas fa-check"></i>
                                                </ng-container>

                                            </td>
                                            <td>

                                                <input type="text" id="{{'Ghi_chu'+p.STT}}" disabled name="{{p.STT}}"
                                                    value="{{p.Ghi_chu}}">

                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>
<!-- /.content -->