import { Component, OnInit, ViewChild, Input, EventEmitter, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl } from '@angular/forms';
import { TimeSwapService } from 'src/app/Utils/service/time-swap.service';
import { ClassService } from 'src/app/Utils/service/class.service';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ExportService } from 'src/app/Utils/service/export.service';
import { SendMailTimeSwapComponent } from './send-mail-time-swap/send-mail-time-swap.component';
import { AppConfig, AppConfiguration } from 'src/configuration';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-time-swap',
  templateUrl: './time-swap.component.html',
  styleUrls: ['./time-swap.component.scss']
})
export class TimeSwapComponent implements OnInit {
  @ViewChild(SendMailTimeSwapComponent) send_mail: SendMailTimeSwapComponent
  CheckEdit: boolean = false;
  Status: any;
  Ly_do_huy: any;
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private modalService: NgbModal,
    private TimeSwapService: TimeSwapService,
    private ClassService: ClassService,
    public router: Router,
    private toastr: ToastrService,
    private exportService: ExportService,
    // private spinner: NgxSpinnerService
  ) { }
  Filter = new FormGroup({
    Hoc_ky_ft: new FormControl(1),
    Nam_hoc_ft: new FormControl((new Date().getFullYear() -1) + '-' + new Date().getFullYear()),
    ID_trang_thai_ft: new FormControl(1),
    Duyet_ft: new FormControl(1), //
  });
  Insert = new FormGroup({
    Tu_tiet: new FormControl(null, [Validators.required, Validators.min(1)]),
    Den_tiet: new FormControl(null, [Validators.required, Validators.min(1)]),
    Tu_ngay: new FormControl(null, [Validators.required]),
    Den_ngay: new FormControl(null, [Validators.required]),
    Ly_do: new FormControl(null),
    Ghi_chu: new FormControl(null),
    ID_trang_thai: new FormControl('', [Validators.required]),
    Email_duyet: new FormControl(null,[Validators.required, Validators.email])
  });
  dtMail: any;
  dataTable: any = []
  Nam_hoc_Arr: any = []
  ClassData: any = []
  Data: any = []
  requestObject: RequestBase = new RequestBase()
  ID: any
  checkInsert: any
  titleModal: any
  closeResult: string
  submitted = false
  dtOptions: DataTables.Settings;
  dtOptions2: DataTables.Settings;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();
  dtTrigger2 = new Subject();
  public com: common
  Token: any
  Ten_khoa: any
  ID_cb: any
  ngOnInit() {
    this.com = new common(this.router);
    var User = this.com.getUserinfo();
    this.ID_cb = User.Info.UserID
    this.Ten_khoa = User.Info.Ten_khoa
    var yearNow = new Date().getFullYear() + 3;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1)
      let object = {
        'value': Nam_hoc_string,
        'name': Nam_hoc_string
      }
      this.Nam_hoc_Arr.push(object)
      yearNow--
    }
    this.requestObject.UserName = User.Info.UserName
    this.com = new common(this.router)
    this.com.CheckLogin();
    var a = this.com.getUserinfo()
    this.Token = a.access_token
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID',
          visible: false,
        },
        {
          title: 'Mã cán bộ',
          visible: false,
        },
        {
          title: 'Họ tên',
          visible: false,
        },
        {
          title: 'Tên lớp',
        },
        {
          title: 'Tên môn',
        },
        {
          title: 'Sỹ số',
          className: 'dt-center',
        },
        {
          title: 'Tiết bắt đầu nghỉ',
          className: 'dt-center',
        },
        {
          title: 'Ngày nghỉ',
          className: 'dt-center',
        },
        {
          title: 'Tiết bắt đầu dạy',
          className: 'dt-center',
        },
        {
          title: 'Ngày dạy',
          className: 'dt-center',
        },
        {
          title: 'Trạng thái',
          className: 'dt-center',
        },
        {
          title: 'Lý do hủy duyệt',
        },
        {
          title: 'Thao tác',
          className: 'dt-center',
          width: "13%",
        },
      ],
      "order": [[0, "desc"]]
    };
    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          className: 'dt-center',
        },
        {
          title: 'Tên môn',
        },
        {
          title: 'Tên lớp',
        },
        {
          title: 'Học kỳ',
          className: 'dt-center',
        },
        {
          title: 'Năm học',
          className: 'dt-center',
        },
        {
          title: 'Sỹ số',
          className: 'dt-center',
        }
      ],

    };
    this.getList()
  }
  getList() {
    this.TimeSwapService.getList(this.Filter.value.Hoc_ky_ft, this.Filter.value.Nam_hoc_ft, this.Filter.value.ID_trang_thai_ft, this.ID_cb, this.Token).subscribe((z) => {
      // if (z.Data.length > 0) {
      //   z.Data = z.Data.filter(dt => {
      //      
      //     let check_tt;
      //     if (dt.Duyet == null) {
      //       check_tt = 'null'
      //     } else {
      //       check_tt = dt.Duyet.toString();          
      //       return this.Filter.value.Duyet_ft == dt.Duyet;
      //     }
      //   })
      // }
      this.dataTable = z.Data
      this.ClassService.getClass(this.requestObject, this.Token).subscribe(z => {
        this.ClassData = z.Data
        this.dataTable.forEach((element, index) => {
          this.ClassData.forEach(value => {
            if (element.ID_lop == value.ID_lop) {
              this.dataTable[index].Ten_lop = value.Ten_lop
            }
          });
        });
        this.dtTrigger.next()
      });
    });
  }
  changeFilter() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.getList();
  }
  open(content, sizm, Loai, Data) {
    this.ID = Data.ID_doi_gio
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.Insert.reset()
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.Insert.reset()
        }
      );

    this.CheckEdit = false

    if (Loai == 'Add') {
      this.Data = []
      // this.CheckEdit = false
      this.Insert.get('Tu_tiet').setValue('');
      this.Insert.get('Den_tiet').setValue('');
      this.Insert.get('Tu_ngay').setValue('');
      this.Insert.get('Den_ngay').setValue('');
      this.Insert.get('Ly_do').setValue('');
      this.Insert.get('Ghi_chu').setValue('');
      this.Insert.get('ID_trang_thai').setValue('');
      this.Insert.get('Email_duyet').setValue('');
      this.Status = 0;
      this.Ly_do_huy = '';
      this.titleModal = 'Thêm mới đổi, bù giờ, nghỉ'
      this.checkInsert = true
      this.ClassService.getClass(this.requestObject, this.Token).subscribe(z => {
        this.ClassData = z.Data
      });
    } else if (Loai == 'Edit') {
      this.checkInsert = false
      this.Status = Data.Duyet;
      this.Ly_do_huy = Data.Ly_do_duyet;
      this.Insert.get('Tu_tiet').setValue(Data.Tu_tiet);
      this.Insert.get('Den_tiet').setValue(Data.Den_tiet);
      this.Insert.get('Tu_ngay').setValue(Data.Tu_ngay.split("T")[0]);
      if (Data.Den_ngay != null) {
        this.Insert.get('Den_ngay').setValue(Data.Den_ngay.split("T")[0]);
      }
      this.Insert.get('Ly_do').setValue(Data.Ly_do);
      this.Insert.get('Ghi_chu').setValue(Data.Ghi_chu);
      this.Insert.get('ID_trang_thai').setValue(Data.ID_trang_thai);
      this.Insert.get('Email_duyet').setValue(Data.Email_duyet);
      this.titleModal = 'Sửa đổi, bù giờ, nghỉ'
      if (Data.Duyet > 0) {
        this.CheckEdit = true
      }
      this.ClassService.getClass(this.requestObject, this.Token).subscribe(z => {
        this.ClassData = z.Data
        z.Data.forEach((element, index) => {
          if (element.Hoc_ky == Data.Hoc_ky && element.Nam_hoc == Data.Nam_hoc && element.ID_mon == Data.ID_mon && element.ID_lop && element.So_sv == Data.Sy_so) {
            z.Data[index].Selected = true
            this.SelectRow(z.Data[index])
          } else {
            z.Data[index].Selected = false
          }
        });
      });
    } else if (Loai == 'SendMail') {
      this.dtMail = Data;
    } else if (Loai = 'Delete') {
      if (Data.Duyet > 0) {
        this.CheckEdit = true
      }
    }

  }
  get checkvalue() {
    return this.Insert.controls
  }
  ExportExcel(ID_doi_gio, Hoc_ky, Nam_hoc, ID_cb) {
    this.TimeSwapService.ExportExcel(ID_doi_gio, Hoc_ky, Nam_hoc, ID_cb, this.Ten_khoa, this.Token).subscribe((z) => {
      this.exportService.exportExcelByte(z.FileData, 'PhieuBaoDoiBuGio');
      this.toastr.success("File đã được tải xuống", "Thao tác thành công")
    });
  }
  onSubmit() {
    if (this.Data == null || this.Data == undefined || this.Data == '' || this.Data.length == 0) {
      this.toastr.warning("Bạn chưa chọn lớp học!")
      return false
    }
    this.submitted = true
    if (this.Insert.controls.ID_trang_thai.value == "" ||this.Insert.controls.ID_trang_thai.value == "1" ) {
      if (this.Insert.invalid) {
        return false
      }
    }
    if (this.Insert.value.ID_trang_thai == "0") {
      if (this.Insert.controls.Tu_tiet.status == "INVALID" && this.Insert.controls.Tu_ngay.status == "INVALID") {
        return false
      }
    }
    this.create()
  }
  Delete() {
    this.TimeSwapService.Delete(this.ID, this.Token).subscribe((z) => {
      if (z.Status == 2) {
        this.toastr.warning(z.Message);
      } else if (z.Status == 1) {
        this.toastr.success(z.Message);
      } else if (z.Status == 4) {
        this.toastr.error('Xóa thất bại!');
      }
      this.modalService.dismissAll('AddModal');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.getList();
    });
  }
  create() {
    let input = {
      ID_doi_gio: this.ID,
      Hoc_ky: this.Data.Hoc_ky,
      Nam_hoc: this.Data.Nam_hoc,
      ID_cb: this.ID_cb,
      ID_mon: this.Data.ID_mon,
      ID_lop: this.Data.ID_lop,
      Sy_so: this.Data.So_sv,
      Tu_tiet: this.Insert.controls.Tu_tiet.value,
      Den_tiet: this.Insert.controls.Den_tiet.value,
      Tu_ngay: this.Insert.controls.Tu_ngay.value,
      Den_ngay: this.Insert.controls.Den_ngay.value,
      Ghi_chu: this.Insert.controls.Ghi_chu.value,
      Ly_do: this.Insert.controls.Ly_do.value,
      ID_trang_thai: this.Insert.controls.ID_trang_thai.value,
      Email_duyet: this.Insert.controls.Email_duyet.value,
      Duyet: 0
    }
    if (this.checkInsert == true) {
      this.TimeSwapService.Insert(input, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else if (z.Status == 4) {
          this.toastr.error('Thêm mới không thành công!');
        } else if (z.Status == 8) {
          this.toastr.warning('Đã tồn tại đổi bù giờ!');
        }
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
        // this.getList(1, new Date().getFullYear() + '-' + (new Date().getFullYear() + 1));
      });
    } else {
      this.TimeSwapService.Update(input, this.Token).subscribe((z) => {
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else if (z.Status == 4) {
          this.toastr.error('Cập nhật không thành công!');
        } else if (z.Status == 8) {
          this.toastr.warning('Đã tồn tại đổi bù giờ!');

        }
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getList();
        // this.getList(1, new Date().getFullYear() + '-' + (new Date().getFullYear() + 1));
      });
    }

  }
  SelectRow(Data) {
    this.Data = Data
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC'
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`
    }
  }
  setStyle(x: any) {
    let floorElements = document.getElementById(x)
    floorElements.setAttribute('style', 'border: 10px')
  }

  help() {
    const urlHelp = this.appConfig.HelpUrl + "?" + this.appConfig.document_doi_bu_gio_nghi;
    window.open(urlHelp, '_blank');
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger2.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }
}



