<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
  
          </div><!-- /.col -->
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                  <li class="breadcrumb-item active">Danh sách lớp học</li>
              </ol>
          </div><!-- /.col -->
      </div><!-- /.row -->
  </div><!-- /.container-fluid -->
  </section>

<!-- Main content -->
<section class="content">

    <div class="card">
        <div class="card-header p-2 " style="align-content:flex-end ">
            <div class="row" style="padding-left:10px; padding-right:10px">
                <div class="col-md-4">
                    <label class="col-md-10">Năm học:</label>
                    <select class="form-control" id="Nam_hoc" onchange="filter()"></select>

                </div>
                <div class="col-md-4">
                    <label class="col-md-10">Học kỳ:</label>
                    <select class="form-control" id="Hoc_ky" onchange="filter()">
                        <option value="-1" selected>Tất cả</option>
                        <option value="0">1</option>
                        <option value="1">2</option>
                        <option value="1">3</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label class="col-md-10">Môn học:</label>
                    <input type="text" class="form-control" onkeyup="filter()" id="Mon_hoc" />
                </div>
            </div><hr />
        </div><!-- /.card-header -->
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="card card-primary card-outline">
                                <div class="card-header">
                                    <h3 class="card-title">Danh sách lớp</h3>
                                    <!-- /.card-tools -->
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-0">
                                    <div class="table-responsive mailbox-messages">
                                        <!-- <table id="maintable" class="table table-hover table-striped" style="width:100%;z-index: -1"></table> -->
                                        <table datatable id="classTable" [dtOptions]="dtOptions" class="row-border hover"></table>
                                        <!-- /.table -->
                                    </div>
                                    <!-- /.mail-box-messages -->
                                </div>
                                <!-- /.card-body -->
                            </div>

                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>
<!-- /.content -->
