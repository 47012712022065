import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  DebugElement,
  Inject,
} from "@angular/core";
import { AppService } from "src/app/Utils/services/app.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { Class } from "src/app/Utils/services/Class.service";
import { common } from "src/app/app.common";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { ClassService } from "src/app/Utils/service/class.service";
import { AttendenceService } from "src/app/Utils/service/attendence.service";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import {
  RequestTeachingScheduleList,
  RequestClassInfo,
} from "src/app/Models/input.model/RequestTeachingScheduleList";
import { ResponseDiemDanhChiTiet } from "src/app/Models/output.model/ResponseTeachingScheduleList";
import { ResponseBase } from "src/app/Models/output.model/ResponseBase";
import { getMaxListeners } from "process";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppConfig, AppConfiguration } from "src/configuration";

@Component({
  selector: "app-attendence-edit",
  templateUrl: "./attendence-edit.component.html",
  styleUrls: ["./attendence-edit.component.scss"],
})
export class AttendenceEditComponent implements OnInit {
  dtOptions: DataTables.Settings;
  Res: ResponseDiemDanhChiTiet = new ResponseDiemDanhChiTiet();
  Response: ResponseBase = new ResponseBase();
  ClassData: any;
  requestObject: RequestClassInfo = new RequestClassInfo();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  IDLop: number;
  ID_lich_day: number;
  Loai_lop: number;
  ID_mon: number;
  Ngay_thang: any;
  CaHoc: any;
  Tiet: number;
  IsAll: boolean;
  Title: string = "Đang tải danh sách điểm danh";
  TitleClass: string = "spinner-border text-muted";
  TitleTenLop: string;
  Status: any;
  Token: string;
  TitileTenMon: any;
  closeResult: string;
  isShowQrCode: boolean = false;
  QrCodeData: string;
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private modalService: NgbModal,
    public route: ActivatedRoute,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private ClassService: ClassService,
    private AttendenceService: AttendenceService,
    private spinner: NgxSpinnerService,
    private AppService: AppService
  ) {
    //
  }
  dtTrigger = new Subject();
  public com: common;
  ngOnInit() {
    this.isShowQrCode = this.appConfig.qr_code_diem_danh
      ? this.appConfig.qr_code_diem_danh.active
      : false;
    this.IsAll = true;
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.access_token;
    this.route.queryParams.subscribe((params) => {
      this.IDLop = params.IDLop;
      this.CaHoc = params.CaHoc;
      this.Tiet = params.Tiet;
      this.TitleTenLop = params.Ten_lop;
      this.ID_lich_day = params.ID_lich_day;
      this.Loai_lop = params.Loai_lop;
      this.ID_mon = params.ID_mon;
      this.Status = params.Status;
      this.Ngay_thang = params.Ngay_thang;
      this.TitileTenMon = params.Ten_mon;
    });
    this.requestObject.UserName = a.Info.UserName;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 500,
      ordering: false,
      lengthChange: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "ID_sv",
          visible: false,
        },
        {
          title: "ID_dt",
          visible: false,
        },
        {
          title: "Mã SV",
        },
        {
          title: "Họ tên",
          width: "15%",
        },
        {
          title: "Ngày sinh",
        },
        {
          title: "Giới tính",
        },
        {
          title: "Tên khoa",
        },
        {
          title: "ID_lop",
          visible: false,
        },
        {
          title: "Có lý do",
          className: "dt-center",
        },
        {
          title: "Không lý do",
          className: "dt-center",
        },
        {
          title: "Đi học muộn",
          className: "dt-center",
        },
        {
          title: "Đi học",
          className: "dt-center",
        },
        {
          title: "Ghi chú",
          className: "dt-center",
        },
        {
          title: "Ghi chú",
          visible: false,
        },
      ],
    };
    this.requestObject.IDLop = this.IDLop;
    if (this.CaHoc == "Sáng") {
      this.requestObject.CaHoc = 0;
    }
    if (this.CaHoc == "Chiều") {
      this.requestObject.CaHoc = 1;
    }
    if (this.CaHoc == "Tối") {
      this.requestObject.CaHoc = 2;
    }
    this.requestObject.RequestID = this.ID_lich_day;
    this.requestObject.Tiet = this.Tiet;
    this.getList();
    if (this.isShowQrCode) {
      this.refreshQRCode();
      setInterval(() => {
        this.refreshQRCode();
      }, this.appConfig.qr_code_diem_danh.refresh_time);
    }
  }

  refreshQRCode() {
    this.QrCodeData =
      this.appConfig.qr_code_diem_danh.url +
      "&ID_lop=" +
      this.IDLop +
      "&ID_mon=" +
      this.ID_mon +
      "&Loai_lop=" +
      this.Loai_lop +
      "&Ngay_thang=" +
      this.Ngay_thang +
      "&Tiet=" +
      this.Tiet +
      "&Ca_hoc=" +
      this.CaHoc +
      "&unix=" +
      Math.floor(Date.now() / 1000);
  }

  getList() {
    var dateParts = this.Ngay_thang.split("/");
    this.requestObject.NgayThang = new Date(
      +dateParts[2],
      dateParts[1] - 1,
      +dateParts[0] + 1
    );
    if (this.Loai_lop == 2) {
      if (this.Status == "false") {
        this.ClassCreditGetInfo(this.requestObject, this.Token);
      } else {
        this.ClassCreditGetInfoPlan(this.requestObject, this.Token);
      }
    } else {
      if (this.Status == "false") {
        this.ClassGetInfo(this.requestObject, this.Token);
      } else {
        this.ClassGetInfoPlan(this.requestObject, this.Token);
      }
    }
  }

  Save() {
    this.SaveAttendance(this.ClassData);
  }
  SaveAttendance(m) {
    var a = this.com.getUserinfo();
    //if (this.ValidateAttendance(m)) {
    var req = this.GetKetQua(
      m,
      this.Tiet,
      this.IDLop,
      this.requestObject.CaHoc
    );
    this.Send(req, a.access_token);
    //SendDiemDanh(req);
    //}
  }

  ValidateAttendance(m) {
    var isValid = true;
    var Message = "";

    for (var i = 0; i < m.length; i++) {
      var rs = $('input[name="' + m[i].ID_sv + '"]:checked').serialize();
      if (!rs) {
        isValid = false;
        Message += i + 1 + ": " + m[i].Ho_ten + "\n";
      }
    }
    if (!isValid) {
      Message =
        "Vui lòng kiểm tra lại điểm danh các sinh viên sau: \n" + Message;
      this.toastr.warning(Message);
      this.spinner.hide();
      return false;
    } else {
      this.spinner.hide();
      return true;
    }
  }
  GetKetQua(m, tiet, idlop, cahoc) {
    var KetQuaDiemDanhs = [];
    for (var i = 0; i < m.length; i++) {
      var DiHoc = $("#Di_hoc" + m[i].ID_sv).is(":checked");
      var CoLyDo = $("#Co_ly_do" + m[i].ID_sv).is(":checked");
      var Muon = $("#Di_hoc_muon" + m[i].ID_sv).is(":checked");
      var KhongLyDo = $("#Khong_ly_do" + m[i].ID_sv).is(":checked");
      var DiemDanh = {
        ID_diem_danh_chi_tiet: m[i].ID_diem_danh_chi_tiet,
        ID_sv: m[i].ID_sv,
        Di_hoc: DiHoc,
        So_tiet: tiet,
        Co_ly_do: CoLyDo,
        Khong_ly_do: KhongLyDo,
        Di_muon: Muon,
        Ghi_chu: $("#Ghi_chu" + m[i].ID_sv).val(),
      };
      KetQuaDiemDanhs.push(DiemDanh);
    }
    var MonHoc = {
      ID_lop_tc: idlop,
      ID_mon: this.ID_mon,
      Ca_hoc: cahoc,
      Tiet: tiet,
      Ngay_thang: this.Ngay_thang,
    };
    var req = {
      DiemDanh: KetQuaDiemDanhs,
      MonHoc: MonHoc,
      RequestID: this.ID_lich_day,
    };
    return req;
  }
  Send(req: any, token) {
    this.spinner.show();
    if (this.Loai_lop == 2) {
      if (this.Status == "false") {
        this.AttendenceService.AttendenceCreditSave(req, token).subscribe(
          (z) => {
            this.Response = z;
            this.Title = "Danh sách diểm danh";
            this.TitleClass = "";
            if (this.Response.Status == 1) {
              this.toastr.success(this.Response.Message, "Thao tác thành công");
            } else {
              this.toastr.error(this.Response.Message);
            }
            this.datatableElement.dtInstance.then(
              (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
              }
            );
            this.getList();
            this.spinner.hide();
          },
          (errors) => {
            this.AppService.logout();
            this.spinner.hide();
          }
        );
      } else {
        let dateParts = this.Ngay_thang.split("/");

        let dateObject = new Date(
          +dateParts[2],
          dateParts[1] - 1,
          +dateParts[0] + 1
        );
        req.MonHoc.Ngay_thang = dateObject;
        this.AttendenceService.AttendenceCreditPlanSave(req, token).subscribe(
          (z) => {
            this.Response = z;
            this.Title = "Danh sách diểm danh";
            this.TitleClass = "";
            if (this.Response.Status == 1) {
              this.toastr.success(this.Response.Message, "Thao tác thành công");
            } else {
              this.toastr.error(this.Response.Message);
            }
            this.datatableElement.dtInstance.then(
              (dtInstance: DataTables.Api) => {
                dtInstance.destroy();
              }
            );
            this.getList();
            this.spinner.hide();
          },
          (errors) => {
            this.AppService.logout();
            this.spinner.hide();
          }
        );
      }
    } else {
      if (this.Status == "false") {
        this.AttendenceService.AttendenceSave(req, token).subscribe((z) => {
          this.Response = z;
          this.Title = "Danh sách diểm danh";
          this.TitleClass = "";
          if (this.Response.Status == 1) {
            this.toastr.success(this.Response.Message, "Thao tác thành công");
          } else {
            this.toastr.error(this.Response.Message);
          }
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getList();
          this.spinner.hide();
        });
      } else {
        this.AttendenceService.AttendencePlanSave(req, token).subscribe((z) => {
          this.Response = z;
          this.Title = "Danh sách diểm danh";
          this.TitleClass = "";
          if (this.Response.Status == 1) {
            this.toastr.success(this.Response.Message, "Thao tác thành công");
          } else {
            this.toastr.error(this.Response.Message);
          }
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getList();
          this.spinner.hide();
        });
      }
    }
  }
  ClassCreditGetInfo(req: RequestClassInfo, token) {
    this.AttendenceService.ClassCreditGetInfo(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.Res = z;
        this.ClassData = this.Res.Data;
        this.dtTrigger.next();
        this.Title = "";
        this.TitleClass = "";
      } else {
        this.toastr.warning(z.Message);
        this.Title = "";
        this.TitleClass = "";
      }
    });
  }
  ClassGetInfo(req: RequestClassInfo, token) {
    this.AttendenceService.ClassGetInfo(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.Res = z;
        this.ClassData = this.Res.Data;
        this.dtTrigger.next();
        this.Title = "";
        this.TitleClass = "";
      } else {
        this.toastr.warning(z.Message);
        this.Title = "";
        this.TitleClass = "";
      }
    });
  }

  ClassCreditGetInfoPlan(req: RequestClassInfo, token) {
    this.AttendenceService.ClassCreditGetInfoPlan(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.Res = z;
        this.ClassData = this.Res.Data;
        this.dtTrigger.next();
        this.Title = "";
        this.TitleClass = "";
      } else {
        this.toastr.warning(z.Message);
        this.Title = "";
        this.TitleClass = "";
      }
    });
  }
  ClassGetInfoPlan(req: RequestClassInfo, token) {
    this.AttendenceService.ClassGetInfoPlan(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.Res = z;
        this.ClassData = this.Res.Data;
        this.dtTrigger.next();
        this.Title = "";
        this.TitleClass = "";
      } else {
        this.toastr.warning(z.Message);
        this.Title = "";
        this.TitleClass = "";
      }
    });
  }
  CheckAllDiHoc() {
    this.ClassData.forEach((i) => {
      i.Di_hoc = this.IsAll;
    });
    this.IsAll = !this.IsAll;
  }

  open(content, size) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: size })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
