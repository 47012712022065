<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Đổi, bù giờ, nghỉ</li>
                </ol>
            </div>
            <div class="col-md-6">
                <button class="btn btn-success btn-sm" (click)="open(ThemModal, 'xl', 'Add', '')" style="float: right;">
                    <i class="fas fa-plus-circle"></i> Thêm mới
                </button>
                <button (click)="help()" class="btn btn-warning btn-sm" type="submit"
                    style="float: right; margin-right: 10px;">
                    <i class="fas fa-info-circle"></i>
                    Hướng dẫn
                </button>

            </div>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <form class="form-horizontal" [formGroup]="Filter">
                <div class="row">
                    <div class="col-md-3 ">
                        <label>Học kỳ</label>
                        <select class="form-control" formControlName="Hoc_ky_ft" (change)="changeFilter()">
                            <option value="1">Học kỳ I</option>
                            <option value="2">Học kỳ II</option>
                        </select>
                    </div>
                    <div class="col-md-3 ">
                        <label>Năm học</label>
                        <select class="form-control" formControlName="Nam_hoc_ft" (change)="changeFilter()">
                            <option *ngFor="let p of Nam_hoc_Arr" value="{{p.value}}">{{p.name}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 ">
                        <label>Loại</label>
                        <select class="form-control" formControlName="ID_trang_thai_ft" (change)="changeFilter()">
                            <option value="1">Đổi, bù giờ</option>
                            <option value="2">Nghỉ</option>
                        </select>
                    </div>
                    <div class="col-md-3 ">
                        <label>Trạng thái</label>
                        <select class="form-control" formControlName="Duyet_ft" (change)="changeFilter()">
                            <option value="0">Chờ duyệt</option>
                            <option value="1">Duyệt</option>
                            <option value="2">Hủy duyệt</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of dataTable;">
                                            <td>{{ p.ID_doi_gio }}</td>
                                            <td>{{ p.Ma_cb }}</td>
                                            <td>{{ p.Ho_ten }}</td>
                                            <td>{{ p.Ten_lop}}</td>
                                            <td>{{ p.Ten_mon }}</td>
                                            <td>{{ p.Sy_so}}</td>
                                            <td>{{ p.Tu_tiet}}</td>
                                            <td>{{ p.Tu_ngay|date:'dd/MM/yyyy'}}</td>
                                            <td>{{ p.Den_tiet}}</td>
                                            <td>{{ p.Den_ngay|date:'dd/MM/yyyy'}}</td>
                                            <td>
                                                <div *ngIf="p.Duyet == 1">
                                                    Đã duyệt
                                                </div>
                                                <div *ngIf="p.Duyet == 2">
                                                    Hủy duyệt
                                                </div>
                                                <div *ngIf="p.Duyet == 0">
                                                    Chờ xử lý
                                                </div>
                                            </td>
                                            <td>{{ p.Ly_do_duyet}}</td>
                                            <td style="white-space: nowrap;">
                                                <button class="btn btn-sm btn-warning"
                                                    (click)="open(ThemModal, 'xl', 'Edit', p)"
                                                    style="margin-right: 5px;">
                                                    <i class="fas fa-edit"></i> </button>
                                                <button class="btn btn-sm btn-primary"
                                                    (click)="ExportExcel(p.ID_doi_gio,p.Hoc_ky,p.Nam_hoc,p.ID_cb)"
                                                    style="margin-right: 5px;" title="Tải xuống kế hoạch">
                                                    <i class="fas fa-file-download"></i></button>
                                                <button class="btn btn-sm btn-danger"
                                                    (click)="open(DeleteModal, 'xs', 'Delete', p)"
                                                    style="margin-right: 5px;" title="Xóa kế hoạch">
                                                    <i class="fas fa-trash-alt"></i> </button>
                                                <button ngbTooltip="Gừi mail"
                                                    (click)="open(SendMailModal, 'xl', 'SendMail', p)"
                                                    class="btn btn-info btn-sm">
                                                    <i class="fas fa-mail-bulk"></i>
                                                </button>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #ThemModal let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
        <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
        <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body cus-fs">
        <div class="tab-content">
            <div class="active tab-pane">
                <form class="form-horizontal" [formGroup]="Insert">
                    <div class="row" style="margin-bottom: 20px;">
                        <div class="col-md-6">
                            <label class="col-md-10 require">Loại</label>
                            <select class="form-control" formControlName="ID_trang_thai" [disabled]="CheckEdit"
                                [ngClass]="{ 'is-invalid': submitted && checkvalue.ID_trang_thai.errors }">
                                <option value="">Chọn</option>
                                <option value=1 [disabled]="CheckEdit">Đổi, bù giờ</option>
                                <option value=2 [disabled]="CheckEdit">Nghỉ</option>
                            </select>
                            <div *ngIf="submitted && checkvalue.ID_trang_thai.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.ID_trang_thai.errors.required">
                                    Chọn loại
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-10 require">Email người duyệt</label>
                            <input type="text" class="form-control" formControlName="Email_duyet" [readonly]="CheckEdit"
                                [readonly]="CheckEdit"
                                [ngClass]="{ 'is-invalid': submitted && checkvalue.Email_duyet.errors }"
                                placeholder="Nhập email duyệt">
                            <div *ngIf="submitted && checkvalue.Email_duyet.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Email_duyet.errors.required">
                                    Email không được bỏ trống!
                                </div>
                                <div *ngIf="submitted && checkvalue.Email_duyet.errors.email ">
                                    Email không đúng định dạng!
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-bottom: 20px;">
                        <div class="col-md-3">
                            <label class="col-md-10 require">Tiết bắt đầu nghỉ</label>
                            <input type="number" class="form-control" formControlName="Tu_tiet" [readonly]="CheckEdit"
                                placeholder="Nhập tiết bắt đầu nghỉ"
                                [ngClass]="{ 'is-invalid': submitted && checkvalue.Tu_tiet.errors }">
                            <div *ngIf="submitted && checkvalue.Tu_tiet.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Tu_tiet.errors.required">
                                    Tiết bắt đầu nghỉ không được bỏ trống!
                                </div>
                                <div *ngIf="checkvalue.Tu_tiet.errors.min">
                                    Tiết bắt đầu nghỉ không được nhỏ hơn 1!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Ngày nghỉ</label>
                            <input type="date" class="form-control" formControlName="Tu_ngay" placeholder="Nhập ngày nghỉ"
                                [readonly]="CheckEdit"
                                [ngClass]="{ 'is-invalid': submitted && checkvalue.Tu_ngay.errors }">
                            <div *ngIf="submitted && checkvalue.Tu_ngay.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Tu_ngay.errors.required">
                                    Ngày nghỉ không được bỏ trống!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Tiết bắt đầu dạy</label>
                            <input type="number" class="form-control" formControlName="Den_tiet" [readonly]="CheckEdit"
                                placeholder="Nhập tiết bắt đầu dạy"
                                [ngClass]="{ 'is-invalid': submitted && checkvalue.Den_tiet.errors }">
                            <div *ngIf="submitted && checkvalue.Den_tiet.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Den_tiet.errors.required">
                                    Tiết bắt đầu dạy không được bỏ trống!
                                </div>
                                <div *ngIf="checkvalue.Den_tiet.errors.min">
                                    Tiết bắt đầu dạy không được nhỏ hơn 1!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Ngày dạy</label>
                            <input type="date" class="form-control" formControlName="Den_ngay" [readonly]="CheckEdit"
                                placeholder="Nhập ngày dạy"
                                [ngClass]="{ 'is-invalid': submitted && checkvalue.Den_ngay.errors }">
                            <div *ngIf="submitted && checkvalue.Den_ngay.errors" class="invalid-feedback">
                                <div *ngIf="checkvalue.Den_ngay.errors.required">
                                    Ngày dạy được bỏ trống!
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row" style="margin-bottom: 20px;">
                        <div class="col-md-6">
                            <label class="col-md-10 ">Lý do</label>
                            <textarea name="Ly_do" cols="100" rows="2" class="form-control" formControlName="Ly_do"
                                [readonly]="CheckEdit" placeholder="Nhập Lý do"></textarea>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-10 ">Ghi chú</label>
                            <textarea name="Ghi_chu" cols="100" rows="2" class="form-control" formControlName="Ghi_chu"
                                [readonly]="CheckEdit" placeholder="Nhập ghi chú"></textarea>
                        </div>
                    </div>
                </form>
                <div class="row" style="margin-bottom: 20px;">
                    <div class="col-md-6">
                        <label *ngIf="Status == 2">
                            Trạng thái: đã duyệt
                        </label>
                        <label *ngIf="Status == 1">
                            Trạng thái: hủy duyệt
                        </label>
                        <label *ngIf="Status == 0">
                            Trạng thái: chờ xử lý
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label>Lý do hủy duyệt : {{Ly_do_huy}} </label>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 20px;">
                    <div class="col-md-12">
                        <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                            <table datatable [dtOptions]="dtOptions2"
                                class="table-bordered dataTables_wrapper no-footer">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of ClassData;">
                                        <td>
                                            <input type="radio" (change)="SelectRow(p)" name="SelectData"
                                                [checked]="p.Selected" />
                                        </td>
                                        <td>{{ p.Ten_mon }}</td>
                                        <td>{{ p.Ten_lop }}</td>
                                        <td>{{ p.Hoc_ky }}</td>
                                        <td>{{ p.Nam_hoc}}</td>
                                        <td>{{ p.So_sv }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="float-right">
            <div class="row">
                <div *ngIf="CheckEdit == false">
                    <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
                        <i class="fas fa-save"></i> Lưu
                    </button>
                </div>
                <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                    style="margin-left: 1em;">
                    <i class="far fa-times-circle"></i> Đóng
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #DeleteModal let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <div class="icon-box">
                <i class="fas fa-trash-alt"
                    style="color: #f15e5e;font-size: 46px;display: inline-block;margin-top: 13px;"></i>
            </div>
            <div *ngIf="CheckEdit != 0">
                <p
                    style="color: inherit; text-decoration: none;font-size: 20px; text-align: center; font-weight: 600; margin-top: 3em;">
                    Thông tin đã được xác nhận không thể thao tác
                </p>
            </div>
            <div *ngIf="CheckEdit == 0">
                <p
                style="color: inherit; text-decoration: none;font-size: 20px; text-align: center; font-weight: 600; margin-top: 3em;">
                Bạn có chắc chắn muốn xóa
            </p>
            </div>
            <div class="row" style="justify-content: center;">
                <div *ngIf="CheckEdit == 0">
                    <button type="button" class="btn btn-md btn-success" (click)="Delete()">
                        Đồng ý
                    </button>
                </div>
                <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                    style="margin-left: 1em;">
                    Hủy bỏ
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #SendMailModal let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
        <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
        <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body ">
        <div class="tab-content">
            <div class="active tab-pane">
                <send-mail-time-swap [dtMail]="dtMail"></send-mail-time-swap>
            </div>
        </div>
    </div>
</ng-template>