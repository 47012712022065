<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> Trang chủ</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/note-book-class/']"> &nbsp;Sổ lên lớp</a>
          </li>
          <li class="breadcrumb-item active">Sổ tay lớp {{TenLop}}</li>
          <li class="breadcrumb-item active">Môn {{TenMon}}</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button class="btn btn-primary btn-sm" (click)="exportExcel()" style="float: right; margin-left: 5px;">
          <i title="Tải bảng ghi tóm tắt nội dung" class="fas fa-file-download"></i> Xuất excel
        </button>
        <button class="btn btn-primary btn-sm" (click)="ImportFromTKB()" style="float: right; margin-left: 5px;">
          <i title="Thêm giờ từ TKB" class="fa fa-calendar-check-o"></i> Thêm giờ từ TKB
        </button>
        <button class="btn btn-success btn-sm" (click)="open(mymodal, 'xl', false, '')" style="float: right;">
          <i class="fas fa-plus-circle"></i> Thêm mới
        </button>
      </div>
    </div>
  </div>
</section>
<section class="content cus-body" style="font-size: 13px;">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <!-- <div class="{{TitleClass}}"></div>
                    <div class=" col-md-3">
                        <h3 class="card-title">{{Title}}</h3>
                    </div>
                  </div> -->
            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">

              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table-bordered dataTables_wrapper no-footer">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of TableData; index as i">
                    <td>{{ i+1 }}</td>
                    <td>{{ p.ID_so_len_lop }}</td>
                    <td>{{ p.Ngay_len_lop | date: 'dd/MM/yyyy'}}</td>
                    <td>{{ p.Ca_hoc }}</td>
                    <td>{{ p.Hoc_ky }}</td>
                    <td>{{ p.Nam_hoc }}</td>
                    <td>{{ p.So_tiet_ly_thuyet}}</td>
                    <td>{{ p.So_tiet_thuc_hanh }}</td>
                    <td>{{ p.So_tiet_tich_hop }}</td>
                    <td>{{ p.So_tiet_kiem_tra }}</td>
                    <td>{{ p.So_sv_vang }}</td>
                    <td>{{ p.Noi_dung_bai_giang }}</td>
                    <td>{{ p.Ghi_chu }}</td>
                    <td class="ws-nowap">
                      <button class="btn btn-sm btn-warning" style="margin-right: 4px;"
                        (click)="open(mymodal,'lg', true, p)">
                        <i class="fas fa-edit"></i>
                      </button>

                      <button class="btn btn-sm btn-danger" (click)="DeleteConfirm(p)">
                        <i class="fas fa-trash-alt" title="Xóa sự kiện"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.mail-box-messages -->
          </div>
          <!-- /.card-body -->
        </div>
      </div>
      <!-- /.col -->
    </div>
  </div>
</section>
<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Thông tin chi tiết</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body cus-fs13">
    <div class="container">
      <form [formGroup]="Insert">
        <div class="cus-fs13">
          <div class="row">
            <div class="col-md-4">
              <label class="col-md-10" for="ID_so_tay">ID sổ lên lớp</label>
              <input readonly class="form-control" type="number" id="ID_so_len_lop" formControlName="ID_so_len_lop" />
            </div>
            <div class="col-md-4">
              <label class="col-md-10 require" for="Ngay_len_lop">Ngày lên lớp</label>
              <input class="form-control" required type="date" id="Ngay_len_lop" formControlName="Ngay_len_lop" (change)="changeSV()"
                [ngClass]="{
                    'is-invalid': submitted && checkvalue.Ngay_len_lop.errors
                  }" />
              <div *ngIf="submitted && checkvalue.Ngay_len_lop.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.Ngay_len_lop.errors.required">
                  Ngày lên lớp không được để trống
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label class="col-md-10" for="Hoc_ky">Học kỳ</label>
              <input class="form-control" readonly type="text" formControlName="Hoc_ky" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label class="col-md-10" for="Nam_hoc">Năm học</label>
              <input class="form-control" readonly type="text" formControlName="Nam_hoc" />
            </div>

            <div class="col-md-4">
              <label class="col-md-10 require" for="So_tiet_thuc_hanh">Số tiết thực hành</label>
              <input class="form-control" required type="number" id="So_tiet_thuc_hanh"
                formControlName="So_tiet_thuc_hanh" />
            </div>
            <div class="col-md-4">
              <label class="col-md-10" for="So_tiet_ly_thuyet">Số tiết lý thuyết</label>
              <input class="form-control" type="number" id="number" formControlName="So_tiet_ly_thuyet" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label class="col-md-10 require" for="So_tiet_tich_hop">Số tiết tích hợp</label>
              <input class="form-control" required type="number" id="So_tiet_tich_hop"
                formControlName="So_tiet_tich_hop" />
            </div>
            <div class="col-md-4">
              <label class="col-md-10" for="So_tiet_kiem_tra">Số tiết kiểm tra</label>
              <input class="form-control" type="number" id="So_tiet_kiem_tra" formControlName="So_tiet_kiem_tra" />
            </div>
            <div class="col-md-4">
              <label class="col-md-10" for="So_sv_vang">Ca học</label>
              <ng-select notFoundText="Không có dữ liệu" formControlName="Ca_hoc" (change)="changeCaHoc()" (change)="changeSV()">
                <ng-option value="Sáng">Sáng</ng-option>
                <ng-option value="Chiều">Chiều</ng-option>
                <ng-option value="Tối">Tối</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label class="col-md-10 require">Từ tiết</label>
              <ng-select notFoundText="Không có dữ liệu" formControlName="Tu_tiet" (change)="changeSV()">
                <ng-option value="1">1</ng-option>
                <ng-option value="2">2</ng-option>
                <ng-option value="3">3</ng-option>
                <ng-option value="4">4</ng-option>
                <ng-option value="5">5</ng-option>
                <ng-option value="6">6</ng-option>
                <ng-option value="7">7</ng-option>
                <ng-option value="8">8</ng-option>
                <ng-option value="9">9</ng-option>
                <ng-option value="10">10</ng-option>
                <ng-option value="11">11</ng-option>
                <ng-option value="12">12</ng-option>
                <ng-option value="13">13</ng-option>
                <ng-option value="14">14</ng-option>
                <ng-option value="15">15</ng-option>
              </ng-select>
              <!-- <div *ngIf="submitted && checkValue.Tu_tiet.errors" class="invalid-feedback">
                <div *ngIf="checkValue.Tu_tiet.errors.required">
                  Từ tiết không được bỏ trống!
                </div>
                <div *ngIf="checkValue.Tu_tiet.errors.min">
                  Từ tiết không được nhỏ hơn 1!
                </div>
              </div> -->
            </div>
            <div class="col-md-4">
              <label class="col-md-10 require">Đến tiết</label>
              <ng-select notFoundText="Không có dữ liệu" formControlName="Den_tiet" (change)="changeSV()" >
                <ng-option value="1">1</ng-option>
                <ng-option value="2">2</ng-option>
                <ng-option value="3">3</ng-option>
                <ng-option value="4">4</ng-option>
                <ng-option value="5">5</ng-option>
                <ng-option value="6">6</ng-option>
                <ng-option value="7">7</ng-option>
                <ng-option value="8">8</ng-option>
                <ng-option value="9">9</ng-option>
                <ng-option value="10">10</ng-option>
                <ng-option value="11">11</ng-option>
                <ng-option value="12">12</ng-option>
                <ng-option value="13">13</ng-option>
                <ng-option value="14">14</ng-option>
                <ng-option value="15">15</ng-option>
              </ng-select>
              <!-- <div *ngIf="submitted && checkValue.Den_tiet.errors" class="invalid-feedback">
                <div *ngIf="checkValue.Den_tiet.errors.required">
                  Đến tiết không được bỏ trống!
                </div>
                <div *ngIf="checkValue.Den_tiet.errors.min">
                  Đến tiết không được nhỏ hơn 1!
                </div>
              </div> -->
            </div>
            <div class="col-md-4">
              <label class="col-md-10" for="So_sv_vang">Số sinh viên vắng</label>
              <input class="form-control" type="number" id="So_sv_vang" formControlName="So_sv_vang" />
            </div>
          </div>
          <div class="row">

            <div class="col-md-12">
              <label class="col-md-10" for="Ghi_chu">Ghi chú</label>
              <input class="form-control" type="text" id="Ghi_chu" formControlName="Ghi_chu" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">

              <label class="col-md-10" for="Noi_dung_bai_giang">Nội dung bài giảng</label>
              <div *ngIf="submitted && checkvalue.Noi_dung_bai_giang.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.Noi_dung_bai_giang.errors.required">
                  Nội dung bài giảng không được bỏ trống
                </div>
              </div>
              <ckeditor [editor]="Editor" data="{{dataMoTa}}" (change)="onChange($event)"></ckeditor>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="onSubmit()" class="btn btn-primary">
      Lưu
    </button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Đóng
    </button>
  </div>
</ng-template>
<!-- <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#ffffff"
    type="square-loader"
    [fullScreen]="true"
  >
    <p style="color: white;">Đang tải dữ liệu ...</p>
  </ngx-spinner> -->