import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { reduce, timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { TeachingCommentService } from 'src/app/Utils/services/teaching-comment.service';
import { multiplyDuration } from '@fullcalendar/core';
import { ExportService } from 'src/app/Utils/service/export.service';
// export function removeSpaces(control: AbstractControl) {
//   if (control && control.value && !control.value.replace(/\s/g, '').length) {
//     control.setValue('');
//   }
//   return null;
// }

@Component({
  selector: 'app-teching-comment',
  templateUrl: './teching-comment.component.html',
  styleUrls: ['./teching-comment.component.scss']
})
export class TechingCommentComponent implements OnInit {
  @Input() dtClass: any;
  dtOptions: DataTables.Settings;
  Token: string;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  closeResult: string;
  ClassData: any;
  ID_nx_giang_day_giang_vien: any;
  Hoc_ky: any = 0;
  Nam_hoc: any = '2020-2021';
  ID_mon: any;
  ID_lop: any;
  Ten_mon: any;
  Ten_lop: any;
  So_Gio_Lop: number = 0;
  LT: any = 0;
  TH: any = 0;
  titleModal: string;
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkActionModal: boolean;
  submitted = false;
  Nam_hocTitle: string;
  Title: string = 'Đang tải cơ sở...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  ActionModal = new FormGroup({
    // Hoc_ky: new FormControl(null),
    // Nam_hoc: new FormControl(null),
    // ID_mon: new FormControl(null),
    HSSV_ca_biet: new FormControl(''),
    Danh_gia_qua_trinh_giang_day: new FormControl(''),
    Ghi_chu: new FormControl(''),
  });
  Id_cb: number;
  Hoc_ky_lop: any;
  Nam_hoc_lop: any;
  InputDatas: { ID_nx_giang_day_giang_vien: any; Hoc_ky: any; Nam_hoc: any; ID_mon: any; ID_lop: any; ID_cb: number; HSSV_ca_biet: any; Danh_gia_qua_trinh_giang_day: any; Ghi_chu: any; };
  ModalType: any;

  constructor(
    private modalService: NgbModal,
    private TeachingCommentService: TeachingCommentService,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute,
    private exportService: ExportService
  ) { }
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    // let element, Nam_hoc, arr;
    // element = document.getElementById('menu1');
    // arr = element.classNam_hoc.split(' ');
    // Nam_hoc = 'menu-open';
    // if (arr.indexOf(Nam_hoc) == -1) {
    //   element.classNam_hoc += ' ' + Nam_hoc;
    // }
    this.route.queryParams
      .subscribe(params => {
        this.Ten_mon = params.Ten_mon;
        this.Ten_lop = params.Ten_lop;
        this.ID_mon = params.ID_mon;
        this.ID_lop = params.ID_lop;
        this.Hoc_ky_lop = params.Hoc_ky;
        this.Nam_hoc_lop = params.Nam_hoc;
        this.LT = params.Ly_thuyet;
        this.TH = params.Thuc_hanh;

      });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Id_cb = a.Info.UserID;
    this.Token = a.access_token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,

      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'STT', className: 'dt-center',
          width: '4%',
        },

        {
          title: 'Học sinh cá biệt', className: 'dt-center',
          width: '7%',
        },
        {
          title: 'Đánh giá quá trình giảng dạy',
          width: '15%',
        },
        {
          title: 'Ghi chú',
          width: '10%',
        },
        {
          title: 'Thao tác',
          className: 'dt-center',
          width: '10%'
        },
      ],
      "order": [[1, "desc"]]
    };
    this.getList();
  }

  getList() {
    this.InputDatas = {
      ID_nx_giang_day_giang_vien: 0,
      Hoc_ky: this.Hoc_ky_lop,
      Nam_hoc: this.Nam_hoc_lop,
      ID_mon: this.ID_mon,
      ID_lop: this.ID_lop,
      ID_cb: this.Id_cb,
      HSSV_ca_biet: this.ActionModal.value.HSSV_ca_biet,
      Danh_gia_qua_trinh_giang_day: this.ActionModal.value.Danh_gia_qua_trinh_giang_day,
      Ghi_chu: this.ActionModal.value.Ghi_chu,
    };
    this.spinner.show();
    this.TeachingCommentService.List(this.InputDatas, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        if (z.Data.length > 0) {
          this.ID_nx_giang_day_giang_vien = z.Data[0].ID_nx_giang_day_giang_vien
          this.ActionModal.setValue({
            HSSV_ca_biet: z.Data[0].HSSV_ca_biet,
            Danh_gia_qua_trinh_giang_day: z.Data[0].Danh_gia_qua_trinh_giang_day,
            Ghi_chu: z.Data[0].Ghi_chu
          })
        }
      }
      // else{
      //   this.ID_nx_giang_day_giang_vien = undefined
      // }
      this.spinner.hide();
    });
  } // Load data

  open(content, sizm, Type) {
    this.ModalType = Type
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Type == 'Edit') {
      this.titleModal = 'Bạn có chắc chắn sửa nhận xét';

    } else if (Type == 'Add') {
      this.titleModal = 'Bạn có chắc chắn thêm mới nhận xét';
      this.submitted = false;
      // this.ActionModal.reset();
    } else if (Type == 'Delete') {
      this.titleModal = 'Bạn có chắc chắn xóa nhận xét';

    }
    else if (Type == 'Export') {
      this.titleModal = 'Bạn có chắc chắn xuất excel ?';

    }

  } // Mở Modal

  get checkvalue() {
    return this.ActionModal.controls;
  } //check value

  onSubmit() {
    this.submitted = true;
    if (this.ActionModal.invalid) {
      return false;
    }
    this.Confirm();
  }
  
  Confirm() {
    this.InputDatas = {
      ID_nx_giang_day_giang_vien: this.ID_nx_giang_day_giang_vien,
      Hoc_ky: this.Hoc_ky_lop,
      Nam_hoc: this.Nam_hoc_lop,
      ID_mon: this.ID_mon,
      ID_lop: this.ID_lop,
      ID_cb: this.Id_cb,
      HSSV_ca_biet: this.ActionModal.value.HSSV_ca_biet,
      Danh_gia_qua_trinh_giang_day: this.ActionModal.value.Danh_gia_qua_trinh_giang_day,
      Ghi_chu: this.ActionModal.value.Ghi_chu,
    };
    if (this.ActionModal.value.HSSV_ca_biet == '' && this.ActionModal.value.Danh_gia_qua_trinh_giang_day == '') {
      this.toastr.warning("Bạn chưa nhập nhận xét vui lòng kiểm tra lại !")
    } else {

      if (this.ModalType == 'Edit') {
        this.TeachingCommentService.Update(this.InputDatas, this.Token).subscribe((z) => {
          if (z.Status == 1) {
            this.toastr.success(z.Message);
          } else {
            this.toastr.error(z.Message);
          }
          this.ActionModal.reset();
          this.getList();
        });
      } if (this.ModalType == 'Add') {
        this.TeachingCommentService.Insert(this.InputDatas, this.Token).subscribe((z) => {
          if (z.Status == 1) {
            this.toastr.success(z.Message);
          } else {
            this.toastr.error(z.Message);
          }
          this.getList();
        });
      }
      if (this.ModalType == 'Delete') {
        this.TeachingCommentService.Delete(this.InputDatas, this.Token).subscribe((z) => {
          if (z.Status == 1) {
            this.toastr.success(z.Message);
          } else {
            this.toastr.error(z.Message);
          }
          this.ActionModal.reset();
          this.ID_nx_giang_day_giang_vien = undefined
          this.ActionModal.value.HSSV_ca_biet = ''
          this.getList();
        });
      }
      if (this.ModalType == 'Export') {
        var Request = {
          ID_cb: this.Id_cb,
          ID_lop: this.ID_lop,
          ID_mon: this.ID_mon,
          Hoc_ky: this.Hoc_ky_lop,
          Nam_hoc: this.Nam_hoc_lop,
          Ten_mon:this.Ten_mon,
          Ten_lop: this.Ten_lop
        }
        this.TeachingCommentService.TeachingCommentExport(Request, this.Token).subscribe((z) => {
          this.exportService.exportExcelByte(z.FileData, 'NhanXetGiangDay');
          this.toastr.success("File đã được tải xuống", "Thao tác thành công")
        });
      }
    }
    this.modalService.dismissAll('AddModal');

  } // Confirm action

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }
}