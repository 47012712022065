<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <ol class="breadcrumb float-sm-left">
                <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                <!-- <li class="breadcrumb-item"><a [routerLink]="['/admin/attendencehistory']">Lịch sử điểm danh</a></li> -->
                <li class="breadcrumb-item"><a style="color: #007bff;" (click)="backClicked()">Tra cứu điểm danh</a>
                </li>
                <!-- <li class="breadcrumb-item active">{{TitleTenSV}} &nbsp;</li> -->
                <li class="breadcrumb-item active">{{TitleLop}} &nbsp;</li>

                <li class="breadcrumb-item active">{{TitleTenSV}} &nbsp;</li>
                <!-- <li class="breadcrumb-item active">Dữ liệu điểm danh sinh viên &nbsp;</li> -->
                <div class="{{TitleClass}}"></div>
            </ol>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<!-- Main content -->
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <!-- <table datatable id="classTable" [dtOptions]="dtOptions" class="row-border hover"></table> -->
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                         
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData;let i = index ">
                                            <td>{{ i+1 }}</td>
                                            <td>{{ p.Ngay_thang }}</td>
                                            <td>{{ p.Ten_ca }}</td>
                                            <td>{{ p.So_tiet_ly_thuyet }}</td>
                                            <td>{{ p.So_tiet_thuc_hanh}}</td>
                                            <td>
                                                <input type="text" id="{{'Ghi_chu'+p.STT}}" disabled name="{{p.STT}}"
                                                    value="{{p.Ghi_chu}}">

                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>
<!-- /.content -->