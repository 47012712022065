import { Injectable,Inject } from '@angular/core';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TeachingPlanService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient) { }

  List(res, token) : Observable<any>{
    return this.http.post<any>(this.appConfig.TeacherUrl + 'TeachingPlan/TeachingPlanList',res,{
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    } ).pipe(map(z=> {return z}))  
  }

  Update(res, token) : Observable<any>{
    return this.http.post<any>(this.appConfig.TeacherUrl + 'TeachingPlan/Update',res,{
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    } ).pipe(map(z=> {return z}))  
  }

  Insert(res, token) : Observable<any>{
    return this.http.post<any>(this.appConfig.TeacherUrl + 'TeachingPlan/Insert',res,{
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    } ).pipe(map(z=> {return z}))  
  }

  Delete(res, token) : Observable<any>{
    return this.http.post<any>(this.appConfig.TeacherUrl + 'TeachingPlan/Delete',res,{
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    } ).pipe(map(z=> {return z}))  
  }

  CheckExist(res, token) : Observable<any>{
    return this.http.post<any>(this.appConfig.TeacherUrl + 'TeachingPlan/CheckExist',res,{
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    } ).pipe(map(z=> {return z}))  
  }

  TeachingPlanExport(req, token) : Observable<any>{
    return this.http.post<any>(this.appConfig.TeacherUrl + 'TeachingPlan/TeachingPlanExport',req,{
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    } ).pipe(map(z=> {return z}))
  }
}
