import { Injectable } from '@angular/core';
import { io } from 'socket.io-client/build/index';
import { Observable } from 'rxjs';
import { AnyRecord } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  socket: any
  readonly url: string = "https://socket-thienan.herokuapp.com/"

  constructor() {
    // this.socket = io(this.url, { transports: ['websocket'] })
  }
  listen(eventName) {
    return new Observable((subsciber) => {
      this.socket.on(eventName, (data) => {
        subsciber.next(data)
      })
    })
  }
  sendData(eventName, data) {
    this.socket.emit(eventName,data)
  }
}
