import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppService } from 'src/app/Utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { MessageService } from 'src/app/Utils/service/message.service';
import { ResponseClassListAll, ClassInfo } from 'src/app/Models/output.model/ResponseClassListAll';
import { Subject } from 'rxjs';
import { MessagesDropdownMenuComponent } from 'src/app/Components/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {
  Title: string;
  Content: string;
  FromUserName: string;
  ToUserName: string;
  SendDate: string;
  AttachmentName: string;
  Attachment: string;
  TitleClass: string;
  Res: any
  ID: number
  Req: RequestBase = new RequestBase()
  AnnounceData: any
  com: common;
  constructor(
    public route: ActivatedRoute,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    private MessageService: MessageService
  ) { }
  
  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.ID = params.ID
      });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Req.RequestID=this.ID
    this.Req.UserName=a.Info.UserName
    this.getMessage(this.Req,a.Token)
    
  }
  getMessage(req: RequestBase, token) {
    this.MessageService.GetMessage(req, token)
      .subscribe(z => {
        this.Res = z
        if(this.Res.Status==1){
          this.AnnounceData = this.Res.Data
          this.Content=this.AnnounceData.Content
          this.FromUserName=this.AnnounceData.FromUserName
          this.ToUserName=this.AnnounceData.ToUserName
          this.Title=this.AnnounceData.Title
          this.SendDate=this.AnnounceData.SendDate
          this.AttachmentName=this.AnnounceData.AttachmentName
          this.Attachment=this.AnnounceData.Attachment
        }
        else{
          this.toastr.error(this.Res.Message)
        }
        
      })
      ;
  }

}
