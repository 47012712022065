import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
export class TablefiltercbComponent implements PipeTransform {

  transform(list: any[], value: string) {
    return value ? list.filter(item => item.Nam_hoc === value  ) : list;
  }

}
