import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  MaxLengthValidator,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { reduce, timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { TeachingPlanService } from 'src/app/Utils/services/teaching-plan.service';
import { ExportService } from 'src/app/Utils/service/export.service';
import { UserInfo } from 'src/app/Models/output.model/ResponseLogin';
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}

@Component({
  selector: 'app-teaching-plan',
  templateUrl: './teaching-plan.component.html',
  styleUrls: ['./teaching-plan.component.scss']
})
export class TeachingPlanComponent implements OnInit {
  @Input() dtClass: any;
  dtOptions: DataTables.Settings;
  Token: string;
  Res: any;
  req: any;
  check: any;
  dataTable: any = [];
  closeResult: string;
  ClassData: any;
  ID_kh_giang_day_giang_vien: any;
  Hoc_ky: any = 0;
  Nam_hoc: any = '2020-2021';
  ID_mon: any;
  ID_lop: any;
  Ten_mon: any;
  Ten_lop: any;
  So_Gio_Lop: number = 0;
  LT: any = 0;
  TH: any = 0;
  titleModal: string;
  default: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  checkActionModal: boolean;
  submitted = false;
  Nam_hocTitle: string;
  Title: string = 'Đang tải cơ sở...';
  TitleClass: string = 'spinner-border text-muted';
  Request: any;
  ActionModal = new FormGroup({
    ID_kh_giang_day_giang_vien: new FormControl(null),
    Hoc_ky: new FormControl(null),
    Nam_hoc: new FormControl(null),
    ID_mon: new FormControl(null),
    STT_bai: new FormControl(null, [Validators.required]),
    Ten_bai_giang: new FormControl(null, [Validators.required,removeSpaces]),
    So_gio: new FormControl(null, [Validators.required]),
    Ngay_ke_hoach: new FormControl(null, [Validators.required]),
    Ngay_thuc_te: new FormControl(null, [Validators.required]),
    Giao_trinh: new FormControl(null,removeSpaces),
    Thiet_bi_thuc_hanh: new FormControl(null,removeSpaces),
    Ghi_chu: new FormControl(null,removeSpaces),
    Ca_hoc: new FormControl(-2),
    Phong_hoc:  new FormControl(null,removeSpaces),
    Noi_dung_giang_day:new FormControl(null,removeSpaces)
  });
  Id_cb: number;
  Hoc_ky_lop: any;
  Nam_hoc_lop: any;
  InputDatas:any;
  ID_KH: any;
  Info: UserInfo;

  constructor(
    private modalService: NgbModal,
    private TeachingPlanService: TeachingPlanService,
    private fb: FormBuilder,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute,
    private exportService: ExportService,
  ) { }
  dtTrigger = new Subject();
  dt2Trigger = new Subject();
  public com: common;

  ngOnInit() {
    // let element, Nam_hoc, arr;
    // element = document.getElementById('menu1');
    // arr = element.classNam_hoc.split(' ');
    // Nam_hoc = 'menu-open';
    // if (arr.indexOf(Nam_hoc) == -1) {
    //   element.classNam_hoc += ' ' + Nam_hoc;
    // }
    this.route.queryParams
      .subscribe(params => {
        this.Ten_mon = params.Ten_mon;
        this.Ten_lop = params.Ten_lop;
        this.ID_mon = params.ID_mon;
        this.ID_lop = params.ID_lop;
        this.Hoc_ky_lop = params.Hoc_ky;
        this.Nam_hoc_lop = params.Nam_hoc;
        this.LT = params.Ly_thuyet;
        this.TH = params.Thuc_hanh;

      });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Id_cb = a.Info.UserID;
    this.Info = a.Info
    this.Token = a.access_token;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,

      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'ID_kh_giang_day_giang_vien',
          visible: false,
        },
        {
          title: 'STT bài', className: 'dt-center',
          width: '4%',
        },
        {
          title: 'Tên bài giảng',
          width: '18%',
        },
        {
          title: 'Số giờ', className: 'dt-center',
          width: '4%',
        },
        {
          title: 'Ca học',
        },
        {
          title: 'Phòng học',
        },
        {
          title: 'Ngày kế hoạch', className: 'dt-center',
          width: '7%',
        },
        {
          title: 'Ngày thực tế', className: 'dt-center',
          width: '7%',
        },
        {
          title: 'Giáo trình',
          width: '15%',
        },
        {
          title: 'Nội dung bài giảng',
          width: '15%',
        },
        {
          title: 'Thiết bị thực hành',
          width: '15%',
        },
        {
          title: 'Ghi chú',
          width: '10%',
        },
        
        {
          title: 'Thao tác',
          className: 'dt-center',
          width: '10%'
        },
      ],
      "order": [[1, "desc"]]
    };
    this.getList();
  }

  getList() {
    this.InputDatas = {
      ID_kh_giang_day_giang_vien: this.ID_kh_giang_day_giang_vien,
      Hoc_ky: this.Hoc_ky_lop,
      Nam_hoc: this.Nam_hoc_lop,
      ID_mon: this.ID_mon,
      ID_lop: this.ID_lop,
      ID_cb: this.Id_cb,
      STT_bai: this.ActionModal.value.STT_bai,
      Ten_bai_giang: this.ActionModal.value.Ten_bai_giang,
      So_gio: this.ActionModal.value.So_gio,
      Ngay_ke_hoach: this.ActionModal.value.Ngay_ke_hoach,
      Ngay_thuc_te: this.ActionModal.value.Ngay_thuc_te,
      Giao_trinh: this.ActionModal.value.Giao_trinh,
      Thiet_bi_thuc_hanh: this.ActionModal.value.Thiet_bi_thuc_hanh,
      Ghi_chu: this.ActionModal.value.Ghi_chu,
      Noi_dung_bai_giang:this.ActionModal.value.Noi_dung_bai_giang
    };
    this.spinner.show();
    this.TeachingPlanService.List(this.InputDatas, this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      // this.ActionModal.value.So_gio = this.dataTable.reduce(x=>{x.So_gio});
      let sg =  0; 
      for (let a = 0; a < this.dataTable.length; a++) {
        sg += this.dataTable[a].So_gio;
      }
      this.So_Gio_Lop = sg;
      this.dtTrigger.next();
      this.spinner.hide();
    });
  }

  //get
  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
   this.ID_KH = Data.ID_kh_giang_day_giang_vien;
      if (Loai == 'Edit') {
      this.titleModal = 'Sửa kế hoạch';
      this.checkActionModal = true;
      
      this.ActionModal.get('Hoc_ky').setValue(Data.Hoc_ky);
      this.ActionModal.get('Nam_hoc').setValue(Data.Nam_hoc);
      this.ActionModal.get('STT_bai').setValue(Data.STT_bai);
      this.ActionModal.get('Ten_bai_giang').setValue(Data.Ten_bai_giang);
      this.ActionModal.get('So_gio').setValue(Data.So_gio);
      this.ActionModal.get('Ngay_ke_hoach').setValue(Data.Ngay_ke_hoach.substring(0, 10));
      this.ActionModal.get('Ngay_thuc_te').setValue(Data.Ngay_thuc_te.substring(0, 10));
      this.ActionModal.get('Giao_trinh').setValue(Data.Giao_trinh);
      this.ActionModal.get('Thiet_bi_thuc_hanh').setValue(Data.Thiet_bi_thuc_hanh);
      this.ActionModal.get('Ghi_chu').setValue(Data.Ghi_chu);
      this.ActionModal.get('Ca_hoc').setValue(Data.Ca_hoc);
      this.ActionModal.get('Phong_hoc').setValue(Data.Phong_hoc);  
      this.ActionModal.get('Noi_dung_giang_day').setValue(Data.Noi_dung_giang_day);
      this.check = Data.Hoc_ky;
      this.ID_kh_giang_day_giang_vien = Data.ID_kh_giang_day_giang_vien;
      this.Hoc_ky = Data.Hoc_ky;
      this.Nam_hoc = Data.Nam_hoc;
      this.ID_mon = Data.ID_mon;
    } else if (Loai == 'Add') {
      this.titleModal = 'Thêm mới kế hoạch';
      this.submitted = false;
      this.ActionModal.reset();
      this.ActionModal.get('STT_bai').setValue(this.dataTable.length != null ? this.dataTable.length + 1 : 1);
      this.checkActionModal = false;
    } else if (Loai == 'Delete') {
      this.InputDatas = {
        ID_kh_giang_day_giang_vien: Data.ID_kh_giang_day_giang_vien,
        Hoc_ky: this.Hoc_ky_lop,
        Nam_hoc: this.Nam_hoc_lop,
        ID_mon: this.ID_mon,
        ID_lop: this.ID_lop,
        ID_cb: this.Id_cb,
        STT_bai: this.ActionModal.value.STT_bai,
        Ten_bai_giang: this.ActionModal.value.Ten_bai_giang,
        So_gio: this.ActionModal.value.So_gio,
        Ngay_ke_hoach: this.ActionModal.value.Ngay_ke_hoach,
        Ngay_thuc_te: this.ActionModal.value.Ngay_thuc_te,
        Giao_trinh: this.ActionModal.value.Giao_trinh,
        Thiet_bi_thuc_hanh: this.ActionModal.value.Thiet_bi_thuc_hanh,
        Ghi_chu: this.ActionModal.value.Ghi_chu,
        Ca_hoc: this.ActionModal.value.Ca_hoc,
        Phong_hoc: this.ActionModal.value.Phong_hoc, 
        Noi_dung_bai_giang: this.ActionModal.value.Noi_dung_giang_day,
      }

    }
  }
  //check value
  get checkvalue() {
    return this.ActionModal.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.ActionModal.invalid) {
      return false;
    }
    this.create();
  }

  // create
  create() {
    
    this.InputDatas = {
      ID_kh_giang_day_giang_vien: this.ID_kh_giang_day_giang_vien,
      Hoc_ky: this.Hoc_ky_lop,
      Nam_hoc: this.Nam_hoc_lop,
      ID_mon: this.ID_mon,
      ID_lop: this.ID_lop,
      ID_cb: this.Id_cb,
      STT_bai: this.ActionModal.value.STT_bai,
      Ten_bai_giang: this.ActionModal.value.Ten_bai_giang,
      So_gio: this.ActionModal.value.So_gio,
      Ngay_ke_hoach: this.ActionModal.value.Ngay_ke_hoach.substring(0, 10),
      Ngay_thuc_te: this.ActionModal.value.Ngay_thuc_te.substring(0, 10),
      Giao_trinh: this.ActionModal.value.Giao_trinh,
      Thiet_bi_thuc_hanh: this.ActionModal.value.Thiet_bi_thuc_hanh,
      Ghi_chu: this.ActionModal.value.Ghi_chu,
      Ca_hoc: this.ActionModal.value.Ca_hoc,
      Phong_hoc: this.ActionModal.value.Phong_hoc,
      Noi_dung_giang_day: this.ActionModal.value.Noi_dung_giang_day,
    };
    if(this.ActionModal.value.So_gio <= 0)
    {
     this.toastr.warning("Số giờ không thể nhỏ hơn 0 hoặc bằng 0")
    }else{
      if (this.checkActionModal == true) {
        this.TeachingPlanService.Update(this.InputDatas, this.Token).subscribe((z) => {
          if (z.Status == 1) {
            this.toastr.success(z.Message);
  
          } else {
            this.toastr.error(z.Message);
          }
          this.modalService.dismissAll('AddModal');
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getList();
        });
      } else {
        this.TeachingPlanService.Insert(this.InputDatas, this.Token).subscribe((z) => {
          if (z.Status == 1) {
            this.toastr.success(z.Message);
          } else {
            this.toastr.error(z.Message);
          }
          this.modalService.dismissAll('AddModal');
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getList();
        });
  
      }
    }
  }

  //modal
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  setStyle(x: any) {
    let floorElements = document.getElementById(x);
    floorElements.setAttribute('style', 'border: 10px');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dt2Trigger.unsubscribe();

    $.fn['dataTable'].ext.search.pop();
  }

  Delete() {
    this.TeachingPlanService.Delete(this.InputDatas, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message);
      } else {
        this.toastr.error(z.Message);
      }
      this.modalService.dismissAll('AddModal');
      this.datatableElement.dtInstance.then(
        (dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        }
      );
      this.getList();
    });
  }

  exportExcel(){
    let res = {
      ID_kh_giang_day_giang_vien: 0,
      Ten_lop: this.Ten_lop,
      Ten_mon: this.Ten_mon,
      LT: this.LT,
      TH: this.TH,
      Hoc_ky: this.Hoc_ky_lop,
      Nam_hoc: this.Nam_hoc_lop,
      ID_mon: this.ID_mon,
      ID_lop: this.ID_lop,
      ID_cb: this.Id_cb
    }
    this.TeachingPlanService.TeachingPlanExport(res, this.Token)
      .subscribe(z => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'KeHoachGiangVien_' + this.Info.FullName + '_' + this.Info.Ten_khoa);

          this.toastr.success("File đã được tải xuống", "Thao tác thành công")
        }
        else {
          this.toastr.error(z.Message, "Thao tác thất bại")
        }
        this.TitleClass = ""
      });
  }
}
