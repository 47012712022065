import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class ApprovedRegistrationService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }
  Load_ky_dang_ky(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.PortalUrl + "DangKyLopTinChi/Load_ky_dang_ky", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Load_QuyDinhThoiGianDuyetCoVan(Ky_dang_ky, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.PortalUrl +
          "DangKyLopTinChi/Load_QuyDinhThoiGianDuyetCoVan?Ky_dang_ky=" +
          Ky_dang_ky,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Load_danh_sach_dang_ky(Ky_dang_ky, TrangThai, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.PortalUrl +
          "DuyetDangKySinhVien/Load_danh_sach_dang_ky?Ky_dang_ky=" +
          Ky_dang_ky +
          "&TrangThai=" +
          TrangThai,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Load_KetQuaHocTap(ID_sv, ID_bang_diem, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.PortalUrl +
          "DuyetDangKySinhVien/Load_KetQuaHocTap?ID_sv=" +
          ID_sv +
          "&ID_bang_diem=" +
          ID_bang_diem,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Load_HocPhanDangKy(Ky_dang_ky, ID_sv, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.PortalUrl +
          "DuyetDangKySinhVien/Load_HocPhanDangKy?Ky_dang_ky=" +
          Ky_dang_ky +
          "&ID_sv=" +
          ID_sv,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Update_DanhSachLopTinChi(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.PortalUrl +
          "DuyetDangKySinhVien/Update_DanhSachLopTinChi",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}
