<section class="content-header" style="padding: 0.5% !important">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item active">Lịch giảng</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button
          (click)="help()"
          class="btn btn-warning btn-sm"
          type="submit"
          style="float: right"
        >
          <i class="fas fa-info-circle"></i>
          Hướng dẫn
        </button>
        <button
          (click)="open(LichBieuModal, 'l', 'Add', '')"
          id="show-lich-bieu"
          class="btn btn-warning btn-sm"
          type="submit"
          style="float: right; visibility: hidden"
        >
          <i class="fas fa-info-circle"></i>
          Show lịch biểu
        </button>
      </div>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <full-calendar
            id="calendar"
            defaultView="dayGridWeek"
            [plugins]="calendarPlugins"
            [locale]="locale"
            [header]="options.header"
            [eventTextColor]="'white'"
            [customButtons]="options.customButtons"
            [eventClassName]="'hieupv'"
            [events]="eventsModel"
            (eventClick)="eventClick($event)"
            (datesRender)="myFunction($event)"
          >
          </full-calendar>
        </div>
        <!-- /.col -->
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>

<ng-template #LichBieuModal let-modal >
  <div class="modal-content" >
    <div class="modal-body">
        <div class="icon-box" style="text-align: center;">
            <i class="fas fa-trash-alt"
                style="color: #f15e5e;font-size: 46px;display: inline-block;margin-top: 13px;"></i>
        </div>
        <p
            style="color: inherit; text-decoration: none;font-size: 20px; text-align: center;  margin-top: 10px;">
            Bạn có muốn báo nghỉ cho lịch giảng này không?
        </p>
        <div class="text-center">
            <button type="button" class="btn btn-md btn-success" (click)="BaoNghi()">
                Đồng ý
            </button>
            <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                style="margin-left: 1em;">
                Hủy bỏ
            </button>

        </div>
    </div>
</div>
</ng-template>
