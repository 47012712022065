import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import {
  RequestTeachingScheduleList,
  RequestClassInfo,
} from "src/app/Models/input.model/RequestTeachingScheduleList";
import { environment } from "src/environments/environment";
import { map, filter, catchError } from "rxjs/operators";
import {
  ResponseClassListAll,
  ResponseStudentsClass,
} from "src/app/Models/output.model/ResponseClassListAll";
import { ResponseListSchedule } from "src/app/Models/output.model/ResponseListSchedule";
import { ResponseDiemDanhChiTiet } from "src/app/Models/output.model/ResponseTeachingScheduleList";
import { ResponseBase } from "src/app/Models/output.model/ResponseBase";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class AttendenceService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  getListSchedule(
    req: RequestTeachingScheduleList,
    token
  ): Observable<ResponseListSchedule> {
    return this.http
      .post<ResponseListSchedule>(
        this.appConfig.TeacherUrl + "Attendence/ListSchedule",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getListPlanSchedule(user, ID, Nam_hoc, Hoc_ky, Loai, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Attendence/ListPlanSchedule?User=" +
          user +
          "&ID_lop=" +
          ID +
          "&Nam_hoc=" +
          Nam_hoc +
          "&Hoc_ky=" +
          Hoc_ky +
          "&Loai_lop=" +
          Loai,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemDanhKeHoach_Load_List(
    user,
    ID_lop,
    ID_mon,
    Loai,
    token
  ): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Attendence/DiemDanhKeHoach_Load_List?User=" +
          user +
          "&ID_lop=" +
          ID_lop +
          "&ID_mon=" +
          ID_mon +
          "&Loai_lop=" +
          Loai,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemDanhKeHoach_ChiTiet_Load_List(
    user,
    ID_lop,
    ID_mon,
    Loai,
    Ngay_thang,
    Tiet,
    So_tiet,
    Ca_hoc,
    token
  ): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Attendence/DiemDanhKeHoach_ChiTiet_Load_List?User=" +
          user +
          "&ID_lop=" +
          ID_lop +
          "&ID_mon=" +
          ID_mon +
          "&Loai_lop=" +
          Loai +
          "&Ngay_thang=" +
          Ngay_thang +
          "&Tiet=" +
          Tiet +
          "&So_tiet=" +
          So_tiet +
          "&Ca_hoc=" +
          Ca_hoc,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemDanhKeHoach_ChiTiet_Load_New(
    user,
    ID_lop,
    ID_mon,
    Loai,
    Ngay_thang,
    Tu_tiet,
    Den_tiet,
    Ca_hoc,
    token
  ): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Attendence/DiemDanhKeHoach_ChiTiet_Load_New?User=" +
          user +
          "&ID_lop=" +
          ID_lop +
          "&ID_mon=" +
          ID_mon +
          "&Loai_lop=" +
          Loai +
          "&Ngay_thang=" +
          Ngay_thang +
          "&Tu_tiet=" +
          Tu_tiet +
          "&Den_tiet=" +
          Den_tiet +
          "&Ca_hoc=" +
          Ca_hoc,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemDanhKeHoach_Delete(
    user,
    ID_lop,
    ID_mon,
    Loai,
    Ngay_thang,
    Tiet,
    So_tiet,
    Ca_hoc,
    token
  ): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Attendence/DiemDanhKeHoach_Delete?User=" +
          user +
          "&ID_lop=" +
          ID_lop +
          "&ID_mon=" +
          ID_mon +
          "&Loai_lop=" +
          Loai +
          "&Ngay_thang=" +
          Ngay_thang +
          "&Tiet=" +
          Tiet +
          "&So_tiet=" +
          So_tiet +
          "&Ca_hoc=" +
          Ca_hoc,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemDanhKeHoach_Update(req: any, token): Observable<ResponseBase> {
    return this.http
      .post<ResponseBase>(
        this.appConfig.TeacherUrl + "Attendence/DiemDanhKeHoach_Update",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ClassGetInfo(
    req: RequestClassInfo,
    token
  ): Observable<ResponseDiemDanhChiTiet> {
    return this.http
      .post<ResponseDiemDanhChiTiet>(
        this.appConfig.TeacherUrl + "Attendence/AttendenceClassGetInfo",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ClassGetInfoPlan(
    req: RequestClassInfo,
    token
  ): Observable<ResponseDiemDanhChiTiet> {
    return this.http
      .post<ResponseDiemDanhChiTiet>(
        this.appConfig.TeacherUrl + "Attendence/AttendenceClassPlanGetInfo",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ClassCreditGetInfo(
    req: RequestClassInfo,
    token
  ): Observable<ResponseDiemDanhChiTiet> {
    return this.http
      .post<ResponseDiemDanhChiTiet>(
        this.appConfig.TeacherUrl + "Attendence/AttendenceClassCreditGetInfo",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ClassCreditGetInfoPlan(
    req: RequestClassInfo,
    token
  ): Observable<ResponseDiemDanhChiTiet> {
    return this.http
      .post<ResponseDiemDanhChiTiet>(
        this.appConfig.TeacherUrl +
          "Attendence/AttendenceClassCreditPlanGetInfo",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AttendenceGetInfoSV(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Attendence/AttendenceGetInfoSV",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AttendenceGetInfoSV_NienChe(ID_lop, ID_mon, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Attendence/AttendenceGetInfoSV_NienChe?ID_lop=" +
          ID_lop +
          "&ID_mon=" +
          ID_mon,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AttendenceGetListDay_NienChe(ID_lop, ID_mon, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Attendence/AttendenceGetListDay_NienChe?ID_lop=" +
          ID_lop +
          "&ID_mon=" +
          ID_mon,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AttendenceGetListDay_TinChi(ID_lop_tc, ID_mon, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Attendence/AttendenceGetListDay_TinChi?ID_lop_tc=" +
          ID_lop_tc +
          "&ID_mon=" +
          ID_mon,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemDanhSVHocPhan(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Attendence/DiemDanhSVHocPhan",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemDanhNgayNienChe(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Attendence/TraCuuDiemDanhNgayChiTiet",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemDanhSVNienChe(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Attendence/DiemDanhSVNienChe",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AttendenceCreditSave(req: any, token): Observable<ResponseBase> {
    return this.http
      .post<ResponseBase>(
        this.appConfig.TeacherUrl + "Attendence/AttendenceCreditSave",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AttendenceCreditPlanSave(req: any, token): Observable<ResponseBase> {
    return this.http
      .post<ResponseBase>(
        this.appConfig.TeacherUrl + "Attendence/AttendenceCreditPlanSave",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AttendenceSave(req: any, token): Observable<ResponseBase> {
    return this.http
      .post<ResponseBase>(
        this.appConfig.TeacherUrl + "Attendence/AttendenceSave",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AttendencePlanSave(req: any, token): Observable<ResponseBase> {
    return this.http
      .post<ResponseBase>(
        this.appConfig.TeacherUrl + "Attendence/AttendencePlanSave",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AttendenceApprove_Day_Off_NienChe(ID_lop, ID_mon, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Attendence/AttendenceApprove_Day_Off_NienChe?ID_lop=" +
          ID_lop +
          "&ID_mon=" +
          ID_mon,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AttendenceApprove_Day_Off_TinChi(ID_lop, ID_mon, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Attendence/AttendenceApprove_Day_Off_TinChi?ID_lop=" +
          ID_lop +
          "&ID_mon=" +
          ID_mon,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  AttendenceExport_NienChe(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl +
          "BaoCao/ExportToExcelBaoCao_DiemDanhChiTiet",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  AttendenceExport_TinChi(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl +
          "BaoCao/ExportToExcelBaoCao_DiemDanhChiTiet",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  AttendenceDayOffExport(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Attendence/AttendenceDayOff_Export",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AttendenceExport_DiemDanh(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl +
          "Attendence/AttendenceGetInfoSV_LoadLopExport",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  AttendenceExport_DiemDanhChiChiet(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl +
          "BaoCao/ExportToExcelBaoCao_DiemDanhChiTiet",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  BaoCaoDiemDanhKeHoach_Export(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "BaoCao/BaoCaoDiemDanhKeHoach_Export",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  BangDiemDanhTheoDoi_Export(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl +
          "Attendence/Attendence_DiemDanhSinhVienTheoKy",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}
