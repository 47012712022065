<section class="content-header" style="padding:5px !important">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <ol class="breadcrumb float-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                    <li class="breadcrumb-item active">Báo cáo &nbsp;</li>
                    <div class="{{TitleClass}}"></div>
                </ol>
            </div>
            <div class="col-md-6">
                <button (click)="help()" class="btn btn-warning btn-sm"  type="submit" style="float: right; ">
                    <i class="fas fa-info-circle"></i>
                    Hướng dẫn
                </button>
            </div>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body" style="padding: 0px 20px 0px 20px;">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row pt10" style="margin-bottom: 1%;">
                        <div class="col-md-4">
                            <label class="col-md-10">Năm học</label>
                            <select class="form-control" [(ngModel)]="Nam_hoc_fil">
                                <option value="">Tất cả</option>
                                <option *ngFor = "let p of Nam_hoc_arr" value="{{p.value}}">{{p.name}}</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label class="col-md-10">Tên môn</label>
                            <input  class="form-control" type="text" placeholder="Nhập tên môn"
                            [(ngModel)]="Ten_mon_fil">
                        </div>
                        <div class="col-md-4">
                            <label class="col-md-10">Tên lớp</label>
                            <input  class="form-control" type="text" placeholder="Nhập tên lớp"
                            [(ngModel)]="Ten_lop_fil">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class=" table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData | tableFilter: Nam_hoc_fil | FilterClass:{Ten_mon : Ten_mon_fil , Ten_lop : Ten_lop_fil }">

                                            <td>{{ p.Ten_mon }}</td>
                                            <td>{{ p.Ten_lop }}</td>
                                            <td>{{ p.Hoc_ky }}</td>
                                            <td>{{ p.ID_lop }}</td>
                                            <td>{{ p.Nam_hoc }}</td>
                                            <td>{{ p.Ky_hieu }}</td>
                                            <td>{{ p.So_sv }}</td>
                                            <td>{{ p.Ten_loai_lop }}</td>
                                            <td>{{ p.Loai_lop }}</td>
                                            <td>
                                                <button class="btn btn-sm btn-info"
                                                    (click)="open(ExcelModal, '', '', p)"
                                                    style="margin-right: 7px;" ngbTooltip="Xuất">
                                                    <i class="fas fa-download"></i>
                                                </button>
                                            </td>
                                            <!-- <td> -->
                                                <!-- <a style="margin-right: 5px;" class="btn btn-primary btn-sm"
                                                    [routerLink]="['/admin/studentsclass']"
                                                    [queryParams]="{CategoryID: p.Loai_lop,ID:p.ID_lop,Ten_lop:p.Ten_lop,ID_mon:p.ID_mon,Hoc_ky:p.Hoc_ky,Nam_hoc:p.Nam_hoc}"
                                                    queryParamsHandling="merge">
                                                    <i class="fas fa-eye" title="Xem danh sách"></i>
                                                </a>
                                                <a [routerLink]="['/admin/create']" class="btn btn-default btn-sm"
                                                    [queryParams]="{Category: 4,ID:p.ID_lop,LoaiLop:p.Loai_lop}"
                                                    queryParamsHandling="merge">
                                            <i class="far fa-envelope cus-cr" title="Gửi tin nhắn"></i>
                                            </a> -->
                                            <!-- <select (change)="getReport(p.Hoc_ky,p.Nam_hoc,p.ID_mon,p.ID_lop,p.Ten_mon,p.Ten_lop,p.Ly_thuyet,p.Thuc_hanh,$event.target.value,p.Loai_lop)" >
                                                <option value="0">Chọn mẫu</option>
                                                <option value="5">Sổ lên lớp </option>
                                                <option value="2">Bảng điểm danh</option>
                                                <option value="1">Bảng điểm môn học</option>
                                                <option value="4">Kế hoạch giảng dạy </option>
                                                <option value="6">Nhận xét giảng dạy</option>
                                                
                                            </select> -->
                                            <!-- </td> -->
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #ExcelModal let-modal>
    <div class="modal-content cus-fs">
        <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
            <h4 class="modal-title h-title w-100">Tải xuống biểu mẫu</h4>
            <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
        </div>
        <div class="modal-body">
            <div class="row" *ngIf="!CheckDiemDanh">
                <div class="col-md-12" >
                    <label class="col-md-10">Tháng năm</label>
                    <input type="month" class="form-control" [(ngModel)]="Thang_nam">
                </div>
            </div>
            <table class=" table table-striped">
            <thead>
                <tr>
                    <th>Biểu mẫu<ul></ul></th>
                    <th class="text-center">Tải</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let p of DataTableExcel">
                    <td>{{p.Ten_bieu_mau}}</td>
                    <td class="text-center">
                        <button class="btn btn-sm btn-info"
                            (click)="getReport(p.ID)"
                            style="margin-right: 7px;" ngbTooltip="Tải xuống">
                            <i class="fas fa-file-download"></i>
                        </button>
                    </td>
                </tr>
            </tbody>

        </table>
        </div>
    </div>
</ng-template>