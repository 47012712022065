import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { Injectable, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { common } from "src/app/app.common";
import { SettingService } from "src/app/Utils/service/setting.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AppConfiguration, AppConfig } from "src/configuration";
import { AppService } from "src/app/Utils/services/app.service";
import { Account } from "src/app/Utils/services/Account.service";
@Component({
  selector: "app-menu-sidebar",
  templateUrl: "./menu-sidebar.component.html",
  styleUrls: ["./menu-sidebar.component.scss"],
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild("mainSidebar", { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();
  Roles: any;
  Co_van_xem_diem: any;
  Xem_diem_sinh_vien: any;
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    public AccountServirce: Account,
    private spinner: NgxSpinnerService,
    public appService: AppService,
    public settingService: SettingService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  Thong_tin_ca_nhan: boolean = false;
  Ke_hoach_giang_day: boolean = false;
  Lich_giang_day: boolean = false;
  Dang_ky_doi_bu_gio: boolean = false;
  Lich_coi_thi: boolean = false;
  Quan_ly_lop_hoc: boolean = false;
  So_len_lop: boolean = false;
  Diem_danh_sinh_vien: boolean = false;
  Tra_cuu_diem_danh: boolean = false;
  Diem_mon_hoc: boolean = false;
  Nhan_xet_giang_day: boolean = false;
  Duyet_ket_qua_dang_ky: boolean = false;
  Bieu_mau_bao_cao: boolean = false;
  Dinh_muc_giang_day: boolean = false;
  Ke_khai_khoi_luong_giang_day: boolean = false;
  Thanh_toan_vuot_gio: boolean = false;
  Ly_lich_khoa_hoc: boolean = false;
  Dang_ky_de_xuat: boolean = false;
  De_tai_thuc_hien: boolean = false;
  Redirect_UniLeader: boolean = false;
  Redirect_UniSurvey: boolean = false;
  public com: common;
  MenuList: any;
  Token: any;
  TALoginCode: any;
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var UserData = this.com.getUserinfo();
    this.Token = UserData.access_token;
    this.TALoginCode = UserData.TALoginCode;
    this.Roles = UserData.Roles;
    if (this.Roles.length == 0) {
      this.toastr.warning(
        "Bạn chưa được cấp bất kỳ quyền nào! Vui lòng liên hệ với quản trị viên để được hỗ trợ"
      );
    } else {
      this.SetUpMenu(this.Roles);
    }
    // this.getList();
  }

  menu(id: string) {
    let element, name, arr;
    element = document.getElementById(id);
    arr = element.className.split(" ");
    name = "menu-open";
    if (arr.indexOf(name) == -1) {
      element.className += " " + name;
    } else {
      element.className = "nav-item";
    }
  }

  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
  }

  SetUpMenu(R) {
    this.Thong_tin_ca_nhan = R.some((x) => x.ID_quyen == 1301);

    this.Ke_hoach_giang_day = R.some((x) => x.ID_quyen == 1302);

    this.Lich_giang_day = R.some((x) => x.ID_quyen == 1303);

    this.Dang_ky_doi_bu_gio = R.some((x) => x.ID_quyen == 1304);

    this.Lich_coi_thi = R.some((x) => x.ID_quyen == 1305);

    this.Quan_ly_lop_hoc = R.some((x) => x.ID_quyen == 1306);

    this.So_len_lop = R.some((x) => x.ID_quyen == 1307);

    this.Diem_danh_sinh_vien = R.some((x) => x.ID_quyen == 1308);

    this.Tra_cuu_diem_danh = R.some((x) => x.ID_quyen == 1309);

    this.Diem_mon_hoc = R.some((x) => x.ID_quyen == 1310);

    this.Nhan_xet_giang_day = R.some((x) => x.ID_quyen == 1311);

    this.Duyet_ket_qua_dang_ky = R.some((x) => x.ID_quyen == 1312);

    this.Bieu_mau_bao_cao = R.some((x) => x.ID_quyen == 1313);

    this.Dinh_muc_giang_day = R.some((x) => x.ID_quyen == 1314);

    this.Ke_khai_khoi_luong_giang_day = R.some((x) => x.ID_quyen == 1315);

    this.Thanh_toan_vuot_gio = R.some((x) => x.ID_quyen == 1316);

    this.Ly_lich_khoa_hoc = R.some((x) => x.ID_quyen == 1317);

    this.Dang_ky_de_xuat = R.some((x) => x.ID_quyen == 1318);

    this.De_tai_thuc_hien = R.some((x) => x.ID_quyen == 1319);

    this.Co_van_xem_diem = R.some((x) => x.ID_quyen == 1321);

    this.Xem_diem_sinh_vien = R.some((x) => x.ID_quyen == 1322);

    this.Redirect_UniLeader = R.some((x) => x.ID_quyen == 1323);
    this.Redirect_UniSurvey = R.some((x) => x.ID_quyen == 1326);
  }

  redirectToUniLeader() {
    var r = window.confirm(
      "Bạn có muốn chuyển hướng sang trang UniLeader không?"
    );
    if (!r) {
      return;
    }
    this.spinner.show();
    var UserData = this.com.getUserinfo();
    this.AccountServirce.getTALoginCode(UserData.access_token).subscribe(
      (z) => {
        this.spinner.hide();
        if (z.Status == 1) {
          window.open(z.Content[0].UniLeader_link, "_blank");
        } else {
          this.toastr.error(z.Message, "Thao tác thất bại");
        }
      }
    );
  }

  redirectToUniSurvey() {
    var r = window.confirm(
      "Bạn có muốn chuyển hướng UniSurvey - Phân hệ Khảo sát đánh giá?"
    );
    if (!r) {
      return;
    }
    if (this.TALoginCode != null) {
       window.open(this.appConfig.LoginPortalSurvey+"?TALoginCode="+this.TALoginCode+"&cat=Teacher");
    } else {
      this.toastr.warning(
        "Không tìm thấy mã đăng nhập, vui lòng liên hệ quản trị viên Update UniSystemAPI phiên bản mới để sử dụng chức năng này!"
      );
    }
  }

  redirectToHrmApp() {
    var HrmLink =
      this.appConfig.HrmUrl +
      "/redirect?TALoginCode=" +
      this.TALoginCode +
      "&page=1";
    window.open(HrmLink, "_blank");
  }
}
