<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/teachingplanclass']">Lớp học</a></li>
                    <li class="breadcrumb-item active">Kế hoạch giảng dạy</li>
                </ol>
            </div>
            <div class="col-md-6">
                <button class="btn btn-info btn-sm" (click)="exportExcel()" style="float: right; margin-right: 10px;" *ngIf="dataTable">
                    <i class="fas fa-file-excel"></i> Xuất Excel
                </button>
                <button class="btn btn-success btn-sm" (click)="open(ThemModal, 'xl', 'Add', '')" style="float: right; margin-right: 10px;">
                    <i class="fas fa-plus"></i> Thêm mới
                </button>
              
            </div>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row pt10">
                        <div class="col-md-6">
                            <label class="col-md-10">Môn học : {{Ten_mon}}</label>
                        </div>
                        <div class="col-md-2">
                            <label class="col-md-10">Số giờ : {{So_Gio_Lop}}</label>
                        </div>
                        <div class="col-md-2 ">
                            <label class="col-md-10">LT : {{LT}}</label>
                        </div>
                        <div class="col-md-2">
                            <label class="col-md-10">TH : {{TH}}</label>
                        </div>
                    </div>
                    <div class="row pt10">
                        <div class="col-md-6">
                            <label class="col-md-10">Lớp : {{Ten_lop}}</label>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-10">Học kỳ : {{Hoc_ky_lop}} - Năm học : {{Nam_hoc_lop}} </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of dataTable; index as i">
                                            <td>{{ p.ID_kh_giang_day_giang_vien }}</td>
                                            <td>{{ p.STT_bai }}</td>
                                            <td>{{ p.Ten_bai_giang }}</td>
                                            <td>{{ p.So_gio }}</td>
                                            <td>{{ p.Ten_ca }}</td>
                                            <td>{{ p.Phong_hoc }}</td>
                                            <td>{{ p.Ngay_ke_hoach | date:'dd/MM/yyyy'}}</td>
                                            <td>{{ p.Ngay_thuc_te | date:'dd/MM/yyyy'}}</td>
                                            <td>{{ p.Giao_trinh }}</td>
                                            <td>{{ p.Noi_dung_giang_day }}</td>
                                            <td>{{ p.Thiet_bi_thuc_hanh }}</td>
                                            <td>{{ p.Ghi_chu }}</td>
                                            
                                            <td>
                                                <button class="btn btn-sm btn-warning"
                                                    (click)="open(ThemModal, 'xl', 'Edit', p)"
                                                    style="margin-right: 7px;">
                                                    <i class="fas fa-edit" title="Sửa kế hoạch"></i>
                                                </button>
                                                <button class="btn btn-sm btn-danger"
                                                    (click)="open(DeleteModal, '', 'Delete', p)"
                                                    style="margin-right: 7px;">
                                                    <i class="fas fa-trash-alt" title="Xoá kế hoạch"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<ng-template #ThemModal let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
        <h4 class="modal-title h-title w-100">{{ titleModal }}</h4>
        <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body cus-fs">
        <form class="form-horizontal" [formGroup]="ActionModal">
            <div class="row pt10">
                <div class="col-md-3">
                    <label class="col-md-10 require">STT bài</label>
                    <input type="text" class="form-control" disabled required placeholder="STT bài" formControlName="STT_bai" [ngClass]="{
                        'is-invalid': submitted && checkvalue.STT_bai.errors
                      }">
                    <div *ngIf="submitted && checkvalue.STT_bai.errors" class="invalid-feedback">
                        <div *ngIf="checkvalue.STT_bai.errors.required">
                            STT không được bỏ trống!
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10 require">Tên bài giảng</label>
                    <input type="text" class="form-control" required placeholder="Tên bài giảng" formControlName="Ten_bai_giang"
                        [ngClass]="{
                        'is-invalid': submitted && checkvalue.Ten_bai_giang.errors
                      }">
                    <div *ngIf="submitted && checkvalue.Ten_bai_giang.errors" class="invalid-feedback">
                        <div *ngIf="checkvalue.Ten_bai_giang.errors.required">
                            Tên bài giảng không được bỏ trống!
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10">Ca học</label>
                    <select class="form-control" formControlName="Ca_hoc">
                        <option value="-2">Chọn ca học</option>
                        <option value="0">Sáng</option>
                        <option value="1">Chiều</option>
                        <option value="2">Tối</option>
                        <option value="-1">Cả ngày</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10">Phòng học</label>
                    <input type="text" class="form-control" placeholder="Phòng học" formControlName="Phong_hoc">
                   
                </div>
            </div>
            <div class="row pt10">
                <div class="col-md-3">
                    <label class="col-md-10 require">Số giờ</label>
                    <input type="number" class="form-control" required placeholder="Số giờ" formControlName="So_gio"    [ngClass]="{
                        'is-invalid': submitted && checkvalue.So_gio.errors
                      }">
                    <div *ngIf="submitted && checkvalue.So_gio.errors" class="invalid-feedback">
                        <div *ngIf="checkvalue.So_gio.errors.required">
                            Số giờ không được bỏ trống!
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label class="col-md-10 require">Ngày kế hoạch</label>
                    <input type="date" class="form-control" required placeholder="Ngày kế hoạch" formControlName="Ngay_ke_hoach"    [ngClass]="{
                        'is-invalid': submitted && checkvalue.Ngay_ke_hoach.errors
                      }">
                    <div *ngIf="submitted && checkvalue.Ngay_ke_hoach.errors" class="invalid-feedback">
                        <div *ngIf="checkvalue.Ngay_ke_hoach.errors.required">
                            Ngày kế hoạch không được bỏ trống!
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10 require">Ngày thực tế</label>
                    <input type="date" class="form-control" required placeholder="Ngày thực tế" formControlName="Ngay_thuc_te"    [ngClass]="{
                        'is-invalid': submitted && checkvalue.Ngay_thuc_te.errors
                      }">
                    <div *ngIf="submitted && checkvalue.Ngay_thuc_te.errors" class="invalid-feedback">
                        <div *ngIf="checkvalue.Ngay_thuc_te.errors.required">
                            Ngày thực tế không được bỏ trống!
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10">Ghi chú</label>
                    <input type="text" class="form-control" placeholder="Nhập ghi chú ..."
                        formControlName="Ghi_chu">
                </div>
         
            </div>

            <div class="row pt10">
                <div class="col-md-6">
                    <label class="col-md-10">Giáo trình</label>
                    <textarea rows="5" type="text" class="form-control" placeholder="Nhập giáo trình ..." formControlName="Giao_trinh"></textarea>
                </div>
              
                <div class="col-md-6">
                    <label class="col-md-10">Thiết bị thực hành</label>
                    <textarea rows="5" type="text" class="form-control" placeholder="Nhập thiết bị thực hành ..."
                        formControlName="Thiet_bi_thuc_hanh"></textarea>
                </div>
                <div class="col-md-12">
                    <label class="col-md-10">Nội dung bài giảng</label>
                    <textarea rows="5" type="text" class="form-control" placeholder="Nội dung bài giảng ..."
                        formControlName="Noi_dung_giang_day"></textarea>
                </div>
            </div>


        </form>
    </div>
    <div class="modal-footer">
        <div class="float-right">

            <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
                <i class="fas fa-save"></i> Lưu
                
            </button>
            <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                style="margin-left: 1em;">
                <i class="far fa-times-circle"></i> Đóng
            </button>
        </div>
    </div>
</ng-template>
<ng-template #DeleteModal let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <div class="icon-box">
                <i class="fas fa-trash-alt"
                    style="color: #f15e5e;font-size: 46px;display: inline-block;margin-top: 13px;"></i>
            </div>
            <p
                style="color: inherit; text-decoration: none;font-size: 20px; text-align: center; font-weight: 600; margin-top: 3em;">
                Bạn có chắc chắn muốn xóa
            </p>
            <div class="text-center">
                <button type="button" class="btn btn-md btn-success" (click)="Delete()">
                    Đồng ý
                </button>
                <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                    style="margin-left: 1em;">
                    Hủy bỏ
                </button>

            </div>
        </div>
    </div>
</ng-template>