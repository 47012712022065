import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { AppService } from 'src/app/Utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Class } from 'src/app/Utils/services/Class.service';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ClassService } from 'src/app/Utils/service/class.service';
import { AttendenceService } from 'src/app/Utils/service/attendence.service';
import { ResponseClassListAll, ClassInfo } from 'src/app/Models/output.model/ResponseClassListAll';
import { ResponseListSchedule, ClassData } from 'src/app/Models/output.model/ResponseListSchedule';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { RequestTeachingScheduleList } from 'src/app/Models/input.model/RequestTeachingScheduleList';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-attendence-plan',
  templateUrl: './attendence-plan.component.html',
  styleUrls: ['./attendence-plan.component.scss']
})
export class AttendencePlanComponent implements OnInit {
  dtOptions: DataTables.Settings
  dtOptions2: DataTables.Settings
  Res: ResponseListSchedule = new ResponseListSchedule()
  ClassData: ClassData[]
  requestObject: RequestTeachingScheduleList = new RequestTeachingScheduleList()
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  HocKy: number;
  NamHoc: string;
  MonHoc: string;
  token: string;
  DiemDanhKeHoach: boolean = false;
  Title: string = "Đang tải danh sách lớp..."
  TitleClass: string = "spinner-border text-muted"
  //public Datetime: FormGroup;
  public FromDay: any;
  public ToDay: any;
  Datetime = new FormGroup({
    FromDay: new FormControl(null),
    ToDay: new FormControl(null)
  });
  Ten_mon:string
  Ten_lop:string
  TableData: ClassData[];
  ID_lop: any;
  Nam_hoc: any;
  Hoc_ky: any;
  Loai_lop: any;
  constructor(
    public route: ActivatedRoute,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private ClassService: ClassService,
    private AttendenceService: AttendenceService,
    private spinner: NgxSpinnerService
  ) {
    // 

  }
  dtTrigger = new Subject();
  dtTrigger2 = new Subject();
  public com: common;
  ngOnInit() {
    this.spinner.show()
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.route.queryParams
      .subscribe(params => {
        this.ID_lop = params.IDLop
        this.Nam_hoc = params.Nam_hoc
        this.Hoc_ky = params.Hoc_ky
        this.Loai_lop = params.Loai_lop
        this.Ten_mon = params.Ten_mon
        this.Ten_lop = params.Ten_lop
      });
    //var rs=this.Class.ClassListAll(a.Info.UserName, a.access_token)
    this.requestObject.UserName = a.Info.UserName
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      // ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [
        {
          title: 'Ngày giảng dạy',
          className: "dt-center"
        },
        {
          title: 'Thứ',
          className: "dt-center"
        },
        {
          title: 'Ca học',
          className: "dt-center"
        },
        {
          title: 'Từ tiết',
          className: "dt-center"
        },
        {
          title: 'Đến tiết',
          className: "dt-center"
        },
        {
          title: 'Từ giờ',
          className: "dt-center"
        },
        {
          title: 'Đến giờ',
          className: "dt-center"
        },
        {
          title: 'Thao tác',
          width: "10%",
          className: "dt-center"
        },
      ],
      order: [[6, 'asc']]
    };
    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [

        {
          title: 'Tên môn',
          data: 'Ten_mon'
        },
        {
          title: 'Tên lớp',
          data: 'Ten_lop'
        },
        {
          title: 'Học kỳ',
          className: "dt-center",
          data: 'Hoc_ky'
        },
        {
          title: 'ID_lop',
          data: 'ID_lop',
          visible: false
        },
        {
          title: 'Năm học',
          className: "dt-center",
          data: 'Nam_hoc'
        },
        {
          title: 'Ký hiệu',
          data: 'Ky_hieu'
        },
        {
          title: 'Số sinh viên',
          className: "dt-center",
          data: 'So_sv'
        },
        {
          title: 'Tên loại lớp',
          data: 'Ten_loai_lop'
        },
        {
          title: 'Loại lớp',
          data: 'Loai_lop',
          visible: false
        },
        {
          title: 'Thao tác',
          width: "12%",
          className: "dt-center",
          defaultContent: '<i class="fas fa-file-alt" id="NhapDiemThi" title"Nhập điểm thi"></i>'
        },
      ],
      "order": [[2, "desc"]]
    };
    this.token = a.access_token;
    this.getListSchedule(a.Info.UserName, this.ID_lop, this.token)
  }

  getListSchedule(user, ID_lop, token) {
    this.AttendenceService.getListPlanSchedule(user, ID_lop, this.Nam_hoc, this.Hoc_ky, this.Loai_lop, token)
      .subscribe(z => {
        if(z.Status==1){
          let dt = z.Events
          for(var j = 0;j<dt.length;j++)
          {
            dt[j].dtSchedule[0].Thu = dt[j].dtSchedule[0].Thu + 1;
            dt[j].start = dt[j].start.substring(0,10).split("-").reverse().join("/")
            var temp = dt[j];
            var dtSchedule = temp.dtSchedule[0];
  
            dt[j] = Object.assign(temp,dtSchedule);
          }
          if ((this.Datetime.value.FromDay != null)) {
            this.FromDay = this.Datetime.value.FromDay
            this.ClassData = dt.filter(x => {
              let Ngay_hoc = x.start.split("/").reverse().join("-");
              return this.FromDay == Ngay_hoc 
            });
          } else {
   
            this.ClassData = dt
          }
          this.dtTrigger.next();
        }
        else{
          this.toastr.warning(z.Message)
        }
        this.Title = ""
        this.TitleClass = ""
        this.spinner.hide()
      })
      ;
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  GetData(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    if (this.Datetime.value.ToDay == '') {
      this.Datetime.value.ToDay = null
    }
    if (this.Datetime.value.FromDay == '') {
      this.Datetime.value.FromDay = null
    }
    this.getListSchedule(this.requestObject.UserName, this.ID_lop, this.token)
  }

}
