import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { AppService } from 'src/app/Utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Class } from 'src/app/Utils/services/Class.service';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ClassService } from 'src/app/Utils/service/class.service';
import { AttendenceService } from 'src/app/Utils/service/attendence.service';
import { ResponseClassListAll, ClassInfo } from 'src/app/Models/output.model/ResponseClassListAll';
import { ResponseListSchedule, ClassData } from 'src/app/Models/output.model/ResponseListSchedule';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { RequestTeachingScheduleList } from 'src/app/Models/input.model/RequestTeachingScheduleList';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { formatDate } from '@angular/common' 
@Component({
  selector: 'app-attendence-history',
  templateUrl: './attendence-history.component.html',
  styleUrls: ['./attendence-history.component.scss']
})
export class AttendenceHistoryComponent implements OnInit {
   dtOptions: DataTables.Settings
  Res: ResponseListSchedule = new ResponseListSchedule()
  ClassData: ClassData[]
  requestObject: RequestBase = new RequestBase()
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  HocKy: number;
  NamHoc: string;
  MonHoc: string;
  Title: string = "Đang tải danh sách lớp..."
  TitleClass: string = "spinner-border text-muted"
  Nam_hoc_fil: string = "";
  Ten_mon_fil:string;
  Ten_lop_fil:string
  Nam_hoc_arr: any = []
  //public Datetime: FormGroup;
  public FromDay: any;
  public ToDay: any;
  Datetime = new FormGroup({
    FromDay: new FormControl(null),
    ToDay: new FormControl(null)
  });
  constructor(
    @Inject(AppConfig) 
    private readonly appConfig: AppConfiguration,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private ClassService: ClassService,
  ) {
    // 

  }
  dtTrigger = new Subject();
  public com: common;
  ngOnInit() {   
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let hockydt = data[2]; // use data for the id column
      let NamHocdt = data[4] + ""; // use data for the id column
      let MonHocdt = data[0] + ""; // use data for the id column
      let bool1 = true
      let bool2 = true
      let bool3 = true
      let fillter = true;
      if (this.HocKy > 0) {
        bool1 = this.HocKy == hockydt
      }
      if (this.NamHoc != undefined) {
        bool2 = NamHocdt.includes(this.NamHoc)
      }
      if (this.MonHoc != undefined) {
        bool3 = MonHocdt.includes(this.MonHoc)
      }
      fillter = bool1 && bool2 && bool3;
      return fillter;
    });
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    //var rs=this.Class.ClassListAll(a.Info.UserName, a.access_token)
    this.requestObject.UserName = a.Info.UserName
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [
        {
          title: 'Ký hiệu', className: "dt-center",
          data: 'Ky_hieu'
        },
        {
          title: 'Tên môn',
          data: 'Ten_mon'
        },
        {
          title: 'Tên lớp',
          data: 'Ten_lop'
        },
         {
          title: 'Học kỳ', className: "dt-center",
          data: 'Hoc_ky'
        },
        // {
        //   title: 'ID_lop',
        //   data: 'ID_lop'
        // },
        {
          title: 'Năm học',
          data: 'Nam_hoc'
        },
        {
          title: 'Đợt', className: "dt-center",
          data: ''
        },
        {
          title: 'Số sinh viên', className: "dt-center",
          data: 'So_sv'
        },
        {
          title: 'Tên loại lớp',
          data: 'Ten_loai_lop'
        },
        // {
        //   title: 'Loại lớp',
        //   data: 'Loai_lop'
        // },
        {
          title: 'Thao tác',
          width: "10%",
          className: "dt-center"
        },
      ],
    };
    // let curr = new Date; // get current date
    // let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    // let last = first + 7; // last day is the first day + 6
    // let firstday = new Date(curr.setDate(first));
    // let lastday = new Date(curr.setDate(last));
    // // let curDate = new Date(Date.now())
    // let FromDay = new Date(firstday.getFullYear()-10, firstday.getMonth(), firstday.getDate())
    // let ToDay = new Date(lastday.getFullYear(), lastday.getMonth(), lastday.getDate())
    // let m = (ToDay.getMonth() + 1).toString();
    // let d = ToDay.getDate().toString();
    // if (m.length == 1) m = '0' + m;
    // if (d.length == 1) d = '0' + d;
    // let m2 = (FromDay.getMonth() + 1).toString();
    // let d2 = FromDay.getDate().toString();
    // if (m2.length == 1) m2 = '0' + m2;
    // if (d2.length == 1) d2 = '0' + d2;
    // let ToDayString = ToDay.getFullYear() + "-" + m + "-" + d
    // let FromDayString = FromDay.getFullYear() + "-" + m2 + "-" + d2
    // this.Datetime.controls.FromDay.setValue(FromDayString);
    // this.Datetime.get("ToDay").setValue(ToDayString);
    this.Datetime.controls.FromDay.setValue(formatDate(this.getMonday(new Date()),'yyyy-MM-dd','en'))
    this.Datetime.controls.ToDay.setValue(formatDate(this.getSunday(new Date()),'yyyy-MM-dd','en'))
    // this.requestObject.FromDay = new Date(FromDay)
    // this.requestObject.ToDay = new Date(ToDay)
    this.getListHistoryAtten(this.requestObject, a.access_token)
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl+"?"+this.appConfig.document_Tra_cuu_diem_danh ;
    window.open(urlHelp,'_blank');
  }
  getListHistoryAtten(req: RequestBase, token) {
    this.ClassService.getClass(req, token)
      .subscribe(z => {
        this.Res = z
        this.ClassData = this.Res.Data
        this.dtTrigger.next();
        this.Title = ""
        this.TitleClass = ""
      });
  }
  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  getSunday(date)
  {
    var lastday = date.getDate() - (date.getDay() - 1) + 6;
    return new Date(date.setDate(lastday));
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }

  GetData(): void {
   
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();   
    });  
    this.ToDay = $('#ToDay').val();    
    this.FromDay = $('#FromDay').val();
    // this.requestObject.ToDay =new Date(this.ToDay)
    // this.requestObject.FromDay=new Date(this.FromDay)
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.getListHistoryAtten(this.requestObject, a.access_token)
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
}
