import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { RequestTeachingScheduleList, RequestClassInfo } from 'src/app/Models/input.model/RequestTeachingScheduleList';
import {  ResponseListSchedule } from 'src/app/Models/output.model/ResponseListSchedule';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfiguration, AppConfig } from 'src/configuration';
@Injectable({
  providedIn: 'root'
})
export class ExamSchedule {
  constructor(@Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http : HttpClient) { }
  List(req:RequestTeachingScheduleList, token) : Observable<ResponseListSchedule>{
    return this.http.post<ResponseListSchedule>(this.appConfig.TeacherUrl  + 'ExamSchedule/ExamScheduleList',req,{
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    } ).pipe(map(z=> {return z}))  
    // try {
    //   $.ajax({
    //     type: "POST",
    //     url: environment.TeacherUrl + "ExamSchedule/ExamScheduleList",
    //     dataType: "json",
    //     async: false,
    //     data: { 'UserName': UserName,
    //             'FromDay':FromDay,
    //             'ToDay':ToDay},
    //     beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + token) },
    //     success: function (data) {
    //       m=data;
    //     },
    //     error: function (err) {
    //       m.Status = 3;
    //     },
    //   });
    // }
    // catch (err) {
    //   m.Status = 3;
    // }
    // finally {
    //   return m;
    // }
  }
}
