import { Component, OnInit, OnDestroy, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MarkService } from 'src/app/Utils/service/mark.service';
import { common } from 'src/app/app.common';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/Utils/services/app.service';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup } from '@angular/forms';
import { HeDaoTaoService } from 'src/app/Utils/service/he-dao-tao.service';
import { ExportService } from 'src/app/Utils/service/export.service';
@Component({
  selector: 'app-co-van-hoc-tap-xem-diem',
  templateUrl: './co-van-hoc-tap-xem-diem.component.html',
  styleUrls: ['./co-van-hoc-tap-xem-diem.component.scss']
})
export class CoVanHocTapXemDiemComponent implements OnInit {
  Token: any;
  Info: any;
  ClassData: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings;
  closeResult: string;
  lblTBCHT4: any
  lblTBCHT10: any
  lblTBC_tich_luy: any
  lblXep_loai_hoc_tap10: any
  lblXep_loai_hoc_tap4: any
  lblSo_mon_hoc_lai: any
  lblSo_mon_thi_lai: any
  lblSo_tin_chi_tich_luy: any
  lblXep_hang_hoc_luc: any
  Title: string = "Đang tải danh sách "
  Nam_hoc_arr: any = []
  dataHe: any = []
  BangDiemSVChiTiet: any;
  Ma_sv: any;
  Ten_sv: any;
  searchString: any;
  QuyChe: any;
  constructor(
    private MaskService : MarkService,
    public route: ActivatedRoute,
    private AppService: AppService,
    private toastr: ToastrService,
    public router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private HeDaoTaoService: HeDaoTaoService,
    private exportService: ExportService,
  ) { }
  dtTrigger = new Subject();
  dtTrigger2 = new Subject();
  public com: common;
  filter = new FormGroup({
    Hoc_ky: new FormControl(1),
    Nam_hoc: new FormControl((new Date().getFullYear() - 1)+ '-' + new Date().getFullYear()),
    ID_he: new FormControl(1),
    Loai_thong_ke: new FormControl(2),
    Ma_sv: new FormControl(''),
    Ho_ten: new FormControl(''),
    Khoa: new FormControl(''),
    Lop: new FormControl(''),
  });
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.access_token
    this.Info = a.Info
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [
        {
          title: 'Mã sinh viên',
        },
        {
          title: 'Họ tên'
        },
        {
          title: 'Ngày sinh', className: "dt-center"
        },
        {
          title: 'Chuyên ngành',
        },
        {
          title: 'Khóa', className: "dt-center"
        },
        {
          title: 'Lớp',
        },
        {
          title: 'Hệ',
          visible:false
        },
        {
          title: 'Thao tác',
          width: "12%",
          className: "dt-center",
        },
      ],
    };
    this.GetHe()
    this.ClassCreditGetInfo()
  }

changeFilter(){
  this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    dtInstance.destroy();
  });
  this.ClassCreditGetInfo();
}

  ClassCreditGetInfo() {
    this.spinner.show()
      this.MaskService.ListDSCoVan(this.Info.UserName,this.Token)
        .subscribe(z => {
          var data = z.Data;
          if (this.filter.value.ID_he != 0) {
            data = data.filter(x => x.ID_he == this.filter.value.ID_he)
          }
          if (this.filter.value.Ma_sv != '') {
            data = data.filter(x => x.Ma_sv.includes(this.filter.value.Ma_sv) )
          }
          if (this.filter.value.Ho_ten != '') {
            data = data.filter(x => x.Ho_ten.includes(this.filter.value.Ho_ten))
          }
          if (this.filter.value.Khoa != '') {
            data = data.filter(x => x.Khoa_hoc == this.filter.value.Khoa)
          }
          if (this.filter.value.Lop != '') {
            data = data.filter(x => x.Ten_lop.includes(this.filter.value.Lop))
          }
          this.ClassData = data
          this.dtTrigger.next();
          this.spinner.hide();
        }, errors => {
          this.AppService.logout();
          this.spinner.hide();
        });
  }

  GetDataSV(dt) {
    this.lblTBCHT4 = ''
    this.lblTBCHT10 = ''
    this.lblTBC_tich_luy = ''
    this.lblXep_loai_hoc_tap10 = ''
    this.lblXep_loai_hoc_tap4 = ''
    this.lblSo_mon_hoc_lai = ''
    this.lblSo_mon_thi_lai = ''
    this.lblSo_tin_chi_tich_luy = ''
    this.lblXep_hang_hoc_luc = ''
    this.BangDiemSVChiTiet = []
    let req = {
      "Loai_bang_diem": this.filter.value.Loai_thong_ke,
      "Nam_hoc": this.filter.value.Nam_hoc,
      "Hoc_ky": this.filter.value.Hoc_ky,
      "ID_dt": dt.ID_dt,
      "ID_sv": dt.ID_sv,
      "ID_he": this.filter.value.ID_he,
    }
    this.Ma_sv =dt.Ma_sv
    this.Ten_sv = dt.Ho_ten
    this.spinner.show()
    this.MaskService.BangDiemSV(req, this.Token).subscribe((z) => {
      this.spinner.hide();
      if(z.Status==1){
        this.lblTBCHT4 = z.lblTBCHT4
        this.lblTBCHT10 = z.lblTBCHT10
        this.lblTBC_tich_luy = z.lblTBC_tich_luy
        this.lblXep_loai_hoc_tap10 = z.lblXep_loai_hoc_tap10
        this.lblXep_loai_hoc_tap4 = z.lblXep_loai_hoc_tap4
        this.lblSo_mon_hoc_lai = z.lblSo_mon_hoc_lai
        this.lblSo_mon_thi_lai = z.lblSo_mon_thi_lai
        this.lblSo_tin_chi_tich_luy = z.lblSo_tin_chi_tich_luy
        this.lblXep_hang_hoc_luc = z.lblXep_hang_hoc_luc
        this.BangDiemSVChiTiet = z.BangDiemSVChiTiet
        this.QuyChe = z.Quy_che

      }else{
        this.toastr.error(z.Message)
      }
    }, errors => {
      this.toastr.error(errors.Message)
      this.spinner.hide();
    });
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  GetHe(){
    this.HeDaoTaoService.getList(this.Token).subscribe((z) => {
      this.dataHe = z.Data
    });
  }
  changeHe() {}

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
  ExportToExcel_BaoCaoCoVan() {
    
    let req={
      UserName: this.Info.UserName
    }
    this.MaskService.ExportToExcel_BaoCaoCoVan(req, this.Token)
     .subscribe(z => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'BangDiemRenLuyen' + this.Info.UserName );
          this.toastr.success("File đã được tải xuống", "Thao tác thành công")
        }
        else {
          this.toastr.error(z.Message, "Thao tác thất bại")
        }
        this.Title = "Danh sách điểm"
      })
      ;
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }
}
