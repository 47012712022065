<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Chi tiết phiên bản</li>
                </ol>
            </div>
            <div class="col-md-6">
                <!-- <button class="btn btn-success btn-sm" (click)="open(ThemModal, 'xl', 'Add', '')" style="float: right; margin-left: 10px;">
                      <i class="fas fa-users-cog"></i> Xếp phòng tự động
                  </button> -->

            </div>
        </div>
    </div>
</section>
<!-- <section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="row">
                            <div class="col-md-12" *ngFor="let k of versionDetail">
                                <label>Version: {{k.Version}} | </label> {{k.Date}}
                                <ul>
                                    <li *ngFor="let p of k.Detail">{{ p }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section>
    <div class="container">
        <ul class="timeline2" *ngFor="let k of versionDetail,index as i">
            <li *ngIf="i%2==0">
                <div class="timeline2-badge info"><i class="fas fa-cogs"></i></div>
                <div class="timeline2-panel">
                    <div class="timeline2-heading">
                        <h4 class="timeline2-title">Version: {{k.Version}} </h4>
                        <p><small class="text-muted"><i class="glyphicon glyphicon-time"></i>Date: {{k.Date}}</small></p>
                    </div>
                    <div class="timeline2-body">
                        <ul>
                            <li *ngFor="let p of k.Detail">{{ p }}</li>
                        </ul>
                    </div>
                </div>
            </li>
            <li *ngIf="i%2!=0" class="timeline2-inverted" >
                <div class="timeline2-badge warning"><i class="fas fa-cogs"></i></div>
                <div class="timeline2-panel">
                    <div class="timeline2-heading">
                        <h4 class="timeline2-title">Version: {{k.Version}}</h4>
                        <p><small class="text-muted"><i class="glyphicon glyphicon-time"></i>Date: {{k.Date}}</small></p>
                    </div>
                    <div class="timeline2-body">
                        <ul>
                            <li *ngFor="let p of k.Detail">{{ p }}</li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>