<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/teaching-comment-class']">Lớp học</a></li>
                    <li class="breadcrumb-item active">Nhận xét giảng dạy</li>
                </ol>
            </div>
            <div class="col-md-6">
              <button class="btn btn-info btn-sm" (click)="open(ModalConfirm, '', 'Export')" style="float: right; margin-right: 10px;" *ngIf="ID_nx_giang_day_giang_vien">
                <i class="fas fa-file-excel"></i> Xuất Excel
            </button>
                <button class="btn btn-danger btn-sm" (click)="open(ModalConfirm, '', 'Delete')" style="float: right; margin-right: 10px;" *ngIf="ID_nx_giang_day_giang_vien">
                    <i class="fas fa-trash-alt"></i> Xóa
                </button>
                <button class="btn btn-success btn-sm" (click)="open(ModalConfirm, '', 'Edit')" style="float: right; margin-right: 10px;" *ngIf="ID_nx_giang_day_giang_vien">
                    <i class="fas fa-edit"></i> Lưu
                </button>
                <button class="btn btn-success btn-sm" (click)="open(ModalConfirm, '', 'Add')" style="float: right;" *ngIf="!ID_nx_giang_day_giang_vien">
                    <i class="fas fa-plus-circle"></i> lưu
                </button>
 
            </div>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row pt10">
                        <div class="col-md-6">
                            <label class="col-md-10">Môn học : {{Ten_mon}}</label>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-10">Học kỳ : {{Hoc_ky_lop}} - Năm học : {{Nam_hoc_lop}} </label>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-10">Lớp : {{Ten_lop}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <form class="form-horizontal" [formGroup]="ActionModal">
                <div class="row pt10">
                    <div class="col-md-6">
                        <label class="col-md-10 ">HSSV cá biệt</label>
                        <textarea type="text" class="form-control" required placeholder="Nhập HSSV cá biệt" formControlName="HSSV_ca_biet" rows="7"
                       > </textarea>
              
                    </div>
                    <div class="col-md-6">
                        <label class="col-md-10">Đánh giá quá trình giảng dạy</label>
                        <textarea rows="7" type="text" class="form-control" placeholder="Nhập đánh giá quá trình giảng dạy ..."
                            formControlName="Danh_gia_qua_trinh_giang_day"></textarea>
                    </div>
                </div>
                <div class="row pt10">
                    <div class="col-md-12">
                        <label class="col-md-10">Ghi chú</label>
                        <textarea type="text" class="form-control" placeholder="Nhập ghi chú ..." rows="2"
                            formControlName="Ghi_chu"></textarea>
                    </div>
                </div>
    
            </form>
        </div>
    </div>
</section>
<!-- 
<ng-template #ModalConfirm let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <div class="icon-box">
                <i class="fas fa-trash-alt"
                    style="color: #f15e5e;font-size: 46px;display: inline-block;margin-top: 13px;"></i>
            </div>
            <p
                style="color: inherit; text-decoration: none;font-size: 20px; text-align: center; font-weight: 600; margin-top: 3em; font-family: sans-serif;">
                {{titleModal}}
            </p>
            <div class="text-center">
                <button type="button" class="btn btn-md btn-success" (click)="Delete()">
                    Đồng ý
                </button>
                <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                    style="margin-left: 1em;">
                    Hủy bỏ
                </button>

            </div>
        </div>
    </div>
</ng-template> -->

<ng-template #ModalConfirm let-modal>
    <div class="modal-content">
      <div class="modal-body">
        <div class="icon-box-add" *ngIf="ModalType ==='Add'">
          <i
            class="fas fa-plus-circle"
            style="
              color: #49b74c;
              font-size: 46px;
              display: inline-block;
              margin-top: 13px;
            "
          ></i>
        </div>
        <div class="icon-box-edit" *ngIf="ModalType ==='Edit'">
            <i
              class="fas fa-edit"
              style="
                color: #f7d724;
                font-size: 46px;
                display: inline-block;
                margin-top: 13px;
              "
            ></i>
          </div>
          <div class="icon-box" *ngIf="ModalType ==='Delete'">
            <i
              class="fas fa-trash-alt"
              style="
                color: #f15e5e;
                font-size: 46px;
                display: inline-block;
                margin-top: 13px;
              "
            ></i>
          </div>
        <p
          style="
            color: inherit;
            text-decoration: none;
            font-size: 20px;
            text-align: center;
            font-weight: 600;
            margin-top: 3em;
            font-family: sans-serif;
          "
        >
        {{titleModal}}
        </p>
        <div class="text-center">
          <div>
                <button 
                  type="button"
                  class="btn btn-md btn-success"
                  (click)="Confirm()"
                >
                  Đồng ý
                </button>
            <button
              type="button"
              class="btn btn-md btn-danger"
              (click)="modal.dismiss('Cross click')"
              style="margin-left: 1em;"
            >
              Hủy bỏ
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>