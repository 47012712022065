<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Thông báo</li>
                </ol>
            </div>
            <div class="col-md-6">
                <button (click)="help()" class="btn btn-warning btn-sm"  type="submit" style="float: right; ">
                    <i class="fas fa-info-circle"></i>
                    Hướng dẫn
                </button>
            </div>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <label class="col-md-10">Tiêu đề thông báo:</label>
                    <input class="form-control" placeholder="Nhập tiêu đề thông báo cần tìm..." type="text"
                        [(ngModel)]="TieuDe" (keyup)="filterById()">
                </div>
            </div>
        </div><!-- /.card-header -->
    </div>
</section>
<section class="content cus-body">

    <div class="card">

        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class=" table-bordered dataTables_wrapper no-footer ">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData">
                                            <td>{{ p.Anh_dai_dien }}</td>
                                            <td>{{ p.CategoryName }}</td>
                                            <td>{{ p.ID_thong_bao }}</td>
                                            <td>{{ p.Loai_thong_bao }}</td>
                                            <td>{{ p.Noi_dung }}</td>
                                            <td>{{ p.Tieu_de }}</td>
                                            <td>{{ p.Trich_dan }}</td>
                                            <td><a class="btn btn-primary btn-sm" [routerLink]="['/announceedit']"
                                                    [queryParams]="{ID: p.ID_thong_bao}" queryParamsHandling="merge">
                                                    <i class="fas fa-bullhorn" title="Xem chi tiết"></i>
                                                </a></td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>