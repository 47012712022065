<section class="content-header" style="padding:5px !important">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                    <li class="breadcrumb-item active">Lớp học &nbsp;</li>
                    <div class="{{TitleClass}}"></div>
                </ol>
            </div>
            <div class="col-md-6">
                <button (click)="help()" class="btn btn-warning btn-sm"  type="submit" style="float: right; ">
                    <i class="fas fa-info-circle"></i>
                    Hướng dẫn
                </button>
            </div>
            
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>


<section class="content cus-body">
    <div class="card">
        <div class="card-body" style="padding: 0px 20px 0px 20px;">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row pt10" style="margin-bottom: 1%;">
                        <div class="col-md-4">
                            <label class="col-md-10">Năm học</label>
                            <select class="form-control" [(ngModel)]="Nam_hoc_fil">
                                <option value="">Tất cả</option>
                                <option *ngFor = "let p of Nam_hoc_arr" value="{{p.value}}">{{p.name}}</option>
          
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label class="col-md-10">Tên môn</label>
                            <input  class="form-control" type="text" placeholder="Nhập tên môn"
                            [(ngModel)]="Ten_mon_fil">
                        </div>
                        <div class="col-md-4">
                            <label class="col-md-10">Tên lớp</label>
                            <input  class="form-control" type="text" placeholder="Nhập tên lớp"
                            [(ngModel)]="Ten_lop_fil">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" >
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="table-responsive  container-fluid  cus-fs">
                                <table class="table-bordered table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Tên môn</th>
                                            <th style="width:20%;">Tên lớp</th>
                                            <th>Phòng học</th>
                                            <th style="width:7%;text-align: center;">Học kỳ</th>
                                            <th style="width:7%;text-align: center;">Năm học</th>
                                            <th style="width:7% ;text-align: center;">Ký hiệu</th>
                                            <th style="width:7%;text-align: center;">Số SV</th>
                                            <th style="text-align: center;">Loại lớp</th>
                                            <th style="text-align: center;">Thao tác</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData | tableFilter: Nam_hoc_fil | FilterClass:{Ten_mon : Ten_mon_fil , Ten_lop : Ten_lop_fil }">
                                            <td>{{ p.Ten_mon }}</td>
                                            <td>{{ p.Ten_lop }}</td>
                                            <td>{{ p.So_phong }}</td>
                                            <td style="text-align: center;">{{ p.Hoc_ky }}</td>
                                            <td style="text-align: center;">{{ p.Nam_hoc }}</td>
                                            <td style="text-align: center;">{{ p.Ky_hieu }}</td>
                                            <td style="text-align: center;">{{ p.So_sv }}</td>
                                            <td style="text-align: center;">{{ p.Ten_loai_lop }}</td>
                                            <td style="text-align: center; white-space: nowrap;">
                                                <a style="margin-right: 5px;" class="btn btn-primary btn-sm"
                                                    [routerLink]="['/admin/studentsclass']"
                                                    [queryParams]="{CategoryID: p.Loai_lop,ID:p.ID_lop,Ten_lop:p.Ten_lop,ID_mon:p.ID_mon,Hoc_ky:p.Hoc_ky,Nam_hoc:p.Nam_hoc}"
                                                    queryParamsHandling="merge">
                                                    <i class="fas fa-eye" title="Xem danh sách"></i>
                                                </a>
                                                <a [routerLink]="['/admin/create']" class="btn btn-default btn-sm"
                                                    [queryParams]="{Category: 4,ID:p.ID_lop,LoaiLop:p.Loai_lop}"
                                                    queryParamsHandling="merge">
                                            <i class="far fa-envelope cus-cr" title="Gửi tin nhắn"></i>
                                            </a>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>
<!-- /.content -->