import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { environment } from "src/environments/environment";
import { map, filter, catchError } from "rxjs/operators";
import {
  ResponseClassListAll,
  ResponseStudentsClass,
  ResponseClassListAllOnline,
} from "src/app/Models/output.model/ResponseClassListAll";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class ClassService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  getClass(RequestBase: RequestBase, token): Observable<ResponseClassListAll> {
    return this.http
      .post<ResponseClassListAll>(
        this.appConfig.TeacherUrl + "Class/ClassListAll",
        RequestBase,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getClassStudents(
    RequestBase: RequestBase,
    token
  ): Observable<ResponseStudentsClass> {
    return this.http
      .post<ResponseStudentsClass>(
        this.appConfig.TeacherUrl + "Class/StudentsClassCredit",
        RequestBase,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getClassStudentsNienChe(
    RequestBase: RequestBase,
    token
  ): Observable<ResponseStudentsClass> {
    return this.http
      .post<ResponseStudentsClass>(
        this.appConfig.TeacherUrl + "Class/StudentsClassKeHoach",
        RequestBase,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getClassOnline(
    RequestBase: RequestBase,
    token
  ): Observable<ResponseClassListAllOnline> {
    return this.http
      .post<ResponseClassListAllOnline>(
        this.appConfig.TeacherUrl + "Class/ClassListAllOnline",
        RequestBase,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getStudentTruant(
    RequestBase: RequestBase,
    token
  ): Observable<ResponseClassListAllOnline> {
    return this.http
      .post<ResponseClassListAllOnline>(
        this.appConfig.TeacherUrl + "Class/StudentTruantListAll",
        RequestBase,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Export_ClassStudents(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "BaoCao/ExportToExcelBaocao_DanhDachLop",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  // Export_ClassStudentsNienChe(req: any, token): Observable<any> {
  //   return this.http.post<any>(this.appConfig.TeacherUrl + 'BaoCao/ExportToExcelBaocao_DanhDachLop', req, {
  //     headers: new HttpHeaders()
  //       .set('Authorization', `Bearer ${token}`)
  //   }).pipe(map(z => { return z }))
  // }
  Export_ClassStudentsNienChe(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "BaoCao/ExportToExcelBaocao_DanhDachLop",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  ClassListFull(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.TeacherUrl + "Class/ClassListFull", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}
