import { Component, OnInit, OnDestroy, Renderer2 } from "@angular/core";
import { AppService } from "src/app/Utils/services/app.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Class } from "src/app/Utils/services/Class.service";
import { common } from "src/app/app.common";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { ClassService } from "src/app/Utils/service/class.service";
import {
  ResponseClassListAll,
  ClassInfo,
} from "src/app/Models/output.model/ResponseClassListAll";
import { Account } from "src/app/Utils/services/Account.service";
import { DataTableDirective } from "angular-datatables";
import { RequestLogin } from "src/app/Models/input.model/RequestLogin";
import { ResponseLogin } from "src/app/Models/output.model/ResponseLogin";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { AppConfiguration, AppConfig } from "src/configuration";
import { Injectable, Inject } from "@angular/core";
@Component({
  selector: "app-change-pass",
  templateUrl: "./change-pass.component.html",
  styleUrls: ["./change-pass.component.scss"],
})
export class ChangePassComponent implements OnInit {
  public ChangePassForm: FormGroup;
  public m: RequestLogin;
  public LoginResult: ResponseLogin;
  public UserName: any;
  public Password: any;
  public Password2: any;
  public Password3: any;
  public com: common;
  isPassword: boolean = true;

  Title = "Đổi mật khẩu";
  TitleVal: string;
  requestObject: RequestLogin = new RequestLogin();
  InfoUser: ResponseLogin;
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    public AccountServirce: Account,
    private toastr: ToastrService,
    private appService: AppService,
    public router: Router
  ) {}

  ngOnInit() {
    this.ChangePassForm = new FormGroup({
      Password: new FormControl(null, Validators.required),
      Password2: new FormControl(null, Validators.required),
      Password3: new FormControl(null, Validators.required),
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    this.InfoUser = this.com.getUserinfo();
  }
  help() {
    const urlHelp =
      this.appConfig.HelpUrl + "?" + this.appConfig.document_doi_mat_khau;
    window.open(urlHelp, "_blank");
  }

  show() {
    this.isPassword = !this.isPassword;
  }

  ChangePass() {
    if (this.ChangePassForm.valid) {
      let a = this.com.getUserinfo();
      this.UserName = a.Info.UserName;
      this.Password = this.ChangePassForm.controls.Password.value;
      this.Password2 = this.ChangePassForm.controls.Password2.value;
      this.Password3 = this.ChangePassForm.controls.Password3.value;
      this.requestObject.UserName = a.Info.UserName;
      //var checkregex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
      var checkregex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})");
      if (checkregex.test(this.Password2)) {
        if (this.Password2 == this.Password3) {
          let req = {
            UserName: this.UserName,
            Password: this.Password,
            NewPassword: this.Password2,
            RequestID: this.InfoUser.Info.UserID,
            UserCategory: 2,
          };
          this.AccountServirce.ChangePass(req, a.access_token).subscribe((z) => {
            if (z.Status == 1) {
              this.toastr.success("Đổi mật khẩu thành công");
              this.router.navigate(["/"]);
            } else {
              this.toastr.error(z.Message, "Thao tác thất bại");
            }
          });
        } else {
          this.toastr.warning(
            "Mật khẩu mới của bạn không trùng khớp",
            "Thao tác thất bại"
          );
        }
      } else {
        this.toastr.warning(
          "Mật khẩu không đúng định dạng",
          "Thao tác thất bại"
        );
        this.TitleVal = "Mật khẩu phải bao gồm chữ và số và từ 8 ký tự trở lên";
      }
    } else {
      this.toastr.error("Vui lòng nhập đầy đủ thông tin", "Thao tác thất bại");
    }
  }
}
