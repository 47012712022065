<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row">
            <div class=" col-md-6 ">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/attendencehistory']">Tra cứu điểm danh </a></li>
                    <!-- <li class="breadcrumb-item active">{{TitleTenLop}} &nbsp; </li> -->
                    <!-- <li class="breadcrumb-item active">Tra cứu điểm danh &nbsp; </li> -->
                    <li class="{{TitleClass}}"></li>
                </ol>
            </div>
            <div class=" col-md-6 ">
                <button class="btn btn-info btn-sm" (click)="exportExcel()" style="float: right;"
                    *ngIf="ClassData">
                    <i class="fas fa-file-excel"></i> Xuất Excel
                </button>
                <button class="btn btn-sm btn-success" (click)="open(ModalConfirm, '', 'Duyet')"  style="float: right; margin-right: 10px;"><i
                        class="fas fa-save"></i> Duyệt</button>
            </div>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body" style="padding: 0px 20px 0px 20px;">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row pt10">
                        <div class="col-md-6">
                            <label class="col-md-10">Môn học : {{TitleTenMon}}</label>
                        </div>
                        <!-- <div class="col-md-5">
                            <label class="col-md-10">Học kỳ : {{TitleHocKy}} - Năm học : {{TitleNamHoc}} </label>
                        </div> -->
                        <div class="col-md-6">
                            <label class="col-md-10">Lớp : {{TitleTenLop}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Main content -->
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <!-- <table datatable id="classTable" [dtOptions]="dtOptions" class="row-border hover"></table> -->
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        
                                       
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData;let i = index">
                                            <td>{{ p.ID_sv }}</td>
                                            <td>{{ p.ID_dt }}</td>
                                            <td>{{ p.Ma_sv }}</td>
                                            <td>{{ p.Ho_ten }}</td>
                                            <td>{{ p.Ngay_sinh }}</td>
                                            <td>{{ p.Gioi_tinh }}</td>
                                            <td>{{ p.Ten_khoa }}</td>
                                            <td>{{ p.ID_lop }}</td>
                                            <td>{{ p.So_tiet_ly_thuyet }}</td>
                                            <td>{{ p.So_tiet_thuc_hanh }}</td>
                                         
                                         
                                            <td style="text-align: center;">
                                                <a class="btn btn-warning btn-sm" [routerLink]="['/admin/AttendenceInfoTheoTiet']"
                                                    [queryParams]="{IDLop:p.Ten_lop ,ID_sv:p.ID_sv,Ho_ten:p.Ho_ten,Ten_lop:TitleTenLop}"
                                                    queryParamsHandling="merge">
                                                    <i class="fas fa-user-check" title="Chi tiết điểm danh"></i> </a>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>

<ng-template #ModalConfirm let-modal>
    <div class="modal-content">
      <div class="modal-body">
   
        <p
          style="
            color: inherit;
            text-decoration: none;
            font-size: 20px;
            text-align: center;
            font-weight: 600;
            margin-top: 3em;
            font-family: sans-serif;
          "
        >
        {{titleModal}}
        </p>
        <i>Số tiết nghỉ chỉ được duyệt cập nhật khi điểm sinh viên chưa được khóa</i>
        <div class="text-center">
          <div>
                <button 
                  type="button"
                  class="btn btn-md btn-success"
                  (click)="Approve()"
                >
                  Đồng ý
                </button>
            <button
              type="button"
              class="btn btn-md btn-danger"
              (click)="modal.dismiss('Cross click')"
              style="margin-left: 1em;"
            >
              Hủy bỏ
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>