import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { common } from 'src/app/app.common';
import { Subject } from 'rxjs';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { ApprovedRegistrationService } from 'src/app/Utils/service/approved-registration.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AppConfig, AppConfiguration } from 'src/configuration';

@Component({
  selector: 'app-approved-registration',
  templateUrl: './approved-registration.component.html',
  styleUrls: ['./approved-registration.component.scss']
})
export class ApprovedRegistrationComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  token: string;
  Ky_dang_ky: any;
  TitleClass: string = 'spinner-border text-muted';
  UserName: string;
  TBCHT4_tich_luy: string;
  Xep_hang: string;
  Tong_so_hoc_trinh_tich_luy: string;
  Tong_so_mon_thi_lai: string;
  Tong_so_mon_hoc_lai: string;
  TBCHT10: string;
  Xep_loai_tich_luy4: string;
  So_mon_cho_diem: string;
  TableData: any;
  HocPhans: any;
  KetQuaHocTapNew: any=[];
  isvalid=false;
  ThongBao: any="Chọn kỳ đăng ký"
  ID_sv: any;
  closeResult: string;
  SearchForm = new FormGroup({
    Ky_dang_ky: new FormControl(0, [Validators.required]),
    Trang_thai: new FormControl(0, [Validators.required])
  });
  constructor(
    @Inject(AppConfig) 
    private readonly appConfig: AppConfiguration,
    public route: ActivatedRoute,
    private toastr: ToastrService,
    public router: Router,
    private service: ApprovedRegistrationService,
    private modalService: NgbModal,
  ) { }
  public com: common;
  dtTrigger = new Subject();
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.UserName = a.Info.UserName;
    this.token = a.access_token;
    this.Load_ky_dang_ky(this.token)
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: true,
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'excel',
      ],
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'STT',
        },
        {
          title: 'ID',
        },
        {
          title: 'Mã sinh viên',
        },
        {
          title: 'Họ và tên',
        },
        {
          title: 'Lớp hành chính',
        },
        {
          title: 'Số tín chỉ đăng ký',
        },
        {
          title: 'Thao tác',
          width: '10%',
          className: 'dt-center',
        },
      ],
    };
    this.TableData = [];
    this.dtTrigger.next();
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl+"?"+this.appConfig.document_duyet_dang_ky ;
    window.open(urlHelp,'_blank');
  }
  ngAfterViewInit() {
    this.TableData = []
    this.dtTrigger.next();
  }
  Load_ky_dang_ky(token) {
    this.service.Load_ky_dang_ky(token).subscribe((z) => {
      this.Ky_dang_ky = z.Content;
      this.TitleClass = '';
    });
  }
  Load_QuyDinhThoiGianDuyetCoVan(Ky_dang_ky,token) {
    this.service.Load_QuyDinhThoiGianDuyetCoVan(Ky_dang_ky,token).subscribe((z) => {
      if(z.Content.length>0){
        let TuNgay= new Date(z.Content[0].Tu_ngay)
        let DenNgay= new Date(z.Content[0].Den_ngay)
        let NgayHienThoi= new Date()
        if(NgayHienThoi >= TuNgay && NgayHienThoi <= DenNgay)
        {
          this.isvalid=true;
          this.ThongBao="Duyệt từ ngày "+z.Content[0].Tu_ngay+" đến ngày "+z.Content[0].Den_ngay
        }else{
          this.isvalid=false;
          if(TuNgay>NgayHienThoi){
            this.ThongBao="Chưa đến thời gian duyệt!"+ " Duyệt từ ngày "+z.Content[0].Tu_ngay+" đến ngày "+z.Content[0].Den_ngay
          }
          else{
            this.ThongBao="Đã hết thời gian duyệt!"+ " Duyệt từ ngày "+z.Content[0].Tu_ngay+" đến ngày "+z.Content[0].Den_ngay
          }
        }
        
      }
      else{
        this.ThongBao="Chưa quy định thời gian duyệt đăng ký, vui lòng quay lại sau"
        this.isvalid=false;
      }
      
    });
  }
  onSubmit() {
    if (this.SearchForm.value.Ky_dang_ky==0) {
      this.toastr.warning("Vui lòng chọn kỳ đăng ký")
      return false;
    }
    this.TitleClass = 'spinner-border text-muted'
    this.service.Load_danh_sach_dang_ky(this.SearchForm.value.Ky_dang_ky, this.SearchForm.value.Trang_thai, this.token).subscribe((z) => {
      this.Load_QuyDinhThoiGianDuyetCoVan(this.SearchForm.value.Ky_dang_ky,this.token)
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.TableData = z.Content;
      this.TitleClass = '';
      this.dtTrigger.next();
    });
   
  }
  Load_KetQuaHocTap(ID_sv) {
    this.service.Load_KetQuaHocTap(ID_sv,0, this.token).subscribe((z) => {
      this.KetQuaHocTapNew =[];
      this.KetQuaHocTapNew = z.Content;
      this.TBCHT4_tich_luy = z.TBCHT4_tich_luy;
      this.Tong_so_hoc_trinh_tich_luy = z.Tong_so_hoc_trinh_tich_luy;
      this.Tong_so_mon_thi_lai = z.Tong_so_mon_thi_lai;
      this.Tong_so_mon_hoc_lai = z.Tong_so_mon_hoc_lai;
      this.TBCHT10 = z.TBCHT10;
      this.Xep_loai_tich_luy4 = z.Xep_loai_tich_luy4;
      this.Xep_hang = z.Xep_hang;
      this.So_mon_cho_diem = z.So_mon_cho_diem;
   
    });
  }
  Load_HocPhanDangKy(ID_sv) {
    this.service.Load_HocPhanDangKy(this.SearchForm.value.Ky_dang_ky, ID_sv, this.token).subscribe((z) => {
      this.HocPhans = z.Content;
    });
  }
  open(content, size, Data) {
    this.ID_sv = Data.ID_sv
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: size, scrollable: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    this.HocPhans = []
    this.Load_HocPhanDangKy(Data.ID_sv)
  }
  openKetQuaHocTap(content, size, Data) {
    this.ID_sv = Data.ID_sv
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: size, scrollable: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    this.Load_KetQuaHocTap(Data.ID_sv)
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  Save() {
    if(this.isvalid){
      var InputArr = []
      for (let index = 0; index < this.HocPhans.length; index++) {
        this.HocPhans[index].Duyet = $('#Duyet' + this.HocPhans[index].ID_lop_tc).prop('checked');
        this.HocPhans[index].Ly_do1 = $('#Ly_do1' + this.HocPhans[index].ID_lop_tc).val()
        this.HocPhans[index].Ly_do = $('#Ly_do1' + this.HocPhans[index].ID_lop_tc).val()
        var item = {
          ID_lop_tc: this.HocPhans[index].ID_lop_tc,
          Duyet: this.HocPhans[index].Duyet,
          Ly_do: this.HocPhans[index].Ly_do,
          UserName: "",
        }
        InputArr.push(item)
      }
      var m = {
        ID_sv: this.ID_sv,
        Data: InputArr,
      }
      this.InsertData(m)
    }else{
      this.toastr.warning(this.ThongBao)
    }
    
  }
  InsertData(req) {
    this.service.Update_DanhSachLopTinChi(req, this.token).subscribe((z) => {
      if (z.Status == 1) {
        this.onSubmit()
        this.toastr.success(z.Message);
      } else {
        this.toastr.error(z.Message);
      }
    });
  }
}
