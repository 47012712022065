import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { WebSocketService } from 'src/app/Utils/service/web-socket.service';
@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.scss']
})
export class ReloadComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private webSocketService: WebSocketService,
  ) { }

  ngOnInit() {
    // this.http.get<any>('assets/version.json').subscribe(data => {
    //   if (data.version != localStorage.getItem("Version") ) {
    //     this.webSocketService.sendData('updateVersion', "reload")
    //     localStorage.setItem("Version", data.version);
    //   }
    // })
  }

}
