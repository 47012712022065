import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class MarkService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }
  StudentsCredit_LoadList(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Mark/StudentsCredit_LoadList",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  StudentsNienChe_LoadList(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Mark/StudentsKeHoach_LoadList",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  StudentsCredit_DiemThiLoadList(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Mark/StudentsCredit_DiemThiLoadList",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  StudentsNienChe_DiemThiLoadList(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Mark/StudentsKeHoach_DiemThiLoadList",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  MarkCreditSave(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Mark/MarkCreditSave", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  MarkNienCheSave(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Mark/MarkNienCheSave", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemQuaTrinhCreditExport(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Mark/DiemQuaTrinhCreditExport",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemQuaTrinhNienCheExport(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Mark/DiemQuaTrinhNienCheExport",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  // DiemQuaTrinhNienCheExport(req : any, token) : Observable<any>{
  //   return this.http.post<any>(this.appConfig.TeacherUrl + 'BaoCao/ExportToExcelBaocao_BangDiemQuaTrinhLopNienChe',req,{
  //     headers: new HttpHeaders()
  //       .set('Authorization', `Bearer ${token}`)
  //   } ).pipe(map(z=> {return z}))
  // }
  DiemThiCreditExport(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Mark/DiemThiCreditExport", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemThiCreditSave(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Mark/DiemThiCreditSave", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemThiNienCheSave(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Mark/DiemThiNienCheSave", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  MarkKhoaDiem(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Mark/MarkKhoaDiem", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemThiCreditKhoaDiem(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Mark/DiemThiCreditKhoaDiem",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  DiemThiNienCheExport(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Mark/DiemThiNienCheExport", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  TongHopDiemNienCheExport(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Mark/TongHopDiemNienCheExport",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GetThamSoHeThong(id: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Mark/GetThamSoHeThong", id, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  BangDiemSV(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Mark/LoadBangDiemToanKhoaSV",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ListDSCoVan(req: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl + "Mark/ListDSCoVan?userName=" + req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ListDSSinhVien_Lop(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Mark/GetListTranscriptStudents",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ExportToExcel_BaoCaoCoVan(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "BaoCao/ExportToExcel_BaoCaoCoVan",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Import(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Mark/Import", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  PublishDiemThanhPhan(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Mark/PublishDiemThanhPhan", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  PublishDiemThi(req: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Mark/PublishDiemThi", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}
