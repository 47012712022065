<!-- Navbar -->
<nav class="main-header navbar navbar-expand bg-info navbar-light ">

  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" (click)="toggleMenuSidebar.emit()"
        ><i class="fas fa-bars cus-icon"></i
      ></a>

    </li>
  </ul>


  <!-- Right navbar links -->

  <ul class="navbar-nav ml-auto">
    <!-- Messages Dropdown Menu -->
    <li class="nav-item">
      <a title="Gửi yêu cầu hỗ trợ" class="nav-link" (click)="getSupportLink()">
        <i style="color:white" class="fas fa-headset"></i>
        <span class="badge badge-danger navbar-badge" id="AnnounceTotal"></span>
      </a>
    </li>
    <app-user-dropdown-menu></app-user-dropdown-menu>
    <app-messages-dropdown-menu></app-messages-dropdown-menu>
    <!-- Notifications Dropdown Menu -->
    <!-- <app-notifications-dropdown-menu></app-notifications-dropdown-menu> -->
    <li class="nav-item">
      <a title="Thông báo"
        class="nav-link"
        [routerLink]="['/admin/announce']"
      >
        <i class="fas fa-bell cus-icon"></i>
        <span class="badge badge-danger navbar-badge" id="AnnounceTotal"></span>
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        (click)="logout()"
      >
        <i class="fas fa-power-off cus-icon"></i>
      </a>
    </li>
  </ul>
</nav>
<!-- /.navbar -->
