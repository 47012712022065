<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-5">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Kê khai công việc, giảng dạy</li>
                </ol>
            </div>
            <div class="col-md-7">
                <button class="btn btn-success btn-sm" style="float: right;margin-left:5px"
                    (click)="open(ThemModalKH, 'xl', 'AddKH', '')">
                    <i class="fas fa-plus-circle"></i> Thêm mới công việc NCKH
                </button>
                <button class="btn btn-success btn-sm" style="float: right;margin-left:5px"
                    (click)="open(ThemModalCV, 'xl', 'AddCV', '')">
                    <i class="fas fa-plus-circle"></i>
                    Ra đề, chấm bài, trông thi, KLTN
                </button>
                <button class="btn btn-success btn-sm" style="float: right;" (click)="open(ThemModal, 'xl', 'Add', '')">
                    <i class="fas fa-plus-circle"></i> Kê khai giờ lên lớp
                </button>
                <!-- <button class="btn btn-success btn-sm" style="float: right; margin-right:5px;"
                    (click)="open(ThemKyModal, 'xl', 'AddKy', '')">
                    <i class="fas fa-plus-circle"></i> Thêm mới giờ giảng theo kỳ
                </button> -->
                <button (click)="Export_KeKhaiGiangDay()" style="float: right;margin-right:5px;"
                    class="btn btn-sm btn-info "><i class="fas fa-file-excel"></i> Xuất Excel</button>

                <!-- <button class="btn btn-danger btn-sm" style="float: right;margin-right:5px"
                    (click)="open(multipleUpdate, 'xs', 'Status', '')">
                    <i class="fas fa-lock"></i> Mở/khoá kê khai
                </button> -->
            </div>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <p><b style="color: red;">Thời gian kê khai: </b>{{dataQuyDinh}}</p>
            <div class="row">
                <div class="col-md-2 ">
                    <p><b style="color: red;">Tổng số giờ thực hiện: </b><b>{{tonggiothuchien}}</b></p>
                </div>
                <div class="col-md-2 ">
                    <p><b>ĐM năm học: </b><b>{{dinhmucnamhoc}}</b></p>
                </div>
                <div class="col-md-2 ">
                    <p><b>ĐM giảng dạy: </b><b>{{dinhmucgiangday}}</b></p>
                </div>
                <div class="col-md-2 ">
                    <p><b>ĐM chuyên môn: </b><b>{{dinhmucchuyenmon}}</b></p>
                </div>
                <div class="col-md-2 ">
                    <p><b>ĐM học tập: </b><b>{{dinhmuchoctap}}</b></p>
                </div>
                <div class="col-md-2 ">
                    <p><b>ĐM khoa học: </b><b>{{dinhmuckhoahoc}}</b></p>
                </div>
            </div>
            <form class="form-horizontal" [formGroup]="Filter">
                <div class="row">
                    <div class="col-md-3 ">
                        <label>Học kỳ</label>
                        <ng-select notFoundText="Không có dữ liệu" formControlName="Hoc_ky" (change)="changeFilter()">
                            <ng-option [value]="0">Cả năm</ng-option>
                            <ng-option [value]="1">Học kỳ 1</ng-option>
                            <ng-option [value]="2">Học kỳ 2</ng-option>
                            <ng-option [value]="3">Học kỳ 3</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-md-3 ">
                        <label>Năm học</label>
                        <select class="form-control" formControlName="Nam_hoc" (change)="changeFilter()">
                            <option *ngFor="let p of Nam_hoc_Arr" [value]="p.value">{{p.name}}</option>
                        </select>

                    </div>
                    <div class="col-md-3 ">
                        <label>Nhóm công việc</label>
                        <ng-select notFoundText="Không có dữ liệu" formControlName="Nhom_cong_viec"
                            (change)="changeFilter()">
                            <ng-option value="">Chọn nhóm</ng-option>
                            <ng-option *ngFor="let p of dataNhomCongViec" [value]="p.ID_nhom_cong_viec">
                                {{p.Ten_nhom_cong_viec}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-md-3 ">
                        <label>Tên công việc</label>
                        <input type="text" class="form-control" formControlName="Ten_cong_viec"
                            placeholder="Nhập tên công việc" (change)="changeFilter()">
                    </div>
                    <div class="col-md-3 ">
                        <label>Từ ngày</label>
                        <input type="date" class="form-control" formControlName="Tu_ngay" (change)="changeFilter()">
                    </div>
                    <div class="col-md-3 ">
                        <label>Đến ngày</label>
                        <input type="date" class="form-control" formControlName="Den_ngay" (change)="changeFilter()">
                    </div>
                    <div class="col-md-3 ">
                        <label>Tên môn/Nội dung cv</label>
                        <input type="text" class="form-control" formControlName="Noi_dung" (change)="changeFilter()"
                            placeholder="Nhập tên môn/nội dung">
                    </div>
                    <div class="col-md-3 ">
                        <label>Tên lớp</label>
                        <input type="text" class="form-control" formControlName="Ten_lop" (change)="changeFilter()"
                            placeholder="Nhập tên lớp">
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table id="datatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th><input type="checkbox" id="SelectALL" (change)="CheckAll()"
                                                    value="SelectALL"></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of dataTable;">
                                            <td>
                                                <input type="checkbox" (change)="SelectRow(p.ID_cv_gd)"
                                                    id="{{ p.ID_cv_gd }}" [checked]="p.Selected"
                                                    value="{{ p.ID_cv_gd }}" />
                                            </td>
                                            <td>{{ p.ID_cv_gd }}</td>
                                            <td>{{ p.Hoc_ky }}</td>
                                            <td>{{ p.Nam_hoc }}</td>
                                            <td>{{p.ID_nhom_cong_viec}}</td>
                                            <td>{{p.Ten_nhom_cong_viec}}</td>
                                            <td>{{p.Ten_cong_viec}}</td>
                                            <td>{{p.So_gio_goc}}</td>
                                            <td>{{p.So_gio_chuan}}</td>
                                            <td>{{p.Ten_mon}}</td>
                                            <td style="white-space:nowrap">{{p.Ten_lop}}</td>
                                            <td>{{p.So_sv}}</td>
                                            <td>{{p.Tu_ngay == null ? '' : p.Tu_ngay|date:'dd/MM/yyyy'}}</td>
                                            <td>{{p.Den_ngay == null ? '' : p.Den_ngay|date:'dd/MM/yyyy'}}</td>
                                            <td>{{p.Don_gia}}</td>
                                            <td>{{p.Thanh_tien}}</td>
                                            <td *ngIf="p.Duyet_khoa"><input type="checkbox" checked disabled></td>
                                            <td *ngIf="!p.Duyet_khoa"><input type="checkbox" disabled></td>
                                            <td>{{p.Ngay_khoa_duyet == null ? '' : p.Ngay_khoa_duyet|date:'dd/MM/yyyy'}}
                                            </td>
                                            <td *ngIf="p.Duyet_phong"><input type="checkbox" checked disabled></td>
                                            <td *ngIf="!p.Duyet_phong"><input type="checkbox" disabled></td>
                                            <td>{{p.Ngay_phong_duyet == null ? '' :
                                                p.Ngay_phong_duyet|date:'dd/MM/yyyy'}}</td>
                                            <td *ngIf="p.Duyet_dao_tao"><input type="checkbox" checked disabled></td>
                                            <td *ngIf="!p.Duyet_dao_tao"><input type="checkbox" disabled></td>
                                            <td>{{p.Ngay_dao_tao_duyet == null ? '' :
                                                p.Ngay_dao_tao_duyet|date:'dd/MM/yyyy'}}</td>
                                            <td *ngIf="p.Duyet_tai_vu"><input type="checkbox" checked disabled></td>
                                            <td *ngIf="!p.Duyet_tai_vu"><input type="checkbox" disabled></td>
                                            <td>{{p.Ngay_tai_vu_duyet == null ? '' :
                                                p.Ngay_tai_vu_duyet|date:'dd/MM/yyyy'}}</td>
                                            <td *ngIf="p.Da_thanh_toan"><input type="checkbox" checked disabled></td>
                                            <td *ngIf="!p.Da_thanh_toan"><input type="checkbox" disabled></td>
                                            <td>{{p.Ngay_thanh_toan == null ? '' : p.Ngay_thanh_toan|date:'dd/MM/yyyy'}}
                                            </td>
                                            <td *ngIf="p.Nguon_nhap_lieu"><input type="checkbox" checked disabled></td>
                                            <td *ngIf="!p.Nguon_nhap_lieu"><input type="checkbox" disabled></td>
                                            <td>{{p.Ghi_chu}}</td>
                                            <td style="white-space: nowrap;">
                                                <!-- <button class="btn btn-sm btn-info" style="margin-right: 5px;"
                                                    (click)="open(DetailModal, 'xl', 'Detail', p)">
                                                    <i class="far fa-eye"></i> </button> -->
                                                <!-- <button class="btn btn-sm btn-danger" style="margin-right: 5px;"
                                                    title="Mở/Khoá" (click)="open(updateStatus, '', 'updateStatus', p)">
                                                    <i class="fas fa-lock"></i> </button> -->
                                                <button *ngIf="p.Ma_nhom_cong_viec == 'GD'"
                                                    class="btn btn-sm btn-warning" style="margin-right: 5px;"
                                                    title="Sửa " (click)="open(ThemModal, 'xl', 'Edit', p)">
                                                    <i class="fas fa-edit"></i> </button>
                                                <button *ngIf="p.Ma_nhom_cong_viec =='CM'"
                                                    class="btn btn-sm btn-warning" style="margin-right: 5px;"
                                                    title="Sửa " (click)="open(ThemModalCV, 'xl', 'EditCV', p)">
                                                    <i class="fas fa-edit"></i> </button>
                                                <button *ngIf="p.Ma_nhom_cong_viec == 'KH'"
                                                    class="btn btn-sm btn-warning" style="margin-right: 5px;"
                                                    title="Sửa " (click)="open(ThemModalKH, 'xl', 'EditKH', p)">
                                                    <i class="fas fa-edit"></i> </button>
                                                <button class="btn btn-sm btn-danger" style="margin-right: 5px;"
                                                    title="Xóa" (click)="open(DeleteModal, '', 'Delete', p)">
                                                    <i class="fas fa-trash-alt"></i> </button>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Thêm sửa giờ giảng -->
<ng-template #ThemModal let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
        <h4 class="modal-title h-title w-100">{{ titleModal }} Giờ giảng</h4>
        <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body">
        <div class="tab-content">
            <div class="active tab-pane">
                <form class="form-horizontal" [formGroup]="Insert">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="col-md-10 require">Hệ</label>
                            <ng-select (change)="changeDanhSachLop()" notFoundText="Không có dữ liệu"
                                (change)="changeHe()" [ngClass]="{ 'is-invalid': submitted && checkValue.ID_he.errors }"
                                formControlName="ID_he">
                                <ng-option value="">Chọn hệ</ng-option>
                                <ng-option *ngFor="let value of dataHe" [value]="value.ID_he">{{value.Ten_he}}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="submitted && checkValue.ID_he.errors" class="invalid-feedback">
                                <div *ngIf="checkValue.ID_he.errors.required">
                                    Hệ không được bỏ trống!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Khoa</label>
                            <ng-select (change)="changeDanhSachLop()" notFoundText="Không có dữ liệu"
                                formControlName="ID_khoa"
                                [ngClass]="{ 'is-invalid': submitted && checkValue.ID_khoa.errors }">
                                <ng-option value="">Chọn khoa</ng-option>
                                <ng-option *ngFor="let value of dataKhoa" [value]="value.ID_khoa">{{value.Ten_khoa}}
                                </ng-option>
                            </ng-select>

                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Khoá học</label>
                            <ng-select (change)="changeDanhSachLop()" notFoundText="Không có dữ liệu"
                                formControlName="Khoa_hoc"
                                [ngClass]="{ 'is-invalid': submitted && checkValue.Khoa_hoc.errors }">
                                <ng-option value="">Chọn khoá học</ng-option>
                                <ng-option *ngFor="let value of dataKhoaHoc" [value]="value.Khoa_hoc">{{value.Khoa_hoc}}
                                </ng-option>
                            </ng-select>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="col-md-10 require">Học kỳ</label>
                            <ng-select (change)="changeDanhSachLop()" notFoundText="Không có dữ liệu"
                                formControlName="Hoc_ky">
                                <ng-option [value]="0">Chọn học kỳ</ng-option>
                                <ng-option [value]="1">1</ng-option>
                                <ng-option [value]="2">2</ng-option>
                                <ng-option [value]="3">3</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Năm học</label>
                            <ng-select (change)="changeDanhSachLop()" notFoundText="Không có dữ liệu"
                                formControlName="Nam_hoc">
                                <ng-option *ngFor="let p of Nam_hoc_Arr" [value]="p.value">{{p.name}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Đợt</label>
                            <ng-select (change)="changeDanhSachLop()" notFoundText="Không có dữ liệu"
                                formControlName="Dot">
                                <ng-option value="1">1</ng-option>
                                <ng-option value="2">2</ng-option>
                                <ng-option value="3">3</ng-option>
                                <ng-option value="4">4</ng-option>
                                <ng-option value="5">5</ng-option>
                                <ng-option value="6">6</ng-option>
                                <ng-option value="7">7</ng-option>
                                <ng-option value="8">8</ng-option>
                                <ng-option value="9">9</ng-option>
                                <ng-option value="10">10</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="col-md-10">Nhóm công việc</label>
                            <ng-select formControlName="Nhom_cong_viec" (change)="changeNhomCongViecGD()">
                                <ng-option value="">Chọn nhóm công việc</ng-option>
                                <ng-option *ngFor="let p of dataNhomCongViec "
                                    [value]="p.ID_nhom_cong_viec">{{p.Ten_nhom_cong_viec}}
                                </ng-option>
                            </ng-select>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="col-md-10 require">Công việc</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Cong_viec"
                                (change)="changeCongViec()"
                                [ngClass]="{ 'is-invalid': submitted && checkValue.Cong_viec.errors }">
                                <ng-option value="">Chọn công việc</ng-option>
                                <ng-option *ngFor="let p of dataCongViec" [value]="p.ID_cong_viec">{{p.Ten_cong_viec}}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="submitted && checkValue.Cong_viec.errors" class="invalid-feedback">
                                <div *ngIf="checkValue.Cong_viec.errors.required">
                                    Công việc không được bỏ trống!
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="col-md-10 require">Tên môn học</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="ID_mon"
                                [ngClass]="{ 'is-invalid': submitted && checkValue.ID_mon.errors }"
                                (change)="changeDanhSachLop()">
                                <ng-option value="">Chọn môn học</ng-option>
                                <ng-option *ngFor="let p of dataMonHoc" [value]="p.ID_mon">{{p.Ten_mon}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && checkValue.ID_mon.errors" class="invalid-feedback">
                                <div *ngIf="checkValue.ID_mon.errors.required">
                                    Bạn chưa chọn môn học!
                                </div>
                            </div>

                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Loại lớp</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Loai_lop">
                                <ng-option value="">Chọn loại lớp</ng-option>
                                <ng-option *ngFor="let p of dataLoaiLop" [value]="p.ID_loai_lop">{{p.Ten_loai_lop}}
                                </ng-option>
                            </ng-select>

                        </div>
                        <div class="col-md-6">
                            <label class="col-md-10 require">Tên lớp</label>
                            <ng-select [ngbTooltip]="tipContent" container="body" placement="bottom"
                                placeholder="Select" (change)="changeLop()" notFoundText="Không có dữ liệu"
                                formControlName="ID_lop"
                                [ngClass]="{ 'is-invalid': submitted && checkValue.ID_lop.errors }">
                                <ng-option value="">Chọn lớp</ng-option>
                                <ng-option *ngFor="let p of dataLop" [value]="p.ID_lop"><span
                                        [title]="p.Ten_lop">{{p.Ten_lop}}</span></ng-option>
                            </ng-select>
                            <div *ngIf="submitted && checkValue.ID_lop.errors" class="invalid-feedback">
                                <div *ngIf="checkValue.ID_lop.errors.required">
                                    Tên lớp không được bỏ trống!
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-3">
                            <label class="col-md-10">Loại môn</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Loai_mon">
                                <ng-option value="">Chọn loại môn</ng-option>
                                <ng-option *ngFor="let p of dataHeSoMon" [value]="p.ID_he_so_mon">{{p.Ten_he_so_mon}}
                                </ng-option>
                            </ng-select>
                        </div> -->
                        <div class="col-md-3">
                            <label class="col-md-10">Đơn vị tính</label>
                            <input type="text" class="form-control" formControlName="Don_vi" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Số tiết/ Số giờ chuẩn</label>
                            <input type="number" class="form-control" formControlName="So_gio">
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Từ ngày</label>
                            <input type="date" class="form-control" (change)="changeTuNgay()" formControlName="Tu_ngay"
                                [readonly]="!disabled"
                                [ngClass]="{ 'is-invalid': submitted && checkValue.Tu_ngay.errors }">
                            <div *ngIf="submitted && checkValue.Tu_ngay.errors" class="invalid-feedback">
                                <div *ngIf="checkValue.Tu_ngay.errors.required">
                                    Từ ngày không được bỏ trống!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Đến ngày</label>
                            <input type="date" class="form-control" (change)="changeDenNgay()" [readonly]="!disabled"
                                formControlName="Den_ngay"
                                [ngClass]="{ 'is-invalid': submitted && checkValue.Den_ngay.errors }">
                            <div *ngIf="submitted && checkValue.Den_ngay.errors" class="invalid-feedback">
                                <div *ngIf="checkValue.Den_ngay.errors.required">
                                    Đến ngày không được bỏ trống!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Số sinh viên</label>
                            <input type="number" class="form-control" formControlName="So_sinh_vien"
                                [ngClass]="{ 'is-invalid': submitted && checkValue.So_sinh_vien.errors }"
                                placeholder="Nhập số sinh viên">
                            <div *ngIf="submitted && checkValue.So_sinh_vien.errors" class="invalid-feedback">
                                <div *ngIf="checkValue.So_sinh_vien.errors.required">
                                    Số sinh viên không được bỏ trống!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Thứ</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Thu">
                                <ng-option value="">Chọn thứ</ng-option>
                                <ng-option value="2">2</ng-option>
                                <ng-option value="3">3</ng-option>
                                <ng-option value="4">4</ng-option>
                                <ng-option value="5">5</ng-option>
                                <ng-option value="6">6</ng-option>
                                <ng-option value="7">7</ng-option>
                                <ng-option value="8">CN</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && checkValue.Thu.errors" class="invalid-feedback">
                                <div *ngIf="checkValue.Thu.errors.required">
                                    Thứ trong tuần không được bỏ trống!
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-md-3">
                            <label class="col-md-10 ">Ngoài giờ</label>
                            <select class="form-control" formControlName="Ngoai_gio">
                                <option value="0">Chọn ngoài giờ</option>
                                <option *ngFor="let p of dataNgoaiGio" value="{{p.ID_ngoai_gio}}">{{p.Ten_ngoai_gio}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Địa điểm giảng dạy</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Ngoai_truong">
                                <ng-option value="">Chọn địa điểm giảng dạy</ng-option>
                                <ng-option *ngFor="let p of dataNgoaiTruong" [value]="p.ID_ngoai_truong">
                                    {{p.Ten_ngoai_truong}}</ng-option>
                            </ng-select>
                        </div> -->
                        <div class="col-md-3">
                            <label class="col-md-10 ">Từ tiết</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Tu_tiet">
                                <ng-option value="1">1</ng-option>
                                <ng-option value="2">2</ng-option>
                                <ng-option value="3">3</ng-option>
                                <ng-option value="4">4</ng-option>
                                <ng-option value="5">5</ng-option>
                                <ng-option value="6">6</ng-option>
                                <ng-option value="7">7</ng-option>
                                <ng-option value="8">8</ng-option>
                                <ng-option value="9">9</ng-option>
                                <ng-option value="10">10</ng-option>
                                <ng-option value="11">11</ng-option>
                                <ng-option value="12">12</ng-option>
                                <ng-option value="13">13</ng-option>
                                <ng-option value="14">14</ng-option>
                                <ng-option value="15">15</ng-option>
                            </ng-select>

                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Đến tiết</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Den_tiet">
                                <ng-option value="1">1</ng-option>
                                <ng-option value="2">2</ng-option>
                                <ng-option value="3">3</ng-option>
                                <ng-option value="4">4</ng-option>
                                <ng-option value="5">5</ng-option>
                                <ng-option value="6">6</ng-option>
                                <ng-option value="7">7</ng-option>
                                <ng-option value="8">8</ng-option>
                                <ng-option value="9">9</ng-option>
                                <ng-option value="10">10</ng-option>
                                <ng-option value="11">11</ng-option>
                                <ng-option value="12">12</ng-option>
                                <ng-option value="13">13</ng-option>
                                <ng-option value="14">14</ng-option>
                                <ng-option value="15">15</ng-option>
                            </ng-select>

                        </div>

                        <!-- <div class="col-md-3">
                            <label class="col-md-10">Tổ</label>
                            <input type="number" class="form-control" formControlName="so_nhom" min="1"
                                [ngClass]="{ 'is-invalid': checkValue.so_nhom.errors }" placeholder="Nhập số tổ">
                            <div *ngIf="checkValue.so_nhom.errors" class="invalid-feedback">
                                <div *ngIf="checkValue.so_nhom.errors.min">
                                    Tổ phải là số dương!
                                </div>
                            </div>
                        </div> -->

                    </div>
                    <!-- <div class="row" *ngIf="isDisplay">
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số công việc</label>
                            <input type="text" class="form-control" formControlName="He_so_cong_viec" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số chức danh</label>
                            <input type="text" class="form-control" formControlName="He_so_chuc_danh" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số hệ đào tạo</label>
                            <input type="text" class="form-control" formControlName="He_so_he" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số loại lớp</label>
                            <input type="text" class="form-control" formControlName="He_so_loai_lop" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số ngoài giờ</label>
                            <input type="text" class="form-control" formControlName="He_so_ngoai_gio" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số ngoài trường</label>
                            <input type="text" class="form-control" formControlName="He_so_ngoai_truong" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số lớp đông</label>
                            <input type="text" class="form-control" formControlName="He_so_lop" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số loại môn</label>
                            <input type="text" class="form-control" formControlName="He_so_loai_mon" disabled>
                        </div>

                    </div> -->
                </form>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="float-right">
            <button type="button" class="btn btn-md btn-success" (click)="onSubmit()">
                <i class="fas fa-save"></i> Lưu
            </button>
            <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                style="margin-left: 1em;">
                <i class="far fa-times-circle"></i> Đóng
            </button>
        </div>
    </div>
</ng-template>
<ng-template #ThemModalCV let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
        <h4 class="modal-title h-title w-100">{{ titleModal }} - Công việc</h4>
        <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body">
        <div class="tab-content">
            <div class="active tab-pane">
                <form class="form-horizontal" [formGroup]="InsertCV">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="col-md-10 require">Hệ</label>
                            <ng-select (change)="changeDanhSachLopCV()" notFoundText="Không có dữ liệu"
                                (change)="changeHeCV()" [ngClass]="{ 'is-invalid': submitted && checkValueCV.ID_he.errors }"
                                formControlName="ID_he">
                                <ng-option value="">Chọn hệ</ng-option>
                                <ng-option *ngFor="let value of dataHe" [value]="value.ID_he">{{value.Ten_he}}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="submitted && checkValueCV.ID_he.errors" class="invalid-feedback">
                                <div *ngIf="checkValueCV.ID_he.errors.required">
                                    Hệ không được bỏ trống!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Khoa</label>
                            <ng-select (change)="changeDanhSachLopCV()" notFoundText="Không có dữ liệu"
                                formControlName="ID_khoa"
                                [ngClass]="{ 'is-invalid': submittedCV && checkValueCV.ID_khoa.errors }">
                                <ng-option value="">Chọn khoa</ng-option>
                                <ng-option *ngFor="let value of dataKhoa" [value]="value.ID_khoa">{{value.Ten_khoa}}
                                </ng-option>
                            </ng-select>

                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Khoá học</label>
                            <ng-select (change)="changeDanhSachLopCV()" notFoundText="Không có dữ liệu"
                                formControlName="Khoa_hoc"
                                [ngClass]="{ 'is-invalid': submittedCV && checkValueCV.Khoa_hoc.errors }">
                                <ng-option value="">Chọn khoá học</ng-option>
                                <ng-option *ngFor="let value of dataKhoaHoc" [value]="value.Khoa_hoc">{{value.Khoa_hoc}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="col-md-10 require">Học kỳ</label>
                            <ng-select notFoundText="Không có dữ liệu" (change)="filterLopTinChi()"  [ngClass]="{ 'is-invalid': submitted && checkValueCV.Hoc_ky.errors }" formControlName="Hoc_ky">
                                <ng-option value="">Chọn học kỳ</ng-option>
                                <ng-option value="1">1</ng-option>
                                <ng-option value="2">2</ng-option>
                                <ng-option value="3">3</ng-option>
                            </ng-select>
                            <div *ngIf="submittedCV && checkValueCV.Hoc_ky.errors" class="invalid-feedback">
                                <div *ngIf="checkValueCV.Hoc_ky.errors.required">
                                Học kỳ không được bỏ trống!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Năm học</label>
                            <ng-select notFoundText="Không có dữ liệu" (change)="filterLopTinChi()" formControlName="Nam_hoc">
                                <ng-option *ngFor="let p of Nam_hoc_Arr" [value]="p.value">{{p.name}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="col-md-10 require">Nhóm công việc</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Nhom_cong_viec"
                                (change)="changeNhomCongViecCM()"
                                [ngClass]="{ 'is-invalid': submittedCV && checkValueCV.Nhom_cong_viec.errors }">
                                <ng-option value="">Chọn nhóm công việc</ng-option>
                                <ng-option *ngFor="let p of dataNhomCongViec" [value]="p.ID_nhom_cong_viec">
                                    {{p.Ten_nhom_cong_viec}}</ng-option>
                            </ng-select>
                            <div *ngIf="submittedCV && checkValueCV.Nhom_cong_viec.errors" class="invalid-feedback">
                                <div *ngIf="checkValueCV.Nhom_cong_viec.errors.required">
                                    Nhóm công việc không được bỏ trống!
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="col-md-10 require">Công việc</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Cong_viec"
                                (change)="changeCongViecCV()"
                                [ngClass]="{ 'is-invalid': submittedCV && checkValueCV.Cong_viec.errors }">
                                <ng-option value="">Chọn công việc</ng-option>
                                <ng-option *ngFor="let p of dataCongViec" [value]="p.ID_cong_viec">
                                    <div title="{{p.Ten_cong_viec}}">{{p.Ten_cong_viec}}</div>
                                </ng-option>
                            </ng-select>
                            <div *ngIf="submittedCV && checkValueCV.Cong_viec.errors" class="invalid-feedback">
                                <div *ngIf="checkValueCV.Cong_viec.errors.required">
                                    Công việc không được bỏ trống!
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="col-md-10 ">Nội dung công việc</label>
                            <textarea class="form-control" formControlName="Ten_mon" rows="3"
                                placeholder="Chỉ áp dụng cho chấm KLTN, VD: Nguyễn Văn A - Lớp - Đề tài"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="col-md-10 ">Loại lớp</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Loai_lop">
                                <ng-option value="">Chọn loại lớp</ng-option>
                                <ng-option *ngFor="let p of dataLoaiLop" [value]="p.ID_loai_lop">{{p.Ten_loai_lop}}
                                </ng-option>
                            </ng-select>

                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Tên lớp</label>
                            <ng-select [ngbTooltip]="tipContent" container="body" placement="bottom"
                                (change)="changeDanhSachLopCV()" placeholder="Select" notFoundText="Không có dữ liệu"
                                formControlName="ID_lop">
                                <ng-option value="">Chọn lớp</ng-option>
                                <ng-option *ngFor="let p of ClassListAll" [value]="p.ID_lop"><span
                                        [title]="p.Ten_lop">{{p.Ten_lop}}</span></ng-option>
                            </ng-select>

                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Đơn vị tính</label>
                            <input type="text" class="form-control" formControlName="Don_vi" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Khối lượng công việc</label>
                            <input type="number" class="form-control" formControlName="So_gio"
                                [ngClass]="{ 'is-invalid': submittedCV && checkValueCV.So_gio.errors }"
                                placeholder="Nhập số giờ">
                            <div *ngIf="submittedCV && checkValueCV.So_gio.errors" class="invalid-feedback">
                                <div *ngIf="checkValueCV.So_gio.errors.required">
                                    Số giờ không được bỏ trống!
                                </div>

                            </div>
                        </div>
                        <!-- <div class="col-md-3">
                            <label class="col-md-10 require">Từ ngày</label>
                            <input type="date" class="form-control" formControlName="Tu_ngay" (change)="changeTuNgay()"
                                [ngClass]="{ 'is-invalid': submittedCV && checkValueCV.Tu_ngay.errors }">
                            <div *ngIf="submittedCV && checkValueCV.Tu_ngay.errors" class="invalid-feedback">
                                <div *ngIf="checkValueCV.Tu_ngay.errors.required">
                                    Từ ngày không được bỏ trống!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Đến ngày</label>
                            <input type="date" class="form-control" formControlName="Den_ngay"
                                (change)="changeDenNgay()"
                                [ngClass]="{ 'is-invalid': submittedCV && checkValueCV.Den_ngay.errors }">
                            <div *ngIf="submittedCV && checkValueCV.Den_ngay.errors" class="invalid-feedback">
                                <div *ngIf="checkValueCV.Den_ngay.errors.required">
                                    Đến ngày không được bỏ trống!
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="col-md-3">
                            <label class="col-md-10">Từ tiết</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Tu_tiet"
                                (change)="changeTiet()">
                                <ng-option value="0">0</ng-option>
                                <ng-option value="1">1</ng-option>
                                <ng-option value="2">2</ng-option>
                                <ng-option value="3">3</ng-option>
                                <ng-option value="4">4</ng-option>
                                <ng-option value="5">5</ng-option>
                                <ng-option value="6">6</ng-option>
                                <ng-option value="7">7</ng-option>
                                <ng-option value="8">8</ng-option>
                                <ng-option value="9">9</ng-option>
                                <ng-option value="10">10</ng-option>
                                <ng-option value="11">11</ng-option>
                                <ng-option value="12">12</ng-option>
                                <ng-option value="13">13</ng-option>
                                <ng-option value="14">14</ng-option>
                                <ng-option value="15">15</ng-option>
                            </ng-select>
                       
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Đến tiết</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Den_tiet"
                                (change)="changeTiet()">
                                <ng-option value="0">0</ng-option>
                                <ng-option value="1">1</ng-option>
                                <ng-option value="2">2</ng-option>
                                <ng-option value="3">3</ng-option>
                                <ng-option value="4">4</ng-option>
                                <ng-option value="5">5</ng-option>
                                <ng-option value="6">6</ng-option>
                                <ng-option value="7">7</ng-option>
                                <ng-option value="8">8</ng-option>
                                <ng-option value="9">9</ng-option>
                                <ng-option value="10">10</ng-option>
                                <ng-option value="11">11</ng-option>
                                <ng-option value="12">12</ng-option>
                                <ng-option value="13">13</ng-option>
                                <ng-option value="14">14</ng-option>
                                <ng-option value="15">15</ng-option>
                            </ng-select>
                          
                        </div> -->
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div class="float-right">
            <button type="button" class="btn btn-md btn-success" (click)="onSubmitCV()">
                <i class="fas fa-save"></i> Lưu
            </button>
            <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                style="margin-left: 1em;">
                <i class="far fa-times-circle"></i> Đóng
            </button>
        </div>
    </div>
</ng-template>
<ng-template #ThemModalKH let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
        <h4 class="modal-title h-title w-100">{{ titleModal }} - Khoa học</h4>
        <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body">
        <div class="tab-content">
            <div class="active tab-pane">
                <form class="form-horizontal" [formGroup]="InsertKH">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="col-md-10 require">Học kì</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Hoc_ky">
                                <ng-option value="">Chọn học kỳ</ng-option>
                                <ng-option value="1">1</ng-option>
                                <ng-option value="2">2</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Năm học</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Nam_hoc">
                                <ng-option *ngFor="let p of Nam_hoc_Arr" [value]="p.value">{{p.name}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="col-md-10 ">Nhóm công việc</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Nhom_cong_viec"
                                (change)="changeNhomCongViecKH()">
                                <ng-option value="">Chọn nhóm công việc</ng-option>
                                <ng-option *ngFor="let p of dataNhomCongViec" [value]="p.ID_nhom_cong_viec">
                                    {{p.Ten_nhom_cong_viec}}</ng-option>
                            </ng-select>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="col-md-10 require">Công việc</label>
                            <ng-select notFoundText="Không có dữ liệu" formControlName="Cong_viec"
                                (change)="changeNhomCongViecKH()"
                                [ngClass]="{ 'is-invalid': submittedKH && checkValueKH.Cong_viec.errors }">
                                <ng-option value="">Chọn công việc</ng-option>
                                <ng-option *ngFor="let p of dataCongViec" [value]="p.ID_cong_viec">
                                    <div title="{{p.Ten_cong_viec}}">{{p.Ten_cong_viec}}</div>
                                </ng-option>
                            </ng-select>
                            <div *ngIf="submittedKH && checkValueKH.Cong_viec.errors" class="invalid-feedback">
                                <div *ngIf="checkValueCV.Cong_viec.errors.required">
                                    Công việc không được bỏ trống!
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-10 require">Số người thực hiện:</label>
                            <input type="number" class="form-control" formControlName="So_nguoi_tham_gia" min="1"
                                [ngClass]="{ 'is-invalid': checkValueKH.So_nguoi_tham_gia.errors }"
                                placeholder="Nhập số người tham gia">
                            <div *ngIf="checkValueKH.So_nguoi_tham_gia.errors" class="invalid-feedback">
                                <div *ngIf="checkValueKH.So_nguoi_tham_gia.errors.min">
                                    Số giờ phải là số dương!
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-10 require">Số lượng</label>
                            <input type="number" class="form-control" formControlName="So_gio" min="0"
                                [ngClass]="{ 'is-invalid': checkValueKH.So_gio.errors }" placeholder="Nhập số giờ">
                            <div *ngIf="checkValueKH.So_gio.errors" class="invalid-feedback">
                                <div *ngIf="checkValueKH.So_gio.errors.min">
                                    Số giờ phải là số dương!
                                </div>

                            </div>
                        </div>
                        <div class="col-md-12">
                            <label class="col-md-10">Nội dung công việc</label>
                            <textarea class="form-control" formControlName="Ten_mon"
                                placeholder="Nhập tên nội dung"></textarea>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div class="float-right">
            <button type="button" class="btn btn-md btn-success" (click)="onSubmitKH()">
                <i class="fas fa-save"></i> Lưu
            </button>
            <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                style="margin-left: 1em;">
                <i class="far fa-times-circle"></i> Đóng
            </button>
        </div>
    </div>
</ng-template>
<!-- <ng-template #DetailModal let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
        <h4 class="modal-title h-title w-100">Chi tiết hệ số</h4>
        <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body">
        <div class="tab-content">
            <div class="active tab-pane">
                <form class="form-horizontal" [formGroup]="Edit">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="col-md-10 ">Hệ</label>
                            <select class="form-control" disabled formControlName="ID_he">
                                <option value="">Chọn hệ</option>
                                <option *ngFor="let value of dataHe" [value]="value.ID_he">{{value.Ten_he}}
                                </option>
                            </select>

                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Khoa</label>
                            <select class="form-control" disabled formControlName="ID_khoa">
                                <option value="">Chọn Khoa</option>
                                <option *ngFor="let value of dataKhoa" [value]="value.ID_khoa">{{value.Ten_khoa}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Khoá học</label>
                            <select class="form-control" disabled formControlName="Khoa_hoc">
                                <option value="">Chọn Khoá</option>
                                <option *ngFor="let value of dataKhoa" [value]="value.Khoa_hoc">{{value.Khoa_hoc}}
                                </option>
                            </select>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="col-md-10 ">Học kỳ</label>
                            <select class="form-control" disabled formControlName="Hoc_ky">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Năm học</label>
                            <select class="form-control" disabled formControlName="Nam_hoc">
                                <option *ngFor="let p of Nam_hoc_Arr" [value]="p.value">{{p.name}}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Đợt</label>
                            <select class="form-control" formControlName="Dot" disabled>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="col-md-10 ">Nhóm công việc</label>
                            <select class="form-control" formControlName="Nhom_cong_viec" disabled>
                                <option value="1">Giảng dạy trên lớp</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-10 ">Công việc</label>
                            <select class="form-control" formControlName="Cong_viec" disabled>
                                <option value="">Chọn công việc</option>
                                <option *ngFor="let p of dataCongViec" [value]="p.ID_cong_viec">{{p.Ten_cong_viec}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Đơn vị tính</label>
                            <input type="text" class="form-control" formControlName="Don_vi" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Số giờ thực hiện</label>
                            <input type="number" class="form-control" formControlName="So_gio" disabled>

                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Loại môn</label>
                            <select class="form-control" formControlName="Loai_mon" disabled>
                                <option value="">Chọn loại môn</option>
                                <option *ngFor="let p of dataHeSoMon" [value]="p.ID_he_so_mon">{{p.Ten_he_so_mon}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-10 ">Tên môn học</label>
                            <select class="form-control" formControlName="ID_mon" disabled>
                                <option value="">Chọn môn học</option>
                                <option *ngFor="let p of dataMonHoc" [value]="p.ID_mon_hoc">{{p.Ten_mon}}
                                </option>
                            </select>

                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Từ ngày</label>
                            <input type="date" class="form-control" formControlName="Tu_ngay" disabled>

                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Đến ngày</label>
                            <input type="date" class="form-control" formControlName="Den_ngay" disabled>
                        </div>

                        <div class="col-md-3">
                            <label class="col-md-10 ">Ngoài giờ</label>
                            <select class="form-control" formControlName="Ngoai_gio" disabled>
                                <option value="0">Chọn ngoài giờ</option>
                                <option *ngFor="let p of dataNgoaiGio" value="{{p.ID_ngoai_gio}}">{{p.Ten_ngoai_gio}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Địa điểm giảng dạy</label>

                            <select class="form-control" formControlName="Ngoai_truong" disabled>
                                <option value="">Chọn địa điểm giảng dạy</option>
                                <option *ngFor="let p of dataNgoaiTruong" value="{{p.ID_ngoai_truong}}">
                                    {{p.Ten_ngoai_truong}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Từ tiết</label>
                            <input type="number" class="form-control" formControlName="Tu_tiet" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Đến tiết</label>
                            <input type="number" class="form-control" formControlName="Den_tiet" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Loại lớp</label>
                            <select class="form-control" formControlName="Loai_lop" disabled>
                                <option value="">Chọn loại lớp</option>
                                <option *ngFor="let p of dataLoaiLop" value="{{p.ID_loai_lop}}">{{p.Ten_loai_lop}}
                                </option>
                            </select>

                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 ">Tên lớp</label>
                            <select class="form-control" formControlName="Ten_lop" disabled>
                                <option value="">Chọn lớp</option>
                                <option *ngFor="let p of dataLop" value="{{p.ID_lop}}">{{p.Ten_lop}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Số sinh viên</label>
                            <input type="number" class="form-control" formControlName="So_sinh_vien" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10 require">Tổ</label>
                            <input type="number" class="form-control" formControlName="so_nhom" disabled>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số công việc</label>
                            <input type="text" class="form-control" formControlName="He_so_cong_viec" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số chức danh</label>
                            <input type="text" class="form-control" formControlName="He_so_chuc_danh" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số hệ đào tạo</label>
                            <input type="text" class="form-control" formControlName="He_so_he" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số loại lớp</label>
                            <input type="text" class="form-control" formControlName="He_so_loai_lop" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số ngoài giờ</label>
                            <input type="text" class="form-control" formControlName="He_so_ngoai_gio" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số ngoài trường</label>
                            <input type="text" class="form-control" formControlName="He_so_ngoai_truong" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số lớp đông</label>
                            <input type="text" class="form-control" formControlName="He_so_lop" disabled>
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-10">Hệ số loại môn</label>
                            <input type="text" class="form-control" formControlName="He_so_loai_mon" disabled>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template> -->
<ng-template #DeleteModal let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <div class="icon-box">
                <i class="fas fa-trash-alt"
                    style="color: #f15e5e;font-size: 46px;display: inline-block;margin-top: 13px;"></i>
            </div>
            <p
                style="color: inherit; text-decoration: none;font-size: 20px; text-align: center; font-weight: 600; margin-top: 3em;">
                Bạn có chắc chắn muốn xóa
            </p>
            <div class="text-center">
                <button type="button" class="btn btn-md btn-success" (click)="Delete()">
                    Đồng ý
                </button>
                <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                    style="margin-left: 1em;">
                    Hủy bỏ
                </button>

            </div>
        </div>
    </div>
</ng-template>
<ng-template #multipleUpdate let-modal>
    <div class="modal-content">
        <div class="modal-body">

            <p style="color: inherit; text-decoration: none;font-size: 20px; text-align: center; font-weight: 600;">
                Bạn có chắc chắn muốn Mở/Khoá kê khai
            </p>
            <div class="text-center">
                <button type="button" class="btn btn-md btn-success" (click)="MultiUpdateStatus()">
                    Đồng ý
                </button>
                <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                    style="margin-left: 1em;">
                    Hủy bỏ
                </button>

            </div>
        </div>
    </div>
</ng-template>
<ng-template #updateStatus let-modal>
    <div class="modal-content">
        <div class="modal-body">

            <p style="color: inherit; text-decoration: none;font-size: 20px; text-align: center; font-weight: 600;">
                Bạn có chắc chắn muốn Mở/Khoá kê khai
            </p>
            <div class="text-center">
                <button type="button" class="btn btn-md btn-success" (click)="UpdateStatus()">
                    Đồng ý
                </button>
                <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                    style="margin-left: 1em;">
                    Hủy bỏ
                </button>

            </div>
        </div>
    </div>
</ng-template>