<section class="content-header" style="padding: 0.5% !important">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-4">
        <ol class="breadcrumb float-sm-left">
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/']"
              ><i class="fas fa-home"></i> &nbsp;Trang chủ</a
            >
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/admin/mark']">Điểm môn học</a>
          </li>
          <li class="breadcrumb-item active">Điểm quá trình</li>
          &nbsp;
        </ol>
      </div>
      <!-- /.col -->
      <div class="col-sm-8">
        <button
        *ngIf="ImportDiemEnable"
          (click)="open(ChooseFileImport, 'xs')"
          style="float: right"
          class="btn btn-sm btn-info"
        >
          <i class="fas fa-upload"></i> Import Excel
        </button>

        <button
          (click)="ExportTOExcel()"
          style="float: right; margin-right: 10px"
          class="btn btn-sm btn-info"
        >
          <i class="fas fa-file-excel"></i> Xuất Excel
        </button>
        <button
        *ngIf="CongBoDiemDiemEnable"
          (click)="Publish(false)"
          style="float: right; margin-right: 10px"
          class="btn btn-sm btn-warning"
        >
          <i class="fas fa-times"></i> Huỷ công bố
        </button>
        <button
        *ngIf="CongBoDiemDiemEnable"
          (click)="Publish(true)"
          style="float: right; margin-right: 10px"
          class="btn btn-sm btn-info"
        >
          <i class="fas fa-bullhorn"></i> Công bố
        </button>
        <button
          class="btn btn-sm btn-danger"
          style="float: right; margin-right: 10px"
          (click)="KhoaDiem()"
        >
          <i class="fas fa-lock"></i> Khóa
        </button>
        <button
          style="float: right; margin-right: 10px"
          class="btn btn-sm btn-success"
          (click)="Save()"
        >
          <i class="fas fa-save"></i> Lưu
        </button>
        <select
        *ngIf="hien_thi_combo_box_lan_hoc"
          class="btn btn-sm"
          (change)="LanHocChange()"
          [(ngModel)]="Lan_hoc"
          id="Lan_hoc"
          style="background-color: lightgray; float: right; margin-right: 20px"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
        <span  *ngIf="hien_thi_combo_box_lan_hoc" style="font-size: 15px; float: right">Lần học :&nbsp;</span>
        <select
          class="btn btn-sm"
          (change)="StudentFil()"
          [(ngModel)]="sinhVienFil"
          id="Loc"
          style="background-color: rgb(255, 255, 255); float: right; margin-right: 20px; text-align: left;"
          *ngIf="hien_thi_combo_box_loc_sinh_vien"
        >
          <option value=''>Chọn sinh viên</option>
          <option *ngFor="let p of allDiemTP" [value]="p.Ma_sv">{{p.Ma_sv}} - {{p.Ho_ten}}</option>
        </select>
        <span *ngIf="hien_thi_combo_box_loc_sinh_vien" style="font-size: 15px; float: right">Lọc :&nbsp;</span>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>
<section class="content cus-body">
  <div class="card">
    <div class="card-body" style="padding: 0px 20px 0px 20px">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-6">
              <label>Môn học : {{ TitleMon }}</label>
            </div>
            <div class="col-md-6">
              <label>Lớp : {{ TitleLop }}</label>
            </div>
            <div class="col-md-6">
              <label
                >Học kỳ : {{ TitleHocKy }} - Năm học : {{ TitleNamHoc }}
              </label>
            </div>
            <div class="col-md-6" *ngIf="hien_thi_check_box_sinh_vien_hoc_lai">
              <span
                ><label>Sinh viên học lại</label>&nbsp;<input
                  type="checkbox"
                  [(ngModel)]="checkboxNoLabel"
                  (change)="LanHocChange()"
              /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content cus-body">
  <div class="card">
    <div class="card-body cus-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div class="col-md-12">
              <div
                class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
                style="height: 70vh"
              >
                <table
                  class="table-bordered dataTables_wrapper no-footer table table-hover table-striped"
                  (keydown)="onKeydown($event)"
                  id="lstStudents"
                ></table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content-header" style="padding: 0.5% !important">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-12 text-center">
        <button
        *ngIf="ImportDiemEnable"
          (click)="open(ChooseFileImport, 'xs')"
          style="float: right"
          class="btn btn-sm btn-info"
        >
          <i class="fas fa-upload"></i> Import Excel
        </button>
        <button
          (click)="ExportTOExcel()"
          style="float: right; margin-right: 10px"
          class="btn btn-sm btn-info"
        >
          <i class="fas fa-file-download"></i> Xuất Excel
        </button>
        <button
        *ngIf="CongBoDiemDiemEnable"
          (click)="Publish(false)"
          style="float: right; margin-right: 10px"
          class="btn btn-sm btn-warning"
        >
          <i class="fas fa-times"></i> Huỷ công bố
        </button>
        <button
        *ngIf="CongBoDiemDiemEnable"
          (click)="Publish(true)"
          style="float: right; margin-right: 10px"
          class="btn btn-sm btn-info"
        >
          <i class="fas fa-bullhorn"></i> Công bố
        </button>
        <button
          class="btn btn-sm btn-danger"
          style="float: right; margin-right: 10px"
          (click)="KhoaDiem()"
        >
          <i class="fas fa-lock"></i> Khóa
        </button>
        <button
          style="float: right; margin-right: 10px"
          class="btn btn-sm btn-success"
          (click)="Save()"
        >
          <i class="fas fa-save"></i> Lưu
        </button>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>

<ng-template #ChooseFileImport let-modal>
  <div class="modal-content">
    <div
      class="modal-header m-header text-center"
      style="padding-top: 0px; padding-bottom: 0px"
    >
      <h4 class="modal-title h-title w-100">
        Nhập điểm quá trình từ file excel
      </h4>
      <span
        (click)="modal.dismiss('Cross click')"
        aria-hidden="true"
        style="font-size: 25px"
        >&times;</span
      >
    </div>
    <div class="modal-body">
      <div class="text-right">
        <div class="form-group">
          <input
            type="file"
            class="form-control"
            style="height: 45px !important"
            name="currentInput"
            [(ngModel)]="currentInput"
            (change)="onFileSelected($event)"
          />
        </div>
        <button
          type="button"
          class="btn btn-md btn-success"
          (click)="ImportExcel()"
        >
          Tải lên
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger"
          (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em"
        >
          Hủy bỏ
        </button>
      </div>
    </div>
  </div>
</ng-template>
