<section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-md-6">
              <ol class="breadcrumb float-sm-left">
                  <li class="breadcrumb-item">
                      <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                  </li>
                  <li class="breadcrumb-item active">Lịch coi thi</li>
              </ol>
          </div>
          <div class="col-md-6">
              <button (click)="help()" class="btn btn-warning btn-sm"  type="submit" style="float: right; ">
                  <i class="fas fa-info-circle"></i>
                  Hướng dẫn
              </button>
          </div>
      </div>
  </div>
</section>
  <section class="content cus-body">
    <div class="card">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <full-calendar 
            defaultView="dayGridWeek"
             [plugins]="calendarPlugins"
            [locale]="locale"
            [header]="options.header"
            [events]="eventsModel"
            (eventClick)="eventClick($event)"
             ></full-calendar>
        </div>
        <!-- /.col -->
    </div>
    </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </section>


