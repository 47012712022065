<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
               
                <div class="col-md-6">
                    <ol class="breadcrumb float-left">
                        <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                        <li class="breadcrumb-item active">Điểm môn học</li>
                    </ol>
                </div>
                <div class="col-md-6">
                    <button (click)="help()" class="btn btn-warning btn-sm"  type="submit" style="float: right; ">
                        <i class="fas fa-info-circle"></i>
                        Hướng dẫn
                    </button>
                </div>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body" style="padding: 0px 20px 0px 20px;">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row pt10" style="margin-bottom: 1%;">
                        <div class="col-md-4">
                            <label class="col-md-10">Năm học</label>
                            <select class="form-control" [(ngModel)]="Nam_hoc_fil">
                                <option value="">Tất cả</option>
                                <option *ngFor = "let p of Nam_hoc_arr" value="{{p.value}}">{{p.name}}</option>
          
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label class="col-md-10">Tên môn</label>
                            <input  class="form-control" type="text" placeholder="Nhập tên môn"
                            [(ngModel)]="Ten_mon_fil">
                        </div>
                        <div class="col-md-4">
                            <label class="col-md-10">Tên lớp</label>
                            <input  class="form-control" type="text" placeholder="Nhập tên lớp"
                            [(ngModel)]="Ten_lop_fil">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                                    <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                        <!-- <table datatable id="classTable" [dtOptions]="dtOptions" class="row-border hover"></table> -->
                                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                            class="table-bordered dataTables_wrapper no-footer">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let p of ClassData | tableFilter: Nam_hoc_fil | FilterClass:{Ten_mon : Ten_mon_fil , Ten_lop : Ten_lop_fil }">
                                             
                                                    <td>{{ p.Ky_hieu }}</td>
                                                    <td>{{ p.Ten_mon }}</td>
                                                    <td>{{ p.Ten_lop }}</td>
                                                    <td>{{ p.Hoc_ky }}</td>
                                                    <td>{{ p.Nam_hoc }}</td>
                                                    <td>{{ p.Tu_ngay |  date:'dd/MM/yyyy'}}</td>
                                                    <td>{{ p.Den_ngay |  date:'dd/MM/yyyy'}}</td>
                                                    <td>{{ p.Dot }}</td>
                                                    <td>{{ p.So_sv }}</td>
                                                    <td>{{ p.Ten_loai_lop }}</td>
                                                    <td>
                                                       
                                                        <a class="btn btn-success btn-sm"  data-toggle="tooltip" title="Nhập điểm quá trình"
                                                            [routerLink]="['/admin/DiemQuaTrinh']"
                                                            [queryParams]="{CategoryID: p.Loai_lop,ID:p.ID_lop,Ten_lop:p.Ten_lop,ID_mon:p.ID_mon,Ten_mon:p.Ten_mon,Hoc_ky:p.Hoc_ky,Nam_hoc:p.Nam_hoc}"
                                                            queryParamsHandling="merge">
                                                            <i class="fas fa-tasks"></i>
                                                        </a>
                                                        <a class="btn btn-warning btn-sm" style="margin-left: 10px;" data-toggle="tooltip" title="Nhập điểm thi"
                                                        [routerLink]="['/admin/DiemThi']"
                                                        [queryParams]="{CategoryID: p.Loai_lop,ID:p.ID_lop,Ten_lop:p.Ten_lop,ID_mon:p.ID_mon,Ten_mon:p.Ten_mon,Hoc_ky:p.Hoc_ky,Nam_hoc:p.Nam_hoc}"
                                                        queryParamsHandling="merge">
                                                        <i class="fas fa-file-alt"></i>
                                                    </a>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>
<!-- /.content -->