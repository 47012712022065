<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid cus-fs" >
      <div class="row">
            
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                    <li class="breadcrumb-item active">Tra cứu điểm danh &nbsp;</li>
                    <div class="{{TitleClass}}"></div>
                </ol>
            </div>
            <div class="col-md-6">
                <button (click)="help()" class="btn btn-warning btn-sm"  type="submit" style="float: right; ">
                    <i class="fas fa-info-circle"></i>
                    Hướng dẫn
                </button>
            </div>
      </div><!-- /.row -->
  </div><!-- /.container-fluid -->
  </section>

  <section class="content cus-body">
    <div class="card">
        <div class="card-body" style="padding: 0px 20px 0px 20px;">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row pt10" style="margin-bottom: 1%;">
                        <div class="col-md-4">
                            <label class="col-md-10">Năm học</label>
                            <select class="form-control" [(ngModel)]="Nam_hoc_fil">
                                <option value="">Tất cả</option>
                                <option *ngFor = "let p of Nam_hoc_arr" value="{{p.value}}">{{p.name}}</option>
          
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label class="col-md-10">Tên môn</label>
                            <input  class="form-control" type="text" placeholder="Nhập tên môn"
                            [(ngModel)]="Ten_mon_fil">
                        </div>
                        <div class="col-md-4">
                            <label class="col-md-10">Tên lớp</label>
                            <input  class="form-control" type="text" placeholder="Nhập tên lớp"
                            [(ngModel)]="Ten_lop_fil">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">

        <div class="card-body cus-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="col-md-12">
                                    <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table-bordered dataTables_wrapper no-footer">
                                            <thead>
                                              <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let p of ClassData | tableFilter: Nam_hoc_fil | FilterClass:{Ten_mon : Ten_mon_fil , Ten_lop : Ten_lop_fil }">
                                                    <td>{{ p.Ky_hieu }}</td>
                                                    <td>{{ p.Ten_mon }}</td>
                                                    <td>{{ p.Ten_lop }}</td>
                                                    <td>{{ p.Hoc_ky }}</td>
                                                    <td>{{ p.Nam_hoc }}</td>
                                                    <td>{{ p.Dot }}</td>
                                                    <td>{{ p.So_sv }}</td>
                                                    <td>{{ p.Ten_loai_lop }}</td>
                                                    <td style="text-align: center; white-space: nowrap;">

                                                    <a *ngIf="!p.Diem_danh_theo_tiet" [routerLink]="['/admin/attendencesearch']" class="btn btn-primary btn-sm cus-fs"  ngbTooltip="Tra cứu theo sinh viên"  [queryParams]="{IDLop: p.ID_lop,Ten_lop:p.Ten_lop,
                                                        Loai_lop:p.Loai_lop,ID_mon:p.ID_mon,Hoc_ky:p.Hoc_ky,Nam_hoc:p.Nam_hoc,Ten_mon:p.Ten_mon}"
                                                     queryParamsHandling="merge" style="margin-right: 10px;">
                                                     <i class="fas fa-list-alt"></i>
                                                    </a>
                                                    <a *ngIf="p.Diem_danh_theo_tiet" [routerLink]="['/admin/AttendenceSearchTheoTiet']" class="btn btn-primary btn-sm cus-fs"  ngbTooltip="Tra cứu theo sinh viên"  [queryParams]="{IDLop: p.ID_lop,Ten_lop:p.Ten_lop,
                                                        Loai_lop:p.Loai_lop,ID_mon:p.ID_mon,Hoc_ky:p.Hoc_ky,Nam_hoc:p.Nam_hoc,Ten_mon:p.Ten_mon}"
                                                     queryParamsHandling="merge" style="margin-right: 10px;">
                                                     <i class="fas fa-list-alt"></i>
                                                    </a>
                                                    
                                                    <a *ngIf="!p.Diem_danh_theo_tiet" [routerLink]="['/admin/attendenceday']" ngbTooltip="Tra cứu theo ngày" class="btn btn-info btn-sm cus-fs"  [queryParams]="{IDLop: p.ID_lop,Ten_lop:p.Ten_lop,Loai_lop:p.Loai_lop,ID_mon:p.ID_mon,Ten_mon:p.Ten_mon}" queryParamsHandling="merge">
                                                        <i class="fas fa-clipboard-list"></i>
                                                    </a>
                                                    <a *ngIf="p.Diem_danh_theo_tiet" [routerLink]="['/admin/AttendenceDayTheoTiet']" ngbTooltip="Tra cứu theo ngày" class="btn btn-info btn-sm cus-fs"  [queryParams]="{IDLop: p.ID_lop,Ten_lop:p.Ten_lop,Loai_lop:p.Loai_lop,ID_mon:p.ID_mon,Ten_mon:p.Ten_mon}" queryParamsHandling="merge">
                                                        <i class="fas fa-clipboard-list"></i>
                                                    </a>
                                                </td>
                                                </tr>
                                            </tbody>
                                                
                                          </table>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

