import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { ResponseClassListAll } from "src/app/Models/output.model/ResponseClassListAll";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { ResponseGetInfo } from "src/app/Models/output.model/ResponseGetInfo";
import { AppConfiguration, AppConfig } from "src/configuration";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class ProfileService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  GetInfo(RequestBase: RequestBase, token): Observable<ResponseGetInfo> {
    return this.http
      .post<ResponseGetInfo>(
        this.appConfig.TeacherUrl + "Profile/GetInfo",
        RequestBase,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  UpdateInfo(data, token): Observable<ResponseGetInfo> {
   
    return this.http
      .post<ResponseGetInfo>(
        this.appConfig.TeacherUrl + "Profile/UpdateInfo",
        data,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
    // try {
    //   $.ajax({
    //     type: "POST",
    //     url: this.appConfig.TeacherUrl + "Profile/UpdateInfo",
    //     dataType: "json",
    //     async: false,
    //     data: {
    //       'UserName': UserName,
    //       'Data': data,
    //     },
    //     beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + token) },
    //     success: function (data) {
    //       m = data;
    //     },
    //     error: function (err) {
    //       if(err.status ==401){
    //         m.Status = 401;
    //       }
    //       m.Status = 3;
    //     },
    //   });
    // }
    // catch (err) {
    //   m.Status = 3;
    // }
    // finally {
    //   return m;
    // }
  }
}
