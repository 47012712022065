import { Injectable, Inject } from "@angular/core";
import { AppConfig, AppConfiguration } from "src/configuration";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { environment } from "src/environments/environment";
import { map, filter, catchError } from "rxjs/operators";
import {
  ResponseClassListAll,
  ResponseStudentsClass,
  ResponseClassListAllOnline,
} from "src/app/Models/output.model/ResponseClassListAll";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class DeclarationV2Service extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  getList(token: any, ID_cb: number): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl + "DeclarationV2/Load_List?ID_gv=" + ID_cb,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  Update(request: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "DeclarationV2/Update ", request, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  Delete(ID_cv_gd: number, token: any): Observable<any> {
    return this.http
      .delete<any>(
        this.appConfig.TeacherUrl + "DeclarationV2/Delete?ID_cv_gd=" + ID_cv_gd,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  Insert(request: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "DeclarationV2/Insert", request, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  ThoiGianKeKhai(token: any): Observable<any> {
    return this.http
      .get<any>(this.appConfig.TeacherUrl + "DeclarationV2/ThoiGianKeKhai", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  KeKhaiGioGiang(token: any): Observable<any> {
    return this.http
      .get<any>(this.appConfig.TeacherUrl + "DeclarationV2/KeKhaiGioGiang", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getDanhSachLop(
    req: any,
    UserName: any,
    ID_mon: any,
    token: any
  ): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl +
          "DeclarationV2/DanhSachLop?UserName=" +
          UserName +
          "&ID_mon=" +
          ID_mon,
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getDanhSachLop_LoadAll(
    req: any,
    ID_mon: any,
    token: any
  ): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl +
          "DeclarationV2/DanhSachLop_LoadAll?ID_mon=" + ID_mon,
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getListMonHoc(req: any, token: any): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "DeclarationV2/MonHoc", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getQuyDinhKeKhai(req: any, token: any): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl + "DeclarationV2/QuyDinhKeKhai?Hoc_ky=" + req.Hoc_ky + "&Nam_hoc=" + req.Nam_hoc,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getQuyDinhDinhMuc(req: any, token: any): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl + "DeclarationV2/QuyDinhDinhMuc?ID_gv=" + req.ID_gv + "&Nam_hoc=" + req.Nam_hoc,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  Export_KeKhaiGiangDay(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "BaoCao/ExportToExcel_KeKhaiGiangDay",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getHe(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "He/Danh_sach_he", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getKhoa(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Khoa/Danh_sach_khoa", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getKhoaHoc(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/KhoaHoc_Load_List", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getSoSv(ID_lop, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl + "DeclarationV2/SoSinhVien?ID_lop=" + ID_lop,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}
