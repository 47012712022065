import { RequestBase } from './RequestBase';
export class RequestSendMessage extends RequestBase {
    Title: string;
    Content: string;
    SendDate: Date;
    FromUser: string;
    Attachment: string;
    ToUsers: string[];
    StringToUsers: string;
    FileBase64: string;
    Email_send: string;
}