<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-8">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/attendence']">Điểm danh</a></li>
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/ds-diem-danh-theo-tiet']"
                            [queryParams]="{IDLop: IDLop,Loai_lop:Loai_lop,ID_mon:ID_mon,Ten_mon:TitileTenMon,Ten_lop:TitleTenLop}"
                            queryParamsHandling="merge">Điểm danh lớp kế hoạch</a></li>
                    <li class="breadcrumb-item active">Cập nhật ngày điểm danh</li>
                </ol>
            </div>
            <div class=" col-md-4">
                <button class="btn btn-sm btn-success" (click)="Save()" style="float: right;margin-left: 5px;"><i
                        class="fas fa-save"></i> Lưu</button>
   
            </div>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body" style="padding: 0px 20px 0px 20px;">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row pt10">
                        <div class="col-md-8">
                            <label class="col-md-12">Môn học : {{TitileTenMon}}</label>
                        </div>
                        <div class="col-md-4">
                            <label class="col-md-12">Lớp : {{TitleTenLop}}</label>
                        </div>
                        <!-- <div class="col-md-12">
                            <label class="col-md-12">Ngày điểm danh : {{Ngay_thang | date:'dd/MM/yyyy'}}</label>
                        </div> -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <form class="form-horizontal" [formGroup]="DiemDanh">
                <div class="card-header p-2 " style="align-content:flex-end ">
                    <div class="row" style="padding-left:10px; padding-right:10px">
                        <div class="col-md-3">
                            <label class="col-md-10">Ngày giảng dạy</label>
                            <input class="form-control" readonly type="date" formControlName="Ngay_thang">
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-12">Ca học</label>
                            <select class="form-control" disabled formControlName="Ca_hoc">
                                <option value="0">Sáng</option>
                                <option value="1">Chiều</option>
                                <option value="2">Tối</option>
                                <option value="-1">Cả ngày</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <!-- <table datatable id="classTable" [dtOptions]="dtOptions" class="row-border hover"></table> -->
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData;let i = index">
                                            <td>{{i+1  }}</td>
                                            <td>{{ p.ID_sv }}</td>
                                            <td>{{ p.ID_dt }}</td>
                                            <td>{{ p.Ma_sv }}</td>
                                            <td>{{ p.Ho_ten }}</td>
                                            <td>{{ p.Ngay_sinh }}</td>
                                            <td>{{ p.Gioi_tinh }}</td>
                                            <td>{{ p.Ten_khoa }}</td>
                                            <td>{{ p.ID_lop }}</td>
                                            <td>
                                                <input type="number" min=0 max="30"  id="{{'So_tiet_ly_thuyet'+p.ID_sv}}" value="{{ p.So_tiet_ly_thuyet ? p.So_tiet_ly_thuyet : 0 }}" />
                                            </td>
                                             <td>
                                                <input type="number" min=0 max="30"  id="{{'So_tiet_thuc_hanh'+p.ID_sv}}" value="{{ p.So_tiet_thuc_hanh ? p.So_tiet_thuc_hanh : 0  }}" />
                                            </td>
                                            <td>
                                                <input type="text" id="{{'Ghi_chu'+p.ID_sv}}" value="{{ p.Ghi_chu }}" />
                                            </td>
                                            <td>
                                                <ng-container *ngIf="p.Not_Locked==0">
                                                    <input type="radio" id="{{'Not_Locked'+p.Ma_sv}}"
                                                        name="{{p.Ma_sv+'Not_Locked'}}" value="{{p.Not_Locked}}">
                                                </ng-container>
                                                <ng-container *ngIf="p.Not_Locked==1">
                                                    <input type="radio" id="{{'Not_Locked'+p.Ma_sv}}"
                                                        name="{{p.Ma_sv+'Not_Locked'}}" value="{{p.Not_Locked}}"
                                                        checked>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <!-- /.card-body -->
                            </div>

                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>