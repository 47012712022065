import { Component, OnInit, ViewChild, Input, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
// import { NgxSpinnerService } from 'ngx-spinner';
import { formatValidator } from '@angular-devkit/schematics/src/formats/format-validator';
import { forEach } from '@angular-devkit/schematics';
import { timeout } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { TimeSwapService } from 'src/app/Utils/service/time-swap.service';
import { ClassService } from 'src/app/Utils/service/class.service';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { TeachingPlanService } from 'src/app/Utils/services/teaching-plan.service';
import { ExportService } from 'src/app/Utils/service/export.service';

@Component({
  selector: 'app-report-statistic',
  templateUrl: './report-statistic.component.html',
  styleUrls: ['./report-statistic.component.scss']
})
export class ReportStatisticComponent implements OnInit {
  Filter = new FormGroup({
    Hoc_ky_ft: new FormControl(1),
    Nam_hoc_ft: new FormControl(new Date().getFullYear() + '-' + (new Date().getFullYear() + 1)),
    Ten_lop_ft: new FormControl(''),
    Ten_mon_ft: new FormControl(''),
  });
  requestObject: RequestBase = new RequestBase()
  Id_cb: number
  Nam_hoc_Arr: any = [];
  ClassData: any;
  SubjectData: any;
  Token: string;
  dtClass: any;
  DataReport: any = [];
  Title: string;
  TitleClass: string;
  dtLstClass: any;
  constructor(
    public router: Router,
    private ClassService: ClassService,
    private toastr: ToastrService,
    private TeachingPlanService: TeachingPlanService,
    private exportService: ExportService
  ) { }
  public com: common;
  ngOnInit() {

    this.DataReport = [{
      id: 1,
      name: 'Báo cáo kế hoạch giảng viên'
    }]
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.access_token
    this.requestObject.UserName = a.Info.UserName;
    this.Id_cb = a.Info.UserID;
    var yearNow = new Date().getFullYear();
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1)
      let object = {
        'value': Nam_hoc_string,
        'name': Nam_hoc_string
      }
      this.Nam_hoc_Arr.push(object)
      yearNow--
    }
    this.getListClass()
  }

  changeFilter() {
    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.destroy();
    // });
    this.ClassData = this.dtClass.filter(x => {
      return x.Nam_hoc == (this.Filter.value.Nam_hoc_ft == '' ? new Date().getFullYear() + '-' + (new Date().getFullYear() + 1) : this.Filter.value.Nam_hoc_ft) && x.Hoc_ky == (this.Filter.value.Hoc_ky_ft == '' ? 1 : this.Filter.value.Hoc_ky_ft)
    })
    this.SubjectData = this.ClassData


  }

  getListClass() {
    this.ClassService.getClass(this.requestObject, this.Token)
      .subscribe(z => {
        this.dtClass = z.Data
      });
  }

  ExportTOExcel(id) {
    let r = confirm("Bạn có muốn tải xuống file không?")
    if (r) {

      if (this.ClassData) {
        this.dtLstClass = this.ClassData.find(x => x.ID_mon == this.Filter.value.Ten_mon_ft && x.ID_lop == this.Filter.value.Ten_lop_ft )
      }
      this.Title = "Đang tạo file dữ liệu, vui lòng chờ"
      this.TitleClass = "spinner-border text-muted"
      let req = {
        ID_kh_giang_day_giang_vien: 0,
        Hoc_ky: this.Filter.value.Hoc_ky_ft,
        Nam_hoc: this.Filter.value.Nam_hoc_ft,
        ID_mon: this.Filter.value.Ten_mon_ft,
        ID_lop: this.Filter.value.Ten_lop_ft,
        ID_cb: this.Id_cb,
        Ten_lop: this.dtLstClass.Ten_lop,
        Ten_mon: this.dtLstClass.Ten_mon,
        LT: this.dtLstClass.Ly_thuyet,
        TH: this.dtLstClass.Thuc_hanh,
      }
      if (id == 1) {
        this.TeachingPlanService.TeachingPlanExport(req, this.Token)
          .subscribe(z => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(z.FileData, 'KeHoachGiangVien_' + this.requestObject.UserName + '_' + this.requestObject.RequestID);

              this.toastr.success("File đã được tải xuống", "Thao tác thành công")
            }
            else {
              this.toastr.error(z.Message, "Thao tác thất bại")
            }
            this.Title = "Danh sách điểm"
            this.TitleClass = ""
          });
      }
      else {

      }

    }
  }
}
