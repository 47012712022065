import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppConfiguration, AppConfig } from "src/configuration";
@Injectable({
  providedIn: "root",
})
export class CasService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {}

  validate(ticket): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.UtilsUrl + "/cas/validateTicketTeacher?ticket=" + ticket+"&service="+this.appConfig.CAS.Service,
        {}
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  refreshToken(refresh_token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "/SSO/RefreshToken?refresh_token=" +
          refresh_token,
        {}
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  RedirectCAS() {
    window.location.href =
      this.appConfig.CAS.ServerUrl +
      "cas/login" +
      "?service=" +
      this.appConfig.CAS.Service;
  }

  
}
