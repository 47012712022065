import { Component, Inject, OnInit } from "@angular/core";
import {
  RequestBase,
  RequestStudentsCreditList,
} from "src/app/Models/input.model/RequestBase";
import { MarkService } from "src/app/Utils/service/mark.service";
import { common } from "src/app/app.common";
import { Router, ActivatedRoute } from "@angular/router";
import { LooseObject } from "src/app/Models/output.model/ResponseBase";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ExportService } from "src/app/Utils/service/export.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { AppConfig, AppConfiguration } from "src/configuration";
@Component({
  selector: "app-diem-qua-trinh",
  templateUrl: "./diem-qua-trinh.component.html",
  styleUrls: ["./diem-qua-trinh.component.scss"],
})
export class DiemQuaTrinhComponent implements OnInit {
  currentInput: any;
  submitted = false;
  closeResult: string;
  ClassData: any;
  dtOptions: DataTables.Settings;
  obj: LooseObject = {};
  StudentsMark: any;
  Token: String;
  UserName: String;
  ID_lop_tc: any;
  ID_mon: any;
  Hoc_ky: any;
  params: any;
  Nam_hoc: string;
  DiemThanhPhans: any;
  allDiemTP: any;
  sinhVienFil: any = "";
  dtTrigger = new Subject();
  Title: string = "Đang tải danh sách điểm";
  TitleClass: string = "spinner-border text-muted cus_width";
  CategoryID: number;
  TitleLop: string;
  IsDone: boolean;
  Lan_hoc: number = 1;
  requestObject: RequestStudentsCreditList = new RequestStudentsCreditList();
  TitleMon: any;
  TitleNamHoc: any;
  TitleHocKy: any;
  TitleID_lop: any;
  TitleID_he: any;
  Roles: any;
  dtThamSoHeThong: any;
  Hien_thi_cot_khong_thi_lan1: number = 0;
  Hien_thi_cot_Ly_do_hoc_lai: number = 0;
  Hien_thi_cot_so_tiet_nghi_lt: number = 1;
  Hien_thi_cot_so_tiet_nghi_th: number = 0;
  Hien_thi_cot_thieu_bai_th: number = 0;
  Hien_thi_cot_TBCBP: number = 0;
  Hien_thi_khong_du_dieu_kien_thi: number = 0;
  Diem_qua_trinh_cho_phep_nhap_ky_hieu_thi: number = 0;
  checkboxNoLabel: boolean;
  CongBoDiemDiemEnable: boolean = false;
  ImportDiemEnable: boolean = false;
  checkbox: boolean;
  // Setting
  hien_thi_check_box_sinh_vien_hoc_lai: boolean = true;
  hien_thi_combo_box_loc_sinh_vien: boolean = true;
  hien_thi_combo_box_lan_hoc: boolean = true;
  //
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    public route: ActivatedRoute,
    private MarkService: MarkService,
    public router: Router,
    private toastr: ToastrService,
    private exportService: ExportService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {}
  public com: common;
  ngOnInit() {
    this.hien_thi_check_box_sinh_vien_hoc_lai = this.appConfig.setting.diem_qua_trinh.hien_thi_check_box_sinh_vien_hoc_lai;
    this.hien_thi_combo_box_loc_sinh_vien= this.appConfig.setting.diem_qua_trinh.hien_thi_combo_box_loc_sinh_vien;
    this.hien_thi_combo_box_lan_hoc= this.appConfig.setting.diem_qua_trinh.hien_thi_combo_box_lan_hoc ;
    this.checkboxNoLabel = false;
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.access_token;
    this.UserName = a.Info.UserName;
    this.Roles = a.Roles;
    this.ImportDiemEnable = this.Roles.some((x) => x.ID_quyen == 1325);
    this.CongBoDiemDiemEnable = this.Roles.some((x) => x.ID_quyen == 1324);
    this.route.queryParams.subscribe((params) => {
      this.params = params;
      this.requestObject.RequestID = params.ID;
      this.requestObject.ID_mon = params.ID_mon;
      this.CategoryID = params.CategoryID;
      this.ID_mon = params.ID_mon;
      this.TitleLop = params.Ten_lop;
      this.TitleMon = params.Ten_mon;
      this.TitleHocKy = params.Hoc_ky;
      this.TitleNamHoc = params.Nam_hoc;
      this.TitleID_lop = params.ID_lop;
      this.TitleID_he = params.ID_he;
    });
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 500,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "ID_sv",
          visible: false,
        },
        {
          title: "ID_dt",
          visible: false,
        },
        {
          title: "Mã SV",
        },
        {
          title: "Họ tên",
        },
        {
          title: "Ngày sinh",
          className: "dt-center",
        },
        {
          title: "Giới tính",
        },
        {
          title: "Tên khoa",
        },
        {
          title: "ID_lop",
          visible: false,
        },
      ],
    };
    this.requestObject.UserName = a.Info.UserName;

    let req = {
      RequestID: this.requestObject.RequestID,
      ID_mon: this.requestObject.ID_mon,
      UserName: this.UserName,
      LanHoc: this.Lan_hoc,
      HocKy: this.TitleHocKy,
      NamHoc: this.TitleNamHoc,
      Sap_xep: 1,
    };

    this.GetThamSoHeThong();

    if (this.CategoryID == 2) {
      this.ClassCreditGetInfo(req, this.Token);
    } else {
      this.ClassNienCheGetInfo(req, this.Token);
    }
  }
  Save() {
    this.spinner.show();
    var checkdt = this.CheckKQ(this.ClassData.Data);
    if (checkdt == "") {
      this.Title = "Đang lưu điểm, vui lòng chờ";
      this.TitleClass = "spinner-border text-muted";
      var m = this.GetKetQua(this.ClassData.Data);
      let req = {
        Data: m,
        RequestID: this.requestObject.RequestID,
        ID_mon: this.requestObject.ID_mon,
        UserName: this.UserName,
        LanHoc: this.Lan_hoc,
        HocKy: this.TitleHocKy,
        NamHoc: this.TitleNamHoc,
      };
      if (this.CategoryID == 2) {
        this.MarkCreditSave(req, this.Token);
      } else {
        this.MarkNienCheSave(req, this.Token);
      }
    } else {
      alert(
        "Vui lòng kiểm tra lại dữ liệu \n" +
          checkdt +
          "\n" +
          "Điểm phải là số 0<= điểm <=10"
      );
      this.spinner.hide();
    }
  }
  StudentFil() {
    if (this.sinhVienFil == "" || this.sinhVienFil == undefined) {
      this.ClassData.Data = this.allDiemTP;
    } else {
      this.ClassData.Data = this.allDiemTP.filter(
        (x: any) => x.Ma_sv == this.sinhVienFil
      );
    }
    this.GetStudentsMark(this.ClassData);
  }
  ClassCreditGetInfo(req: any, token: any) {
    this.spinner.show();
    this.MarkService.StudentsCredit_LoadList(req, token).subscribe(
      (z) => {
        this.ClassData = z;
        this.allDiemTP = z.Data;
        this.DiemThanhPhans = z.DiemThanhPhans;
        for (let index = 0; index < this.ClassData.Data.length; index++) {
          if (this.ClassData.Data[index].SoTietNghi == null) {
            this.ClassData.Data[index].SoTietNghi = "";
          }
          for (var j = 0; j < this.ClassData.Data[index].Diems.length; j++) {
            if (this.ClassData.Data[index].Diems[j].Diem == null) {
              this.ClassData.Data[index].Diems[j].Diem = "";
            }
          }
        }
        this.StudentFil();
        this.Title = "Danh sách điểm lớp: " + this.ClassData.Lop.Ten_lop;
        this.TitleClass = "";
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  ClassNienCheGetInfo(req: any, token) {
    this.spinner.show();

    this.MarkService.StudentsNienChe_LoadList(req, token).subscribe(
      (z) => {
        var array = z.Data.map((x) => x.ID_sv);
        this.allDiemTP = z.Data;
        var data = z;
        if (this.Lan_hoc > 1 && this.checkboxNoLabel == true) {
          let req2 = {
            RequestID: this.requestObject.RequestID,
            ID_mon: this.requestObject.ID_mon,
            UserName: this.UserName,
            LanHoc: this.Lan_hoc - 1,
            Checked: this.checkboxNoLabel,
            HocKy: this.TitleHocKy,
            NamHoc: this.TitleNamHoc,
            Sap_xep: 1,
          };
          this.MarkService.StudentsNienChe_LoadList(req2, this.Token).subscribe(
            (oldData) => {
              var dataOld = oldData.Data.filter((x) => array.includes(x.ID_sv));
              data.Data.forEach((itemNew, index) => {
                if (itemNew.Diems.every((x) => x.Diem == "")) {
                  data.Data[index] = dataOld.filter(
                    (x) => x.ID_sv == itemNew.ID_sv
                  )[0];
                }
              });
              //  console.log("123zxc",oldData.Data.filter(x=>array.includes(x.ID_sv)))
              //   data.Data = oldData.Data.filter(x=>array.includes(x.ID_sv))
              this.ClassData = data;
              this.DiemThanhPhans = z.DiemThanhPhans;
              for (let index = 0; index < this.ClassData.Data.length; index++) {
                if (this.ClassData.Data[index].SoTietNghi == null) {
                  this.ClassData.Data[index].SoTietNghi = "";
                }
                for (
                  var j = 0;
                  j < this.ClassData.Data[index].Diems.length;
                  j++
                ) {
                  if (this.ClassData.Data[index].Diems[j].Diem == null) {
                    this.ClassData.Data[index].Diems[j].Diem = "";
                  }
                }
              }
              this.GetStudentsMark(this.ClassData);
              if (
                z.Message ==
                "Bạn chỉ có thể nhập điểm thành phần trước ngày thi 7 ngày"
              ) {
                this.toastr.warning(z.Message);
              }
            }
          );
        }
        if (z.Status == 1) {
          this.ClassData = data;
          this.DiemThanhPhans = z.DiemThanhPhans;
          for (let index = 0; index < this.ClassData.Data.length; index++) {
            if (this.ClassData.Data[index].SoTietNghi == null) {
              this.ClassData.Data[index].SoTietNghi = "";
            }
            for (var j = 0; j < this.ClassData.Data[index].Diems.length; j++) {
              if (this.ClassData.Data[index].Diems[j].Diem == null) {
                this.ClassData.Data[index].Diems[j].Diem = "";
              }
            }
          }
          this.GetStudentsMark(this.ClassData);
          if (
            z.Message ==
            "Bạn chỉ có thể nhập điểm thành phần trước ngày thi 7 ngày"
          ) {
            this.toastr.warning(z.Message);
          }
        } else {
          this.toastr.error(z.Message);
        }

        this.Title = "Danh sách điểm lớp: " + this.ClassData.Lop.Ten_lop;
        this.TitleClass = "";
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  MarkCreditSave(req: any, token) {
    this.spinner.show();
    this.MarkService.MarkCreditSave(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success("Điểm đã được lưu", "Thao tác thành công");
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }

      this.Title = "Danh sách điểm";
      this.TitleClass = "";
      let req = {
        RequestID: this.requestObject.RequestID,
        ID_mon: this.requestObject.ID_mon,
        UserName: this.UserName,
        LanHoc: this.Lan_hoc,
        HocKy: this.TitleHocKy,
        NamHoc: this.TitleNamHoc,
        Sap_xep: 1,
      };
      if (this.CategoryID == 2) {
        this.ClassCreditGetInfo(req, this.Token);
      } else {
        this.ClassNienCheGetInfo(req, this.Token);
      }
    });
  }
  MarkNienCheSave(req: any, token) {
    this.spinner.show();

    this.MarkService.MarkNienCheSave(req, token).subscribe((z) => {
      this.spinner.hide();
      if (z.Status == 1) {
        this.toastr.success("Điểm đã được lưu", "Thao tác thành công");
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.Title = "Danh sách điểm";
      this.TitleClass = "";
      let req = {
        RequestID: this.requestObject.RequestID,
        ID_mon: this.requestObject.ID_mon,
        UserName: this.UserName,
        LanHoc: this.Lan_hoc,
        Checked: this.checkboxNoLabel,
        HocKy: this.TitleHocKy,
        NamHoc: this.TitleNamHoc,
      };
      if (this.CategoryID == 2) {
        this.ClassCreditGetInfo(req, this.Token);
      } else {
        this.ClassNienCheGetInfo(req, this.Token);
      }
    });
  }
  MarkKhoaDiem(req: any, token) {
    this.spinner.show();

    this.MarkService.MarkKhoaDiem(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message, "Thao tác thành công");
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.Title = "Danh sách điểm";
      this.TitleClass = "";
      let req = {
        RequestID: this.requestObject.RequestID,
        ID_mon: this.requestObject.ID_mon,
        UserName: this.UserName,
        LanHoc: this.Lan_hoc,
        HocKy: this.TitleHocKy,
        NamHoc: this.TitleNamHoc,
      };
      if (this.CategoryID == 2) {
        this.ClassCreditGetInfo(req, this.Token);
      } else {
        this.ClassNienCheGetInfo(req, this.Token);
      }
      this.spinner.hide();
    });
  }
  KhoaDiem() {
    let r = confirm("Bạn có muốn khóa điểm quá trình không?");
    if (r) {
      this.Title = "Đang khóa điểm, vui lòng chờ";
      this.TitleClass = "spinner-border text-muted";
      let data = this.GetDataKhoaDiem(this.ClassData.Data);
      let inputData = {
        RequestData: data,
        RequestID: this.requestObject.RequestID,
        ID_mon: this.requestObject.ID_mon,
        UserName: this.UserName,
        LanHoc: this.Lan_hoc,
        HocKy: this.TitleHocKy,
        NamHoc: this.TitleNamHoc,
      };
      this.MarkKhoaDiem(inputData, this.Token);
    }
  }
  GetDataKhoaDiem(n) {
    var m = [];
    for (var i = 0; i < n.length; i++) {
      if (!n[i].Lock) {
        var KhoaDiem = {
          ID_diem: n[i].ID_diem,
          HocKy: this.TitleHocKy,
          NamHoc: this.TitleNamHoc,
          Lan_hoc: this.Lan_hoc,
          Locked: 1,
        };
        m.push(KhoaDiem);
      }
    }
    return m;
  }
  GetStudentsMark(m) {
    this.StudentsMark = [];

    for (let index = 0; index < m.Data.length; index++) {
      this.obj = {};
      this.obj.ID_sv = m.Data[index].ID_sv;
      this.obj.ID_dt = m.Data[index].ID_dt;
      this.obj.ID_mon = m.Data[index].ID_mon;
      this.obj.ID_lop = m.Data[index].ID_lop;
      this.obj.ID_dv = m.Data[index].ID_dv;
      this.obj.idx_diem = m.Data[index].idx_diem;
      this.obj.idx_diem_thi = m.Data[index].idx_diem_thi;
      this.obj.Edited = m.Data[index].Edited;
      this.obj.ID_diem = m.Data[index].ID_diem;
      this.obj.idx_diem_danh = m.Data[index].idx_diem_danh;
      this.obj.So_hoc_trinh_thuc_hanh = m.Data[index].So_hoc_trinh_thuc_hanh;
      this.obj.So_hoc_trinh = m.Data[index].So_hoc_trinh;
      this.obj.Mon_ly_thuyet_thuc_hanh = m.Data[index].Mon_ly_thuyet_thuc_hanh;
      this.obj.So_tiet = m.Data[index].So_tiet;
      this.obj.Diem_thi = m.Data[index].Diem_thi;
      this.obj.TBCHP = m.Data[index].TBCHP;
      this.obj.Ma_sv = m.Data[index].Ma_sv;
      this.obj.Ho_ten = m.Data[index].Ho_ten;
      if (m.Data[index].Ngay_sinh == null) {
        this.obj.date = m.Data[index].Ngay_sinh;
      } else {
        this.obj.date = m.Data[index].Ngay_sinh.toString().split("T");
        this.obj.dateime = this.obj.date[0].split("-");
        this.obj.Ngay_sinh =
          this.obj.dateime[2] +
          "/" +
          this.obj.dateime[1] +
          "/" +
          this.obj.dateime[0];
      }

      this.obj.Gioi_tinh = m.Data[index].Gioi_tinh;
      this.obj.Ten_lop = m.Data[index].Ten_lop;
      this.obj.Ten_khoa = m.Data[index].Ten_khoa;
      this.obj.Ghi_chu = m.Data[index].Ghi_chu;
      this.obj.ID_lop_tc = m.Data[index].ID_lop_tc;
      this.obj.SoTietNghi = m.Data[index].SoTietNghi;
      this.obj.Lock = m.Data[index].Lock;
      this.obj.LockDiemThi = m.Data[index].LockDiemThi;
      for (let j = 0; j < m.DiemThanhPhans.length; j++) {
        this.obj[m.Data[index].Diems[j].ID_Diem] = m.Data[index].Diems[j].Diem;
      }
      this.StudentsMark.push(this.obj);
    }
    this.dtTrigger.next();
    this.RenderData(this.ClassData.Data, this.DiemThanhPhans);
  }
  GetKetQua(n) {
    var m = n;
    for (var i = 0; i < m.length; i++) {
      if (!m[i].Lock) {
        for (var j = 0; j < m[i].Diems.length; j++) {
          var diem = $("#" + m[i].ID_sv + m[i].Diems[j].ID_Diem + "").val();
          if (diem >= 0 && diem <= 10) {
            m[i].Diems[j].Diem = diem;
          }
          var diemdanh = $("#SoTietNghi" + i + "").val();
          var diemdanhTH = $("#SoTietNghiTH" + i + "").val();
          var Khong_thi_lan = $("#Khong_thi_lan" + i + "").is(":checked");
          var Thieu_bai_thuc_hanh = $("#Thieu_bai_thuc_hanh" + i + "").is(
            ":checked"
          );
          m[i].Khong_thi_lan = Khong_thi_lan;
          m[i].Thieu_bai_thuc_hanh = Thieu_bai_thuc_hanh;
          if (diemdanh >= 0) {
            m[i].SoTietNghi = diemdanh;
          }
          if (diemdanhTH >= 0) {
            m[i].SoTietNghiTH = diemdanhTH;
          }
        }
      }
    }
    return m;
  }
  CheckKQ(n) {
    var m = n;
    var rs = false;
    var stringrs = "";
    var rsdt = false;
    for (var i = 0; i < m.length; i++) {
      rs = false;
      if (!m[i].Lock) {
        let diemdanh = $("#SoTietNghi" + i + "").val();
        if ((diemdanh >= 0 && diemdanh.toString().indexOf(".") === -1) || diemdanh === undefined) {
          rs = false;
        } else {
          rs = true;
        }
        for (var j = 0; j < m[i].Diems.length; j++) {
          var diem = $("#" + m[i].ID_sv + m[i].Diems[j].ID_Diem + "").val();
          if ((diem >= 0 && diem <= 10) || diem == 0) {
          } else {
            rs = true;
          }
        }
        if (rs) {
          stringrs += i + 1 + ": " + m[i].Ho_ten + " \n ";
        }
      }
    }
    return stringrs;
  }
  RenderData(m, tp) {
    var stringHtml = "";
    for (var i = 0; i < this.dtThamSoHeThong.length; i++) {
      if (this.dtThamSoHeThong[i].ID_tham_so == "Hien_thi_cot_khong_thi_lan1") {
        this.Hien_thi_cot_khong_thi_lan1 = parseInt(
          this.dtThamSoHeThong[i].Gia_tri
        );
      }
      if (this.dtThamSoHeThong[i].ID_tham_so == "Hien_thi_cot_Ly_do_hoc_lai") {
        this.Hien_thi_cot_Ly_do_hoc_lai = parseInt(
          this.dtThamSoHeThong[i].Gia_tri
        );
      }
      if (
        this.dtThamSoHeThong[i].ID_tham_so == "Hien_thi_khong_du_dieu_kien_thi"
      ) {
        this.Hien_thi_khong_du_dieu_kien_thi = parseInt(
          this.dtThamSoHeThong[i].Gia_tri
        );
      }
      if (
        this.dtThamSoHeThong[i].ID_tham_so == "Hien_thi_cot_so_tiet_nghi_lt"
      ) {
        this.Hien_thi_cot_so_tiet_nghi_lt = parseInt(
          this.dtThamSoHeThong[i].Gia_tri
        );
      }
      if (
        this.dtThamSoHeThong[i].ID_tham_so == "Hien_thi_cot_so_tiet_nghi_th"
      ) {
        this.Hien_thi_cot_so_tiet_nghi_th = parseInt(
          this.dtThamSoHeThong[i].Gia_tri
        );
      }
      if (this.dtThamSoHeThong[i].ID_tham_so == "Hien_thi_cot_thieu_bai_th") {
        this.Hien_thi_cot_thieu_bai_th = parseInt(
          this.dtThamSoHeThong[i].Gia_tri
        );
      }

      if (this.dtThamSoHeThong[i].ID_tham_so == "Hien_thi_cot_TBCBP") {
        this.Hien_thi_cot_TBCBP = parseInt(this.dtThamSoHeThong[i].Gia_tri);
      }

      if (
        this.dtThamSoHeThong[i].ID_tham_so ==
        "Diem_qua_trinh_cho_phep_nhap_ky_hieu_thi"
      ) {
        this.Diem_qua_trinh_cho_phep_nhap_ky_hieu_thi = parseInt(
          this.dtThamSoHeThong[i].Gia_tri
        );
      }
    }
    stringHtml += ` <thead style=" position: sticky; top: 0; z-index: 1;background-color: #f2f2f2 "> <tr> <th style="text-align:center;padding:6px"> STT</th> 
    <th style="text-align:center;padding:6px"> Mã sinh viên</th>  
    <th style="text-align:center;padding:6px"> Họ tên</th> 
    <th style="text-align:center;padding:6px"> Giới tính</th>
    <th style="text-align:center;padding:6px"> Ngày sinh</th>`;
    if (this.Hien_thi_cot_Ly_do_hoc_lai == 1) {
      stringHtml += `<th  style="text-align:center;padding:6px">Lý do học lại</th>`;
    }
    if (this.Hien_thi_cot_so_tiet_nghi_lt == 1) {
      stringHtml += `<th style="text-align:center;padding:6px"> Số tiết nghỉ</th>`;
    }
    if (this.Hien_thi_cot_so_tiet_nghi_th == 1) {
      stringHtml += `<th style="text-align:center;padding:6px">Số tiết nghỉ TH</th> `;
    }
    if (this.Hien_thi_cot_khong_thi_lan1 == 1) {
      stringHtml += `<th style="text-align:center;padding:6px"> Không thi lần 1</th>  `;
    }
    if (this.Hien_thi_cot_thieu_bai_th == 1) {
      stringHtml += `<th style="text-align:center;padding:6px"> Thiếu bài TH</th>`;
    }
    stringHtml += `<th style="text-align:center;padding:6px"> Ký hiệu</th>`;
    if (this.Hien_thi_khong_du_dieu_kien_thi == 1) {
      stringHtml += `<th style="text-align:center;padding:6px"> Không đủ đk thi</th>`;
    }
    if (this.Hien_thi_cot_TBCBP == 1) {
      stringHtml += `<th style="text-align:center;padding:6px"> TBCBP</th>`;
    }
    for (var j = 0; j < tp.length; j++) {
      stringHtml +=
        ' <th style="text-align:center;padding:6px" >' +
        tp[j].Ten_Diem +
        " (" +
        tp[j].Ty_le_nhom +
        ")</th> ";
    }
    stringHtml += "</tr></thead>";
    stringHtml += "<tbody>";
    for (var i = 0; i < m.length; i++) {
      if (m[i].Ngay_sinh == null) {
        let Ngay_sinh = " ";
        stringHtml +=
          '<tr class="' +
          LockedClass +
          '">   <td> ' +
          (i + 1) +
          '</td> <td style="text-align:center;"> ' +
          m[i].Ma_sv +
          '</td><td style="width:20% !important"> ' +
          m[i].Ho_ten +
          '</td><td style="text-align:center;"> ' +
          m[i].Gioi_tinh +
          '</td><td style="text-align:center;"> ' +
          Ngay_sinh +
          "</td>";
      } else {
        let a = m[i].Ngay_sinh.split("T");
        let date = a[0].split("-");
        let Ngay_sinh = date[2] + "/" + date[1] + "/" + date[0];
        var Locked = "";
        var LockedClass = "";
        if (m[i].Lock) {
          Locked = "Readonly";
          LockedClass = "redclass";
        }
        stringHtml +=
          '<tr class="' +
          LockedClass +
          '">   <td> ' +
          (i + 1) +
          '</td> <td style="text-align:center;"> ' +
          m[i].Ma_sv +
          '</td><td style="width:20% !important"> ' +
          m[i].Ho_ten +
          '</td><td style="text-align:center;"> ' +
          m[i].Gioi_tinh +
          '</td><td style="text-align:center;"> ' +
          Ngay_sinh +
          "</td>";
        if (this.Hien_thi_cot_Ly_do_hoc_lai == 1) {
          stringHtml +=
            '<td style="text-align:center;"> ' + m[i].Ly_do_hoc_lai + "</td>";
        }
      }
      // stringHtml += '<td > ' + m[i].Ngay_sinh + '</td><td style="text-align:center;"> ' + m[i].Ten_lop + '</td>';
      var stn = "";
      var stnth = "";
      var TBCBP = "";
      var Khong_thi_lan = "";
      var Thieu_bai_thuc_hanh = "";
      var Ky_hieu = " ";
      var Khong_du_dieu_kien_thi = "";
      if (m[i].SoTietNghi != null) {
        stn = m[i].SoTietNghi;
      }
      if (m[i].SoTietNghiTH != null) {
        stnth = m[i].SoTietNghiTH;
      }
      if (m[i].TBCBP != null) {
        TBCBP = m[i].TBCBP;
      }
      if (m[i].Ghi_chu != null) {
        Ky_hieu = m[i].Ghi_chu;
      }
      if (m[i].Khong_thi_lan) {
        Khong_thi_lan = "checked";
      }
      if (m[i].Thieu_bai_thuc_hanh) {
        Thieu_bai_thuc_hanh = "checked";
      }
      if (m[i].Khong_du_dieu_kien_thi != null) {
        Khong_du_dieu_kien_thi = m[i].Khong_du_dieu_kien_thi;
      }

      if (m[i].Lock) {
        //  stringHtml += ' <td> <span id="' + m[i].Ma_sv + diem[j].ID_Diem + '" data-nextfocus="' + m[(i + 1)].Ma_sv + diem[j].ID_Diem + '">' + diem[j].Diem + '</span> </td>'
        if (this.Hien_thi_cot_so_tiet_nghi_lt == 1) {
          stringHtml +=
            ' <td style="text-align:center;display"> <span id="SoTietNghi' +
            i +
            '" data-nextfocus="SoTietNghi' +
            (i + 1) +
            '" >' +
            stn +
            "</span> </td>";
        }
        if (this.Hien_thi_cot_so_tiet_nghi_th == 1) {
          stringHtml +=
            ' <td style="text-align:center;display"> <span id="SoTietNghiTH' +
            i +
            '" data-nextfocus="SoTietNghiTH' +
            (i + 1) +
            '" >' +
            stnth +
            "</span> </td>";
        }
        if (this.Hien_thi_cot_khong_thi_lan1 == 1) {
          stringHtml +=
            ' <td style="text-align:center;display"> <input   ' +
            Khong_thi_lan +
            ' disabled="disabled" style="width:50px;text-align:center;" ' +
            Locked +
            ' type="checkbox" id="Khong_thi_lan' +
            i +
            '" data-nextfocus="Khong_thi_lan' +
            (i + 1) +
            '"></td>';
        }
        if (this.Hien_thi_cot_thieu_bai_th == 1) {
          stringHtml +=
            ' <td style="text-align:center;display"> <input  ' +
            Thieu_bai_thuc_hanh +
            ' disabled="disabled" style="width:50px;text-align:center;" ' +
            Locked +
            ' type="checkbox" id="Thieu_bai_thuc_hanh' +
            i +
            '" data-nextfocus="Thieu_bai_thuc_hanh' +
            (i + 1) +
            '"></td>';
        }
        stringHtml +=
          ' <td style="text-align:center;display"> <span id="Ky_hieu' +
          i +
          '" data-nextfocus="Ky_hieu' +
          (i + 1) +
          '" >' +
          Ky_hieu +
          "</span> </td>";
        if (this.Hien_thi_khong_du_dieu_kien_thi == 1) {
          stringHtml +=
            ' <td style="text-align:center;display"> <span id="Khong_du_dieu_kien_thi' +
            i +
            '" data-nextfocus="Khong_du_dieu_kien_thi' +
            (i + 1) +
            '" >' +
            Khong_du_dieu_kien_thi +
            "</span> </td>";
        }
        if (this.Hien_thi_cot_TBCBP == 1) {
          stringHtml +=
            ' <td style="text-align:center;display"> <span id="TBCBP' +
            i +
            '" data-nextfocus="TBCBP' +
            (i + 1) +
            '" >' +
            TBCBP +
            "</span> </td>";
        }
      } else {
        if (this.Hien_thi_cot_so_tiet_nghi_lt == 1) {
          stringHtml +=
            ' <td style="text-align:center;"> <input style="width:50px;text-align:center;" ' +
            Locked +
            ' type="text" id="SoTietNghi' +
            i +
            '" data-nextfocus="SoTietNghi' +
            (i + 1) +
            '" value="' +
            stn +
            '"></td>';
        }
        if (this.Hien_thi_cot_so_tiet_nghi_th == 1) {
          stringHtml +=
            ' <td style="text-align:center;"> <input style="width:50px;text-align:center;" ' +
            Locked +
            ' type="text" id="SoTietNghiTH' +
            i +
            '" data-nextfocus="SoTietNghiTH' +
            (i + 1) +
            '" value="' +
            stnth +
            '"></td>';
        }
        if (this.Hien_thi_cot_khong_thi_lan1 == 1) {
          stringHtml +=
            ' <td style="text-align:center;"> <input   ' +
            Khong_thi_lan +
            ' style="transform: scale(1.7);text-align:center;" ' +
            Locked +
            ' type="checkbox" id="Khong_thi_lan' +
            i +
            '" data-nextfocus="Khong_thi_lan' +
            (i + 1) +
            '"></td>';
        }
        if (this.Hien_thi_cot_thieu_bai_th == 1) {
          stringHtml +=
            ' <td style="text-align:center;"> <input  ' +
            Thieu_bai_thuc_hanh +
            ' style="transform: scale(1.7);text-align:center;" ' +
            Locked +
            ' type="checkbox" id="Thieu_bai_thuc_hanh' +
            i +
            '" data-nextfocus="Thieu_bai_thuc_hanh' +
            (i + 1) +
            '"></td>';
        }

        // if (this.Diem_qua_trinh_cho_phep_nhap_ky_hieu_thi == 1) {
        //   stringHtml +=
        //     ' <td style="width:60px;text-align:center;"> <select style="font-size:13px;width:50px;text-align:center;" data-nextfocus="Ghi_chu' +
        //     (i + 1) +
        //     '" id="Ghi_chu' +
        //     i +
        //     '">';
        //   stringHtml += ' <option value="-1"> </option>';
        //   var selected = "";
        //   this.ClassData.KyHieus.forEach((element) => {
        //     if (m[i].Ghi_chu == element.Ky_hieu) {
        //       selected = "selected";
        //     } else {
        //       selected = "";
        //     }
        //     stringHtml +=
        //       " <option " +
        //       selected +
        //       ' value="' +
        //       element.Ky_hieu +
        //       '" title=" ' +
        //       element.Ten_ky_hieu +
        //       '" >' +
        //       element.Ky_hieu +
        //       "</option>";
        //   });
        //   stringHtml += "</select> </td>";
        // } else {
        //   stringHtml +=
        //     ' <td style="text-align:center;display"> <span id="Ky_hieu' +
        //     i +
        //     '" data-nextfocus="Ky_hieu' +
        //     (i + 1) +
        //     '" >' +
        //     Ky_hieu +
        //     "</span> </td>";
        // }

        stringHtml +=
          ' <td style="text-align:center;display"> <span id="Ky_hieu' +
          i +
          '" data-nextfocus="Ky_hieu' +
          (i + 1) +
          '" >' +
          Ky_hieu +
          "</span> </td>";
        if (this.Hien_thi_khong_du_dieu_kien_thi == 1) {
          stringHtml +=
            ' <td style="text-align:center;"> <span id="Khong_du_dieu_kien_thi' +
            i +
            '" data-nextfocus="Khong_du_dieu_kien_thi' +
            (i + 1) +
            '" >' +
            Khong_du_dieu_kien_thi +
            "</span> </td>";
        }
        if (this.Hien_thi_cot_TBCBP == 1) {
          stringHtml +=
            ' <td style="text-align:center;"> <span id="TBCBP' +
            i +
            '" data-nextfocus="TBCBP' +
            (i + 1) +
            '" >' +
            TBCBP +
            "</span> </td>";
        }

        // stringHtml +=
        //   ' <td style="text-align:center;"> <span id="TBCBP' +
        //   i +
        //   '" data-nextfocus="TBCBP' +
        //   (i + 1) +
        //   '" >' +
        //   TBCBP +
        //   "</span> </td>";
      }
      var diem = m[i].Diems;
      for (var j = 0; j < diem.length; j++) {
        if (i < m.length - 1) {
          if (m[i].Lock) {
            stringHtml +=
              ' <td style="text-align:center;display " > <span id="' +
              m[i].ID_sv +
              diem[j].ID_Diem +
              '" data-nextfocus="' +
              m[i + 1].ID_sv +
              diem[j].ID_Diem +
              '">' +
              diem[j].Diem +
              "</span> </td>";
          } else {
            stringHtml +=
              ' <td style="text-align:center;" > <input style="width:50px;text-align:center;"' +
              Locked +
              ' id="' +
              m[i].ID_sv +
              diem[j].ID_Diem +
              '" data-nextfocus="' +
              m[i + 1].ID_sv +
              diem[j].ID_Diem +
              '" type="text" value="' +
              diem[j].Diem +
              '"></td>';
          }
          // else
          // {
          //   if (m[i].Lock) {
          //     stringHtml += ' <td style="text-align:center;" > <span id="' + m[i].ID_sv + diem[j].ID_Diem + '" data-nextfocus="' + m[(i + 1)].ID_sv + diem[j].ID_Diem + '">' + diem[j].Diem + '</span> </td>'
          //   } else {
          //     stringHtml += ' <td style="text-align:center;" > <input style="width:50px;text-align:center;" disabled' + Locked + ' id="' + m[i].ID_sv + diem[j].ID_Diem + '" data-nextfocus="' + m[(i + 1)].ID_sv + diem[j].ID_Diem + '" type="text" value="' + diem[j].Diem + '"></td>'
          //   }
          // }
        } else {
          if (m[i].Lock) {
            stringHtml +=
              ' <td style="text-align:center;display" > <span  id="' +
              m[i].ID_sv +
              diem[j].ID_Diem +
              '">' +
              diem[j].Diem +
              "</span></td>";
          } else {
            stringHtml +=
              ' <td style="text-align:center;" > <input style="width:50px;text-align:center;" ' +
              Locked +
              ' id="' +
              m[i].ID_sv +
              diem[j].ID_Diem +
              '"  type="text" value="' +
              diem[j].Diem +
              '"></td>';
          }
        }
      }
      stringHtml += "</tr>";
    }
    stringHtml += "</tbody>";
    $("#lstStudents").html(stringHtml);
  }
  ExportTOExcel() {
    let r = confirm("Bạn có muốn tải xuống file dữ liệu điểm không?");
    if (r) {
      this.Title = "Đang tạo file dữ liệu, vui lòng chờ";
      this.TitleClass = "spinner-border text-muted";
      let req = {
        RequestID: this.requestObject.RequestID,
        ID_mon: this.requestObject.ID_mon,
        UserName: this.UserName,
        ID_lop: this.requestObject.RequestID,
        HocKy: this.TitleHocKy,
        NamHoc: this.TitleNamHoc,
        ID_he: this.TitleID_he,
        LanHoc: this.Lan_hoc,
      };
      if (this.CategoryID == 2) {
        this.DiemQuaTrinhCreditExport(req, this.Token);
      } else {
        this.DiemQuaTrinhNienCheExport(req, this.Token);
      }
    }
  }
  Print() {
    let r = confirm("Bạn có muốn in file dữ liệu điểm không?");
    if (r) {
      this.Title = "Đang lấy dữ liệu trước khi in, vui lòng chờ";
      this.TitleClass = "spinner-border text-muted";
      let req = {
        RequestID: this.requestObject.RequestID,
        UserName: this.UserName,
        LanHoc: this.Lan_hoc,
      };
      this.GetFilePrint(req, this.Token);
    }
  }

  DiemQuaTrinhCreditExport(req: any, token) {
    this.MarkService.DiemQuaTrinhCreditExport(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.exportService.exportExcelByte(
          z.FileData,
          "Bangtheodoi_" +
            this.requestObject.UserName +
            "_" +
            this.requestObject.RequestID
        );
        this.toastr.success("File đã được tải xuống", "Thao tác thành công");
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.Title = "Danh sách điểm";
      this.TitleClass = "";
    });
  }
  DiemQuaTrinhNienCheExport(req: any, token) {
    this.MarkService.DiemQuaTrinhNienCheExport(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.exportService.exportExcelByte(
          z.FileData,
          "DiemNienChe_" +
            this.requestObject.UserName +
            "_" +
            this.requestObject.RequestID
        );
        this.toastr.success("File đã được tải xuống", "Thao tác thành công");
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.Title = "Danh sách điểm";
      this.TitleClass = "";
    });
  }
  GetFilePrint(req: any, token) {
    this.MarkService.DiemQuaTrinhCreditExport(req, token).subscribe((z) => {
      if (z.Status == 1) {
        var data = this.exportService.GetBase64(
          z.FileData,
          "DiemQuaTrinh_" +
            this.requestObject.UserName +
            "_" +
            this.requestObject.RequestID
        );
        this.PrintData(data);
        //this.toastr.success("File đã được tải xuống", "Thao tác thành công")
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.Title = "Danh sách điểm";
      this.TitleClass = "";
    });
  }
  onKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      var $this = $(event.target);
      var index = $this.attr("data-nextfocus");
      $("#" + index + "").focus();
      $("#" + index + "").select();
    }
  }
  PrintData(m) {
    var winparams =
      "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
      "resizable,screenX=50,screenY=50,width=850,height=1050";

    var htmlPop =
      "<embed width=100% height=100%" +
      ' type="application/vnd.ms-excel; charset=UTF-8"' +
      ' src="data:application/vnd.ms-excel; charset=UTF-8;base64,' +
      escape(m) +
      '"></embed>';

    var printWindow = window.open("", "xlsx", winparams);
    printWindow.document.write(htmlPop);
    printWindow.print();
  }

  GetThamSoHeThong() {
    this.MarkService.GetThamSoHeThong(13, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.dtThamSoHeThong = z.Data;
      } else {
        this.toastr.error(z.Message, "Lỗi không lấy được tham số");
      }
    });
  }
  LanHocChange() {
    let req = {
      RequestID: this.requestObject.RequestID,
      ID_mon: this.requestObject.ID_mon,
      UserName: this.UserName,
      LanHoc: this.Lan_hoc,
      Checked: this.checkboxNoLabel,
      HocKy: this.TitleHocKy,
      NamHoc: this.TitleNamHoc,
    };
    if (this.CategoryID == 2) {
      this.ClassCreditGetInfo(req, this.Token);
    } else {
      this.ClassNienCheGetInfo(req, this.Token);
    }
  }
  open(content, size) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: size })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  ImportExcel() {
    if (!this.currentInput) {
      this.toastr.warning("Vui lòng chọn file import!");
      return;
    }
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.currentInput);
    formData.append("NamHoc", this.params.Nam_hoc);
    formData.append("ID_mon", this.params.ID_mon);
    formData.append("ID_lop_tc", this.params.ID);
    formData.append("Lan_hoc", this.Lan_hoc.toString());
    formData.append("Hoc_ky", this.params.Hoc_ky);
    formData.append("Loai_lop", this.CategoryID.toString());
    this.MarkService.Import(formData, this.Token).subscribe((z) => {
      this.spinner.hide();
      if (z.Status == 1) {
        this.modalService.dismissAll("ChooseFileImport");
        this.exportService.exportExcelByte(
          z.FileData,
          "KetQuaImport" +
            this.requestObject.UserName +
            "_" +
            this.requestObject.RequestID
        );
        this.toastr.success("File đã được tải xuống", "Thao tác thành công");
        let req = {
          RequestID: this.requestObject.RequestID,
          ID_mon: this.requestObject.ID_mon,
          UserName: this.UserName,
          LanHoc: this.Lan_hoc,
          HocKy: this.TitleHocKy,
          NamHoc: this.TitleNamHoc,
          Sap_xep: 1,
        };
        if (this.CategoryID == 2) {
          this.ClassCreditGetInfo(req, this.Token);
        } else {
          this.ClassNienCheGetInfo(req, this.Token);
        }
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
    });
  }
  onFileSelected(event) {
    this.currentInput = event.target.files[0];
  }

  Publish(CongBo: any) {
    var r = confirm(
      CongBo
        ? "Bạn có muốn công bố điểm cho sinh viên không?"
        : "Bạn có muốn huỷ công bố điểm cho sinh viên không?"
    );
    if (!r) {
      return;
    }
    this.spinner.show();
    var req = {
      LanHoc: this.Lan_hoc,
      HocKy: this.params.Hoc_ky,
      NamHoc: this.params.Nam_hoc,
      DiemThanhPhans: this.DiemThanhPhans,
      Data: this.ClassData.Data,
      CongBo: CongBo,
    };
    this.MarkService.PublishDiemThanhPhan(req, this.Token).subscribe((z) => {
      this.spinner.hide();
      if (z.Status == 1) {
        this.toastr.success(
          CongBo ? "Công bố điểm thành công" : "Huỷ công bố điểm thành công",
          "Thao tác thành công"
        );
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
    });
  }
}
