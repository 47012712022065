import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { common } from 'src/app/app.common';
import { ToastrService } from 'ngx-toastr';
import { Class } from 'src/app/Utils/services/Class.service';
import { ClassService } from 'src/app/Utils/service/class.service';
import { Subject } from 'rxjs';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ResponseStudentsClass, Student } from 'src/app/Models/output.model/ResponseClassListAll';
import { ExportService } from 'src/app/Utils/service/export.service';
@Component({
  selector: 'app-students-class',
  templateUrl: './students-class.component.html',
  styleUrls: ['./students-class.component.scss']
})
export class StudentsClassComponent implements OnInit {
  dtOptions: DataTables.Settings
  requestObject: RequestBase = new RequestBase()
  Res: ResponseStudentsClass = new ResponseStudentsClass()
  StudentsData: Student[]
  Title: string = "Đang tải danh sách sinh viên..."
  TitleClass: string = "spinner-border text-muted"
  TitleLop: any
  token: any;
  Loai:any
  constructor(
    public route: ActivatedRoute,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private exportService: ExportService,
    private ClassService: ClassService) { }
    private CategoryID: number
    private ID: number
    private Ten_lop: string
    dtTrigger = new Subject();
     public com: common;
     
  
  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        
        this.CategoryID = params.CategoryID
        this.ID = params.ID
        this.Ten_lop = params.Ten_lop
        this.Loai=this.CategoryID
      });
    this.TitleLop = this.Ten_lop
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [
        {
          title: 'ID',
          data: 'ID_sv',
          visible: false
        },
        {
          title: 'ID_dt',
          data: 'ID_dt',
          visible: false
        },
        {
          title: 'Mã SV',
          data: 'Ma_sv'
        },
        {
          title: 'Họ tên',
          data: 'Ho_ten'
        },
        {
          title: 'Ngày sinh',
          data: 'Ngay_sinh'
        },
        {
          title: 'Giới tính',
          className: "dt-center",
          data: 'Gioi_tinh'
        },
        {
          title: 'Tên lớp',
          className: "dt-center",
          data: 'Ten_lop'
        },
        {
          title: 'Tên khoa',
          data: 'Ten_khoa'
        },
        {
          title: 'ID_lop',
          data: 'ID_lop',
          visible: false
        },
        {
          title: 'Thao tác',
          className: "dt-center",
          width: "12%",
        },

      ],
    };
    //this.LanThi = parseInt($('#Lan_thi').children("option:selected").val().toString());
    if (this.CategoryID == 2) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.requestObject.UserName = a.Info.UserName
      this.requestObject.RequestID = this.ID
      this.getListStudents(this.requestObject, a.access_token)
    }
    else {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      // this.requestObject.UserName = a.Info.UserName
      // this.requestObject.ID_lop = this.ID
      let req = {
        UserName: a.Info.UserName,
        RequestID: this.ID
      }
      this.getListStudentsNienChe(req, a.access_token)
    }
  }
  getListStudents(req: RequestBase, token) {
    this.ClassService.getClassStudents(req, token)
      .subscribe(z => {
        this.Res = z
        this.StudentsData = this.Res.Data
        this.Title = "Danh sách sinh viên"
        this.TitleClass = ""
        this.dtTrigger.next();
      })
      ;
  }
  getListStudentsNienChe(req: any, token) {
    this.ClassService.getClassStudentsNienChe(req, token)
      .subscribe(z => {
        this.Res = z
        this.StudentsData = this.Res.Data
        this.Title = "Danh sách sinh viên"
        this.TitleClass = ""
        this.dtTrigger.next();
      })
      ;
  }
  Export_DanhSachLopTinChi(req:any,token)
  {
    this.ClassService.Export_ClassStudents(req,token).subscribe(z => {
      if (z.Status == 1) {
        this.exportService.exportExcelByte(z.FileData, 'DanhSachLop');
        this.toastr.success("File đã được tải xuống", "Thao tác thành công")
      }
      else {
        this.toastr.error(z.Message, "Thao tác thất bại")
      }
      this.Title = "Danh sách điểm"
      this.TitleClass = ""
    })
    ;
  }
  Export_DanhSachLopNienChe(req:any,token)
  {
    this.ClassService.Export_ClassStudentsNienChe(req,token).subscribe(z => {
      if (z.Status == 1) {
        this.exportService.exportExcelByte(z.FileData, 'DanhSachLop');
        this.toastr.success("File đã được tải xuống", "Thao tác thành công")
      }
      else {
        this.toastr.error(z.Message, "Thao tác thất bại")
      }
      this.Title = "Danh sách điểm"
      this.TitleClass = ""
    })
    ;
  }
  Export_DanhSachLop()
  {
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.requestObject.UserName = a.Info.UserName
    this.requestObject.RequestID = this.ID
    let r = confirm("Bạn có muốn tải xuống file dữ liệu điểm không?")
    if (r) {
      this.Title = "Đang tạo file dữ liệu, vui lòng chờ"
      this.TitleClass = "spinner-border text-muted"
      let req={
      ID_lop: this.ID,
      Loai:this.Loai
      }
      
    if (this.CategoryID == 2) {
      this.Export_DanhSachLopTinChi(req,a.Token);
    }
    else
    {
      this.Export_DanhSachLopNienChe(req,a.Token)
    }
  }
  }
}
