import { Component, OnInit } from "@angular/core";
import {
  RequestBase,
  RequestStudentsCreditList,
} from "src/app/Models/input.model/RequestBase";
import { MarkService } from "src/app/Utils/service/mark.service";
import { CoreService } from "src/app/Utils/service/core.service";
import { common } from "src/app/app.common";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { LooseObject } from "src/app/Models/output.model/ResponseBase";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ExportService } from "src/app/Utils/service/export.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-diem-thi",
  templateUrl: "./diem-thi.component.html",
  styleUrls: ["./diem-thi.component.scss"],
})
export class DiemThiComponent implements OnInit {
  ClassData: any;
  dtOptions: DataTables.Settings;
  SoLanThiLai: any;
  IsDone: boolean;
  obj: LooseObject = {};
  StudentsMark: any;
  Enable: boolean = false;
  Token: String;
  UserName: String;
  DiemThanhPhans: any;
  TitleLop: String;
  dtTrigger = new Subject();
  Title: string = "Đang tải danh sách điểm";
  TitleClass: string = "spinner-border text-muted cus_width";
  CategoryID: number;
  GiaTriThamSo: any;
  requestObject: RequestStudentsCreditList = new RequestStudentsCreditList();
  TitleMon: any;
  TitleHocKy: any;
  TitleNamHoc: any;
  params: any;
  closeResult: string;
  Lan_hoc: number = 1;
  CongBoDiemDiemEnable: boolean = false;
  Roles: any;
  constructor(
    private modalService: NgbModal,
    public route: ActivatedRoute,
    private MarkService: MarkService,
    private CoreService: CoreService,
    public router: Router,
    private toastr: ToastrService,
    private exportService: ExportService,
    private spinner: NgxSpinnerService
  ) {}
  public com: common;
  ngOnInit() {
    this.spinner.show();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Roles = a.Roles;
    this.CongBoDiemDiemEnable = this.Roles.some((x) => x.ID_quyen == 1324);
    this.Token = a.access_token;
    this.UserName = a.Info.UserName;
    this.route.queryParams.subscribe((params) => {
      this.requestObject.RequestID = params.ID;
      this.requestObject.ID_mon = params.ID_mon;
      this.CategoryID = params.CategoryID;
      this.params = params;
      this.TitleLop = params.Ten_lop;
      this.TitleMon = params.Ten_mon;
      this.TitleHocKy = params.Hoc_ky;
      this.TitleNamHoc = params.Nam_hoc;
    });

    this.requestObject.UserName = a.Info.UserName;

    let req = {
      RequestID: this.requestObject.RequestID,
      ID_mon: this.requestObject.ID_mon,
      UserName: this.UserName,
      LanHoc: this.Lan_hoc,
      LanThi: 1,
      Ma_tham_so: "So_lan_thi_lai",
      HocKy: this.TitleHocKy,
      NamHoc: this.TitleNamHoc,
      Sap_xep: 1,
    };

    this.GetThamSoQuyChe(req, this.Token);
    var id = 9;
    this.GetThamSoHeThong(id, this.Token);
  }
  ClassCreditGetInfo(req: any, token) {
    this.Title = "Đang tải danh sách điểm";
    this.TitleClass = "spinner-border text-muted";

    this.MarkService.StudentsCredit_DiemThiLoadList(req, token).subscribe(
      (z) => {
        this.ClassData = z;
        this.DiemThanhPhans = z.DiemThanhPhans;
        for (let index = 0; index < this.ClassData.Data.length; index++) {
          if (this.ClassData.Data[index].Diem_thi == null) {
            this.ClassData.Data[index].Diem_thi = "";
          }
          if (this.ClassData.Data[index].Ghi_chu == null) {
            this.ClassData.Data[index].Ghi_chu = "";
          }
          if (this.ClassData.Data[index].Ghi_chu_khac == null) {
            this.ClassData.Data[index].Ghi_chu_khac = "";
          }
        }
        this.RenderData(
          this.ClassData.Data,
          this.DiemThanhPhans,
          this.ClassData.MonLyThuyetThucHanh,
          this.ClassData.KyHieus
        );
        this.Title = "Danh sách điểm";
        this.TitleClass = "";
        this.spinner.hide();
      }
    );
  }
  ClassNienCheGetInfo(req: any, token) {
    this.Title = "Đang tải danh sách điểm";
    this.TitleClass = "spinner-border text-muted";
    this.MarkService.StudentsNienChe_DiemThiLoadList(req, token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.ClassData = z;
          this.DiemThanhPhans = z.DiemThanhPhans;
          for (let index = 0; index < this.ClassData.Data.length; index++) {
            if (this.ClassData.Data[index].Diem_thi == null) {
              this.ClassData.Data[index].Diem_thi = "";
            }
            if (this.ClassData.Data[index].Ghi_chu == null) {
              this.ClassData.Data[index].Ghi_chu = "";
            }
          }
          this.RenderData(
            this.ClassData.Data,
            this.DiemThanhPhans,
            this.ClassData.MonLyThuyetThucHanh,
            this.ClassData.KyHieus
          );
          if (z.Message == "Bạn không thể nhập điểm thi sau ngày thi 7 ngày") {
            this.toastr.warning(z.Message);
          }
        } else {
          this.toastr.error(z.Message);
        }

        this.Title = "Danh sách điểm";
        this.TitleClass = "";
        this.spinner.hide();
      }
    );
  }
  CheckKQ(n) {
    var m = n;
    var rs = false;
    var stringrs = "";
    for (var i = 0; i < m.length; i++) {
      rs = false;
      if (!m[i].Lock) {
        var diemdanh = $("#Diem_thi" + i + "").val();
        if (diemdanh >= 0 && diemdanh <= 10) {
          rs = false;
        } else {
          rs = true;
        }
        if (rs) {
          stringrs += i + 1 + ": " + m[i].Ho_ten + " \n ";
        }
      }
    }
    return stringrs;
  }
  LanThiChange() {
    if (this.IsDone) {
      $("#lstStudents").html("");
      var Lan_thi = $("#Lan_thi").children("option:selected").val();
      let req = {
        RequestID: this.requestObject.RequestID,
        ID_mon: this.requestObject.ID_mon,
        UserName: this.UserName,
        LanThi: Lan_thi,
        LanHoc: this.Lan_hoc,
        HocKy: this.TitleHocKy,
        NamHoc: this.TitleNamHoc,
      };
      if (this.CategoryID == 2) {
        this.ClassCreditGetInfo(req, this.Token);
      } else {
        this.ClassNienCheGetInfo(req, this.Token);
      }
    }
  }
  LanHocChange() {
    var Lan_thi = $("#Lan_thi").children("option:selected").val();
    let req = {
      RequestID: this.requestObject.RequestID,
      ID_mon: this.requestObject.ID_mon,
      UserName: this.UserName,
      LanHoc: this.Lan_hoc,
      LanThi: Lan_thi,
      HocKy: this.TitleHocKy,
      NamHoc: this.TitleNamHoc,
    };
    if (this.CategoryID == 2) {
      this.ClassCreditGetInfo(req, this.Token);
    } else {
      this.ClassNienCheGetInfo(req, this.Token);
    }
  }
  KhoaDiem() {
    let r = confirm("Bạn có muốn khóa điểm thi không?");
    if (r) {
      this.Title = "Đang khóa điểm, vui lòng chờ";
      this.TitleClass = "spinner-border text-muted";

      let data = this.GetDataKhoaDiem(this.ClassData.Data);
      var Lan_thi = $("#Lan_thi").children("option:selected").val();
      let inputData = {
        Data: data,
        RequestID: this.requestObject.RequestID,
        ID_mon: this.requestObject.ID_mon,
        UserName: this.UserName,
        LanThi: Lan_thi,
        HocKy: this.TitleHocKy,
        NamHoc: this.TitleNamHoc,
      };
      this.DiemThiCreditKhoaDiem(inputData, this.Token);
    }
  }
  DiemThiCreditKhoaDiem(req: any, token) {
    this.MarkService.DiemThiCreditKhoaDiem(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message, "Thao tác thành công");
        var Lan_thi = $("#Lan_thi").children("option:selected").val();
        let req = {
          RequestID: this.requestObject.RequestID,
          ID_mon: this.requestObject.ID_mon,
          UserName: this.UserName,
          LanThi: Lan_thi,
          LanHoc: this.Lan_hoc,
          HocKy: this.TitleHocKy,
          NamHoc: this.TitleNamHoc,
        };
        if (this.CategoryID == 2) {
          this.ClassCreditGetInfo(req, this.Token);
        } else {
          this.ClassNienCheGetInfo(req, this.Token);
        }
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.Title = "Danh sách điểm";
      this.TitleClass = "";
    });
  }
  GetDataKhoaDiem(n) {
    var m = n;
    for (var i = 0; i < m.length; i++) {
      if (!m[i].Lock) {
        var diemthi = $("#Diem_thi" + i + "").val();
        m[i].Ghi_chu = $("#Ghi_chu" + i + "").val();
        if (diemthi >= 0) {
          m[i].Diem_thi = diemthi;
        }
      }
    }
    return m;
  }
  Save() {
    this.spinner.show();
    var checkdt = this.CheckKQ(this.ClassData.Data);
    if (checkdt == "") {
      this.Title = "Đang lưu điểm, vui lòng chờ";
      this.TitleClass = "spinner-border text-muted";
      var m = this.GetKetQua(this.ClassData.Data);
      var Lan_thi = $("#Lan_thi").children("option:selected").val();
      let req = {
        Data: m,
        RequestID: this.requestObject.RequestID,
        ID_mon: this.requestObject.ID_mon,
        UserName: this.UserName,
        LanThi: Lan_thi,
        LanHoc: this.Lan_hoc,
        HocKy: this.TitleHocKy,
        NamHoc: this.TitleNamHoc,
      };
      if (this.CategoryID == 2) {
        this.DiemThiCreditSave(req, this.Token);
      } else {
        this.DiemThiNienCheSave(req, this.Token);
      }
    } else {
      alert(
        "Vui lòng kiểm tra lại dữ liệu \n" +
          checkdt +
          "\n" +
          'Điểm phải là số 0<= điểm <=10, điểm , dùng dấu "."'
      );
      this.spinner.hide();
    }
  }
  DiemThiCreditSave(req: any, token) {
    this.MarkService.DiemThiCreditSave(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success("Điểm thi đã được lưu", "Thao tác thành công");
        var Lan_thi = $("#Lan_thi").children("option:selected").val();
        let req = {
          RequestID: this.requestObject.RequestID,
          ID_mon: this.requestObject.ID_mon,
          UserName: this.UserName,
          LanThi: Lan_thi,
          LanHoc: this.Lan_hoc,
          HocKy: this.TitleHocKy,
          NamHoc: this.TitleNamHoc,
        };
        this.ClassCreditGetInfo(req, this.Token);
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.spinner.hide();
      this.Title = "Danh sách điểm";
      this.TitleClass = "";
    });
  }
  DiemThiNienCheSave(req: any, token) {
    this.MarkService.DiemThiNienCheSave(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success("Điểm thi đã được lưu", "Thao tác thành công");
        var Lan_thi = $("#Lan_thi").children("option:selected").val();
        let req = {
          RequestID: this.requestObject.RequestID,
          ID_mon: this.requestObject.ID_mon,
          UserName: this.UserName,
          LanThi: Lan_thi,
          LanHoc: this.Lan_hoc,
          HocKy: this.TitleHocKy,
          NamHoc: this.TitleNamHoc,
        };
        this.ClassNienCheGetInfo(req, this.Token);
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.spinner.hide();
      this.Title = "Danh sách điểm";
      this.TitleClass = "";
    });
  }
  GetThamSoHeThong(id, token) {
    this.MarkService.GetThamSoHeThong(id, token).subscribe((z) => {
      for (let i = 0; i < z.Data.length; i++) {
        if (z.Data[i].ID_tham_so == "Cho_phep_nhap_diem_thi_web") {
          this.GiaTriThamSo = z.Data[i].Gia_tri;
          this.Enable = this.GiaTriThamSo == 1;
        }
      }
    });
  }
  GetThamSoQuyChe(req: any, token) {
    this.CoreService.GetThamSoQuyChe(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.SoLanThiLai = [];
        for (let index = 1; index <= z.Data.Gia_tri + 1; index++) {
          this.SoLanThiLai.push(index);
        }
        var Lan_thi = $("#Lan_thi").children("option:selected").val();
        this.IsDone = true;
        let req = {
          RequestID: this.requestObject.RequestID,
          ID_mon: this.requestObject.ID_mon,
          UserName: this.UserName,
          LanThi: 1,
          LanHoc: this.Lan_hoc,
          HocKy: this.TitleHocKy,
          NamHoc: this.TitleNamHoc,
          Sap_xep: 1,
        };
        if (this.CategoryID == 2) {
          this.ClassCreditGetInfo(req, this.Token);
        } else {
          this.ClassNienCheGetInfo(req, this.Token);
        }
      } else {
        this.SoLanThiLai.push(0);
        this.toastr.error(z.Message, "Không thể lấy tham số lần thi");
      }
    });
  }
  GetKetQua(n) {
    var m = n;
    for (var i = 0; i < m.length; i++) {
      var diemthi = $("#Diem_thi" + i + "").val();
      var diemthuchanh = $("#Diem_thuc_hanh" + i + "").val();
      var ghichu = $("#Ghi_chu" + i + "").val();
      var Ghi_chu_khac = $("#Ghi_chu_khac" + i + "").val();
      m[i].Ghi_chu = ghichu;
      if (diemthi >= 0) {
        m[i].Diem_thi = diemthi;
      }
      if (diemthuchanh >= 0) {
        m[i].Diem_thuc_hanh = diemthuchanh;
      }
      m[i].Ghi_chu_khac = Ghi_chu_khac;
    }
    return m;
  }
  RenderData(m, tp, MonLyThuyetThucHanh, KyHieus) {
    var stringHtml = "";
    stringHtml +=
      ' <thead style=" position: sticky; top: 0; z-index: 1;background-color: #f2f2f2 "> <tr> <th style="padding:6px"> STT</th> <th style="padding:6px"> Mã sinh viên</th>  <th style="width:20%;padding:6px"> Họ tên</th> <th style="padding:6px"> Giới tính</th><th style="padding:6px">Ngày sinh</th>';
    for (var j = 0; j < tp.length; j++) {
      stringHtml += " <th>" + tp[j].Ten_Diem + "</th> ";
    }
    stringHtml += "<th> TBKT</th>";
    if (MonLyThuyetThucHanh) {
      stringHtml += "<th> Điểm thi LT</th>";
      stringHtml += "<th> Điểm thi TH</th>";
    } else {
      stringHtml += "<th> Điểm thi</th>";
    }

    stringHtml += "<th> TBCHP </th>";
    stringHtml += "<th>Điểm chữ</th>";
    stringHtml += "<th>Điểm số</th>";
    stringHtml += '<th style="padding:6px">Ký hiệu</th>';
    stringHtml += '<th style="padding:6px"> Ghi chú</th>';
    stringHtml += "</tr></thead>";
    stringHtml += "<tbody>";
    for (var i = 0; i < m.length; i++) {
      var Locked = "";
      var LockedClass = "";
      if (m[i].LockDiemThi || m[i].Lock) {
        LockedClass = "redclass";
      }
      if (this.GiaTriThamSo == "1") {
      } else {
        LockedClass = "redclass";
      }
      var TBCBP = "";
      if (m[i].TBCBP != null) {
        TBCBP = m[i].TBCBP;
      }
      let Ngay_sinh = "";
      if (m[i].Ngay_sinh !== null) {
        let a = m[i].Ngay_sinh.split("T");
        let date = a[0].split("-");
        Ngay_sinh = date[2] + "/" + date[1] + "/" + date[0];
      }

      stringHtml +=
        '<tr class="' +
        LockedClass +
        '">   <td> ' +
        (i + 1) +
        "</td> <td> " +
        m[i].Ma_sv +
        '</td><td style="width:25%"> ' +
        m[i].Ho_ten +
        "</td><td> " +
        m[i].Gioi_tinh +
        "</td><td> " +
        Ngay_sinh +
        "</td>";
      var diem = m[i].Diems;
      for (var j = 0; j < diem.length; j++) {
        var diemtp = "";
        if (diem[j].Diem != null) {
          diemtp = diem[j].Diem;
        }
        if (i < m.length - 1) {
          stringHtml +=
            ' <td> <span style="margin:auto; display:table;" id="' +
            m[i].Ma_sv +
            diem[j].ID_Diem +
            '" data-nextfocus="' +
            m[i + 1].Ma_sv +
            diem[j].ID_Diem +
            '">' +
            diemtp +
            "</span> </td>";
        } else {
          stringHtml +=
            ' <td> <span style="margin:auto; display:table;" id="' +
            m[i].Ma_sv +
            diem[j].ID_Diem +
            '">' +
            diemtp +
            "</span></td>";
        }
      }
      stringHtml += "<td> " + TBCBP + "</td>";
      var DiemThi = "";
      if (m[i].Diem_thi != null) {
        DiemThi = m[i].Diem_thi;
      }
      var Ghi_chu_khac = "";
      if (m[i].Ghi_chu_khac != null) {
        Ghi_chu_khac = m[i].Ghi_chu_khac;
      }

      var Diem_thuc_hanh = "";
      if (m[i].Diem_thuc_hanh != null) {
        Diem_thuc_hanh = m[i].Diem_thuc_hanh;
      }
      if (m[i].Diem_thuc_hanh == null) {
        Diem_thuc_hanh = "";
      }
      var TBCHP = "";
      if (m[i].TBCHP != null) {
        TBCHP = m[i].TBCHP;
      }
      var DiemSo = "";
      if (m[i].Diem_so != null) {
        DiemSo = m[i].Diem_so;
      }
      var DiemChu = "";
      if (m[i].Diem_chu != null) {
        DiemChu = m[i].Diem_chu;
      }

      if (this.GiaTriThamSo == "1") {
        var Lan_thi = $("#Lan_thi").children("option:selected").val();
        if (m[i].LockDiemThi) {
          if (Lan_thi > 1 || this.Lan_hoc > 1) {
            stringHtml +=
              ' <td> <input style="width:50px;text-align:center;"  ' +
              Locked +
              ' type="text" id="Diem_thi' +
              i +
              '" data-nextfocus="Diem_thi' +
              (i + 1) +
              '" value="' +
              m[i].Diem_thi +
              '"></td>';
            if (MonLyThuyetThucHanh) {
              stringHtml +=
                ' <td> <input style="width:50px;text-align:center; "  ' +
                Locked +
                ' type="text" id="Diem_thuc_hanh' +
                i +
                '" data-nextfocus="Diem_thuc_hanh' +
                (i + 1) +
                '" value="' +
                Diem_thuc_hanh +
                '"></td>';
            }
            stringHtml +=
              '<td> <span style="width:50px;text-align:center; " id="TBCHP' +
              i +
              '" data-nextfocus="TBCHP' +
              (i + 1) +
              '" >' +
              TBCHP +
              "</span> </td>";
            stringHtml +=
              '<td> <span style="width:50px;text-align:center; " id="Diem_chu' +
              i +
              '" data-nextfocus="Diem_chu' +
              (i + 1) +
              '" >' +
              DiemChu +
              "</span> </td>";
            stringHtml +=
              '<td> <span style="width:50px;text-align:center; " id="Diem_so' +
              i +
              '" data-nextfocus="Diem_so' +
              (i + 1) +
              '" >' +
              DiemSo +
              "</span> </td>";
            stringHtml +=
              ' <td> <select  style="font-size:13px;width:50px;text-align:center;" data-nextfocus="Ghi_chu' +
              (i + 1) +
              '" id="Ghi_chu' +
              i +
              '">';
            stringHtml += ' <option value="-1"> </option>';
            var selected = "";
            KyHieus.forEach((element) => {
              if (m[i].Ghi_chu == element.Ky_hieu) {
                selected = "selected";
              } else {
                selected = "";
              }
              stringHtml +=
                " <option " +
                selected +
                ' value="' +
                element.Ky_hieu +
                '" title=" ' +
                element.Ten_ky_hieu +
                '" >' +
                element.Ky_hieu +
                "</option>";
            });
            stringHtml += "</select> </td>";
            stringHtml +=
              ' <td> <input style="width:50px;text-align:center;"  ' +
              Locked +
              ' type="text" id="Ghi_chu_khac' +
              i +
              '" data-nextfocus="Ghi_chu_khac' +
              (i + 1) +
              '" value="' +
              Ghi_chu_khac +
              '"></td>';
          } else {
            stringHtml +=
              ' <td> <span style="width:50px;text-align:center;" id="Diem_thi' +
              i +
              '" data-nextfocus="Diem_thi' +
              (i + 1) +
              '" >' +
              DiemThi +
              "</span> </td>";
            if (MonLyThuyetThucHanh) {
              stringHtml +=
                ' <td> <span style="width:50px;text-align:center; " id="Diem_thuc_hanh' +
                i +
                '" data-nextfocus="Diem_thuc_hanh' +
                (i + 1) +
                '" >' +
                Diem_thuc_hanh +
                "</span> </td>";
            }
            stringHtml +=
              '<td> <span style="width:50px;text-align:center; " id="TBCHP' +
              i +
              '" data-nextfocus="TBCHP' +
              (i + 1) +
              '" >' +
              TBCHP +
              "</span> </td>";
            var ghichu = "";
            KyHieus.forEach((element) => {
              if (m[i].Ghi_chu == element.Ky_hieu) {
                ghichu = m[i].Ghi_chu;
              }
            });
            stringHtml +=
              '<td> <span style="width:50px;text-align:center; " id="Diem_chu' +
              i +
              '" data-nextfocus="Diem_chu' +
              (i + 1) +
              '" >' +
              DiemChu +
              "</span> </td>";
            stringHtml +=
              '<td> <span style="width:50px;text-align:center; " id="Diem_so' +
              i +
              '" data-nextfocus="Diem_so' +
              (i + 1) +
              '" >' +
              DiemSo +
              "</span> </td>";
            stringHtml +=
              '<td> <span style="width:50px;text-align:center; " id="Ghi_chu' +
              i +
              '" data-nextfocus="Ghi_chu' +
              (i + 1) +
              '" >' +
              ghichu +
              "</span> </td>";
            stringHtml +=
              ' <td> <span style="width:50px;text-align:center;" id="Ghi_chu_khac' +
              i +
              '" data-nextfocus="Ghi_chu_khac' +
              (i + 1) +
              '" >' +
              Ghi_chu_khac +
              "</span> </td>";
          }
        } else {
          stringHtml +=
            ' <td> <input style="width:50px;text-align:center;"  ' +
            Locked +
            ' type="text" id="Diem_thi' +
            i +
            '" data-nextfocus="Diem_thi' +
            (i + 1) +
            '" value="' +
            m[i].Diem_thi +
            '"></td>';
          if (MonLyThuyetThucHanh) {
            stringHtml +=
              ' <td> <input style="width:50px;text-align:center; "  ' +
              Locked +
              ' type="text" id="Diem_thuc_hanh' +
              i +
              '" data-nextfocus="Diem_thuc_hanh' +
              (i + 1) +
              '" value="' +
              Diem_thuc_hanh +
              '"></td>';
          }
          stringHtml +=
            '<td> <span style="width:50px;text-align:center; " id="TBCHP' +
            i +
            '" data-nextfocus="TBCHP' +
            (i + 1) +
            '" >' +
            TBCHP +
            "</span> </td>";
          stringHtml +=
            '<td> <span style="width:50px;text-align:center; " id="Diem_chu' +
            i +
            '" data-nextfocus="Diem_chu' +
            (i + 1) +
            '" >' +
            DiemChu +
            "</span> </td>";
          stringHtml +=
            '<td> <span style="width:50px;text-align:center; " id="Diem_so' +
            i +
            '" data-nextfocus="Diem_so' +
            (i + 1) +
            '" >' +
            DiemSo +
            "</span> </td>";
          stringHtml +=
            ' <td> <select  style="font-size:13px;width:50px;text-align:center;" data-nextfocus="Ghi_chu' +
            (i + 1) +
            '" id="Ghi_chu' +
            i +
            '">';
          stringHtml += ' <option value="-1"> </option>';
          var selected = "";
          KyHieus.forEach((element) => {
            if (m[i].Ghi_chu == element.Ky_hieu) {
              selected = "selected";
            } else {
              selected = "";
            }
            stringHtml +=
              " <option " +
              selected +
              ' value="' +
              element.Ky_hieu +
              '" title=" ' +
              element.Ten_ky_hieu +
              '" >' +
              element.Ky_hieu +
              "</option>";
          });
          stringHtml += "</select> </td>";
          stringHtml +=
            ' <td> <input style="width:50px;text-align:center;"  ' +
            Locked +
            ' type="text" id="Ghi_chu_khac' +
            i +
            '" data-nextfocus="Ghi_chu_khac' +
            (i + 1) +
            '" value="' +
            m[i].Ghi_chu_khac +
            '"></td>';
        }
      } else {
        stringHtml +=
          ' <td> <span style="width:50px;text-align:center;" id="Diem_thi' +
          i +
          '" data-nextfocus="Diem_thi' +
          (i + 1) +
          '" >' +
          DiemThi +
          "</span> </td>";
        if (MonLyThuyetThucHanh) {
          stringHtml +=
            ' <td> <span style="width:50px;text-align:center;" id="Diem_thuc_hanh' +
            i +
            '" data-nextfocus="Diem_thuc_hanh' +
            (i + 1) +
            '" >' +
            Diem_thuc_hanh +
            "</span> </td>";
        }
        stringHtml +=
          '<td> <span style="width:50px;text-align:center;" id="TBCHP' +
          i +
          '" data-nextfocus="TBCHP' +
          (i + 1) +
          '" >' +
          TBCHP +
          "</span> </td>";
        stringHtml +=
          '<td> <span style="width:50px;text-align:center; " id="Diem_chu' +
          i +
          '" data-nextfocus="Diem_chu' +
          (i + 1) +
          '" >' +
          DiemChu +
          "</span> </td>";
        stringHtml +=
          '<td> <span style="width:50px;text-align:center; " id="Diem_so' +
          i +
          '" data-nextfocus="Diem_so' +
          (i + 1) +
          '" >' +
          DiemSo +
          "</span> </td>";
        stringHtml +=
          '<td> <span style="width:50px;text-align:center;;" id="Ghi_chu' +
          i +
          '" data-nextfocus="Ghi_chu' +
          (i + 1) +
          '" >' +
          m[i].Ghi_chu +
          "</span> </td>";
        stringHtml +=
          ' <td> <input style="width:50px;text-align:center;"  ' +
          Locked +
          ' type="text" id="Ghi_chu_khac' +
          i +
          '" data-nextfocus="Ghi_chu_khac' +
          (i + 1) +
          '" value="' +
          m[i].Ghi_chu_khac +
          '"></td>';
      }
      stringHtml += "</tr>";
    }
    stringHtml += "</tbody>";
    $("#lstStudents").html(stringHtml);
  }
  onKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      var $this = $(event.target);
      var index = $this.attr("data-nextfocus");
      $("#" + index + "").focus();
      $("#" + index + "").select();
    }
  }
  ExportTOExcel() {
    let r = confirm("Bạn có muốn tải xuống file dữ liệu điểm không?");
    if (r) {
      this.Title = "Đang tạo file dữ liệu, vui lòng chờ";
      this.TitleClass = "spinner-border text-muted";
      var Lan_thi = $("#Lan_thi").children("option:selected").val();
      let req = {
        RequestID: this.requestObject.RequestID,
        ID_mon: this.requestObject.ID_mon,
        UserName: this.UserName,
        LanThi: Lan_thi,
        LanHoc: this.Lan_hoc,
        HocKy: this.TitleHocKy,
        NamHoc: this.TitleNamHoc,
        Sap_xep: 1,
      };
      if (this.CategoryID == 2) {
        this.DiemThiCreditExport(req, this.Token);
      } else {
        this.DiemThiNienCheExport(req, this.Token);
      }
    }
  }
  DiemThiCreditExport(req: any, token) {
    this.MarkService.DiemThiCreditExport(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.exportService.exportExcelByte(
          z.FileData,
          "DiemThi_" +
            this.requestObject.UserName +
            "_" +
            this.requestObject.RequestID
        );
        this.toastr.success("File đã được tải xuống", "Thao tác thành công");
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.Title = "Danh sách điểm";
      this.TitleClass = "";
    });
  }
  DiemThiNienCheExport(req: any, token) {
    this.MarkService.DiemThiNienCheExport(req, token).subscribe((z) => {
      if (z.Status == 1) {
        this.exportService.exportExcelByte(
          z.FileData,
          "DiemThi_" +
            this.requestObject.UserName +
            "_" +
            this.requestObject.RequestID
        );
        this.toastr.success("File đã được tải xuống", "Thao tác thành công");
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
      this.Title = "Danh sách điểm";
      this.TitleClass = "";
    });
  }

  open(content, sizm, Loai, Data) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  Publish(CongBo: any) {
    var r = confirm(
      CongBo
        ? "Bạn có muốn công bố điểm cho sinh viên không?"
        : "Bạn có muốn huỷ công bố điểm cho sinh viên không?"
    );
    if (!r) {
      return;
    }
    this.spinner.show();
    var req = {
      LanHoc: this.Lan_hoc,
      HocKy: this.params.Hoc_ky,
      NamHoc: this.params.Nam_hoc,
      DiemThanhPhans: this.DiemThanhPhans,
      Data: this.ClassData.Data,
      CongBo: CongBo,
    };
    this.MarkService.PublishDiemThi(req, this.Token).subscribe((z) => {
      this.spinner.hide();
      if (z.Status == 1) {
        this.toastr.success(
          CongBo ? "Công bố điểm thành công" : "Huỷ công bố điểm thành công",
          "Thao tác thành công"
        );
      } else {
        this.toastr.error(z.Message, "Thao tác thất bại");
      }
    });
  }
}
