import { Component, OnInit } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import viLocale from '@fullcalendar/core/locales/vi';
import esLocale from '@fullcalendar/core/locales/es';
import { OptionsInput } from '@fullcalendar/core';
import listPlugin from '@fullcalendar/list';
import { ExamSchedule } from 'src/app/Utils/services/ExamSchedule.service';
import { ResponseTeachingScheduleList } from 'src/app/Models/output.model/ResponseTeachingScheduleList';
import { common } from 'src/app/app.common';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { RequestTeachingScheduleList } from 'src/app/Models/input.model/RequestTeachingScheduleList';
import { AppConfiguration, AppConfig } from 'src/configuration';
import { Injectable, Inject } from '@angular/core';
@Component({
  selector: 'app-exam-schedule',
  templateUrl: './exam-schedule.component.html',
  styleUrls: ['./exam-schedule.component.scss']
})
export class ExamScheduleComponent implements OnInit {

  calendarPlugins = [dayGridPlugin, listPlugin];
  options: OptionsInput;
  eventsModel: any;
  EventsData: any;
  requestObject: RequestTeachingScheduleList = new RequestTeachingScheduleList()
  constructor(
    @Inject(AppConfig) 
    private readonly appConfig: AppConfiguration,
    public ExamScheduleServirce: ExamSchedule,
    public router: Router,
    private toastr: ToastrService
  ) { }
  public com: common;
  public ResponseTeachingScheduleListResult: ResponseTeachingScheduleList;
  locale: any
  ngOnInit() {
    this.com = new common(this.router);
    this.locale = viLocale
    this.options = {
      editable: true,
      customButtons: {
        myCustomButton: {
          text: 'custom!',
          click: function () {
            alert('clicked the custom button!');
          }
        }
      },
      header: {
        left: 'prev,next today myCustomButton',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,dayGridDay listWeek'
      },
      minTime: "05:00",
      maxTime: "22:00",

    };
    this.GetSchedule()
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl+"?"+this.appConfig.document_xem_lich_coi_thi;
    window.open(urlHelp,'_blank');
  }
  eventClick(model) {
    this.toastr.info( model.event.title);
  }
  GetSchedule() {
    let curDate = new Date(Date.now())
    let ToDay = new Date(Date.now());
    let FromDay = new Date(curDate.getFullYear() - 10, curDate.getMonth(), curDate.getDay())
    let ToDayString = ToDay.getDate() + "/" + (ToDay.getMonth() + 1) + "/" + ToDay.getFullYear()
    let FromDayString = ToDay.getDate() + "/" + (ToDay.getMonth() + 1) + "/" + (ToDay.getFullYear() - 10)
    let a = this.com.getUserinfo();
    this.requestObject.UserName=a.Info.UserName;
    this.requestObject.FromDay= new Date(FromDayString);
    this.requestObject.ToDay=  new Date(ToDayString) ;
    this.ExamScheduleServirce.List(this.requestObject, a.access_token)
    .subscribe(z => {
      this.EventsData = z
       this.eventsModel = this.EventsData.Events
    })
   // let result = this.ExamScheduleServirce.List(a.Info.UserName, FromDayString, ToDayString, a.access_token, this.ResponseTeachingScheduleListResult)
   // return result
  }
}
