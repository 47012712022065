<section class="content-header" style="padding:5px !important">
    <div class="container-fluid">
        <div class="row mb-2">
                <ol class="breadcrumb ">
                    <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp; Trang chủ</a></li>
                    <li class="breadcrumb-item"><a [routerLink]="['/announce']">Thông báo</a></li>
                    <li class="breadcrumb-item active">Chi tiết thông báo</li>
                </ol>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content">

    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="card ">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="{{TitleClass}}"></div>
                                        <div class=" col-md-12">
                                            <div class="row">
                                                <h3 class="card-title" [innerHTML]="Tieu_de"></h3>
                                            </div>
                                            <span id="FromUserName" [innerHTML]="Trich_dan"></span>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-0">
                                    <div class="mailbox-read-message" id="Content" style="overflow: scroll;" [innerHTML]="Noi_dung">
                                       
                                    </div>
                                    <!-- /.mail-box-messages -->
                                </div>
                                <!-- /.card-body -->
                            </div>

                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>