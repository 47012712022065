<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/attendence']">Điểm danh</a></li>
                    <!-- <li class="breadcrumb-item active">{{TitleTenLop}} &nbsp;</li> -->
                    <li class="breadcrumb-item active">Điểm danh theo kế hoạch &nbsp;</li>
                    <li class="breadcrumb-item active">{{Ten_mon}}</li>
                    <li class="breadcrumb-item active">{{Ten_lop}} &nbsp;</li>

                </ol>
            </div>
            <div class=" col-md-6 ">
                <button [routerLink]="['/admin/attendence-plan-new-add']"
                class="btn btn-success btn-sm"
                [queryParams]="{IDLop: ID_lop, Ten_lop:Ten_mon,Loai_lop:Loai_lop,ID_mon:ID_mon,Ten_mon:Ten_mon}"
                queryParamsHandling="merge" class="btn btn-sm btn-success"  style="float: right;margin-left: 5px;"><i
                    class="fas fa-plus"></i> Thêm ngày điểm danh</button>
                    <button  style="float: right;;" class="btn btn-sm btn-info " (click)="BangDiemDanhTheoDoi_Export()"><i
                        class="fas fa-file-excel"></i> Xuất Excel</button>
            </div>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content" style="margin-top: 15px;">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class=" table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData">
                                            <td>{{ p.Ngay_thang | date:'dd/MM/yyyy' }}</td>
                                            <td>{{ p.Ten_ca }}</td>
                                            <td>{{ p.Tu_tiet }}</td>
                                            <td>{{ p.Den_tiet }}</td>
                                            <td>{{ p.Tu_gio }}</td>
                                            <td>{{ p.Den_gio }}</td>
                                            <td><a [routerLink]="['/admin/attendence-plan-new-edit']"
                                                    class="btn btn-success btn-sm"
                                                    [queryParams]="{IDLop: ID_lop,CaHoc:p.Ca_hoc,Tiet:p.So_tiet,Tu_tiet:p.Tu_tiet,Den_tiet:p.Den_tiet,
                                                        Ten_lop:Ten_lop,Loai_lop:Loai_lop,ID_mon:ID_mon,Ngay_thang:p.Ngay_thang,Ten_mon:Ten_mon}"
                                                    queryParamsHandling="merge">
                                                    <i class="fas fa-user-check" title="Điểm danh"></i>
                                                </a>&nbsp;&nbsp;
                                                <button class="btn btn-warning btn-sm"
                                                    (click)="Delete(ID_lop,ID_mon,p.Ngay_thang,p.Tu_tiet,p.Den_tiet,p.Ca_hoc)">
                                                    <i class="fas fa-trash" title="Điểm danh"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>