import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppService } from 'src/app/Utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Class } from 'src/app/Utils/services/Class.service';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ClassService } from 'src/app/Utils/service/class.service';
import { AttendenceService } from 'src/app/Utils/service/attendence.service';
import { ResponseClassListAll, ClassInfo } from 'src/app/Models/output.model/ResponseClassListAll';
import { ResponseListSchedule, ClassData } from 'src/app/Models/output.model/ResponseListSchedule';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { RequestTeachingScheduleList, RequestClassInfo } from 'src/app/Models/input.model/RequestTeachingScheduleList';
import { ResponseDiemDanhChiTiet } from 'src/app/Models/output.model/ResponseTeachingScheduleList';
import { ResponseBase } from 'src/app/Models/output.model/ResponseBase';
import { count } from 'rxjs/operators';
import { Location } from '@angular/common';


@Component({
  selector: 'app-attendence-info-theo-tiet',
  templateUrl: './attendence-info-theo-tiet.component.html',
  styleUrls: ['./attendence-info-theo-tiet.component.scss']
})
export class AttendenceInfoTheoTietComponent implements OnInit {
  dtOptions: DataTables.Settings
  Res: ResponseDiemDanhChiTiet = new ResponseDiemDanhChiTiet()
  Response: ResponseBase = new ResponseBase()
  ClassData: any
  requestObject: RequestClassInfo = new RequestClassInfo()
  requestObject1: RequestTeachingScheduleList = new RequestTeachingScheduleList()
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  IDLop: number;
  IDsv: string;
  Tiet: number;
  Loai_lop: any;
  IsAll: boolean;
  ID_mon: any;
  Title: string = "Đang tải danh sách điểm danh";
  TitleClass: string = "spinner-border text-muted";
  TitleTenSV: string
  TitleLop: string
  Ca_hoc: any;
  constructor(
    private location: Location,
    public route: ActivatedRoute,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private ClassService: ClassService,
    private AttendenceService: AttendenceService
  ) {
    // 

  }
  dtTrigger = new Subject();
  public com: common;
  ngOnInit() {
    this.IsAll = true
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.route.queryParams
      .subscribe(params => {
        this.IDLop = params.IDLop
        this.IDsv = params.ID_sv
        this.TitleTenSV = params.Ho_ten
        this.TitleLop = params.Ten_lop
        this.Loai_lop = params.Loai_lop
        this.ID_mon = params.ID_mon
      });
    this.requestObject.UserName = a.Info.UserName
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [
        {
          title: 'STT', className: "dt-center",
        },
        {
          title: 'Ngày điểm danh', className: "dt-center",  
        },
        {
          title: 'Ca học', className: "dt-center",
        },
        //{
        // title: 'Lần điểm danh'
        // },
        {
          title: 'Số tiết vắng lý thuyết', className: "dt-center",
        },
        {
          title: 'Số tiết vắng thực hành', className: "dt-center",
        },
   
        {
          title: 'Ghi chú',
        }

      ],
    };
    let req = {
      ID_lop_tc: this.IDLop,
      ID_sv: this.IDsv,
      ID_mon: this.ID_mon,
      Ca_hoc: this.Ca_hoc
    }
    this.DiemDanhSVHocPhan(req, a.access_token)
  }
  DiemDanhSVHocPhan(req: any, token) {
    if (this.Loai_lop == 1) {
      this.AttendenceService.DiemDanhSVNienChe(req, token)
        .subscribe(z => {
          this.Res = z
          let curr = new Date;
          let FromDay = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() - 2)
          let ToDay = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate())
          let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
          let last = first + 6; // last day is the first day + 6
          let firstday = new Date(curr.setDate(first));
          let lastday = new Date(curr.setDate(last));
          this.ClassData = this.Res.Data;
          this.dtTrigger.next();
          this.Title = ""
          this.TitleClass = ""
        })
        ;
    } else {
      this.AttendenceService.DiemDanhSVHocPhan(req, token)
        .subscribe(z => {
          this.Res = z
          let curr = new Date;
          let FromDay = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() - 2)
          let ToDay = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate())
          let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
          let last = first + 6; // last day is the first day + 6
          let firstday = new Date(curr.setDate(first));
          let lastday = new Date(curr.setDate(last));

          this.ClassData = this.Res.Data
          this.dtTrigger.next();
          this.Title = ""
          this.TitleClass = ""
        })
        ;
    }

  }
  backClicked() {
    this.location.back();
  }

}
