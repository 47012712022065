import { Injectable, Inject } from "@angular/core";
import { AppConfig, AppConfiguration } from "src/configuration";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { environment } from "src/environments/environment";
import { map, filter, catchError } from "rxjs/operators";
import {
  ResponseClassListAll,
  ResponseStudentsClass,
  ResponseClassListAllOnline,
} from "src/app/Models/output.model/ResponseClassListAll";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class TimeSwapService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  getList(
    Hoc_ky: number,
    Nam_hoc: string,
    ID_trang_thai: number,
    ID_cb: number,
    token
  ): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "TimeSwap/ListAll?Hoc_ky=" +
          Hoc_ky +
          "&Nam_hoc=" +
          Nam_hoc +
          "&ID_trang_thai=" +
          ID_trang_thai +
          "&ID_cb=" +
          ID_cb,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ExportExcel(
    ID_doi_gio: number,
    Hoc_ky: number,
    Nam_hoc: string,
    ID_cb: number,
    Ten_khoa,
    token
  ): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "TimeSwap/ExportExcel?ID_doi_gio=" +
          ID_doi_gio +
          "&Hoc_ky=" +
          Hoc_ky +
          "&Nam_hoc=" +
          Nam_hoc +
          "&ID_cb=" +
          ID_cb +
          "&Ten_khoa=" +
          Ten_khoa,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Update(request: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "TimeSwap/Update", request, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Delete(ID_doi_gio: any, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl + "TimeSwap/Delete?ID_doi_gio=" + ID_doi_gio,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Insert(request: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "TimeSwap/Insert", request, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}
