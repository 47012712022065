<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-body">
                        <div class="form-group">
                            <input type="text" [(ngModel)]="Nguoi_nhan" class="form-control" placeholder="Người nhận">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" [(ngModel)]="Title" placeholder="Chủ đề">
                        </div>
                        <div class="form-group">
                            <label>Nội dung</label>
                            <ckeditor #editor [editor]="Editor" data="{{ Noi_dung }}" (change)="onChange($event)">
                            </ckeditor>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <!-- <div class="btn btn-default btn-file">
                                    <i class="fa fa-paperclip cus-cb"></i> Đính kèm tệp
                                    <input type="file" (change)="handleFileInput($event.target.files)" id="fileattack3"
                                        class="form-control" />
                                </div>
                                <p class="help-block" id="fileName">{{fileName}}</p> -->
                            </div>
                            <div class="col-md-6">
                                <div class=" form-group float-right" *ngIf="CheckEdit == 0">
                                    <button type="button" (click)="SendMessage()" style="width: 200px;"
                                        class="btn btn-md btn-info"><i class="fas fa-paper-plane   "></i>
                                        Gửi</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>