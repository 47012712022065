import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { AppService } from 'src/app/Utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Class } from 'src/app/Utils/services/Class.service';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ClassService } from 'src/app/Utils/service/class.service';
import { ResponseClassListAll, ClassInfo } from 'src/app/Models/output.model/ResponseClassListAll';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AppConfig, AppConfiguration } from 'src/configuration';

@Component({
  selector: 'app-teaching-plan-class',
  templateUrl: './teaching-plan-class.component.html',
  styleUrls: ['./teaching-plan-class.component.scss']
})
export class TeachingPlanClassComponent implements OnInit {
  dtOptions: DataTables.Settings
  Res: ResponseClassListAll = new ResponseClassListAll()
  ClassData: ClassInfo[]
  requestObject: RequestBase = new RequestBase()
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  HocKy: number;
  NamHoc: string;
  MonHoc: string;
  Nam_hoc_fil: string = "";
  Ten_mon_fil:string;
  Ten_lop_fil:string
  Nam_hoc_arr: any = []
  Title: string = "Đang tải danh sách lớp..."
  TitleClass: string = "spinner-border text-muted"
  constructor(
    @Inject(AppConfig) 
    private readonly appConfig: AppConfiguration,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private ClassService: ClassService
  ) {
    // 

  }
  dtTrigger = new Subject();
  public com: common;
  ngOnInit() {
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let hockydt = data[2]; // use data for the id column
      let NamHocdt = data[4] + ""; // use data for the id column
      let MonHocdt = data[0] + ""; // use data for the id column
      let bool1 = true
      let bool2 = true
      let bool3 = true
      let fillter = true;
      if (this.HocKy > 0) {
        bool1 = this.HocKy == hockydt
      }
      if (this.NamHoc != undefined) {
        bool2 = NamHocdt.includes(this.NamHoc)
      }
      if (this.MonHoc != undefined) {
        bool3 = MonHocdt.includes(this.MonHoc)
      }
      fillter = bool1 && bool2 && bool3;
      return fillter;
    });


    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    //var rs=this.Class.ClassListAll(a.Info.UserName, a.access_token)
    this.requestObject.UserName = a.Info.UserName
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [
       
        {
          title: 'Tên môn',
          data: 'Ten_mon'
        },
        {
          title: 'Tên lớp',
          data: 'Ten_lop'
        },
        {
          title: 'Học kỳ',
          className: "dt-center",
          data: 'Hoc_ky'
        },
        {
          title: 'ID_lop',
          data: 'ID_lop',
          visible: false
        },
        {
          title: 'Năm học',
          className: "dt-center",
          data: 'Nam_hoc'
        },
        {
          title: 'Ký hiệu',
          data: 'Ky_hieu'
        },
        {
          title: 'Số sinh viên',
          className: "dt-center",
          data: 'So_sv'
        },
        {
          title: 'Tên loại lớp',
          data: 'Ten_loai_lop'
        },
        {
          title: 'Loại lớp',
          data: 'Loai_lop',
          visible: false
        },
        {
          title: 'Thao tác',
          width: "12%",
          className: "dt-center",
          defaultContent: '<i class="fas fa-file-alt" id="NhapDiemThi" title"Nhập điểm thi"></i>'
        },

      ],
      "order": [[2, "desc"]]
    };
    this.getListClass(this.requestObject, a.access_token)
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl+"?"+this.appConfig.document_Ke_hoach_giang_day ;
    window.open(urlHelp,'_blank');
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }
  getListClass(req: RequestBase, token) {
    this.ClassService.getClass(req, token)
      .subscribe(z => {
        this.Res = z
        this.ClassData = this.Res.Data
        this.dtTrigger.next();
        this.Title = "Danh sách lớp"
        this.TitleClass = ""
      })
      ;
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
}
