import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/Utils/services/app.service";
import { CategoryService } from "src/app/Utils/service/category.service";
import { common } from "src/app/app.common";
import { Router } from "@angular/router";
import { ResponseGetInfo } from "src/app/Models/output.model/ResponseGetInfo";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Profile } from "src/app/Utils/services/Profile.service";
import { ResponseBase } from "src/app/Models/output.model/ResponseBase";
import { ProfileService } from "src/app/Utils/service/profile.service";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { AppConfiguration, AppConfig } from "src/configuration";
import { Injectable, Inject } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { forkJoin } from "rxjs";
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  Token: any;
  HelpLink: any;
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    public AppService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public ProfileServirce: Profile,
    public categoryService: CategoryService,
    public ProfileServirces: ProfileService,
    public datepipe: DatePipe
  ) {}
  public com: common;
  public UserForm: FormGroup;
  public GetInfoResult: ResponseGetInfo;
  public ResBase: ResponseBase;
  public ReqBase: RequestBase = new RequestBase();
  public Response: ResponseGetInfo;

  ChucDanhs: any;
  HocVis: any;
  GioiTinhs: any;
  HocHams: any;
  TonGiaos: any;
  DanTocs: any;
  QuocTichs: any;
  Khoas: any;
  ChucVus: any;

  Title: string = "Đang tải thông tin...";
  TitleClass: string = "spinner-border text-muted";
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var userSave = this.com.getUserinfo();
    this.Token = userSave.access_token;
    this.UserForm = new FormGroup({
      Ho_ten: new FormControl(null),
      Ma_cb: new FormControl(null),
      Ngay_sinh: new FormControl(null),
      So_dien_thoai: new FormControl(null),
      Email: new FormControl(null),
      Dia_chi_lien_he: new FormControl(null),
      ID_chuc_danh: new FormControl("0"),
      Ten_dang_nhap: new FormControl(null),
      ID_hoc_vi: new FormControl("0"),
      ID_gioi_tinh: new FormControl("-1"),
      ID_hoc_ham: new FormControl("0"),
      ID_ton_giao: new FormControl("0"),
      Chuyen_mon_dao_tao: new FormControl(null),
      ID_dan_toc: new FormControl("0"),
      Chuyen_nganh_giang_day: new FormControl(null),
      ID_quoc_tich: new FormControl("0"),
      Phan_loai: new FormControl(null),
      So_so_bao_hiem: new FormControl(null),
      CMTND: new FormControl(null),
      Ngay_cap_CMND: new FormControl(null),
      Noi_cap_CMND: new FormControl(null),
      So_tai_khoan: new FormControl(null),
      Ten_ngan_hang: new FormControl(null),
      Ma_so_thue: new FormControl(null),
      ID_don_vi_quan_ly: new FormControl("0"),
      Don_vi_cong_tac: new FormControl(null),
      ID_chuc_vu: new FormControl("0"),
    });
 
    forkJoin([
      this.categoryService.getChucDanh(this.Token),
      this.categoryService.getHocVi(this.Token),
      this.categoryService.getGioiTinh(this.Token),
      this.categoryService.getHocHam(this.Token),
      this.categoryService.getTonGiao(this.Token),
      this.categoryService.getDanToc(this.Token),
      this.categoryService.getQuocTich(this.Token),
      this.categoryService.getKhoa(this.Token),
      this.categoryService.getChucVu(this.Token),
    ]).subscribe({
      next: ([
        chucDanh,
        hocVi,
        gioiTinh,
        hocHam,
        tonGiao,
        danToc,
        quocTich,
        khoa,
        chucVu,
      ]) => {

        this.ChucDanhs=chucDanh.ListData;
        this.HocVis=hocVi.ListData;
        this.GioiTinhs=gioiTinh.ListData;
        this.HocHams=hocHam.ListData;
        this.TonGiaos=tonGiao;
        this.DanTocs=danToc;
        this.QuocTichs=quocTich;
        this.Khoas=khoa;
        this.ChucVus=chucVu.ListData;
      },
    });
    this.getData();
  }

  help() {
    const urlHelp =
      this.appConfig.HelpUrl + "?" + this.appConfig.document_ho_so_ca_nhan;
    window.open(urlHelp, "_blank");
  }

  SetData(m) {
    this.UserForm.patchValue({
      Ho_ten: m.Ho_ten,
      Ma_cb: m.Ma_cb,
      Ngay_sinh: this.datepipe.transform(new Date(m.Ngay_sinh), "yyyy-MM-dd"),
      So_dien_thoai: m.So_dien_thoai,
      Email: m.Email,
      Dia_chi_lien_he: m.Dia_chi_lien_he,
      ID_chuc_danh: m.ID_chuc_danh,
      Ten_dang_nhap: m.Ten_dang_nhap,
      ID_hoc_vi: m.ID_hoc_vi,
      ID_gioi_tinh: m.ID_gioi_tinh,
      ID_hoc_ham: m.ID_hoc_ham,
      ID_ton_giao: m.ID_ton_giao,
      Chuyen_mon_dao_tao: m.Chuyen_mon_dao_tao,
      ID_dan_toc: m.ID_dan_toc,
      Chuyen_nganh_giang_day: m.Chuyen_nganh_giang_day,
      ID_quoc_tich: m.ID_quoc_tich,
      Phan_loai: m.Phan_loai,
      So_so_bao_hiem: m.So_so_bao_hiem,
      CMTND: m.CMTND,
      Ngay_cap_CMND: this.datepipe.transform(new Date(m.Ngay_cap_CMND), "yyyy-MM-dd"),
      Noi_cap_CMND: m.Noi_cap_CMND,
      So_tai_khoan: m.So_tai_khoan,
      Ten_ngan_hang: m.Ten_ngan_hang,
      Ma_so_thue: m.Ma_so_thue,
      ID_don_vi_quan_ly: m.ID_don_vi_quan_ly,
      Don_vi_cong_tac: m.Don_vi_cong_tac,
      ID_chuc_vu: m.ID_chuc_vu,
    });
  }
  UpdateInfo() {
    var a = this.com.getUserinfo();
    this.ProfileServirces.UpdateInfo(
      this.UserForm.value,
      this.Token
    ).subscribe(
      (z) => {
        this.Response = z;
        if (this.Response.Status == 1) {
          this.toastr.success("Cập nhật thành công");
        } else {
          this.toastr.error(this.Response.Message, "Thao tác thất bại");
        }
      },
      (err) => {
        this.toastr.warning(this.getServerErrorMessage(err));
      }
    );
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 401: {
        return `Bạn không có quyền thực hiện chức năng này!`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Server Error: ${error.message}`;
      }
    }
  }

  getData() {
    var a = this.com.getUserinfo();
    this.ReqBase.UserName = a.Info.UserName;
    this.GetInfo(this.ReqBase, a.Token);
  }
  GetInfo(req: RequestBase, token) {
    this.ProfileServirces.GetInfo(req, token).subscribe(
      (z) => {
        this.Response = z;
        if (this.Response.Status == 1) {
          this.Title = "Thông tin cá nhân";
          this.TitleClass = "";
          this.SetData(this.Response.Data);
        } else {
          this.toastr.error(this.Response.Message, "Thao tác thất bại");
        }
      },
      (errors) => {
        this.toastr.error(this.getServerErrorMessage(errors));
        this.AppService.logout();
      }
    );
  }
}
