import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FilterClass'
})
export class FilterClassComponent implements PipeTransform {

  transform(list: any[], dt:any) {
    if (list && list.length) {
      return list.filter(val => {
        if (dt.Ten_mon && val.Ten_mon.toLowerCase().indexOf(dt.Ten_mon.toLowerCase()) === -1) {
          return false;
        }
        if (dt.Ten_lop && val.Ten_lop.toLowerCase().indexOf(dt.Ten_lop.toLowerCase()) === -1) {
          return false;
        }
        return true;
      })
    } else {
      return list;
    }
  }

}
