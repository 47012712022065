<section class="content-header" style="padding: 0.5% !important;">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Duyệt kết quả đăng ký</li>
                    <li class="{{TitleClass}}"></li>
                </ol>
            </div>
            <div class="col-md-6">
                
                <button (click)="help()" class="btn btn-warning btn-sm"  type="submit" style="float: right; ">
                    <i class="fas fa-info-circle"></i>
                    Hướng dẫn
                </button>
                <label style="float: right;">{{ThongBao}}</label>
                <!-- <button class="btn btn-primary btn-sm" style="float: right; margin-left: 5px;">
                    <i class="fas fa-save"></i> Xem kết quả điểm
                </button>
                <button class="btn btn-primary btn-sm" style="float: right;  ">
                    <i class="fas fa-print"></i> In kết quả đăng ký
                </button> -->
            </div>
        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <form class="form-horizontal" [formGroup]="SearchForm">
                <div class="row">
                    <div class="col-md-3">
                        <label>Học kỳ đăng ký</label>
                        <select class="form-control" id="Ky_dang_ky" formControlName="Ky_dang_ky">
                            <option selected value="0">Vui lòng chọn</option>
                            <option *ngFor="let p of Ky_dang_ky" [value]="p.Ky_dang_ky">{{p.Ten_ky_dang_ky}}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label>Trạng thái</label>
                        <select class="form-control" id="Trang_thai" formControlName="Trang_thai">
                            <option selected value="0">Danh sách chưa duyệt</option>
                            <option value="1">Danh dách đã duyệt</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <button class="btn btn-primary btn-sm" style="margin-top: 9%;" (click)="onSubmit()">
                            <i class="fas fa-search"></i> Xem danh sách
                        </button>
                    </div>
                </div>
            </form>

        </div>
    </div>
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row">
                        <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                class="table-bordered dataTables_wrapper no-footer">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of TableData; index as i">
                                        <td>{{ i+1 }}</td>
                                        <td>{{ p.ID_sv }}</td>
                                        <td>{{ p.Ma_sv }}</td>
                                        <td>{{ p.Ho_ten }}</td>
                                        <td>{{ p.Ten_lop }}</td>
                                        <td>{{ p.So_tin_chi_dang_ky }}</td>
                                        <td>
                                            <button class="btn btn-sm btn-warning" style="margin-right: 4px;"
                                                (click)="open(mymodal,'xl',p)" title="Xem chi tiết">
                                                <i class="fas fa-edit"></i>
                                            </button>
                                            <button class="btn btn-sm btn-primary" style="margin-right: 4px;"
                                                (click)="openKetQuaHocTap(KetQuaHocTap,'xl',p)" title="Kết quả học tập">
                                                <i class="fas fa-binoculars"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.mail-box-messages -->
                    </div>
                    <!-- /.card-body -->
                </div>
            </div>
            <!-- /.col -->
        </div>
    </div>
</section>
<ng-template #mymodal let-modal style="width: 200%;">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Thông tin chi tiết</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body cus-fs13">
        <div class="container-fluid">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Mã học phần</th>
                        <th>Tên học phần</th>
                        <th>Số TC</th>
                        <th>Tên lớp TC</th>
                        <th>Lịch học</th>
                        <th>Giáo viên</th>
                        <th>Phòng học</th>
                        <th>Duyệt</th>
                        <th>Lý do không duyệt</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let p of HocPhans; index as i">
                        <td>{{ p.Ky_hieu }}</td>
                        <td>{{ p.Ten_mon }}</td>
                        <td>{{ p.So_tin_chi }}</td>
                        <td>{{ p.Ten_lop_tc }}</td>
                        <td>{{ p.Thoi_gian }} <br /> Thứ {{p.Thu}}-{{p.Tiet}}</td>
                        <td>{{ p.Ho_ten }}</td>
                        <td>{{ p.Ten_phong }}</td>
                        <td><input type="checkbox" name="SelectData" id="Duyet{{ p.ID_lop_tc }}" [checked]="p.Duyet"
                                value="{{ p.ID_lop_tc }}" /></td>
                        <td><input type="text" id="Ly_do1{{ p.ID_lop_tc }}" [value]="p.Ly_do1" /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary btn-sm" (click)="Save()">
            <i class="fas fa-save"></i> Lưu phê duyệt
        </button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
            Đóng
        </button>
    </div>
</ng-template>
<ng-template #KetQuaHocTap let-modal style="width: 200%;">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Kết quả học tập</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body cus-fs13">
        <div class="form-row">
            <div class="form-group col-md-3">
                <label for="inputEmail4">TBC tích luỹ thang điểm 4: {{TBCHT4_tich_luy}}</label>
            </div>
            <div class="form-group col-md-3">
                <label for="inputEmail4">Xếp hạng học lực: {{Xep_hang}}</label>
            </div>
            <div class="form-group col-md-3">
                <label for="inputEmail4">TBC học tập thang điểm 10: {{TBCHT10}} </label>
            </div>
            <div class="form-group col-md-3">
                <label for="inputEmail4">Xếp loại học tập: {{Xep_loai_tich_luy4}}</label>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-3">
                <label for="inputEmail4">Số môn thi lại: {{Tong_so_mon_thi_lai}} </label>
            </div>
            <div class="form-group col-md-3">
                <label for="inputEmail4">Số tín chỉ đã tích luỹ: {{Tong_so_hoc_trinh_tich_luy}}</label>
            </div>
            <div class="form-group col-md-3">
                <label for="inputEmail4">Số môn học lại: {{Tong_so_mon_hoc_lai}}</label>
            </div>
            <div class="form-group col-md-3">
                <label for="inputEmail4">Số môn chờ điểm: {{So_mon_cho_diem}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Học kỳ</th>
                            <th>Năm học</th>
                            <th>Mã học phần</th>
                            <th>Tên học phần</th>
                            <th>Số tín chỉ</th>
                            <th>Thang điểm 10</th>
                            <th>Thang điểm 4</th>
                            <th>Thang điểm chữ</th>
                            <th>Không tính TBC</th>
                            <th>Ghi chú </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of KetQuaHocTapNew">
                            <td>{{ p.Hoc_ky }}</td>
                            <td>{{ p.Nam_hoc }}</td>
                            <td>{{ p.Ky_hieu }}</td>
                            <td>{{ p.Ten_mon }}</td>
                            <td>{{ p.So_hoc_trinh }}</td>
                            <td>{{ p.TBCHP }}</td>
                            <td>{{ p.Diem_so }}</td>
                            <td>{{ p.Diem_chu }}</td>
                            <td><input type="checkbox" name="SelectData" [checked]="p.Khong_tinh_TBCHT"></td>
                            <td>{{ p.Ghi_chu_hp }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
            Đóng
        </button>
    </div>
</ng-template>