import { Injectable, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { ResponseBase } from "src/app/Models/output.model/ResponseBase";
import {
  RequestTeachingScheduleList,
  RequestClassInfo,
} from "src/app/Models/input.model/RequestTeachingScheduleList";
import { ResponseListSchedule } from "src/app/Models/output.model/ResponseListSchedule";
import { AppConfiguration, AppConfig } from "src/configuration";
import { map, filter, catchError } from "rxjs/operators";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class CategoryService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  getChucDanh(token : any): Observable<ResponseListSchedule> {
    return this.http
      .get<ResponseListSchedule>(
        this.appConfig.TeacherUrl + "Category/ChucDanh",
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getChucVu(token : any): Observable<ResponseListSchedule> {
    return this.http
      .get<ResponseListSchedule>(
        this.appConfig.TeacherUrl + "Category/ChucVu",
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getHocHam(token : any): Observable<ResponseListSchedule> {
    return this.http
      .get<ResponseListSchedule>(
        this.appConfig.TeacherUrl + "Category/HocHam",
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getHocVi(token : any): Observable<ResponseListSchedule> {
    return this.http
      .get<ResponseListSchedule>(
        this.appConfig.TeacherUrl + "Category/HocVi",
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getGioiTinh(token : any): Observable<ResponseListSchedule> {
    return this.http
      .get<ResponseListSchedule>(
        this.appConfig.TeacherUrl + "Category/GioiTinh",
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        map((z) => {
          return z;
        })
      );
  }

  getKhoa(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Khoa/Danh_sach_khoa", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getQuocTich(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "QuocTich/Danh_sach_quoc_tich", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getDanToc(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "DanToc/Danh_sach_dan_toc", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  getTonGiao(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "TonGiao/Danh_sach_ton_giao", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}
