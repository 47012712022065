<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <ol class="breadcrumb float-sm-left">
                <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                <li class="breadcrumb-item"><a style="color: #007bff;" [routerLink]="['/admin/attendencehistory']">Tra cứu điểm danh &nbsp;</a></li>
                <li class="breadcrumb-item"><a style="color: #007bff;" (click)="backClicked()">Ngày điểm danh </a></li>
            </ol>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body" style="padding: 0px 20px 0px 20px;">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row pt10">
                        <div class="col-md-6">
                            <label class="col-md-10">Môn học : {{Ten_mon}}</label>
                        </div>
                        <div class="col-md-5">
                            <label class="col-md-10">Ngày điểm danh: {{Ngay_thang |  date:'dd/MM/yyyy'}} </label>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-10">Lớp : {{TitleLop}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Main content -->
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <!-- <table datatable id="classTable" [dtOptions]="dtOptions" class="row-border hover"></table> -->
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th style="width: 15%;"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData;let i = index">
                                            <td>{{ i+1 }}</td>
                                            <td>{{ p.Ma_sv }}</td>
                                            <td>{{ p.Ho_ten }}</td>
                                            <td>{{ p.Gioi_tinh }}</td>
                                            <td>{{ p.Ngay_sinh |  date:'dd/MM/yyyy'}}</td>
                                            <td>{{ p.So_tiet }}</td>
                                            <td>
                                                <!-- <ng-container *ngIf="p.Co_ly_do==false">
                                                    <input type="radio" id="{{'Co_ly_do'+p.STT}}" disabled
                                                        name="{{p.STT}}" value="{{p.Co_ly_do}}">
                                                </ng-container> -->
                                                <ng-container *ngIf="p.Co_ly_do==true">
                                                    <i style="color:#17a2b8" class="fas fa-check"></i>
                                                </ng-container>

                                            </td>
                                            <td>

                                                <!-- <ng-container *ngIf="p.Khong_ly_do==false">
                                                    <input type="radio" id="{{'Khong_ly_do'+p.STT}}" disabled
                                                        name="{{p.STT}}" value="{{p.Khong_ly_do}}">
                                                </ng-container> -->
                                                <ng-container *ngIf="p.Khong_ly_do==true">
                                                    <i style="color:#17a2b8" class="fas fa-check"></i>
                                                </ng-container>
                                            </td>
                                            <td>

                                                <!-- <ng-container *ngIf="p.Di_muon==false">
                                                    <input type="radio" id="{{'Di_muon'+p.STT}}" disabled
                                                        name="{{p.STT}}" value="{{p.Di_muon}}">
                                                </ng-container> -->
                                                <ng-container *ngIf="p.Di_muon==true">
                                                    <i style="color:#17a2b8" class="fas fa-check"></i>
                                                </ng-container>

                                            </td>
                                            <td>

                                                <!-- <ng-container *ngIf="p.Di_hoc==false">
                                                    <input type="radio" id="{{'Di_hoc'+p.STT}}" disabled
                                                        name="{{p.STT}}" value="{{p.Di_hoc}}">
                                                </ng-container> -->
                                                <ng-container *ngIf="p.Di_hoc==true">
                                                    <i style="color:#17a2b8" class=" fas fa-check"></i>
                                                </ng-container>

                                            </td>
                                            <td>

                                             
                                                {{p.Ghi_chu}}

                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>
<!-- /.content -->