import { Component, OnInit, ViewChild } from "@angular/core";
import { MessageService } from "src/app/Utils/service/message.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { common } from "src/app/app.common";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular/ckeditor.component";
import { RequestSendMessage } from "src/app/Models/input.model/RequestSendMessage";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { ClassService } from "src/app/Utils/service/class.service";
import {
  ResponseStudentsClass,
  Student,
} from "src/app/Models/output.model/ResponseClassListAll";
import { Location } from "@angular/common";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { NoteBookService } from "src/app/Utils/service/note-book.service";
import { ExportService } from "src/app/Utils/service/export.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AttendenceService } from "src/app/Utils/service/attendence.service";
@Component({
  selector: "app-notebook",
  templateUrl: "./notebook.component.html",
  styleUrls: ["./notebook.component.scss"],
})
export class NotebookComponent implements OnInit {
  public Editor = ClassicEditor;
  dataMoTa: any = "";
  dtOptions: any = {};
  token: string;
  UserName: string;
  Ho_ten: string;
  TenLop: string;
  TenMon: string;
  TableData: any;
  TitleClass: string = "spinner-border text-muted";
  closeResult: string;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  isEdit: any;
  ID_cb: any;
  ID_lop: any;
  ID_mon: any;
  Hoc_ky: any;
  Nam_hoc: any;
  ClassData: any;
  Loai_lop: any;
  Colydo: any;
  Khonglydo: any;
  Dimuon: any;
  Dihoc: any;
  submitted = false;
  constructor(
    public route: ActivatedRoute,
    private toastr: ToastrService,
    public router: Router,
    private service: NoteBookService,
    private modalService: NgbModal,
    private exportService: ExportService,
    private spinner: NgxSpinnerService,
    private AttendenceService: AttendenceService
  ) {}
  Insert = new FormGroup({
    ID_so_len_lop: new FormControl(0),
    ID_cb: new FormControl(0),
    ID_mon: new FormControl(0),
    ID_lop: new FormControl(0),
    Hoc_ky: new FormControl(0),
    Nam_hoc: new FormControl(""),
    Ngay_len_lop: new FormControl(null, [Validators.required]),
    So_tiet_thuc_hanh: new FormControl(0),
    So_tiet_ly_thuyet: new FormControl(0),
    So_tiet_tich_hop: new FormControl(0),
    So_tiet_kiem_tra: new FormControl(0),
    Noi_dung_bai_giang: new FormControl(null, [Validators.required]),
    So_sv_vang: new FormControl(0),
    Ghi_chu: new FormControl(null),
    Tu_tiet: new FormControl(1, [Validators.required]),
    Den_tiet: new FormControl(1, [Validators.required]),
    Ca_hoc: new FormControl("Sáng"),
  });
  public com: common;
  dtTrigger = new Subject();
  ngOnInit(): void {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.UserName = a.Info.UserName;
    this.ID_cb = a.Info.UserID;
    this.token = a.access_token;
    this.Ho_ten = a.Info.FullName;
    this.route.queryParams.subscribe((params) => {
      this.ID_lop = params.ID;
      this.ID_mon = params.ID_mon;
      this.Hoc_ky = params.Hoc_ky;
      this.Nam_hoc = params.Nam_hoc;
      this.TenLop = params.Ten_lop;
      this.TenMon = params.Ten_mon;
      this.Loai_lop = params.CategoryID;
      var Request = {
        ID_cb: this.ID_cb,
        ID_lop: params.ID,
        ID_mon: params.ID_mon,
        Hoc_ky: params.Hoc_ky,
        Nam_hoc: params.Nam_hoc,
      };
      this.getAll(this.token, Request);
    });

    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      ordering: true,
      dom: "Bfrtip",
      // Configure the buttons
      buttons: ["excel"],
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },

      columns: [
        {
          title: "TT",
          width: "4%",
          className: "dt-center",
        },
        {
          title: "ID_so_len_lop",
          visible: false,
        },
        {
          title: "Ngày lên lớp",
          width: "8%",
          className: "dt-center",
        },
        {
          title: "Ca học",
          width: "8%",
          className: "dt-center",
        },
        {
          title: "Học kỳ",
          visible: false,
        },
        {
          title: "Năm học",
          visible: false,
        },
        {
          title: "ST lý thuyết",
          width: "5%",
          className: "dt-center",
        },
        {
          title: "ST thực hành",
          width: "6%",
          className: "dt-center",
        },
        {
          title: "ST tích hợp",
          width: "6%",
          className: "dt-center",
        },
        {
          title: "ST kiểm tra",
          width: "6%",
          className: "dt-center",
        },
        {
          title: "Số SV vắng",
          width: "5%",
          className: "dt-center",
        },
        {
          title: "Nội dung bài giảng",
        },
        {
          title: "Ghi chú",
          width: "10%",
        },
        {
          title: "Thao tác",
          width: "10%",
          className: "dt-center",
        },
      ],
      fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
        var ret: string = "";
        let dt = document.createElement("DIV");
        dt.innerHTML = aData[11];
        document.body.appendChild(dt);
        ret = dt.textContent;
        document.body.removeChild(dt);
        let strippedText = ret.replace(/<[^>]*>/g, "");
        $("td:eq(8)", nRow).html("<span>" + strippedText + "</span>");
      },
    };
  }

  open(content, sizm, Loai, Data) {
    this.isEdit = Loai;
    this.submitted = false;
    if (this.isEdit) {
      this.Insert.patchValue({
        ID_so_len_lop: Data.ID_so_len_lop,
        ID_cb: this.ID_cb,
        ID_lop: this.ID_lop,
        Hoc_ky: this.Hoc_ky,
        Nam_hoc: this.Nam_hoc,
        ID_mon: this.ID_mon,
        Ngay_len_lop: this.formatDate(new Date(Data.Ngay_len_lop)),
        So_tiet_thuc_hanh: Data.So_tiet_thuc_hanh,
        So_tiet_ly_thuyet: Data.So_tiet_ly_thuyet,
        So_tiet_tich_hop: Data.So_tiet_tich_hop,
        So_tiet_kiem_tra: Data.So_tiet_kiem_tra,
        So_sv_vang: Data.So_sv_vang,
        Noi_dung_bai_giang: Data.Noi_dung_bai_giang,
        Tu_tiet: Data.Tu_tiet,
        Den_tiet: Data.Den_tiet,
        Ghi_chu: Data.Ghi_chu,
        Ca_hoc: Data.Ca_hoc,
      });
      this.dataMoTa = Data.Noi_dung_bai_giang;
    } else {
      this.resetForm();
    }
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "xl" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  // onChange({ editor }: ChangeEvent) {
  //   this.Insert.patchValue({
  //     Noi_dung_bai_giang: editor.getData()
  //   });
  // }

  ImportFromTKB() {
    var r = confirm("Bạn có muốn thêm giờ từ thời khoá biểu không?");
    if (r) {
      this.spinner.show();
      this.importFromTKB();
    }
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  exportExcel() {
    var Request = {
      ID_cb: this.ID_cb,
      ID_lop: this.ID_lop,
      ID_mon: this.ID_mon,
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
      Ten_mon: this.TenMon,
      Ho_ten: this.Ho_ten,
    };
    this.GetDataExport(this.token, Request);
  }
  resetForm() {
    this.Insert.patchValue({
      ID_so_len_lop: 0,
      ID_cb: this.ID_cb,
      ID_lop: this.ID_lop,
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
      ID_mon: this.ID_mon,
      Ngay_len_lop: this.formatDate(new Date()),
      So_tiet_thuc_hanh: 0,
      So_tiet_ly_thuyet: 0,
      So_tiet_tich_hop: 0,
      So_tiet_kiem_tra: 0,
      So_sv_vang: 0,
      Noi_dung_bai_giang: null,
      Ghi_chu: null,
    });
    this.dataMoTa = "";
  }
  onReset() {
    this.submitted = false;
    this.Insert.reset();
  }
  getAll(token, req) {
    this.service.Load(req, token).subscribe((z) => {
      this.TableData = z.Data;
      this.dtTrigger.next();
      this.TitleClass = "";
    });
  }
  GetDataExport(token, req) {
    this.service.ExportExcel(req, token).subscribe((z) => {
      this.exportService.exportExcelByte(z.FileData, "SoLenLop");
      this.toastr.success("File đã được tải xuống", "Thao tác thành công");
    });
  }

  importFromTKB() {
    var req = {
      ID_cb: this.ID_cb,
      ID_lop: this.ID_lop,
      ID_mon: this.ID_mon,
      Hoc_ky: this.Hoc_ky,
      Nam_hoc: this.Nam_hoc,
    };
    this.service.ImportFromTKB(req, this.token).subscribe((z) => {
      this.spinner.hide();
      if (z.Status == 1) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        var Request = {
          ID_cb: this.ID_cb,
          ID_lop: this.ID_lop,
          ID_mon: this.ID_mon,
          Hoc_ky: this.Hoc_ky,
          Nam_hoc: this.Nam_hoc,
        };
        this.getAll(this.token, Request);
        this.toastr.success(
          "Thêm giờ giảng từ thời khoá biểu thành công!"
        );
      } else {
        this.toastr.warning("Hệ thống không tìm thấy giờ giảng nào!");
      }
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn["dataTable"].ext.search.pop();
  }
  InsertData() {
    this.service.Insert(this.Insert.value, this.token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        var Request = {
          ID_cb: this.ID_cb,
          ID_lop: this.ID_lop,
          ID_mon: this.ID_mon,
          Hoc_ky: this.Hoc_ky,
          Nam_hoc: this.Nam_hoc,
        };
        this.getAll(this.token, Request);
        this.resetForm();
        this.modalService.dismissAll("mymodal");
        this.toastr.success(z.Message);
      } else {
        this.toastr.error(z.Message);
      }
    });
  }
  UpdateData() {
    this.service.Update(this.Insert.value, this.token).subscribe((z) => {
      if (z.Status == 1) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        var Request = {
          ID_cb: this.ID_cb,
          ID_lop: this.ID_lop,
          ID_mon: this.ID_mon,
          Hoc_ky: this.Hoc_ky,
          Nam_hoc: this.Nam_hoc,
        };
        this.getAll(this.token, Request);
        this.resetForm();
        this.modalService.dismissAll("mymodal");
        this.toastr.success(z.Message);
      } else {
        this.toastr.error(z.Message);
      }
    });
  }
  Delete() {
    this.service.Delete(this.Insert.value, this.token).subscribe((z) => {
      if (z.Status == 1) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        var Request = {
          ID_cb: this.ID_cb,
          ID_lop: this.ID_lop,
          ID_mon: this.ID_mon,
          Hoc_ky: this.Hoc_ky,
          Nam_hoc: this.Nam_hoc,
        };
        this.getAll(this.token, Request);
        this.resetForm();
        this.toastr.success(z.Message);
      } else {
        this.toastr.error(z.Message);
      }
    });
  }
  DeleteConfirm(data) {
    var r = confirm("Bạn có muốn xóa mục này không?");
    if (r) {
      this.Insert.patchValue({
        ID_so_len_lop: data.ID_so_len_lop,
      });
      this.Delete();
    }
  }
  onSubmit() {
    this.submitted = true;

    if (this.Insert.value.Noi_dung_bai_giang == null) {
      this.toastr.warning("Vui lòng nhập nội dung bài giảng!");
    }

    if (this.Insert.valid) {
      this.Insert.patchValue({
        ID_cb: this.ID_cb,
        ID_mon: this.ID_mon,
        ID_lop: this.ID_lop,
        Hoc_ky: this.Hoc_ky,
        Nam_hoc: this.Nam_hoc,
      });
      let tutiet = parseInt(this.Insert.value.Tu_tiet);
      let dentiet = parseInt(this.Insert.value.Den_tiet);
      let sotiet =
        this.Insert.value.So_tiet_thuc_hanh +
        this.Insert.value.So_tiet_ly_thuyet +
        this.Insert.value.So_tiet_tich_hop +
        this.Insert.value.So_tiet_kiem_tra;
      let check = true;
      if (tutiet > dentiet) {
        this.toastr.warning("Từ tiết không được lớn hơn đến tiết!");
        check = false;
      }
      if (dentiet - tutiet + 1 != sotiet) {
        this.toastr.warning(
          "Vui lòng nhập số tiết khớp với thông tin từ tiết đến tiết!"
        );
        check = false;
      }
      if (sotiet <= 0) {
        this.toastr.warning("Vui lòng nhập số tiết!");
        check = false;
      }
      if (check) {
        if (this.isEdit) {
          this.UpdateData();
        } else {
          this.InsertData();
        }
      }
    }
  }
  get checkvalue() {
    return this.Insert.controls;
  }
  public onChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.Insert.patchValue({
      Noi_dung_bai_giang: data,
    });
  }
  changeSV() {
    var cahoc;
    switch (this.Insert.value.Ca_hoc) {
      case "Sáng":
        cahoc = 0;
        break;
      case "Chiều":
        cahoc = 1;
        break;
      case "Tối":
        cahoc = 2;
        break;

      default:
        cahoc = 3;
    }
    this.AttendenceService.DiemDanhKeHoach_ChiTiet_Load_List(
      this.UserName,
      this.ID_lop,
      this.ID_mon,
      this.Loai_lop,
      this.Insert.value.Ngay_len_lop,
      this.Insert.value.Tu_tiet,
      this.Insert.value.Den_tiet,
      cahoc,
      this.token
    ).subscribe((z) => {
      this.spinner.hide();
      var data = z.Data;
      var colydo = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].Co_ly_do == true || data[i].Khong_ly_do == true) {
          colydo++;
        }
      }
      this.Colydo = colydo;
      this.Insert.patchValue({
        So_sv_vang: colydo,
      });
    });
  }
}
