<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                    <li class="breadcrumb-item active">Tin nhắn</li>
                </ol>
            </div>
            <div class="col-md-6">
                <ul class="nav nav-pills flex-column float-sm-right">
                    <a [routerLink]="['/admin/create']" class="btn btn-secondary btn-sm"><i
                            class="fas fa-envelope-open-text"></i> &nbsp; Soạn thư</a>
                </ul>
                <button (click)="help()" class="btn btn-warning btn-sm"  type="submit" style="float: right;margin-right: 5px; ">
                    <i class="fas fa-info-circle"></i>
                    Hướng dẫn
                </button>
            </div>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="row">

</section>
<section class="content cus-body">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <button mat-button  class="btn btn-cus focus " (click)="getListInbox()">    
            <i class="fa fa-inbox"></i> Tin nhắn đến
        </button>
        <button mat-button class="btn btn-cus"  (click)="getListSend()">
            
            <i class="fa fa-envelope"></i> Tin đã gửi
        </button>
      </div>
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fss">
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                class=" table-bordered dataTables_wrapper no-footer">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>{{SendColumnTitle}}</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of ClassData">
                                        <td>{{ p.ID }}</td>
                                        <td>{{ p.Title }}</td>
                                        <td>{{ p.Content }}</td>
                                        <td>{{ p.FromUser }}</td>
                                        <td>{{ p.SendDate }}</td>
                                        <td>{{ p.Viewed ?"Đã xem": "Chưa xem" }}</td>
                                        <td><a [routerLink]="['/admin/read']" class="btn btn-primary btn-sm"
                                                [queryParams]="{ID: p.ID}" queryParamsHandling="merge">
                                                <i class="fas fa-eye" title="Xem chi tiết"></i>
                                            </a></td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section>