import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AppService } from "src/app/Utils/services/app.service";
import { common } from "src/app/app.common";
import { Router } from "@angular/router";
import { Injectable, Inject } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AppConfiguration, AppConfig } from "src/configuration";
import { ToastrService } from "ngx-toastr";
import { SupportService } from "./../../../Utils/service_support/support.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  SupportLink: any;
  Token: any;
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
  public searchForm: FormGroup;
  com: common;
  UserName: string = "Đang lấy dữ liệu...";
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private appService: AppService,
    private spinner: NgxSpinnerService,
    public supportService: SupportService,
    public router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.com = new common(this.router);
    var User = this.com.getUserinfo();
    this.UserName = User.Info.FullName;
    this.searchForm = new FormGroup({
      search: new FormControl(null),
    });
    this.com.CheckLogin();
    var UserData = this.com.getUserinfo();
    this.Token = UserData.access_token;
  }
  getSupportLink() {
    this.spinner.show();
    this.supportService.GetSupportLink(this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.SupportLink = z;
        const urlSupport =
          this.appConfig.SupportUrl +
          "?UniCode=" +
          z.Message +
          "&code_security=" +
          this.appConfig.code_security;
        window.open(urlSupport, "_blank");
      } else {
        this.toastr.warning(z.Message);
      }
      this.spinner.hide();
    });
  }

  logout() {
    var r = confirm("Bạn có muốn đăng xuất không?");
    if (r) {
      if (this.appConfig.AuthorizeType == 1 || (this.appConfig.sso != null && this.appConfig.AuthorizeType== null)) {
        // Đăng xuất SSO
        localStorage.removeItem("UserInfo");
        window.location.href =
          this.appConfig.sso.ssoServer +
          "connect/endsession?post_logout_redirect_uri=" +
          this.appConfig.sso.redirect_uri;
      } 

      if (this.appConfig.AuthorizeType == 0 || ((this.appConfig.sso == null && this.appConfig.AuthorizeType == null))) {
        localStorage.removeItem("UserInfo");
        this.com.redirectToLoginSystem();
      }

      if (this.appConfig.AuthorizeType == 2) {
        localStorage.removeItem("UserInfo");
        window.location.href= this.appConfig.CAS.Logout;
      }
    }
  }
}
