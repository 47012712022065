import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppService } from 'src/app/Utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Class } from 'src/app/Utils/services/Class.service';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ClassService } from 'src/app/Utils/service/class.service';
import { AttendenceService } from 'src/app/Utils/service/attendence.service';
import { ResponseClassListAll, ClassInfo } from 'src/app/Models/output.model/ResponseClassListAll';
import { ResponseListSchedule, ClassData } from 'src/app/Models/output.model/ResponseListSchedule';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { RequestTeachingScheduleList, RequestClassInfo } from 'src/app/Models/input.model/RequestTeachingScheduleList';
import { ResponseDiemDanhChiTiet } from 'src/app/Models/output.model/ResponseTeachingScheduleList';
import { ResponseBase } from 'src/app/Models/output.model/ResponseBase';
import { count } from 'rxjs/operators';
import { ExportService } from 'src/app/Utils/service/export.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { TeachingPlanClassComponent } from '../../teaching-plan/teaching-plan-class/teaching-plan-class.component';

@Component({
  selector: 'app-attendence-search',
  templateUrl: './attendence-search.component.html',
  styleUrls: ['./attendence-search.component.scss']
})
export class AttendenceSearchComponent implements OnInit {
  dtOptions: DataTables.Settings
  Res: ResponseDiemDanhChiTiet = new ResponseDiemDanhChiTiet()
  Response: ResponseBase = new ResponseBase()
  ClassData: any
  closeResult: string;
  requestObject: RequestClassInfo = new RequestClassInfo()
  requestObject1: RequestTeachingScheduleList = new RequestTeachingScheduleList()
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  titleModal: string;
  IDLop: number;
  IDMon: number;
  CaHoc: string;
  Tiet: number;
  LoaiLop: any;
  IsAll: boolean;
  Title: string = "Đang tải danh sách điểm danh"
  TitleClass: string = "spinner-border text-muted cus_width"
  TitleTenLop: string
  Token: string;
  Info: any;
  Hoc_ky:any
  Nam_hoc:any
  TitleTenMon: any;
  ModalType: any;
  constructor(
    public route: ActivatedRoute,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private ClassService: ClassService,
    private AttendenceService: AttendenceService,
    private exportService: ExportService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private AppService: AppService

  ) {
    // 

  }
  dtTrigger = new Subject();
  public com: common;
  ngOnInit() {
    this.IsAll = true
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.access_token
    this.Info = a
    this.route.queryParams
      .subscribe(params => {
        this.IDLop = params.IDLop
        this.IDMon = params.ID_mon
        this.CaHoc = params.CaHoc
        this.Tiet = params.Tiet
        this.TitleTenLop = params.Ten_lop
        this.TitleTenMon = params.Ten_mon
        this.LoaiLop = params.Loai_lop
        this.Hoc_ky = params.Hoc_ky
        this.Nam_hoc = params.Nam_hoc
      });
    this.requestObject.UserName = a.Info.UserName
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [
        {
          title: 'ID_sv',
          visible: false
        },
        {
          title: 'ID_dt',
          visible: false
        },
        {
          title: 'Mã SV',
        },
        {
          title: 'Họ tên'
        },
        {
          title: 'Ngày sinh',
        },
        {
          title: 'Giới tính',
        },
        {
          title: 'Tên khoa',
        },
        {
          title: 'ID_lop',
          visible: false
        },
        {
          title: 'Số tiết đi học', className: "dt-center",
        },
        {
          title: 'Số tiết nghỉ', className: "dt-center",
        },
        {
          title: 'Số tiết nghỉ có lý do', className: "dt-center",
        },
        {
          title: 'Số tiết nghỉ không lý do', className: "dt-center",
        },
        {
          title: 'Tình hình đi học',
        },
        {
          title: 'Chi tiết',
        }
      ],
    };

    this.ClassCreditGetInfo(parseInt(this.IDLop.toString()), a.access_token)
  }
  ClassCreditGetInfo(req: any, token) {
    this.spinner.show()
    if (this.LoaiLop == 1) {
      this.AttendenceService.AttendenceGetInfoSV_NienChe(this.IDLop, this.IDMon, token)
        .subscribe(z => {
          for (let i = 0; i < Object.keys(z.Data).length; i++) {
            z.Data[i].Ten_lop = this.IDLop
          }
          this.Res = z
          this.ClassData = this.Res.Data
          this.dtTrigger.next();
          this.Title = ""
          this.TitleClass = ""
          this.spinner.hide();
        }, errors => {
          this.AppService.logout();
          this.spinner.hide();
        });
    } else {
      this.AttendenceService.AttendenceGetInfoSV(req, token)
        .subscribe(z => {
          for (let i = 0; i < Object.keys(z.Data).length; i++) {
            z.Data[i].Ten_lop = this.IDLop
          }
          this.Res = z
          this.ClassData = this.Res.Data
          this.dtTrigger.next();
          this.Title = ""
          this.TitleClass = ""
          this.spinner.hide();
        }, errors => {
          this.AppService.logout();
          this.spinner.hide();
        });
    }

  }
  getStyle(x) {
    for (let i = 0; i < Object.keys(this.ClassData).length; i++) {
      if (this.ClassData[i].ID_sv == x) {
        let floorElements = document.getElementById(x);
        if (this.ClassData[i].Phan_tram_di_hoc == "NaN") {
          this.ClassData[i].Phan_tram_di_hoc = 0;
        }
        floorElements.setAttribute("style", "width:" + this.ClassData[i].Phan_tram_di_hoc + "%");
      }
    }
  }
  getStyledr(x) {
    for (let i = 0; i < Object.keys(this.ClassData).length; i++) {
      if (this.ClassData[i].ID_sv == x) {
        let floorElements = document.getElementById(x + 'dr');
        if (this.ClassData[i].Phan_tram_nghi_hoc == "NaN") {
          this.ClassData[i].Phan_tram_nghi_hoc = 0;
        }
        floorElements.setAttribute("style", "width:" + this.ClassData[i].Phan_tram_nghi_hoc + "%");
      }
    }
  }
  open(content, sizm, Type) {
    this.ModalType = Type
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (Type == 'Duyet') {
      this.titleModal = 'Bạn có chắc chắn duyệt số tiết nghỉ này của sinh viên';

    } else if (Type == 'Add') {
      this.titleModal = 'Bạn có chắc chắn thêm mới nhận xét';
      // this.ActionModal.reset();
    } else if (Type == 'Delete') {
      this.titleModal = 'Bạn có chắc chắn xóa nhận xét';

    }
    else if (Type == 'Export') {
      this.titleModal = 'Bạn có chắc chắn xuất excel ?';
    }
  } // Mở Modal

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  Approve() {
    this.spinner.show()
    if (this.LoaiLop == 1) {
      this.AttendenceService.AttendenceApprove_Day_Off_NienChe(this.IDLop, this.IDMon, this.Token)
        .subscribe(z => {
          if (z.Status == 1) {
            this.toastr.success('Duyệt thành công số tiết nghỉ của sinh viên');
          }
          else {
            this.toastr.error(z.Message)
          }
          this.modalService.dismissAll('ModalConfirm');
          this.spinner.hide()
        });
    } else {
      this.AttendenceService.AttendenceApprove_Day_Off_TinChi(this.IDLop, this.IDMon, this.Token)
        .subscribe(z => {
          if (z.Status == 1) {
            this.toastr.success('Duyệt thành công số tiết nghỉ của sinh viên');
          }
          else {
            this.toastr.error(z.Message)
          }
          this.modalService.dismissAll('ModalConfirm');
          this.spinner.hide()
        });
    }
  }

  exportExcel() {
    let res = {
     
        ID_mon: this.IDMon,
        ID_lop: this.IDLop,
        Ten_mon: this.TitleTenLop,
        Loai_lop:this.LoaiLop
      
    }
    if(this.LoaiLop == 1){
      this.AttendenceService.AttendenceExport_NienChe(res, this.Token)
      .subscribe(z => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'DiemDanhSinhVien' + this.Info.FullName + '_' + this.Info.Ten_khoa);

          this.toastr.success("File đã được tải xuống", "Thao tác thành công")
        }
        else {
          this.toastr.error(z.Message, "Thao tác thất bại")
        }
        this.TitleClass = ""
      });
    }else{
      this.AttendenceService.AttendenceExport_TinChi(res, this.Token)
      .subscribe(z => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, 'DiemDanhSinhVien' + this.Info.FullName + '_' + this.Info.Ten_khoa);

          this.toastr.success("File đã được tải xuống", "Thao tác thành công")
        }
        else {
          this.toastr.error(z.Message, "Thao tác thất bại")
        }
        this.TitleClass = ""
      });
    }

  }

}
