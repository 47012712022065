import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseBase } from 'src/app/Models/output.model/ResponseBase';
@Injectable({
  providedIn: 'root'
})
export class Profile {
  private http: HttpClient

  // Kết nối api bằng Ajax
  // GetInfo(UserName, token, m) {
  //   try {
  //     $.ajax({
  //       type: "POST",
  //       url: environment.TeacherUrl + "Profile/GetInfo",
  //       dataType: "json",
  //       async: false,
  //       data: { 'UserName': UserName },
  //       beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + token) },
  //       success: function (data) {
  //         m = data;
  //       },
  //       error: function (err) {
  //         m.Status = 3;
  //       },
  //     });
  //   }
  //   catch (err) {
  //     m.Status = 3;
  //   }
  //   finally {
  //     return m;
  //   }
  // }
  //Kết nối API bằng httpclient
  // GetUserInfo(UserName: string, token: string): Observable<HttpResponse<ResponseBase>> {
  //   let httpHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token,
  //   });
  //   return this.http.post<ResponseBase>(environment.TeacherUrl + "Profile/GetInfo", UserName, {
  //     headers: httpHeaders,
  //     observe: 'response'
  //   });
  // }
}
