<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2" >
            <div class=" col-sm-4 ">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/CoVanXemDiem']">Xem kết quả học tập SV </a></li>
                </ol>
            </div>
            <div class="col-sm-8">
                <button  style="float: right;;" class="btn btn-sm btn-info "   (click)="ExportToExcel_BaoCaoCoVan()"><i
                      class="fas fa-file-excel"></i> Xuất Excel</button>
            </div>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body" style="padding: 0px 20px 20px 20px;">
            <form class="form-horizontal" [formGroup]="filter">
                <div class="tab-content">
                    <div class="active tab-pane">
                        <div class="row pt10">
                            <div class="col-md-3 ">
                                <label>Hệ</label>
                                <ng-select notFoundText="Không có dữ liệu" formControlName="ID_he"
                                    (change)="changeFilter()">
                                    <ng-option *ngFor="let p of dataHe" [value]="p.ID_he">
                                        {{p.Ten_he}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-md-3">
                                <label>Học kỳ</label>
                                <select class="form-control" formControlName="Hoc_ky">
                                    <option value="1">Học kỳ I</option>
                                    <option value="2">Học kỳ II</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label>Năm học</label>
                                <select class="form-control" formControlName="Nam_hoc">
                                    <option *ngFor="let i of Nam_hoc_arr" value="{{i.value}}">{{i.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label>Thống kê</label>
                                <select class="form-control" formControlName="Loai_thong_ke">
                                    <option value="0">Học kỳ, năm học</option>
                                    <option value="1">Năm học</option>
                                    <option value="2">Toàn khóa</option>
                                </select>
                            </div>
                            <div class="col-md-3 ">
                                <label>Mã sinh viên</label>
                                <input type="text" class="form-control" formControlName="Ma_sv"
                                    (change)="changeFilter()" placeholder="Nhập Mã sinh viên">
                            </div>
                            <div class="col-md-3 ">
                                <label>Họ Tên</label>
                                <input type="text" class="form-control" formControlName="Ho_ten"
                                    (change)="changeFilter()" placeholder="Họ tên">
                            </div>
                            <div class="col-md-3 ">
                                <label>Khóa</label>
                                <input type="text" class="form-control" formControlName="Khoa" (change)="changeFilter()"
                                    placeholder="Nhập Khóa">
                            </div>
                            <div class="col-md-3 ">
                                <label>Lớp</label>
                                <input type="text" class="form-control" formControlName="Lop" (change)="changeFilter()"
                                    placeholder="Nhập Lớp">
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- Main content -->
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData;let i = index">
                                            <td>{{ p.Ma_sv }}</td>
                                            <td>{{ p.Ho_ten }}</td>
                                            <td>{{ p.Ngay_sinh }}</td>
                                            <td>{{ p.Chuyen_nganh }}</td>
                                            <td>{{ p.Khoa_hoc }}</td>
                                            <td>{{ p.Ten_lop }}</td>
                                            <td>{{ p.ID_he }}</td>
                                            <td>
                                                <a (click)="GetDataSV(p)" (click)="open(BangDiem)"
                                                    class="btn btn-success btn-sm">
                                                    <i class="fas fa-user-check" title="Xem điểm"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #BangDiem let-modal>
    <h3 style="text-align: center;">{{Ten_sv}}-{{Ma_sv}}</h3>
    <div class="modal-content">
        <div class="modal-body" style="padding-bottom: 0px;">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4 ">
                            <label>TBCHT(4)</label>
                            <input class="form-control" type="text" readonly value="{{lblTBCHT4}}" />
                        </div>
                        <div class="col-md-4">
                            <label>TBCHT(10)</label>
                            <input class="form-control" type="text" readonly value="{{lblTBCHT10}}" />
                        </div>
                        <div class="col-md-4">
                            <label>TBCHT tích luỹ</label>
                            <input class="form-control" type="text" readonly value="{{lblTBC_tich_luy}}" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>Xếp loại học tập 4</label>
                            <input class="form-control" type="text" readonly value="{{lblXep_loai_hoc_tap4}}" />
                        </div>
                        <div class="col-md-4">
                            <label>Xếp loại học tập</label>
                            <input class="form-control" type="text" readonly value="{{lblXep_loai_hoc_tap10}}" />
                        </div>
                        <div class="col-md-4">
                            <label>Xếp hạng học lực</label>
                            <input class="form-control" type="text" readonly value="{{lblXep_hang_hoc_luc}}" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>Số môn học lại</label>
                            <input class="form-control" type="text" readonly value="{{lblSo_mon_hoc_lai}}" />
                        </div>
                        <div class="col-md-4">
                            <label>Số môn thi lại</label>
                            <input class="form-control" type="text" readonly value="{{lblSo_mon_thi_lai}}" />
                        </div>
                        <div class="col-md-4">
                            <label>Số tín chỉ đã tích luỹ</label>
                            <input class="form-control" type="text" readonly value="{{lblSo_tin_chi_tich_luy}}" />

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body" style="padding-top: 0px;">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12" style="margin-bottom: 0.5rem;">
                            <input class="form-control" type="text" placeholder="Tìm kiếm..."
                                [(ngModel)]="searchString">
                        </div>
                    </div>
                    <div style="max-height: 60vh; margin-bottom: 15px;"
                        class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs"
                        style="height: 40vh;">
                        <table class="table-bordered dataTables_wrapper no-footer">
                            <thead>
                                <tr>
                                    <th>Mã học phần</th>
                                    <th>Tên học phần</th>
                                    <th>Số tín chỉ</th>
                                    <th>Học kì</th>
                                    <th>Năm xét</th>
                                    <th *ngIf="QuyChe == 43">Điểm lần 1</th>
                                    <th *ngIf="QuyChe == 43">Điểm số lần 1</th>
                                    <th *ngIf="QuyChe == 43">Điểm chữ lần 1</th>
                                    <th *ngIf="QuyChe == 43">Điểm số cao nhất</th>
                                    <th *ngIf="QuyChe == 43">Điểm chữ cao nhất</th>
                                    <th>Điểm cao nhất</th>
                                    <th>Số lần thi</th>
                                    <th>Môn chứng chỉ</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let p of BangDiemSVChiTiet | filter: searchString;let i = index">
                                    <td>{{ p.Ma_hoc_phan }}</td>
                                    <td>{{ p.Ten_hoc_phan }}</td>
                                    <td style="text-align: center;">{{ p.So_tin_chi }}</td>
                                    <td style="text-align: center;">{{ p.Hoc_ky }}</td>
                                    <td style="text-align: center;white-space: nowrap;">{{ p.Nam_xet }}</td>
                                    <td *ngIf="QuyChe == 43" style="text-align: center;">{{ p.Diem_lan_1 }}</td>
                                    <td *ngIf="QuyChe == 43" style="text-align: center;">{{ p.Diem_so_lan_1 }}</td>
                                    <td *ngIf="QuyChe == 43" style="text-align: center;">{{ p.Diem_chu_lan_1 }}</td>
                                    <td *ngIf="QuyChe == 43" style="text-align: center;">{{ p.Diem_so_cao_nhat }}</td>
                                    <td *ngIf="QuyChe == 43" style="text-align: center;">{{ p.Diem_chu_cao_nhat }}</td>
                                    <td style="text-align: center;">{{ p.Diem_cao_nhat }}</td>
                                    <td style="text-align: center;">{{ p.So_lan_thi }}</td>
                                    <td style="text-align: center;">{{ p.Mon_chung_chi }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                    style="margin-left: 1em;">
                    Thoát
                </button>
            </div>
        </div>
    </div>
</ng-template>