<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-8">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                    <li class="breadcrumb-item" *ngIf="Status== 'false'"><a [routerLink]="['/admin/attendence']">Điểm danh</a></li>
                    <li class="breadcrumb-item" *ngIf="Status == 'true'">Điểm danh lớp kế hoạch</li>
                    <div class="{{TitleClass}}"></div>
                </ol>
            </div>
            <div class=" col-md-4">
                    <button class="btn btn-sm btn-success" (click)="Save()" style="float: right;margin-left: 5px;"><i
                        class="fas fa-save"></i> Lưu</button>
                    <button *ngIf="isShowQrCode" class="btn btn-sm btn-primary"  (click)="open(QRCodeModal, 'xl')" style="float: right;margin-left: 5px;"><i
                        class="fa fa-qrcode"></i> QR điểm danh</button>
                    <button class="btn btn-sm btn-primary" (click)="CheckAllDiHoc()" style="float: right;"><i class="fas fa-user-check"></i> Chọn
                        tất cả</button>
            </div>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body" style="padding: 0px 20px 0px 20px;">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row pt10">
                        <div class="col-md-7">
                            <label class="col-md-10">Môn học : {{TitileTenMon}}</label>
                        </div>
                        <div class="col-md-5">
                            <label class="col-md-10">Ngày điểm danh : {{Ngay_thang}}</label>
                        </div>
                        <div class="col-md-12">
                            <label class="col-md-10">Lớp : {{TitleTenLop}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <!-- <table datatable id="classTable" [dtOptions]="dtOptions" class="row-border hover"></table> -->
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData;let i = index">
                                            <td>{{ p.ID_sv }}</td>
                                            <td>{{ p.ID_dt }}</td>
                                            <td>{{ p.Ma_sv }}</td>
                                            <td>{{ p.Ho_ten }}</td>
                                            <td>{{ p.Ngay_sinh }}</td>
                                            <td>{{ p.Gioi_tinh }}</td>
                                            <td>{{ p.Ten_khoa }}</td>
                                            <td>{{ p.ID_lop }}</td>
                                            <td>
                                                <ng-container *ngIf="p.Co_ly_do==false">
                                                    <!-- <input type="radio" id="{{'Co_ly_do'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Co_ly_do}}"> -->
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Co_ly_do'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Co_ly_do}}" /><label
                                                        for="{{'Co_ly_do'+p.ID_sv}}"></label>
                                                </ng-container>
                                                <ng-container *ngIf="p.Co_ly_do==true">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Co_ly_do'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Co_ly_do}}" checked /><label
                                                        for="{{'Co_ly_do'+p.ID_sv}}"></label>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="p.Khong_ly_do==false">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Khong_ly_do'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Khong_ly_do}}"><label
                                                        for="{{'Khong_ly_do'+p.ID_sv}}"></label>
                                                </ng-container>
                                                <ng-container *ngIf="p.Khong_ly_do==true">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Khong_ly_do'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Khong_ly_do}}" checked><label
                                                        for="{{'Khong_ly_do'+p.ID_sv}}"></label>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="p.Di_hoc_muon==false">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Di_hoc_muon'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Di_hoc_muon}}"><label
                                                        for="{{'Di_hoc_muon'+p.ID_sv}}"></label>
                                                </ng-container>
                                                <ng-container *ngIf="p.Di_hoc_muon==true">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Di_hoc_muon'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Di_hoc_muon}}" checked><label
                                                        for="{{'Di_hoc_muon'+p.ID_sv}}"></label>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="p.Di_hoc==false">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Di_hoc'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Di_hoc}}"><label for="{{'Di_hoc'+p.ID_sv}}"></label>
                                                </ng-container>
                                                <ng-container *ngIf="p.Di_hoc==true">
                                                    <input type="radio" class="regular-radio big-radio"
                                                        id="{{'Di_hoc'+p.ID_sv}}" name="{{p.ID_sv}}"
                                                        value="{{p.Di_hoc}}" checked><label
                                                        for="{{'Di_hoc'+p.ID_sv}}"></label>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <input type="text" id="{{'Ghi_chu'+p.ID_sv}}" value="{{ p.Ghi_chu }}" />
                                            </td>
                                            <td>
                                                <ng-container *ngIf="p.Not_Locked==0">
                                                    <input type="radio" id="{{'Not_Locked'+p.Ma_sv}}"
                                                        name="{{p.Ma_sv+'Not_Locked'}}" value="{{p.Not_Locked}}">
                                                </ng-container>
                                                <ng-container *ngIf="p.Not_Locked==1">
                                                    <input type="radio" id="{{'Not_Locked'+p.Ma_sv}}"
                                                        name="{{p.Ma_sv+'Not_Locked'}}" value="{{p.Not_Locked}}"
                                                        checked>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <!-- /.card-body -->
                            </div>

                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <button class="btn btn-sm btn-success" (click)="Save()" style="float: right;"><i
                            class="fas fa-save"></i> Lưu
                        điểm danh</button>
                </div>
            </div>
        </div><!-- /.card-header -->
    </div>
</section>

<ng-template #QRCodeModal let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
        <h5 class="modal-title h-title w-100">QR Code điểm danh sinh viên</h5>
        <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body">
        <div class="tab-content">
            <div class="active tab-pane">
               <div style="text-align: center;">
                <qrcode
                [qrdata]="QrCodeData"
                [allowEmptyString]="true"
                [ariaLabel]="'QR Code image with the following content...'"
                [cssClass]="'center'"
                [colorDark]="'#000000ff'"
                [colorLight]="'#ffffffff'"
                [elementType]="'canvas'"
                [errorCorrectionLevel]="'M'"
                [margin]="4"
                [scale]="1"
                [width]="480"
              ></qrcode>
               </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div class="float-right">
            <button type="button" class="btn btn-md btn-danger" (click)="modal.dismiss('Cross click')"
                style="margin-left: 1em;">
                <i class="far fa-times-circle"></i> Đóng
            </button>
        </div>
    </div>
</ng-template>
<!-- /.content -->