<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <ol class="breadcrumb float-sm-left">
                <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                <li class="breadcrumb-item"><a style="color: #007bff;" [routerLink]="['/admin/attendencehistory']">Tra cứu điểm danh &nbsp;</a></li>
                <li class="breadcrumb-item"><a style="color: #007bff;" (click)="backClicked()">Ngày điểm danh </a></li>
            </ol>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body" style="padding: 0px 20px 0px 20px;">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row pt10">
                        <div class="col-md-6">
                            <label class="col-md-10">Môn học : {{Ten_mon}}</label>
                        </div>
                        <div class="col-md-5">
                            <label class="col-md-10">Ngày điểm danh: {{Ngay_thang |  date:'dd/MM/yyyy'}} </label>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-10">Lớp : {{TitleLop}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Main content -->
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <!-- <table datatable id="classTable" [dtOptions]="dtOptions" class="row-border hover"></table> -->
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                       
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData;let i = index">
                                            <td>{{ i+1 }}</td>
                                            <td>{{ p.Ma_sv }}</td>
                                            <td>{{ p.Ho_ten }}</td>
                                            <td>{{ p.Gioi_tinh }}</td>
                                            <td>{{ p.Ngay_sinh }}</td>
                                            <td>{{ p.So_tiet_ly_thuyet }}</td>
                                            <td>{{ p.So_tiet_thuc_hanh }}</td>
                                      
                                            <td>
                                                {{p.Ghi_chu}}

                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>

    <!-- /.row -->
</section>
<!-- /.content -->