import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppService } from 'src/app/Utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { AnnounceService } from 'src/app/Utils/service/announce.service';
import { ResponseClassListAll, ClassInfo } from 'src/app/Models/output.model/ResponseClassListAll';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-announce-detail',
  templateUrl: './announce-detail.component.html',
  styleUrls: ['./announce-detail.component.scss']
})
export class AnnounceDetailComponent implements OnInit {
  Tieu_de: string;
  Trich_dan: string;
  Noi_dung: string;
  TitleClass: string;
  Res: any
  ID: number
  Req: RequestBase = new RequestBase()
  AnnounceData: any
  com: common;
  constructor(
    public route: ActivatedRoute,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    private AnnounceService: AnnounceService
  ) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.ID = params.ID
      });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Req.RequestID=this.ID
    this.getListAnnounce(this.Req,a.Token)
  }
  getListAnnounce(req: RequestBase, token) {
    this.AnnounceService.getAnnounce(req, token)
      .subscribe(z => {
        this.Res = z
        this.AnnounceData = this.Res.Data
        this.Tieu_de=this.AnnounceData.Tieu_de
        this.Noi_dung=this.AnnounceData.Noi_dung
        this.Trich_dan=this.AnnounceData.Trich_dan
      })
      ;
  }
}
