import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from 'src/app/Components/main/main.component';
import { BlankComponent } from 'src/app/Components/pages/blank/blank.component';
import { LoginComponent } from 'src/app/Components/login/login.component';
import { ProfileComponent } from 'src/app/Components/pages/profile/profile.component';
import { RegisterComponent } from 'src/app/Components/register/register.component';
import { DashboardComponent } from 'src/app/Components/pages/dashboard/dashboard.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MarkCreditEditComponent } from 'src/app/Components/pages/class/mark-credit-edit/mark-credit-edit.component';
import { TeachingScheduleComponent } from './Components/pages/teaching-schedule/teaching-schedule.component';
import { ExamScheduleComponent } from './Components/pages/exam-schedule/exam-schedule.component';
import { ClassComponent } from './Components/pages/class/class.component';
import { StudentsClassComponent } from './Components/pages/class/students-class/students-class.component';
import { MarkComponent } from './Components/pages/mark/mark.component';
import { AttendenceComponent } from './Components/pages/attendence/attendence.component';
import { AttendenceEditComponent } from './Components/pages/attendence/attendence-edit/attendence-edit.component';
import { AttendenceSearchComponent } from './Components/pages/attendence/attendence-search/attendence-search.component';
import { AttendenceHistoryComponent } from './Components/pages/attendence/attendence-history/attendence-history.component';
import { AttendenceInfoComponent } from './Components/pages/attendence/attendence-info/attendence-info.component';
import { AnnounceComponent } from './Components/pages/announce/announce.component';
import { AnnounceDetailComponent } from './Components/pages/announce/announce-detail/announce-detail.component';
import { MessageComponent } from './Components/pages/message/message.component';
import { CreateComponent } from './Components/pages/message/create/create.component';
import { ReadComponent } from './Components/pages/message/read/read.component';
import { DiemThiComponent } from './Components/pages/mark/diem-thi/diem-thi.component';
import { DiemQuaTrinhComponent } from './Components/pages/mark/diem-qua-trinh/diem-qua-trinh.component';
import { ChangePassComponent } from './Components/pages/change-pass/change-pass.component';
import { TimeSwapComponent } from './Components/pages/time-swap/time-swap.component';
import { TeachingPlanComponent } from './Components/pages/teaching-plan/teaching-plan.component';
import { TeachingPlanClassComponent } from './Components/pages/teaching-plan/teaching-plan-class/teaching-plan-class.component';
import { NotebookComponent } from './Components/pages/class/notebook/notebook.component';
import { ApprovedRegistrationComponent } from './Components/pages/approved-registration/approved-registration.component';
import { ReportStatisticComponent } from './Components/pages/report-statistic/report-statistic.component';
import { NoteBookClassComponent } from './Components/pages/note-book-class/note-book-class.component';
import { TeachingCommentClassComponent } from './Components/pages/teching-comment/teaching-comment-class/teaching-comment-class.component';
import { TechingCommentComponent } from './Components/pages/teching-comment/teching-comment.component';
import { ReportComponent } from './Components/pages/report/report.component';
import { AttendencePlanComponent } from './Components/pages/attendence/attendence-plan/attendence-plan.component';
import { AttendenceDayComponent } from './Components/pages/attendence/attendence-day/attendence-day.component';
import { AttendenceDaySvComponent } from './Components/pages/attendence/attendence-day-sv/attendence-day-sv.component';
import { AttendencePlanNewComponent } from './Components/pages/attendence/attendence-plan-new/attendence-plan-new.component';
import { AttendencePlanNewAddComponent } from './Components/pages/attendence/attendence-plan-new/attendence-plan-new-add/attendence-plan-new-add.component';
import { AttendencePlanNewEditComponent } from './Components/pages/attendence/attendence-plan-new/attendence-plan-new-edit/attendence-plan-new-edit.component';
import { VersionDetailComponent } from './Components/pages/version-detail/version-detail.component';
import { SendMailTimeSwapComponent } from './Components/pages/time-swap/send-mail-time-swap/send-mail-time-swap.component';
import { DeclarationComponent } from './Components/pages/declaration/declaration.component';
import { ReloadComponent } from './Components/pages/reload/reload.component';
import { AttendenceEditTheoTietComponent } from './Components/pages/attendence/attendence-edit-theo-tiet/attendence-edit-theo-tiet.component';
import { AttendencePlanTheoTietAddComponent } from './Components/pages/attendence/attendence-edit-theo-tiet/attendence-plan-theo-tiet-add/attendence-plan-theo-tiet-add.component';
import { AttendencePlanTheoTietEditComponent } from './Components/pages/attendence/attendence-edit-theo-tiet/attendence-plan-theo-tiet-edit/attendence-plan-theo-tiet-edit.component';
import { AttendenceSearchTheoTietComponent } from './Components/pages/attendence/attendence-history/attendence-search-theo-tiet/attendence-search-theo-tiet.component';
import { AttendenceDayTheoTietComponent } from './Components/pages/attendence/attendence-history/attendence-day-theo-tiet/attendence-day-theo-tiet.component';
import { AttendenceInfoTheoTietComponent } from './Components/pages/attendence/attendence-history/attendence-info-theo-tiet/attendence-info-theo-tiet.component';
import { AttendenceDayInfoTheoTietComponent } from './Components/pages/attendence/attendence-history/attendence-day-info-theo-tiet/attendence-day-info-theo-tiet.component';
import { CoVanHocTapXemDiemComponent } from './Components/pages/mark/co-van-hoc-tap-xem-diem/co-van-hoc-tap-xem-diem.component';
import { DiemSinhVienComponent } from './Components/pages/diem-sinh-vien/diem-sinh-vien.component';
import { AuthGuard } from './Utils/guards/auth.guard';
import { NonAuthGuard } from './Utils/guards/non-auth.guard';
import { VerificationComponent } from './Components/sso/verification/verification.component';
import { DeclarationV2Component } from './Components/pages/declaration-v2/declaration-v2.component';
const routes: Routes = [
  {
    path: 'admin',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'declaration',
        component: DeclarationComponent
      },
      {
        path: 'ke-khai',
        component: DeclarationV2Component
      },
      {
        path: 'class',
        component: ClassComponent
      },
      {
        path: 'note-book-class',
        component: NoteBookClassComponent
      },
      {
        path: 'notebook',
        component: NotebookComponent
      },
      {
        path: 'mark',
        component: MarkComponent
      },
      {
        path: 'studentsclass',
        component: StudentsClassComponent
      },
      {
        path: 'teachingschedule',
        component: TeachingScheduleComponent
      },
      {
        path: 'examschedule',
        component: ExamScheduleComponent
      },

      {
        path: 'attendence',
        component: AttendenceComponent
      },
      {
        path: 'announce',
        component: AnnounceComponent
      },
      {
        path: 'announceedit',
        component: AnnounceDetailComponent
      },
      {
        path: 'message',
        component: MessageComponent,
      },
      {
        path: 'create',
        component: CreateComponent
      },
      {
        path: 'read',
        component: ReadComponent
      },
      {
        path: 'attendenceedit',
        component: AttendenceEditComponent
      },
      {
        path: 'ds-diem-danh-theo-tiet',
        component: AttendenceEditTheoTietComponent
      },
      {
        path: 'attendencehistory',
        component: AttendenceHistoryComponent
      },
      {
        path: 'attendencesearch',
        component: AttendenceSearchComponent
      },
      {
        path: 'AttendenceSearchTheoTiet',
        component: AttendenceSearchTheoTietComponent
      },
      {
        path: 'attendenceinfo',
        component: AttendenceInfoComponent
      },
      {
        path: 'AttendenceInfoTheoTiet',
        component: AttendenceInfoTheoTietComponent
      },
      {
        path: 'blank',
        component: BlankComponent,
        children: [
        ]
      },
      {
        path: 'DiemThi',
        component: DiemThiComponent
      },
      {
        path: 'DiemQuaTrinh',
        component: DiemQuaTrinhComponent
      },
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'changepass',
        component: ChangePassComponent
      },
      {
        path: 'timeswap',
        component: TimeSwapComponent
      },
      {
        path: 'teachingplan',
        component: TeachingPlanComponent
      },
      {
        path: 'teachingplanclass',
        component: TeachingPlanClassComponent
      },
      {
        path: 'approved-registration',
        component: ApprovedRegistrationComponent
      },
      {
        path: 'report-statistic',
        component: ReportStatisticComponent
      },
      {
        path: 'teaching-comment-class',
        component: TeachingCommentClassComponent
      },
      {
        path: 'teching-comment',
        component: TechingCommentComponent
      },
      {
        path: 'report',
        component: ReportComponent
      },
      {
        path: 'attendence-plan',
        component: AttendencePlanComponent
      },
      {
        path: 'version-detail',
        component: VersionDetailComponent,
      },
      {
        path: 'attendence-plan-new',
        component: AttendencePlanNewComponent
      },
      {
        path: 'attendence-plan-new-add',
        component: AttendencePlanNewAddComponent
      },
      {
        path: 'attendence-plan-add-theo-tiet',
        component: AttendencePlanTheoTietAddComponent
      },
      {
        path: 'attendence-plan-edit-theo-tiet',
        component: AttendencePlanTheoTietEditComponent
      },
      {
        path: 'attendence-plan-new-edit',
        component: AttendencePlanNewEditComponent
      },
      {
        path: 'attendenceday',
        component: AttendenceDayComponent
      },
      {
        path: 'AttendenceDayTheoTiet',
        component: AttendenceDayTheoTietComponent
      },
      {
        path: 'attendencedaystuden',
        component: AttendenceDaySvComponent
      },
      {
        path: 'AttendenceDayInfoTheoTiet',
        component: AttendenceDayInfoTheoTietComponent
      },
      {
        path: 'send-mail-ts',
        component:SendMailTimeSwapComponent
      },
      {
        path: 'CoVanXemDiem',
        component:CoVanHocTapXemDiemComponent
      },
      {
        path: 'XemDiemSinhVien',
        component:DiemSinhVienComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: '',
    component: VerificationComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'reload',
    component: ReloadComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NonAuthGuard]
  },

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    BrowserModule,
    HttpClientModule],
  exports: [RouterModule],
})
export class AppRoutingModule { }
