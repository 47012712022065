<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-12">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/attendence']">Điểm danh</a></li>
                    <!-- <li class="breadcrumb-item active">{{TitleTenLop}} &nbsp;</li> -->
                    <li class="breadcrumb-item active">Điểm danh theo kế hoạch &nbsp;</li>
                    <li class="breadcrumb-item active">{{Ten_mon}}</li>
                    <li class="breadcrumb-item active">{{Ten_lop}} &nbsp;</li>
                    <div class="{{TitleClass}}"></div>
                </ol>
            </div>
            <!-- <div class=" col-md-6 ">
                <button class="btn btn-sm btn-success" (click)="Save()" style="float: right;margin-left: 5px;"><i
                    class="fas fa-save"></i> Lưu
                điểm danh</button>
                <button class="btn btn-sm btn-primary" (click)="CheckAllDiHoc()" style="float: right;"><i class="fas fa-user-check"></i> Chọn
                    tất cả đi học</button>

            </div> -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>

<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <form class="form-horizontal" [formGroup]="Datetime">
                <div class="card-header p-2 " style="align-content:flex-end ">
                    <div class="row" style="padding-left:10px; padding-right:10px">
                        <div class="col-md-4">
                            <label class="col-md-10">Ngày giảng dạy:</label>
                            <input class="form-control" type="date" id="FromDay" formControlName="FromDay">
                        </div>
                        <div class="col-md-4">
                            <label class="col-md-12">&nbsp;</label>
                            <button type="button" (click)="GetData()" class=" btn-info btn"><i class="fas fa-search"></i>Tìm kiếm</button>
                        </div>
                    </div>
                </div>
            </form>
            <div class="tab-content" style="margin-top: 15px;">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="{{TitleClass}}"></div>
                                <div class=" col-md-3">
                                    <h3 class="card-title">{{Title}}</h3>
                                </div>
                            </div>
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class=" table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of ClassData">
                                            <td>{{ p.start }}</td>
                                            <td>{{ p.Thu }}</td>
                                            <td>{{ p.Ca_hoc }}</td>
                                            <td>{{ p.Tu_tiet }}</td>
                                            <td>{{ p.Den_tiet }}</td>
                                            <td>{{ p.Tu_gio }}</td>
                                            <td>{{ p.Den_gio }}</td>
                                            <td><a [routerLink]="['/admin/attendenceedit']" class="btn btn-success btn-sm"
                                                    [queryParams]="{IDLop: p.ID_lop,CaHoc:p.Ca_hoc,Tiet:(p.Den_tiet-p.Tu_tiet+1),ID_lich_day:p.ID_lich_day,
                                                        Ten_lop:p.Ten_lop,Loai_lop:p.Loai_lop,ID_mon:p.ID_mon,Status:true,Ngay_thang:p.start,Ten_mon:p.Ten_mon}"
                                                    queryParamsHandling="merge">
                                                    <i class="fas fa-user-check" title="Điểm danh"></i>
                                                </a>&nbsp;&nbsp;
                                                <!-- <a [routerLink]="['/admin/attendencesearch']" [queryParams]="{IDLop: p.ID_lop}" queryParamsHandling="merge">
                                                                            <i class=  "fas fa-stream"  title="Lịch sử điểm danh" ></i>
                                                                        </a> -->
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>