import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { AppService } from 'src/app/Utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Class } from 'src/app/Utils/services/Class.service';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ClassService } from 'src/app/Utils/service/class.service';
import { AttendenceService } from 'src/app/Utils/service/attendence.service';
import { ResponseClassListAll, ClassInfo } from 'src/app/Models/output.model/ResponseClassListAll';
import { ResponseListSchedule, ClassData } from 'src/app/Models/output.model/ResponseListSchedule';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { RequestTeachingScheduleList } from 'src/app/Models/input.model/RequestTeachingScheduleList';
import { NgxSpinnerService } from "ngx-spinner";
import { AppConfiguration, AppConfig } from 'src/configuration';
import { Injectable, Inject } from '@angular/core';
import { formatDate } from '@angular/common' 
@Component({
  selector: 'app-attendence',
  templateUrl: './attendence.component.html',
  styleUrls: ['./attendence.component.scss']
})
export class AttendenceComponent implements OnInit, OnDestroy {
  dtOptions: DataTables.Settings
  dtOptions2: DataTables.Settings
  Res: ResponseListSchedule = new ResponseListSchedule()
  ClassData: ClassData[]
  requestObject: RequestTeachingScheduleList = new RequestTeachingScheduleList()
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  HocKy: number;
  NamHoc: string;
  MonHoc: string;
  token: string;
  DiemDanhKeHoach: boolean = false;
  Title: string = "Đang tải danh sách lớp..."
  TitleClass: string = "spinner-border text-muted"
  //public Datetime: FormGroup;
  public FromDay: any;
  public ToDay: any;
  Search_table: any;
  Datetime = new FormGroup({
    FromDay: new FormControl(new Date()),
    ToDay: new FormControl(new Date())
  });
  Nam_hoc_fil: string = "";
  Ten_mon_fil: string;
  Ten_lop_fil: string
  Nam_hoc_arr: any = []
  TableData: ClassData[];
  ID_lop: any;
  filteredItems: any;
  constructor(
    @Inject(AppConfig) 
    private readonly appConfig: AppConfiguration,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private ClassService: ClassService,
    private AttendenceService: AttendenceService,
    private spinner: NgxSpinnerService,
    private AppService: AppService
  ) {
    // 

  }
  dtTrigger = new Subject();
  dtTrigger2 = new Subject();
  public com: common;
  ngOnInit() {
    var yearNow = new Date().getFullYear() + 4;
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + '-' + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.Nam_hoc_arr.push(object);
      yearNow--;
    }
    this.spinner.show();
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    //var rs=this.Class.ClassListAll(a.Info.UserName, a.access_token)
    this.requestObject.UserName = a.Info.UserName
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      // ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Tuần này chưa có thời khóa biểu, vui lòng chọn Điểm danh theo kế hoạch",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [

        {
          title: 'ID_lich_day',
          visible: false
        },
        {
          title: 'Ký hiệu',
        },
        {
          title: 'Tên môn',
        },
        {
          title: 'ID_lop',
          visible: false
        },
        {
          title: 'Loai_lop',
          visible: false
        },
        {
          title: 'Tên lớp',
        },
        {
          title: 'Thứ',
          className: "dt-center"
        },
        {
          title: 'Ca học',
          className: "dt-center"
        },
        {
          title: 'Từ tiết',
          className: "dt-center"
        },
        {
          title: 'Đến tiết',
          className: "dt-center"
        },
        {
          title: 'Giao_vien',
          visible: false
        },
        {
          title: 'UserName',
          visible: false
        },
        {
          title: 'Phòng học',
          className: "dt-center"
        },
        {
          title: 'Từ ngày',
          className: "dt-center"
        },
        {
          title: 'Đến ngày',
          className: "dt-center"
        },
        {
          title: 'Tu_gio',
          visible: false
        },
        {
          title: 'Den_gio',
          visible: false
        },
        {
          title: 'Thao tác',
          width: "10%",
          className: "dt-center"
        },
      ],
      order: [[6, 'asc']]
    };
    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [

        {
          title: 'Tên môn',
          data: 'Ten_mon'
        },
        {
          title: 'Tên lớp',
          data: 'Ten_lop'
        },
        {
          title: 'Học kỳ',
          className: "dt-center",
          data: 'Hoc_ky'
        },
        {
          title: 'ID_lop',
          data: 'ID_lop',
          visible: false
        },
        {
          title: 'Năm học',
          className: "dt-center",
          data: 'Nam_hoc'
        },
        {
          title: 'Ký hiệu',
          data: 'Ky_hieu'
        },
        {
          title: 'Số sinh viên',
          className: "dt-center",
          data: 'So_sv'
        },
        {
          title: 'Tên loại lớp',
          data: 'Ten_loai_lop'
        },
        {
          title: 'Loại lớp',
          data: 'Loai_lop',
          visible: false
        },
        {
          title: 'Thao tác',
          width: "12%",
          className: "dt-center",
          defaultContent: '<i class="fas fa-file-alt" id="NhapDiemThi" title"Nhập điểm thi"></i>'
        },

      ],
      "order": [[2, "desc"]]
    };

    // let curr = new Date; // get current date
    // let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    // let last = first + 7;                       // last day is the first day + 6
    // let firstday = new Date(curr.setDate(first));
    // let lastday = new Date(curr.setDate(last));
    // // let curDate = new Date(Date.now())
    // let FromDay = new Date(firstday.getFullYear(), firstday.getMonth(), firstday.getDate())
    // let ToDay = new Date(lastday.getFullYear(), lastday.getMonth(), lastday.getDate())
    // let m = (ToDay.getMonth() + 1).toString();
    // let d = ToDay.getDate().toString();
    // if (m.length == 1) m = '0' + m;
    // if (d.length == 1) d = '0' + d;
    // let m2 = (FromDay.getMonth() + 1).toString();
    // let d2 = FromDay.getDate().toString();
    // if (m2.length == 1) m2 = '0' + m2;
    // if (d2.length == 1) d2 = '0' + d2;
    // let ToDayString = ToDay.getFullYear() + "-" + m + "-" + d
    // let FromDayString = FromDay.getFullYear() + "-" + m2 + "-" + d2
    // this.Datetime.controls.FromDay.setValue(FromDayString);
    // this.Datetime.get("ToDay").setValue(ToDayString);
    this.requestObject.FromDay = this.getMonday(new Date());
    this.requestObject.ToDay = this.getSunday(new Date());
    this.Datetime.controls.FromDay.setValue(formatDate(this.getMonday(new Date()),'yyyy-MM-dd','en'))
    this.Datetime.controls.ToDay.setValue(formatDate(this.getSunday(new Date()),'yyyy-MM-dd','en'))
    this.token = a.access_token;
    this.getListSchedule(this.requestObject, this.token)

  }
   getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  getSunday(date)
  {
    var lastday = date.getDate() - (date.getDay() - 1) + 6;
    return new Date(date.setDate(lastday));
  }
  help() {
    const urlHelp = this.appConfig.HelpUrl+"?"+this.appConfig.document_diem_danh_sv;
    window.open(urlHelp,'_blank');
  }
  getListSchedule(req: RequestTeachingScheduleList, token) {
     
    this.AttendenceService.getListSchedule(req, token)
      .subscribe(z => {
         
        if (z.Status != 1) {
          this.ClassData = []
          this.dtTrigger.next();
          this.Title = ""
          this.TitleClass = ""
        }
        else {
          this.Res = z
          for (var i = 0; i < this.Res.Data.length; i++) {
            if (this.Res.Data[i].Ngay_hoc!=null)
            {
              var datePart = this.Res.Data[i].Ngay_hoc.substring(0, 10).split("-").reverse().join("/")
              this.Res.Data[i].Ngay_hoc = datePart
              this.Res.Data[i].Thu = this.Res.Data[i].Thu + 1
            }
          }
          this.ClassData = this.Res.Data
          this.dtTrigger.next();
          this.Title = ""
          this.TitleClass = ""
        }
        this.getListClass(this.requestObject, this.token)
        this.spinner.hide();
      }, errors => {
        this.AppService.logout();
        this.spinner.hide();
      });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  GetData(): void {
     
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.ToDay = $('#ToDay').val();
    this.FromDay = $('#FromDay').val();
    this.requestObject.ToDay = new Date(this.ToDay)
    this.requestObject.FromDay = new Date(this.FromDay)
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.getListSchedule(this.requestObject, a.access_token)
  }

  getListClass(req: RequestBase, token) {
    this.ClassService.getClass(req, token)
      .subscribe(z => {
        this.Res = z
        this.TableData = this.Res.Data
      }, errors => {
        this.AppService.logout();
        this.spinner.hide();
      });
  }

}
