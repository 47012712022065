import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { ResponseLogin } from "src/app/Models/output.model/ResponseLogin";
import { AppComponent } from "src/app/app.component";
import { common } from "src/app/app.common";
import { Router } from "@angular/router";
import { RequestTeachingScheduleList } from "src/app/Models/input.model/RequestTeachingScheduleList";
import { TeachingSchedule } from "src/app/Utils/services/TeachingSchedule.service";
import { ToastrService } from "ngx-toastr";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { ClassService } from "src/app/Utils/service/class.service";
import {
  ResponseClassListAll,
  ClassInfo,
  StudentTruant,
} from "src/app/Models/output.model/ResponseClassListAll";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from "src/app/Utils/services/app.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  dtOptions: DataTables.Settings;
  Res: ResponseClassListAll = new ResponseClassListAll();
  ClassDataTruant: [];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  // HocKy: number;
  // NamHoc: string;
  // MonHoc: string;
  Title: string = "Đang tải danh sách lớp...";
  TitleClass: string = "spinner-border text-muted";
  public LoginResult: ResponseLogin;
  public com: common;
  eventsModel: any;
  EventsData: any;
  ClassData: any;
  StudentTruant: any;
  ClassNumber: string;
  ClassNumberOnline: string;
  StudentsNumber: string;
  StudentsNumberOnline: string;
  requestObject: RequestTeachingScheduleList =
    new RequestTeachingScheduleList();
  request: RequestBase = new RequestBase();
  Token: any;
  constructor(
    public TeachingScheduleServirce: TeachingSchedule,
    public router: Router,
    private toastr: ToastrService,
    public cookieService: CookieService,
    private ClassService: ClassService,
    private spinner: NgxSpinnerService,
    private AppService: AppService
  ) {}

  dtTrigger = new Subject();
  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    this.GetSchedule();

    let a = this.com.getUserinfo();
    this.request.UserName = a.Info.UserName;

    this.Token = a.access_token;
    this.getListClass(this.request, this.Token);
    this.ClassNumber = "Đang kết nối";
    this.StudentsNumber = "Đang kết nối";
    this.ClassNumberOnline = "Đang kết nối";
    this.StudentTruant = "Đang kết nối";

    this.dtOptions = {
      pageLength: 25,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Hiển thị _MENU_ kết quả",
        emptyTable: "Chưa có dữ liệu điểm danh",
        info: "Xem _START_ đến _END_ trong  _TOTAL_ bản ghi",
        infoEmpty: "Xem 0 đến 0 trong 0 bản ghi",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "Tên môn",
        },
        {
          title: "Số tiết nghỉ",
          className: "dt-body-center",
        },
        {
          title: "Tên lớp",
        },
        {
          title: "Họ tên",
        },
        {
          title: "Mã sinh viên",
        },
      ],
      // "order": [[2, "desc"]]
      // lengthChange: false,
      searching: false,
      info: false,
      // paging: false,
      ordering: false,
      order: [4, "desc"],
    };
  }
  GetSchedule() {
    this.spinner.show();
    let curDate = new Date(Date.now());
    let ToDay = new Date(Date.now());
    let FromDay = new Date(
      curDate.getFullYear() - 10,
      curDate.getMonth(),
      curDate.getDate()
    );
    let a = this.com.getUserinfo();
    this.requestObject.UserName = a.Info.UserName;
    this.requestObject.FromDay = new Date(
      ToDay.getFullYear(),
      ToDay.getMonth(),
      ToDay.getDate()
    );
    this.requestObject.ToDay = new Date(
      FromDay.getFullYear(),
      FromDay.getMonth(),
      FromDay.getDate()
    );
    this.TeachingScheduleServirce.List(
      this.requestObject,
      a.access_token
    ).subscribe(
      (val) => {
        var lst = new Array();
        if (val.Status == 1) {
          this.EventsData = val;
          let dt = this.EventsData.Events;

          let i = 1;
          if (dt) {
            for (var j = 0; j < dt.length; j++) {
              var temp = dt[j];
              if (temp.dtSchedule && temp.dtSchedule.length > 0) {
                var dtSchedule = temp.dtSchedule[0];
                dt[j] = Object.assign(temp, dtSchedule);
              }
            }
          }
          var month = "" + (curDate.getMonth() + 1);
          var day = "" + curDate.getDate();
          var year = curDate.getFullYear();
          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;
          let curdaystring = [year, month, day].join("-");
          for (let index = 0; index < dt.length; index++) {
            if (dt[index].start.substring(0, 10) == curdaystring) {
              lst.push(dt[index]);
              var dateParts = dt[index].start
                .substring(0, 10)
                .split("-")
                .reverse()
                .join("/");
              dt[index].start = dateParts;
              i++;
            }
          }
          if (lst.length == 0) {
            lst.push("Ngày hôm nay không có lịch giảng nào");
          }
        } else {
          this.toastr.warning(val.Message);
        }
        this.eventsModel = lst;
        this.spinner.hide();
      },
      (errors) => {
        this.toastr.error("Hết phiên đăng nhập ! Xin kiểm tra lại");
        this.AppService.logout();
        this.spinner.hide();
      }
    );
  }
  getListClass(req: RequestBase, token) {
    try {
      this.ClassService.getClass(req, token).subscribe(
        (z) => {
          if (z.Status == 1) {
            this.getListClassOnline(this.request, this.Token);
            this.ClassData = z.Data;
            this.ClassNumber = z.Data.length.toString();
            var n = 0;
            for (let index = 0; index < z.Data.length; index++) {
              if (z.Data[index].So_sv != null) {
                n += z.Data[index].So_sv;
              }
            }
            this.StudentsNumber = n.toString();
          } else {
            this.toastr.error(z.Message);
          }
        },
        (errors) => {
          this.toastr.error("Hết phiên đăng nhập ! Xin kiểm tra lại");
          this.AppService.logout();
          this.spinner.hide();
        }
      );
    } catch (error) {}
  }
  getListClassOnline(req: RequestBase, token) {
    this.ClassService.getClassOnline(req, token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.getListStudentTruant(this.request, this.Token);
          this.StudentsNumberOnline = z.Data.reduce(
            (a: number, b) => a + b.StudentOnline,
            0
          );
          this.ClassNumberOnline = z.Data.length.toString();
        } else {
          this.toastr.error(z.Message);
        }
      },
      (errors) => {
        this.toastr.error("Hết phiên đăng nhập ! Xin kiểm tra lại");
        this.AppService.logout();
        this.spinner.hide();
      }
    );
  }

  getListStudentTruant(req: RequestBase, token) {
    this.ClassService.getStudentTruant(req, token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.ClassDataTruant = z.Data;
          this.dtTrigger.next();
        } else {
          this.toastr.error(z.Message);
        }
      },
      (errors) => {
        this.toastr.error("Hết phiên đăng nhập ! Xin kiểm tra lại");
        this.AppService.logout();
        this.spinner.hide();
      }
    );
  }
}
