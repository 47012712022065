<section class="content-header" style="padding:5px !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-8">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/class']">Lớp học</a></li>
                    <li class="breadcrumb-item active">{{TitleLop}}</li>
                    <li class="breadcrumb-item active">Danh sách sinh viên</li>
                </ol>
            </div>
            <div class="col-sm-4">
                <button (click)="Export_DanhSachLop()" style="float: right;;" class="btn btn-sm btn-info "><i
                        class="fas fa-file-excel"></i> Xuất Excel</button>
            </div>

        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content  cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" data-ordering="false"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of StudentsData">
                                            <td>{{ p.ID_sv }}</td>
                                            <td>{{ p.ID_dt }}</td>
                                            <td>{{ p.Ma_sv }}</td>
                                            <td>{{ p.Ho_ten }}</td>
                                            <td>{{ p.Ngay_sinh }}</td>
                                            <td>{{ p.Gioi_tinh }}</td>
                                            <td>{{ p.Ten_lop }}</td>
                                            <td>{{ p.Ten_khoa }}</td>
                                            <td>{{ p.ID_lop }}</td>
                                            <td><a [routerLink]="['/admin/create']" class="btn btn-default btn-sm"
                                                    [queryParams]="{Category: 3,ID:p.Ma_sv}"
                                                    queryParamsHandling="merge">
                                                    <i class="far fa-envelope cus-cr" title="Gửi tin nhắn"></i>
                                                </a></td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>
    <!-- /.row -->
</section>