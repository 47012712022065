
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppService } from 'src/app/Utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Class } from 'src/app/Utils/services/Class.service';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { ClassService } from 'src/app/Utils/service/class.service';
import { AttendenceService } from 'src/app/Utils/service/attendence.service';
import { ResponseClassListAll, ClassInfo } from 'src/app/Models/output.model/ResponseClassListAll';
import { ResponseListSchedule, ClassData } from 'src/app/Models/output.model/ResponseListSchedule';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { RequestTeachingScheduleList, RequestClassInfo } from 'src/app/Models/input.model/RequestTeachingScheduleList';
import { ResponseDiemDanhChiTiet } from 'src/app/Models/output.model/ResponseTeachingScheduleList';
import { ResponseBase } from 'src/app/Models/output.model/ResponseBase';
import { count } from 'rxjs/operators';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-attendence-day-sv',
  templateUrl: './attendence-day-sv.component.html',
  styleUrls: ['./attendence-day-sv.component.scss']
})
export class AttendenceDaySvComponent implements OnInit {
  dtOptions: DataTables.Settings
  Res: ResponseDiemDanhChiTiet = new ResponseDiemDanhChiTiet()
  Response: ResponseBase = new ResponseBase()
  ClassData: any
  requestObject: RequestClassInfo = new RequestClassInfo()
  requestObject1: RequestTeachingScheduleList = new RequestTeachingScheduleList()
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  IDLop: number;
  IDsv: string;
  Tiet: number;
  Loai_lop: any;
  IsAll: boolean;
  ID_mon: any;
  Title: string = "Đang tải danh sách điểm danh";
  TitleClass: string = "spinner-border text-muted";
  TitleTenSV: string
  TitleLop: string
  ID_lop: any;
  Ngay_thang: any;
  Ten_mon: any;
  req: any;
  
  constructor(
    private location: Location,
    public route: ActivatedRoute,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private ClassService: ClassService,
    private AttendenceService: AttendenceService,
    private AppService: AppService,
    public datepipe: DatePipe
  ) {
    // 

  }
  dtTrigger = new Subject();
  public com: common;
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [
        {
          title: 'STT', className: "dt-center",
        },
        {
          title: 'Mã sinh viên', className: "dt-center",
        },
        {
          title: 'Họ tên'
        },
        //{
        // title: 'Lần điểm danh'
        // },
        {
          title: 'Giới tính', className: "dt-center", visible: false
        },
        {
          title: 'Ngày sinh', className: "dt-center",
        },
        {
          title: 'Số tiết', className: "dt-center",
        },
        {
          title: 'Có lý do', className: "dt-center",
        },
        {
          title: 'Không lý do', className: "dt-center",
        },
        {
          title: 'Đi học muộn', className: "dt-center",
        },
        {
          title: 'Đi học', className: "dt-center",
        },
        {
          title: 'Ghi chú',
        }

      ],
    };
    this.IsAll = true
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.route.queryParams
      .subscribe(params => {

        this.IDLop = params.ID_lop
        this.IDsv = params.ID_sv
        this.TitleTenSV = params.Ho_ten
        this.TitleLop = params.Ten_lop
        this.Loai_lop = params.Loai_lop
        var dateParts = params.Ngay_thang.split("/");
        this.ID_mon = params.ID_mon,
          // this.Ngay_thang = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]),
          //this.Ngay_thang=this.toDate(params.Ngay_thang)
         
          this.Ngay_thang=this.datepipe.transform(this.toDate(params.Ngay_thang), 'MM/dd/yyyy');
          this.ID_lop = params.ID_lop,
          this.Ten_mon = params.Ten_mon
        this.req = {
          ID_lop: this.IDLop,
          ID_mon: this.ID_mon,
          Loai_lop: this.Loai_lop,
          Ngay_diem_danh: this.Ngay_thang
        }
        this.DiemDanhSVHocPhan(this.req, a.access_token)
      });
    this.requestObject.UserName = a.Info.UserName



  }
  DiemDanhSVHocPhan(req, token) {
    this.AttendenceService.DiemDanhNgayNienChe(req, token)
      .subscribe(z => {
        this.Res = z
        this.ClassData = this.Res.Data
        this.dtTrigger.next();
        this.Title = ""
        this.TitleClass = ""
      },
        errors => {
          this.toastr.error("ERROR: " + errors.message);
          this.AppService.logout();
        });
  }
  backClicked() {
    this.location.back();
  }
   toDate(dateStr) {
    var parts = dateStr.split("/")
    return new Date(parts[2], parts[1] - 1, parts[0])
  }
}

