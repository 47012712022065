<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row ">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a></li>
                    <li class="breadcrumb-item"><a [routerLink]="['/admin/blank']">Điểm môn học</a></li>
                    <li class="breadcrumb-item active">Nhập điểm thi lớp tín chỉ</li>
                </ol>
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>
<section class="content cus-body">
    <div class="card">
        <div class="card-header p-2 ">
            <div class="row" style="padding-left:10px; padding-right:10px">
                <div class="col-md-12">
                    <button class="btn btn-sm btn-primary" onclick="SaveMarkCredit()"><i class="fas fa-save"></i> Lưu điểm</button>
                    <button class="btn btn-sm btn-primary" onclick="ExportExcel()"><i class="fas fa-file-download"></i> Xuất excel</button>
                    <button class="btn btn-sm btn-outline-danger" onclick="KhoaDiem()"><i class="fas fa-lock"></i> Khóa điểm</button>
                </div>
            </div>
        </div><!-- /.card-header -->
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-md-12">
                            <div class="card card-primary card-outline">
                                <div class="card-header">
                                    <h3 class="card-title">Nhập điểm thi</h3>
                                    <div class="card-tools">
                                        <span style="font-size:20px"> Lần thi: </span>
                                        <select class="btn" id="Lan_thi" style="background-color:lightgray">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>
                                    <!-- /.card-tools -->
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-0">
                                    <div class="table-responsive mailbox-messages">
                                        <table class="table table-hover table-striped" id="lstStudents"></table>
                                        <!-- /.table -->
                                    </div>
                                    <!-- /.mail-box-messages -->
                                </div>
                                <!-- /.card-body -->
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>
                </div>
            </div>
            <!-- /.tab-content -->
        </div><!-- /.card-body -->
    </div>
    <!-- /.row -->
</section>
