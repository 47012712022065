import { Injectable, Inject } from "@angular/core";
import { AppConfig, AppConfiguration } from "src/configuration";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { environment } from "src/environments/environment";
import { map, filter, catchError } from "rxjs/operators";
import {
  ResponseClassListAll,
  ResponseStudentsClass,
  ResponseClassListAllOnline,
} from "src/app/Models/output.model/ResponseClassListAll";
import { BaseService } from "./base.service";
@Injectable({
  providedIn: "root",
})
export class DeclarationService extends BaseService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient
  ) {
    super();
  }

  getList(ID_cb, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl + "Declaration/ListAll?ID_gv=" + ID_cb,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  LopTinChi_Load(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.TeacherUrl + "Declaration/LopTinChi_Load", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  LopTinChi_Load_ID(ID_lop, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Declaration/LopTinChi_Load_ID?ID_lop=" +
          ID_lop,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  quyDinhKeKhai(req, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Declaration/QuyDinhKeKhai?Hoc_ky=" +
          req.Hoc_ky +
          "&Nam_hoc=" +
          req.Nam_hoc,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  QuyDinhDinhMuc(req, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Declaration/QuyDinhDinhMuc?ID_gv=" +
          req.ID_gv +
          "&Nam_hoc=" +
          req.Nam_hoc,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getListMonHoc(req, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Declaration/MonHoc", req, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  LoadMon(ID_mon, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl + "Declaration/LoadMon?ID_mon=" + ID_mon,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Update(request: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Declaration/Update ", request, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Delete(ID_cv_gd, ID_gv, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Declaration/Delete?ID_cv_gd=" +
          ID_cv_gd +
          "&ID_gv=" +
          ID_gv,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Insert(request: any, token): Observable<any> {
    return this.http
      .post<any>(this.appConfig.TeacherUrl + "Declaration/Insert", request, {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  LoaiMon(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.TeacherUrl + "Declaration/LoaiMon", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getHe(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "He/Danh_sach_he", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getKhoa(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Khoa/Danh_sach_khoa", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getKhoaHoc(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.UtilsUrl + "Core/KhoaHoc_Load_List", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getDanhSachLop(req, UserName, ID_mon, token, id_lop): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl +
          "Declaration/DanhSachLop?UserName=" +
          UserName +
          "&ID_mon=" +
          ID_mon+
          "&ID_lop=" +
          id_lop,
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  getSoSv(ID_lop, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl + "Declaration/SoSinhVien?ID_lop=" + ID_lop,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }

  multipleUpdate(req, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "Declaration/MultiUpdateStatus",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  Export_KeKhaiGiangDay(req: any, token): Observable<any> {
    return this.http
      .post<any>(
        this.appConfig.TeacherUrl + "BaoCao/ExportToExcel_KeKhaiGiangDay",
        req,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  GiangVien_Load_ID(ID_gv, token): Observable<any> {
    return this.http
      .get<any>(
        this.appConfig.TeacherUrl +
          "Declaration/GiangVien_Load_ID?ID_gv=" +
          ID_gv,
        {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        }
      )
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
  ThoiGianKeKhai(token): Observable<any> {
    return this.http
      .get<any>(this.appConfig.TeacherUrl + "Declaration/ThoiGianKeKhai", {
        headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
      })
      .pipe(
        catchError(this.handleError),
        map((z) => {
          return z;
        })
      );
  }
}
